import React, { useState, useEffect, useRef } from "react";
import { confirmAlert } from "react-confirm-alert";
import { useTranslation } from "react-i18next";
import { connect, useSelector, useDispatch } from "react-redux";
// import { pullPages, pageNoChange } from "../actions/pages";
import { deleteButtonService } from "../services/api";
import DeleteButton from "./SketchSelectedButtons/DeleteButton";
// import ExitButton from "./SketchSelectedButtons/ExitButton";
import { deleteActivity, editActivity } from "../actions/addActivity";
import { showAnnotationsById } from "../actions/annotationSelection";

const BottomDeleteMenu = (props) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const deleteClickHandler = () => {
    if (props.annotationIdsToShow.length !== 1) {
      return;
      // toastWarning("Sorry no ....");
    }
    const id = props.annotationIdsToShow[0];
    confirmAlert({
      title: t("Delete Activity"),
      message: t("Are you sure you want to delete this content?"),
      buttons: [
        {
          label: t("Yes"),
          onClick: () => {
            setIsLoading(true);
            deleteButtonService(String(props.domain).toLowerCase(), id)
              .then(async (response) => {
                console.log("response FORMMM", response);
                await props.deleteActivity(id);
                await setIsLoading(false);
                await props.showAnnotationsById(id);
                // closeModal();
              })
              .catch((error) => {
                console.log("logged error", error);
                setIsLoading(false);

                // closeModal();
              });
          },
        },
        {
          label: t("No"),
          onClick: () => {
            setIsLoading(false);
          },
        },
      ],
    });
  };
  return (
    <div
      className={`navbar is-fixed-bottom  bottom-annotation-menu ${
        (props.shouldMenuOpened && props.selectedTool!=='curtain') ? "open" : "hide"
      } `}
    >
      <DeleteButton isLoading={isLoading} deleteContent={deleteClickHandler} />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    annotationIdsToShow: state.annotationSelections.annotationIdsToShow,
    selectedTool: state.selectedTool.selectedTool,
  };
};

export default connect(mapStateToProps, {
  deleteActivity,
  editActivity,
  showAnnotationsById,
})(BottomDeleteMenu);
