import React from "react";
import { useTranslation } from "react-i18next";

export default function MRQuestionButton(props) {
  const { t, i18n } = useTranslation();
  const { object } = props;
  const transform = "scale(" + object.scale + ")";
  return (
    <div
      className="interactive-object"
      style={{ transform, "transformOrigin": "left top" }} >
      <span className="object-icon">
        <svg
          width={object.width}
          height={object.height}
          viewBox={`0 0 ${object.width} ${object.height}`}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx={object.width / 2}
            cy={object.height / 2}
            r={object.width / 2}
            fill="white"
            stroke="#FD6D87"
            strokeWidth="0" // Was 2 = Mustafa changed to 0
          />
          <path
            d="M14.0858 8.13577C13.687 7.57859 12.8585 7.57859 12.4596 8.13577L11.3031 9.75122C11.0539 10.0993 11.0539 10.5674 11.3031 10.9154L12.3398 12.3635C12.5413 12.645 12.4856 13.0351 12.2135 13.249C11.9237 13.4766 11.5029 13.4171 11.2883 13.1175C10.7464 12.3612 10.1184 11.4839 9.63124 10.8031C9.23236 10.2457 8.40372 10.2456 8.00472 10.8029L6.13266 13.4179C5.6589 14.0796 6.1319 15 6.94577 15H17.0543C17.8681 15 18.3411 14.0796 17.8674 13.4179L14.0858 8.13577Z"
            fill="#FD6D87"
          />
          <path
            d="M10.5 8.25C10.5 8.94036 9.94037 9.5 9.25001 9.5C8.55965 9.5 8.00001 8.94036 8.00001 8.25C8.00001 7.55964 8.55965 7 9.25001 7C9.94037 7 10.5 7.55964 10.5 8.25Z"
            fill="#FD6D87"
          />
        </svg>
      </span>
      <span className="object-text">{object.description==null? t("Question") :object.description}</span>
    </div>
  );
}
