import React, { Component } from "react";
// import CustomModal from "./CustomModal";
import NewModal from "./NewModal";
import "flexboxgrid";
import "./Sketch/main.css";
import Sketch from "./Sketch/NewSketch";
import { setScalePageDown } from "../actions/scalePageDown";
import { setScalePageUp } from "../actions/scalePageUp";
import { setScalePageReset } from "../actions/scalePageReset";
import { pageNoChange } from "../actions/pages";
import { connect } from "react-redux";
import { toggleCapture } from "../actions/captureStatus";
import Loading from "./Loading";
import VideoButton from "./SubComponents/VideoButton";
import QButton from "./SubComponents/QButton";
import AudioButton from "./SubComponents/AudioButton";
import ImageButton from "./SubComponents/ImageButton";
import H5pButton from "./SubComponents/H5pButton";
import H5pAnswerButton from "./SubComponents/H5pAnswerButton";
import AnswerButton from "./SubComponents/AnswerButton";
import MCQuestionButton from "./SubComponents/MCQuestionButton";
import MRQuestionButton from "./SubComponents/MRQuestionButton";
import LinkButton from "./SubComponents/LinkButton";
import TextButton from "./SubComponents/TextButton";
// import MCQuestion from "./Quiz/MCQuestion";
// import ShowQuestion from "./Quiz/ShowQuestion";
import ShowMCQuestionForm from "./AddButton/ShowMCQuestionForm";
import { mcQuestionModalOpen, mcQuestionModalClose } from "../actions/quiz";
import Draggable from "react-draggable";
import { updateButtonService } from "../services/api";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { confirmMessages } from "../services/constants";
import {
  triggerZoomIn,
  triggerZoomOut,
  setPagePartZoomMode,
  setPagePartZoomObjectToShow,
} from "../actions/settings";

import {
  bookmarkPage,
  isPageBookmarked,
  unBookmarkPage,
} from "../services/util";
import AddButton from "./AddButton/AddButton";
import { isMobile } from "react-device-detect";
import { toggleSideBar } from "../actions/sidebar";
import { toggleSearchBar } from "../actions/searchbar";
import { showAnnotationsById } from "../actions/annotationSelection";
import ZoomButton from "./SubComponents/ZoomButton";
import LoadingAnim from "./LoadingAnim";

class Page extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      modalData: {},
      pageNumber: this.props.pageNumber,
      buttonSizeFactor: 1,
      zoomClass: "zoomPage1",
      _isMounted: false,
      buttonsDragging: false,
      isPageBookmarked: isPageBookmarked(this.props.pageNumber),
      activeDrags: 0,
      recordNewButtonCoordinats: false,
      deltaPosition: {
        x: 0,
        y: 0,
      },
      controlledPosition: {
        x: -400,
        y: 200,
      },
      newCoords: {
        newLeft: null,
        newTop: null,
        objId: null,
      },
      localBackgroundOpacity: 1,
    };
  }

  onLoadImage = () => {
    this.setState({ imageIsReady: true });
  };

  // toggles modal
  toggleModal = (e, modalData) => {
    // console.log("==================modalData");
    if (!this.state.buttonsDragging) {
      this.setState({ modalIsOpen: !this.state.modalIsOpen, modalData });
    } else {
      console.log("bLocked", this.state.buttonsDragging);
    }
  };
  openModal = (e, modalData) => {
    // console.log(
    //   "object.button_type",
    //   modalData.button_type !== "questionHideable",
    //   modalData.button_type
    // );
    if (modalData.button_type === "questionHideable") {
      //   console.log(
      //     "this.props.annotationIdToShow , this.props.annotationIdToShow , modalData.id",
      //     this.props.annotationIdToShow,
      //     this.props.annotationIdToShow,
      //     modalData.id
      //   );
      // if (this.props.annotationIdToShow === modalData.id) {
      //   if (this.props.annotationIdToShow) {
      //     this.props.showAnnotationsById(null);
      //     // this.props.showAnnotationsById(modalData.id);
      //   } else {
      //     // this.props.showAnnotationsById(null);
      //   }
      // } else {
      this.props.showAnnotationsById(modalData.id);
      // }
      return;
    }
    if (modalData.button_type === "zoomPagePart") {
      // console.log("zoompagePart", modalData);
      this.props.setPagePartZoomMode("zoom-show");
      const { orderId, downCoords, upCoords, cutCoords } = JSON.parse(
        modalData.content
      );
      this.props.setPagePartZoomObjectToShow(
        orderId,
        downCoords,
        upCoords,
        cutCoords
      );
      // if (this.props.annotationIdToShow === modalData.id) {
      //   this.props.showAnnotationsById(null);
      //   this.props.showAnnotationsById(modalData.id);
      // } else {
      //   this.props.showAnnotationsById(modalData.id);

      return;
    }
    // console.log("????");
    if (!this.state.buttonsDragging) {
      this.setState({ modalIsOpen: true, modalData });
    } else {
      console.log("bLocked", this.state.buttonsDragging);
    }
  };
  closeModal = (e, modalData) => {
    // console.log("Modal closed!!!");
    this.setState({ modalIsOpen: false, modalData });
  };
  componentDidMount(prev) {
    this.setState({ _isMounted: true });
    this.props.onChangeTool("pan");
    this.onClickDrag([0, 0]);
    if (this.props.captured) this.props.toggleCapture();
  }
  componentWillUnmount() {
    this.setState({ _isMounted: false });
  }
  renderObjectIcon(object, index) {
    object.width = 24;
    object.height = 24;

    switch (object.button_type) {
      case "video":
        return <VideoButton object={object} key={index} />;

      case "audio":
        return <AudioButton object={object} />;

      case "image":
        return <ImageButton object={object} />;

      case "h5p":
        return <H5pButton object={object} />;

      case "h5p-answer":
        return <H5pAnswerButton object={object} />;

      case "link":
        return <LinkButton object={object} />;

      case "text":
        return <TextButton object={object} key={index} />;

      case "answer":
        return <AnswerButton object={object} />;

      case "mc_question":
        return <MCQuestionButton object={object} />;

      case "mr_question":
        return <MRQuestionButton object={object} />;

      case "words":
        return <div className="dOff"></div>;

      case "questionHideable":
        return <QButton object={object} />;

      case "zoomPagePart":
        object.style = "style1";
        return <ZoomButton object={object} />;

      default:
        return;
    }
  }

  onClickZoomIn() {
    this.props.setScalePageUp(this.props.scaleZoomFactorVal.scaleFactor);
  }
  onClickDrag(params) {
    let paGe = document.getElementById("pdfPageList");
    paGe.scrollTop = params[0];
    paGe.scrollLeft = params[1];
    return;
  }
  onClickZoomOut() {
    this.props.setScalePageDown(this.props.scaleZoomFactorVal.scaleFactor);
  }
  onClickZoomReset() {
    this.props.setScalePageReset();
  }
  handleStart = () => {};
  handleDrag = (e, ui) => {
    const { x, y } = this.state.deltaPosition;

    // console.log("HANDLE DRAGGING", this.state.buttonsDragging);
    if (!this.state.buttonsDragging) {
      this.setState({
        buttonsDragging: true,
        deltaPosition: {
          x: x + ui.deltaX,
          y: y + ui.deltaY,
        },
      });
    }
  };
  submitCooords = (ownActivity) => {
    // console.log('ownActivity', ownActivity)
    if (ownActivity) {
      this.submit(confirmMessages.title, confirmMessages.message);
    }
  };
  handleStop = (e, ui, object) => {
    const myRatio =
      this.props.canvasVals.canvasBackgroundImageWidth /
      this.props.canvasVals.canvasWidth; //ratio of the book page
    const originRatio = 1000 / this.props.canvasVals.canvasBackgroundImageWidth; //ratio of the book editor - 1000
    const oldLeft = myRatio * parseInt(ui.lastX) * originRatio; // * props.canvasVals.vpW) / 1000, // -
    const oldTop = myRatio * parseInt(ui.lastY) * originRatio; // * props.canvasVals.vpW) / 1000, // -
    const newLeft = parseFloat(object.left) + oldLeft;
    const newTop = parseFloat(object.top) + oldTop;

    if (ui.deltaX !== 0 || ui.deltaY !== 0) {
      // console.log('ui.deltaX !== 0 || ui.deltaY!==0', ui.deltaX !== 0 , ui.deltaY!==0, ui.deltaX !== 0 || ui.deltaY!==0)
      this.setState(
        {
          newCoords: { newLeft, newTop, objId: object.id },
        },

        this.submitCooords(object.own_activity)
      );
    } else {
      this.openModal(e, object);
    }
    if (this.state.buttonsDragging) {
      setTimeout(() => {
        console.log("timeout done");
        this.setState({ buttonsDragging: false });
      }, 500);
    }
  };

  sendNewCoord = () => {
    const { newLeft, newTop, objId } = this.state.newCoords;
    // console.log("newLeft, newTop, objId", newLeft, newTop, objId);
    if (this.state.recordNewButtonCoordinats) {
      const formData = new FormData();
      formData.append("left", parseInt(newLeft));
      formData.append("top", parseInt(newTop));
      // console.error("____formData", formData);
      updateButtonService(
        formData,
        String(this.props.domain).toLowerCase(),
        objId
      )
        .then(async (response) => {
          console.log("response FORMMM", response);
        })
        .catch((error) => {
          console.log("logged error", error);
        });
    }
  };

  renderBookmarkIcon = (data) => {
    const top = 0;
    const left = this.props.canvasVals.canvasWidth - 40 || -40;

    let scaleProperty = this.props.canvasVals
      ? this.props.canvasVals.canvasWidth / 1000
      : 1;

    let transform = "scale(" + scaleProperty + ")";
    return (
      <button
        className={`bookmark  ${this.state.isPageBookmarked ? "active" : ""}

               `}
        style={{ top, left }}
        data-tip={"Bookmark"}
        onClick={() => {
          if (this.state.isPageBookmarked) {
            unBookmarkPage(data.pageNumber);
            this.setState({ isPageBookmarked: false });
          } else {
            bookmarkPage(data.pageNumber);
            this.setState({ isPageBookmarked: true });
          }
        }}
      >
        <span className="icon bookmark-svg-container">
          <svg
            // width="80px"
            className="bookmark-svg"
            // height="109px"
            viewBox="0 0 80 109"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xlink="http://www.w3.org/1999/xlink"
          >
            <g
              id="Page-1"
              stroke="none"
              strokeWidth="1"
              fill="none"
              fillRule="evenodd"
            >
              <g
                id="insignia"
                fillRule="nonzero"
                stroke="#000000"
                strokeWidth="4"
              >
                <path
                  d="M5,2 C3.34314575,2 2,3.34314575 2,5 L2,103.369193 C2,104.067698 2.24374108,104.74431 2.68919042,105.282347 C3.74579632,106.558572 5.63692933,106.736608 6.91315422,105.680002 L35.1838721,82.2742473 C37.7546673,80.145847 41.4705424,80.1284281 44.0611788,82.2326331 L73.1085942,105.825954 C73.643288,106.260251 74.3111522,106.49731 75,106.49731 C76.6568542,106.49731 78,105.154164 78,103.49731 L78,5 C78,3.34314575 76.6568542,2 75,2 L5,2 Z"
                  id="Rectangle"
                ></path>
              </g>
            </g>
          </svg>
        </span>
      </button>
    );
  };

  renderInteractiveButtons = (data) => {
    const zoomMode =
      this.props.pageZoomPartObjectToShow.orderId &&
      this.props.pageZoomPartMode === "zoom-show";

    // const {
    //   orderId,
    //   downCoords,
    //   upCoords,
    //   cutCoords,
    // } = this.props.pageZoomPartObjectToShow;
    // const myRatio =
    //   this.props.canvasVals.canvasBackgroundImageWidth /
    //   this.props.canvasVals.canvasWidth; //ratio of the book page
    // const originRatio = 1000 / this.props.canvasVals.canvasBackgroundImageWidth; //ratio of the book editor - 1000

    // const fullScale = zoomMode
    //   ? Math.min(
    //       this.props.canvasVals.canvasWidth /
    //         (this.props.pageZoomPartObjectToShow.upCoords.x *
    //           this.props.scaleZoomFactorVal.scaleFactor),
    //       (window.innerHeight - 50) /
    //         (this.props.pageZoomPartObjectToShow.upCoords.y *
    //           this.props.scaleZoomFactorVal.scaleFactor)
    //     )
    //   : 1;
    // const {
    //   pageZoomPartFullScaleVal: fullScale,
    //   pageZoomPartInitialScaleX: scaleX,
    // } = this.props.settings;
    const fullScale = this.props.settings.pageZoomPartFullScaleVal;
    const scaleX = this.props.settings.pageZoomPartInitialScaleX;
    const difference = this.props.scaleZoomFactorVal.canvas.scaleX;
    const difference2 = this.props.scaleZoomFactorVal.canvas.scaleX / scaleX;
    let scaleProperty = this.props.canvasVals
      ? !zoomMode
        ? this.props.canvasVals.canvasWidth / 1300
        : // : (this.props.canvasVals.canvasWidth / 1300) * fullScale/difference //
          (this.props.canvasVals.canvasBackgroundImageWidth *
            fullScale *
            (difference / scaleX)) /
          1300 //* fullScale/difference //
      : 1;
    // console.log(
    //   `this.props.canvasVals.canvasWidth`,
    //   this.props.canvasVals.canvasWidth
    // );
    // console.log(
    //   `fullScale *
    //         (difference / scaleX))`,
    //   fullScale * (difference / scaleX)
    // );
    // console.log(
    //   `fullScale *
    //         (difference / scaleX))`,
    //   (this.props.canvasVals.canvasBackgroundImageWidth *
    //     fullScale *
    //     (difference / scaleX)) /
    //     1300
    // );
    // console.log(`scaleProperty`, scaleProperty);
    scaleProperty = scaleProperty > 2 ? 2 : scaleProperty;
    scaleProperty = isMobile && scaleProperty> 1.5 ? 1 : scaleProperty;
    // console.log(`fullScale`, fullScale);
    // console.log(`this.props`, this.props);
    // console.log(`scaleX`, scaleX);
    // console.log(`difference`, difference);
    // console.log(
    //   `scaleProperty`,
    //   scaleProperty,
    //   ((this.props.canvasVals.canvasWidth / 1300) * fullScale) / difference
    // );
    const buttons = data.buttons.map((object, index) => {
      // console.log(`scaleProperty`, scaleProperty)
      const buttonShow = zoomMode
        ? object.button_type === "zoomPagePart"
          ? false
          : true
        : true;
      // console.log(`scaleProperty/difference`, scaleProperty/difference)
      // console.log(`fullScale/difference`, fullScale/difference)
      let bLeft =
        (parseInt(object.left) * this.props.canvasVals.canvasWidth) / 1000 ||
        -300;
      const zoomAreaLeft = zoomMode
        ? this.props.pageZoomPartObjectToShow.downCoords.x * difference
        : 1;
      const zoomAreaWidth = zoomMode
        ? zoomAreaLeft +
          this.props.pageZoomPartObjectToShow.upCoords.x * difference
        : 1;
      let left = zoomMode
        ? (((bLeft - zoomAreaLeft) * fullScale) /
            this.props.scaleZoomFactorVal.canvas.scaleX) *
          difference2
        : bLeft;
      let bTop =
        (parseInt(object.top) * this.props.canvasVals.canvasWidth) / 1000 ||
        -300;
      const zoomAreaTop = zoomMode
        ? this.props.pageZoomPartObjectToShow.downCoords.y * difference
        : 1;
      const zoomAreaHeight = zoomMode
        ? zoomAreaTop +
          this.props.pageZoomPartObjectToShow.upCoords.y * difference
        : 1;
      let top = zoomMode
        ? (((bTop - zoomAreaTop) * fullScale) /
            this.props.scaleZoomFactorVal.canvas.scaleX) *
          difference2
        : bTop;
      const isHiddenForZoom = !zoomMode
        ? false
        : !buttonShow
        ? false
        : bLeft > zoomAreaWidth ||
          bLeft < zoomAreaLeft ||
          bTop > zoomAreaHeight ||
          bTop < zoomAreaTop;
      //     console.log(`buttonShow`, buttonShow, object.button_type)
      //     console.log(`isHiddenForZoom`, isHiddenForZoom, object.button_type)
      // console.log("bLeft > zoomAreaWidth", bLeft > zoomAreaWidth);
      // console.log("bLeft < zoomAreaLeft", bLeft < zoomAreaLeft);
      // console.log("bTop > zoomAreaHeight", bTop > zoomAreaHeight);
      // console.log("bTop < zoomAreaTop", bTop < zoomAreaTop);

      // console.log(`zoomAreaTop`, zoomAreaTop);
      // console.log(
      //   `parseInt(object.top) , this.props.canvasVals.canvasWidth`,
      //   parseInt(object.top),
      //   this.props.canvasVals.canvasWidth
      // );
      // console.log(
      //   "bTop,zoomAreaTop,fullScale,this.props.scaleZoomFactorVal.canvas.scaleX,difference2",
      //   bTop,
      //   zoomAreaTop,
      //   fullScale,
      //   this.props.scaleZoomFactorVal.canvas.scaleX,
      //   difference2
      // );
      let transform = "scale(" + scaleProperty + ")";
      object.scale = scaleProperty;

      return (
        <>
          {this.props.moveButtonsVal && !isMobile ? (
            <Draggable
              allowAnyClick={false}
              axis="both"
              handle=".handle"
              defaultPosition={{ x: 0, y: 0 }}
              position={null}
              grid={[2, 2]}
              scale={1}
              onStart={this.handleStart}
              onDrag={this.handleDrag}
              onStop={(e, ui) => this.handleStop(e, ui, object)}
              disabled={!this.props.moveButtonsVal}
              key={index}
              // onStop={this.handleStop}
            >
              <div
                className={`interactive-object-area handle ${
                  object.button_type
                }-object ${object.style} ${
                  this.props.hideObjects || isHiddenForZoom
                    ? "hide-element"
                    : ""
                } ${object.button_type === "words" ? "hide-element" : ""}`}
                onClick={(e) => {
                  !this.props.moveButtonsVal && this.openModal(e, object);
                }}
                style={{
                  top,
                  left,
                  transform,
                }}
                key={index}
              >
                {buttonShow && this.renderObjectIcon(object, index)}
              </div>
            </Draggable>
          ) : (
            <div
              className={`interactive-object-area handle ${
                object.button_type
              }-object ${object.style} ${
                this.props.hideObjects || isHiddenForZoom ? "hide-element" : ""
              } ${object.button_type === "words" ? "hide-element" : ""}`}
              onClick={(e) => this.openModal(e, object)}
              style={{ top, left, transform }}
              key={index}
            >
              {buttonShow && this.renderObjectIcon(object)}
            </div>
          )}
        </>
      );
    });

    return buttons;
  };

  interactiveButtonsSizeChanged = (buttonSizeFactor) => {
    if (this.state._isMounted) {
      this.setState({ buttonSizeFactor: buttonSizeFactor });
    }
  };

  submit = (title, message) => {
    confirmAlert({
      title: title,
      message: message,
      buttons: [
        {
          label: "Yes",
          onClick: () =>
            this.setState(
              { recordNewButtonCoordinats: true },
              this.sendNewCoord
            ),
        },
        {
          label: "No",
          onClick: () => this.setState({ recordNewButtonCoordinats: false }),
        },
      ],
    });
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (
      this.props.settings.zoomIn &&
      this.props.settings.zoomIn !== prevProps.settings.zoomIn
    ) {
      this.onClickZoomIn();
      this.props.triggerZoomIn(false);
    }
    if (
      this.props.settings.zoomOut &&
      this.props.settings.zoomOut !== prevProps.settings.zoomOut
    ) {
      this.onClickZoomOut();
      this.props.triggerZoomOut(false);
    }
    if (
      this.props.backgroundImageOpacity !== prevProps.backgroundImageOpacity
    ) {
      console.log("DID UPDATE     BAG", this.props.backgroundImageOpacity);
      this.setState({
        localBackgroundOpacity:
          this.props.backgroundImageOpacity === 1
            ? ""
            : this.props.backgroundImageOpacity === 0
            ? "bg-fade-0"
            : "bg-fade-05",
      });
    }
  };

  closeBars = () => {
    if (isMobile) {
      if (this.props.sideBarOpened) {
        // console.log("tik tik");
        this.props.toggleSideBar(this.props.sideBarOpened);
      }
      if (this.props.searchBarOpened) {
        this.props.toggleSearchBar(this.props.searchBarOpened);
      }
    }
  };

  render() {
    const data = this.props.data;
    let { modalData } = this.state;
    return (
      <div
        className={`${this.props.scaleZoomFactorVal.scaleCss}`}
        key={this.props.pageNumber}
      >
        {/* {!this.props.settings.pageZoomPartShowLoading && <LoadingAnim />} */}

        {modalData ? (
          <NewModal
            isOpen={this.state.modalIsOpen}
            toggleModal={(e) => this.closeModal(e)}
            data={modalData}
          />
        ) : (
          ""
        )}
        <li
          className={"pdf-page " + this.state.localBackgroundOpacity}
          id={data.pageNumber.toString()}
          key={this.props.pageNumber}
          onClick={this.closeBars}
        >
          <figure className="image">
            {!this.props.imagesLoaded ? <Loading /> : ""}
          </figure>
          <Sketch
            image={data}
            sketchSettings={this.props.sketchSettings}
            sidebarIsOpened={this.props.sidebarIsOpened}
            onChangeTool={(param) => this.props.onChangeTool(param)}
            onClickDrag={(param) => this.onClickDrag(param)}
            interactiveButtonsSizeChanged={this.interactiveButtonsSizeChanged}
            pageNumber={this.props.pageNumber}
          />
          {!this.props.hideInteractiveObjectsVal &&
            !this.props.settings.pageZoomPartShowLoading &&
            this.renderInteractiveButtons(data)}
          {!this.props.annotationSelectionMode &&
            this.props.pageZoomPartMode !== "zoom-show" &&
            this.renderBookmarkIcon(data)}
        </li>
        <AddButton
          isOpen={this.state.modalIsOpen}
          toggleModal={(e) => this.toggleModal(e)}
        />
        <ShowMCQuestionForm
          isOpen={this.state.modalIsOpen}
          toggleModal={(e) => this.toggleModal(e)}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentPageNumber: state.pages.currentPageNo,
    currentPageId: state.pages.currentPage[0].id,
    currentPageState: state.pages,
    scaleZoomFactorVal: state.scaleZoomFactor,
    imagesLoaded: state.imagesLoaded,
    hideInteractiveObjectsVal: state.hideInteractiveObjects,
    canvasVals: state.scaleZoomFactor.canvas,
    domain: state.login.user_profile.domain_name,
    moveButtonsVal: state.settings.moveButtons,
    sideBarOpened: state.sideBarOpened,
    backgroundImageOpacity: state.settings.backgroundImageOpacity,
    selectedTool: state.selectedTool.selectedTool,
    captured: state.captured,
    settings: state.settings,
    searchBarOpened: state.searchBarOpen,
    annotationSelectionMode: state.annotationSelections.annotationSelectionMode,
    annotationIdToShow: state.annotationSelections.annotationIdToShow,
    pageZoomPartMode: state.settings.pageZoomPartMode,
    pageZoomPartObjectToShow: state.settings.pageZoomPartObjectToShow,
  };
};

export default connect(
  mapStateToProps,
  {
    setScalePageDown,
    setScalePageReset,
    setScalePageUp,
    pageNoChange,
    toggleCapture,
    mcQuestionModalOpen,
    mcQuestionModalClose,
    triggerZoomIn,
    triggerZoomOut,
    toggleSideBar,
    toggleSearchBar,
    showAnnotationsById,
    setPagePartZoomMode,
    setPagePartZoomObjectToShow,
  },
  null,
  { forwardRef: true }
)(Page);
