import React from "react";
import { useTranslation } from "react-i18next";

export default function ImageButton(props) {
  const { t, i18n } = useTranslation();
  const { object } = props;
  const transform = "scale(" + object.scale + ")";
  return (
    <div
      className="interactive-object-2"
      style={{ transform, transformOrigin: "left top" }}
    >
      <span className="object-icon icon-only">
        <svg
          width={object.width}
          height={object.height}
          viewBox={`0 0 ${object.width} ${object.height}`}
          fill="none"
          // fill="white"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle  cx={object.width / 2}
            cy={object.height / 2}
            r={object.width / 2} fill="#C4C4C4" />
          <path
            d="M14.5682 14.1591L17.2451 16.8353L16.3607 17.7197L13.6845 15.0428C12.6887 15.8411 11.4501 16.2753 10.1738 16.2734C7.06883 16.2734 4.54883 13.7534 4.54883 10.6484C4.54883 7.54344 7.06883 5.02344 10.1738 5.02344C13.2788 5.02344 15.7988 7.54344 15.7988 10.6484C15.8006 11.9247 15.3665 13.1633 14.5682 14.1591ZM9.54883 10.0234H7.67383V11.2734H9.54883V13.1484H10.7988V11.2734H12.6738V10.0234H10.7988V8.14844H9.54883V10.0234Z"
            fill="white"
          />
        </svg>
      </span>
      {/* <span className="object-text">{t("Image")}</span> */}
    </div>
  );
}
