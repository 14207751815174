import {GET_MERRIAM_MODAL,CLOSE_MERRIAM_MODAL} from "./actionTypes";

export const getMerriamModal = () => {
    return {
        type: GET_MERRIAM_MODAL
    }
};


export const closeMerriamModal = () => {
    return {
        type: CLOSE_MERRIAM_MODAL
    }
};