import React from "react";
import { useTranslation } from "react-i18next";

export default function PinToolbar(props) {
  const { t } = useTranslation();

  return (
    <li onClick={props.onClick}>
      <span
        className={`button normal-btn toolbar-pin ${
          props.activeButton === "toolbar-pin" ? "active" : ""
        }`}
        title={t("Toolbar Pin")}
      >
        <img
          src={require("../../assets/img/svg/toolbar-pin.svg")}
          alt={t("Toolbar Pin")}
        />
      </span>
    </li>
  );
}
