import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { connect, useSelector, useDispatch } from "react-redux";
import { toggleSideBar, sideBarOpen, sideBarClose } from "../actions/sidebar";
import { toggleSearchBar } from "../actions/searchbar";
import { pageNoChange } from "../actions/pages";
// import { pullPages, pageNoChange } from "../actions/pages";
import QuestionButton from "./SketchSelectedButtons/QuestionButton";
import CopyButton from "./SketchSelectedButtons/CopyButton";
import NextZoomPagePartButton from "./SketchSelectedButtons/NextZoomPagePartButton";
import PreviousZoomPagePartButton from "./SketchSelectedButtons/PreviousZoomPagePartButton";
import Timer from "./SketchSelectedButtons/Timer";
import ExitButton from "./SketchSelectedButtons/ExitButton";
import ApprovZoomPagePartButtons from "./SketchSelectedButtons/ApprovZoomPagePartButtons";
import CutPagePartButton from "./SketchSelectedButtons/CutPagePartButton";
import {
  hideInteractiveObjects,
  showInteractiveObjects,
} from "../actions/interactiveObjects";
import {
  setAnnotationSelectionMode,
  setAnnotationSelectionCoords,
  saveAnnotationSelectionToButton,
  resetAnnotationSelectionMode,
  setEditSelectionMode,
  setSelectionCopyMode,
  setSelectionPasteMode,
  setSelectionRemoveMode,
  setSelectionUndoMode,
  setSelectionRedoMode,
  setAnnotationBeforeState,
} from "../actions/annotationSelection";
import {
  setPagePartZoomMode,
  setPagePartZoomObjectToShow,
  setPagePartZoomObject,
  setPageZoomPartShowLoading
} from "../actions/settings";

import "react-toastify/dist/ReactToastify.css";

import {
  captureInProcess,
  hideCaptureImage,
  captureNotInprocess,
} from "../actions/imageCapture";

import { insertActivity } from "../actions/addActivity";
import NextPageButton from "./SketchSelectedButtons/NextPageButton.js";
import PreviousPageButton from "./SketchSelectedButtons/PreviousPageButton";

const BottomMenu = (props) => {
  const exitHandler = () => {
    props.setPagePartZoomObject(
      null,
      { x: null, y: null },
      { x: null, y: null }
    );
    exitZoomMode(props);
  };
  const approveHandler = async () => {
    await props.saveZoomButton();
    exitZoomMode(props);
  };
  const { t, i18n } = useTranslation();
  if (!props.currentPage[0] || !props.currentPage[0].buttons) return <></>;
  const qZoomButtons = props.currentPage[0].buttons
    .filter((bt) => bt.button_type === "zoomPagePart")
    .map((btz) => {
      return JSON.parse(btz.content);
    })
    .sort((a,b)=>b.orderId-a.orderId)
    // .reverse();
  // console.log("qZoomButtons", qZoomButtons);
  const currentZoomObjectOrderId = parseInt(
    props.pageZoomPartObjectToShow.orderId
  );
  let currentZoomButtonId = qZoomButtons.findIndex(
    (qz) => parseInt(qz.orderId) === currentZoomObjectOrderId
  );
  // console.log("currentZoomObjectOrderId", currentZoomObjectOrderId);
  // console.log("currentZoomButtonId", currentZoomButtonId);
  // console.log(
  //   "props.pages",
  //   props.pages[parseInt(props.currentPageNumber - 1)],
  //   props.pages
  // );
  const prevPageButtons =
    props.currentPageNumber > 1 &&
    props.pages[parseInt(props.currentPageNumber - 1)]?.buttons
      ? props.pages[props.currentPageNumber - 2].buttons
          .filter((bt) => bt.button_type === "zoomPagePart")
          .map((btz) => {
            return JSON.parse(btz.content);
          })
          .reverse()
      : null;
  const nextPageButtons = props.pages[parseInt(props.currentPageNumber)]
    ?.buttons
    ? props.pages[parseInt(props.currentPageNumber)].buttons
        .filter((bt) => bt.button_type === "zoomPagePart")
        .map((btz) => {
          return JSON.parse(btz.content);
        })
        .reverse()
    : null;
  // console.log(
  //   "nextPageButtons",
  //   parseInt(props.currentPageNumber) + 1,
  //   prevPageButtons,
  //   nextPageButtons
  // );
  const showNextButton =
    props.pageZoomPartMode === "zoom-show" &&
    (currentZoomButtonId !== 0 || nextPageButtons);
  const showPrevButton =
    props.pageZoomPartMode === "zoom-show" &&
    (currentZoomButtonId !== qZoomButtons.length - 1 || prevPageButtons);
  // parseInt(qZoomButtons[qZoomButtons.length - 1].orderId) >
  //   currentZoomObjectOrderId;
  // console.log("showNextButton", showNextButton);
  // console.log("showPrevButton", showPrevButton);
  const {pageZoomPartShowLoading:isLoading}=props.settings
  return (
    <div
      className={`navbar is-fixed-bottom  bottom-annotation-menu ${
        (props.shouldBottomMenuOpened && props.selectedTool!=='curtain') ? "open" : "hide"
      } `}
    >
      {/* <button
        // disabled={props.isLoading}
        className={`button is-small modal-button`}
        onClick={props.onClickHandler}
        title={t("Zoom Mode")}
      >
        <span className="icon is-small has-text-light">Zoom</span>
      </button> */}

      {props.pageZoomPartMode === "zoom-show" && (
        <>
          <PreviousPageButton
            onClickHandler={() =>
              !isLoading && props.pageNoChange(props.currentPageNumber - 1)
            }
            disabled={(props.currentPageNumber === 1) || isLoading}
          />

          <PreviousZoomPagePartButton
            onClickHandler={() => {
              if (showPrevButton && !isLoading) {
                if (currentZoomButtonId === qZoomButtons.length - 1) {
                  props.pageNoChange(parseInt(props.currentPageNumber) - 1);
                } else {
                  const {
                    orderId,
                    downCoords,
                    upCoords,
                    cutCoords,
                  } = qZoomButtons[currentZoomButtonId + 1];
                  props.setPagePartZoomObjectToShow(
                    orderId,
                    downCoords,
                    upCoords,
                    cutCoords
                  );
                }
              }
            }}
            disabled={!showPrevButton || isLoading}
          />
          <NextZoomPagePartButton
            onClickHandler={() => {
              if (showNextButton && !isLoading) {

                if (currentZoomButtonId === 0) {
                  props.pageNoChange(parseInt(props.currentPageNumber) + 1);
                } else {
                  prevButtonHandler(
                    showNextButton,
                    qZoomButtons,
                    currentZoomButtonId,
                    props
                  );
                }
              }
            }}
            disabled={!showNextButton || isLoading}
          />
          <NextPageButton
            onClickHandler={() =>
              !isLoading && props.pageNoChange(props.currentPageNumber + 1)
            }
            disabled={(props.currentPageNumber === props.totalPageNumber) || isLoading}
          />
        </>
      )}
      <ExitButton onClickHandler={exitHandler} />
      {props.annotationSelectionMode && (
        <ApprovZoomPagePartButtons
          onClickHandler={() => {
            props.setAnnotationSelectionMode(false);
          }}
        />
      )}
      {props.pageZoomPartMode === "zoomed" && (
        <>
          <CutPagePartButton />
          <ApprovZoomPagePartButtons onClickHandler={approveHandler} />
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    theme: state.theme,
    pages: state.pages.pages,
    pagesState: state.pages,
    totalPageNumber: state.pages.totalPageNumber,
    currentPageNumber: state.pages.currentPageNo,
    currentPageId: state.pages.currentPage[0]?.id,
    currentPage: state.pages.currentPage,
    pageLoaded: state.pageLoaded,
    imageCapture: state.imageCapture,
    imagesLoaded: state.imagesLoaded,
    scaleZoomFactorVal: state.scaleZoomFactor,
    selectedTool: state.selectedTool.selectedTool,
    searchBarOpened: state.searchBarOpen,
    annotationSelectionMode: state.annotationSelections.annotationSelectionMode,
    editSelectionMode: state.annotationSelections.editSelectionMode,
    currentSelection: state.annotationSelections.currentSelection,
    annotations: state.annotationSelections.annotations,
    domain: state.login.user_profile.domain_name,
    ebookId: state.pages.ebookId,
    hideInteractiveObjectsVal: state.hideInteractiveObjects,
    showAnnotations: state.settings.showAnnotations,
    canvasVals: state.scaleZoomFactor.canvas,
    pageZoomPartMode: state.settings.pageZoomPartMode,
    pageZoomPartObjectToShow: state.settings.pageZoomPartObjectToShow,
    pageZoomPartObject: state.settings.pageZoomPartObject,
    settings: state.settings,
  };
};

export default connect(mapStateToProps, {
  toggleSideBar,
  toggleSearchBar,
  pageNoChange,
  // pullPages,
  captureInProcess,
  captureNotInprocess,
  hideCaptureImage,
  sideBarOpen,
  sideBarClose,
  setAnnotationSelectionMode,
  setEditSelectionMode,
  resetAnnotationSelectionMode,
  setSelectionCopyMode,
  setSelectionPasteMode,
  setSelectionRemoveMode,
  setSelectionUndoMode,
  setSelectionRedoMode,
  setAnnotationBeforeState,
  insertActivity,
  setPagePartZoomMode,
  setPagePartZoomObjectToShow,
  setPagePartZoomObject,
  showInteractiveObjects,
  setPageZoomPartShowLoading
})(BottomMenu);

function prevButtonHandler(
  showNextButton,
  qZoomButtons,
  currentZoomButtonId,
  props
) {
  if (showNextButton) {
    // if (currentZoomButtonId !== 0) {
    //   props.pageNoChange(props.currentPageNumber + 1);
    // }
    try {
      const { orderId, downCoords, upCoords, cutCoords } = qZoomButtons[
        currentZoomButtonId - 1
      ];

      props.setPagePartZoomObjectToShow(
        orderId,
        downCoords,
        upCoords,
        cutCoords
      );
    } catch (error) {}
  }
}

function exitZoomMode(props) {
  // console.log("exitZoomModeeeeee");
  if (props.pageZoomPartMode) {
    props.setPagePartZoomMode(null);
    props.setPagePartZoomObjectToShow(
      null,
      { x: null, y: null },
      { x: null, y: null }
    );
  } else {
    props.resetAnnotationSelectionMode();
    props.showInteractiveObjects();
  }
}
