import {
  GET_PAGES,
  GET_ZIP,
  PAGE_NO_CHANGE,
  ZIP_DOWN_PROG,
  RESET_PAGES,
  INSERT_ACTIVITY,
  DELETE_ACTIVITY,
  INSERT_PAGE,
  UPDATE_THUMBNAIL,
  UPDATE_ACTIVITY,
} from "../actions/actionTypes";

const defaultPageState = {
  pages: [],
  totalPageNumber: 0,
  currentPageNo: 1,
  currentPage: {},
  zipLoaded: false,
  firstImageProps:null,
  toc: {},
  icon: "",
  ebookId: null,
  addedActivities: 0,
};
export default (state = defaultPageState, action) => {
  switch (action.type) {
    case RESET_PAGES:
      return {
        pages: [],
        totalPageNumber: 0,
        currentPageNo: 1,
        currentPage: {},
        firstImageProps:null,
        zipLoaded: false,
        toc: {},
        icon: "",
        ebookId: null,
        addedActivities: 0,
      };
    case GET_PAGES:
      let pages = action.payload.ebook_pages;
      pages.sort((page1, page2) => {
        if (page1.page_number > page2.page_number) return 1;
        if (page1.page_number < page2.page_number) return -1;
        return 0;
      });
      let pagesWithNums = pages.map((page, index) => ({
        ...page,
        pageNumber: index + 1,
      }));
      const itemId = `${pages[0].ebook_id}_localPages`;
      const localPages = JSON.parse(localStorage.getItem(itemId));
      if (localPages) {
        if (localPages[0].pageNumber !== pagesWithNums.length + 1) {
          for (let index = 0; index < localPages.length; index++) {
            const element = localPages[index];
            element.pageNumber = pagesWithNums.length + index + 1;
            element.page_number = pagesWithNums.length + index + 1;
          }
          // localPages.map((lp) => {
          //   lp.pageNumber = lp.pageNumber + 1;
          //   lp.page_number = lp.page_number + 1;
          // });
          localStorage.setItem(itemId, JSON.stringify(localPages));
        }
        pagesWithNums = [...pagesWithNums, ...localPages];
      }
      return {
        pages: pagesWithNums,
        totalPageNumber: pagesWithNums.length,
        currentPageNo: state.currentPageNo,
        currentPage: [pagesWithNums[state.currentPageNo - 1]],
        firstImageProps:action.payload.first_image_props,
        zipLoaded: state.zipLoaded,
        toc: JSON.parse(action.payload.toc),
        icon: action.payload.icon,
        ebookId: pages[0].ebook_id,
      };
    case INSERT_ACTIVITY: {
      let newButton = action.payload[0];
      return {
        ...state,
        currentPage: [
          {
            ...state.currentPage[0],
            buttons: [...state.currentPage[0].buttons, newButton],
          },
        ],
        addedActivities:
          action.payload[0].button_type === "questionHideable"
            ? state.addedActivities + 1
            : state.addedActivities,
      };
    }
    case UPDATE_ACTIVITY: {
      let newButton = action.payload[0];
      return {
        ...state,
        currentPage: [
          {
            ...state.currentPage[0],
            buttons: [...state.currentPage[0].buttons.filter((btn)=>btn.id!==newButton.id), newButton],
          },
        ],

      };
    }
    case DELETE_ACTIVITY:
      let buttonToDelete = action.payload;

      return {
        ...state,
        currentPage: [
          {
            ...state.currentPage[0],
            buttons: state.currentPage[0].buttons.filter(
              (btn) => btn.id !== buttonToDelete
            ),
          },
        ],
        // addedActivities: state.addedActivities - 1,
      };

    case PAGE_NO_CHANGE:
      let newPageNo = action.payload;
      // console.log("called me !!!!!!!!", newPageNo, state.totalPageNumber);
      if (newPageNo === 0) {
        newPageNo = 1;
      } else if (state.totalPageNumber === 0) {
      } else if (newPageNo === state.totalPageNumber + 1) {
        newPageNo = state.totalPageNumber;
        // console.log("called me newPageNo === state.totalPageNumber + 1r");
      } else if (newPageNo > state.totalPageNumber) {
        // console.log("called me newPageNo > state.totalPageNumber");
        newPageNo = state.totalPageNumber;
      }
      // console.log('window.location.hash', window.location.hash)
      window.location.hash = `#/indivibook/${newPageNo}`;

      return {
        ...state,
        // pages: state.pages,
        // totalPageNumber: state.totalPageNumber,
        currentPageNo: newPageNo,
        currentPage: [state.pages[newPageNo - 1]],
        // zipLoaded: state.zipLoaded,
        // toc: state.toc,
        // icon: state.icon,
        // pages: state.pages,
        // totalPageNumber: state.totalPageNumber,
        // currentPageNo: action.payload,
        // currentPage: [state.pages[action.payload - 1]],
        // zipLoaded: state.zipLoaded
      };

    case GET_ZIP:
      // var pagesAfterURLChange = state.pages;
      var blobURLs = action.payload;
      var pagesAfterURLChange = state.pages.map((page, ind) => {
        if (blobURLs[ind]) {
          page.path = blobURLs[ind];
        }
      });

      return {
        ...state,
        // pages: state.pages,
        // totalPageNumber: state.totalPageNumber,
        // currentPageNo: state,
        // currentPage: state.currentPage,
        zipLoaded: true,
        // toc: state.toc,
        // icon: state.icon,
      };

    case INSERT_PAGE:
      console.log("INSERT_PAGE action.payload", action.payload);
      // const newPage = {
      //   id: action.payload,
      //   page_number: state.totalPageNumber + 1,
      //   path: null,
      //   ebook_id: state.ebookId,
      //   pageNumber: state.totalPageNumber + 1,
      //   buttons: [],
      // };
      return {
        ...state,
        // pages: [...state.pages, newPage],
        pages: [...state.pages, action.payload],
        totalPageNumber: state.totalPageNumber + 1,
        // currentPageNo: state.currentPageNo,
        // currentPage: [pagesWithNums[state.currentPageNo - 1]],
      };
    case UPDATE_THUMBNAIL:
      // console.log("UPDATE_THUMBNAIL action.payload", action.payload);
      // const newPage = {
      //   id: action.payload,
      //   page_number: state.totalPageNumber + 1,
      //   path: null,
      //   ebook_id: state.ebookId,
      //   pageNumber: state.totalPageNumber + 1,
      //   buttons: [],
      // };
      return {
        ...state,
        // pages: [...state.pages, newPage],
        // pages: [...state.pages, action.payload],
        // totalPageNumber: state.totalPageNumber + 1,
        // currentPageNo: state.currentPageNo,
        // currentPage: [pagesWithNums[state.currentPageNo - 1]],
      };
    default:
      return state;
  }
};
