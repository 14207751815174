/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { connect } from "react-redux";
import { pullImagesFromPixabay } from "../actions/pixabay";
import { pullImagesFromUnsplash } from "../actions/unsplash";
import { pullVideosFromPixabay } from "../actions/pixabayVideo";
import { pullWordFromMerriam } from "../actions/merriam";
/* import { getWikiZero } from "../actions/wikizero"; */
import { getWikipedia } from "../actions/wikipedia";
import { closePixabay } from "../actions/pixabay";
import { closeUnsplash } from "../actions/unsplash";
import { closePixabayVideo } from "../actions/pixabayVideo";
import { getWiktionary } from "../actions/wiktionary";
import { getQwant } from "../actions/qwant";
import { toggleSearchBar } from "../actions/searchbar";
import { toggleSideBar } from "../actions/sidebar";

// import PixabayIcon from "../assets/img/svg/picture.svg";
import PixabayVideoIcon from "../assets/img/pixabayVideo.png";
import VectorIcon from "../assets/img/vector.png";

import PixabayIcon from "../assets/img/pixabay4.png";
import UnsplashIcon from "../assets/img/unsplash.png";

import WiktionaryImg from "../assets/img/wiktionary.png";
import WikipediaIcon from "../assets/img/wikipedia.png";
import MerriamIcon from "../assets/img/merriam.png";
import WordWeb from "../assets/img/wordweb.png";
import Google from "../assets/img/google.png";
import Pbs from "../assets/img/pbs.png";
import GoogleImages from "../assets/img/google-images.png";
import QwantImg from "../assets/img/qwant.png";
import Select from "react-select";
import SearchButton from "./SubComponents/SearchBar/SearchButton";

import Pixabay from "./Pixabay";
import Unsplash from "./Unsplash";
import PixabayVideo from "./PixabayVideo";
/* import WikiZero from "./WikiZero"; */
import Wikipedia from "./Wikipedia";
import Wiktionary from "./Wiktionary";
import Merriam from "./Merriam";
import Qwant from "./Qwant";
import configrations from "../config.json";
import isElectron from "is-electron";
import { withTranslation } from "react-i18next";
import i18n from "i18next";
import { setInputFocused } from "../actions/settings";
import SBNotFoundMessage from "./SBNotFoundMessage";
import { BsInfoCircleFill } from "react-icons/bs";
import { confirmAlert } from "react-confirm-alert";

class SearchBar extends Component {
  constructor() {
    super();
    this.state = {
      showSources: false,
      inputValue: "",
      pixabayClicked: false,
      unsplashClicked: false,
      pixabayVideoClicked: false,
      marriamClicked: false,
    };
  }

  handleOnChange = (e) => {
    this.props.closePixabay();
    this.props.closeUnsplash();
    this.props.closePixabayVideo();

    if (e.target.value) {
      this.setState({
        showSources: true,
        inputValue: e.target.value,
      });
    } else {
      this.setState({ showSources: false, inputValue: "" });
    }
  };

  pixabayHandler = () => {
    this.setState({
      pixabayClicked: true,
      unsplashClicked: false,
      pixabayVideoClicked: false,
    });
    this.props.pullImagesFromPixabay(this.state.inputValue, 1, "all");
  };
  pixabayHandlerVector = () => {
    this.setState({
      pixabayClicked: true,
      unsplashClicked: false,
      pixabayVideoClicked: false,
    });
    this.props.pullImagesFromPixabay(this.state.inputValue);
  };

  unSplashHandler = () => {
    this.setState({
      pixabayClicked: false,
      unsplashClicked: true,
      pixabayVideoClicked: false,
    });
    this.props.pullImagesFromUnsplash(this.state.inputValue);
  };

  pixabayVideoHandler = () => {
    this.setState({
      pixabayClicked: false,
      unsplashClicked: false,
      pixabayVideoClicked: true,
    });
    this.props.pullVideosFromPixabay(this.state.inputValue);
  };

  /* wikizeroHandler = () => {
    this.props.getWikiZero();
  }; */

  wikipediaHandler = () => {
    this.props.getWikipedia();
  };

  merriamHandler = () => {
    this.setState({
      pixabayClicked: false,
      unsplashClicked: false,
      pixabayVideoClicked: false,
    });
    this.props.pullWordFromMerriam(this.state.inputValue);
  };

  wiktionaryHandler = () => {
    this.props.getWiktionary();
  };
  wordwebHandler = () => {
    // this.props.getQwant();
    // window.open(
    //   // `https://www.qwant.com/?q=${this.state.inputValue}&t=web&s=2`,
    //   // "_blank"
    //   `https://www.wordwebonline.com/search.pl?w=${this.state.inputValue}`,
    //   // `http://gcide.gnu.org.ua/?q=${this.state.inputValue}&define=Define&strategy=.`,
    //   "_blank"
    // );
    let url = `https://www.wordwebonline.com/search.pl?w=${this.state.inputValue}`;
    if (isElectron()) {
      window.require("electron").shell.openExternal(url);
    } else {
      window.open(url, "_blank");
    }
  };

  pbslearningHandler = () => {
    // this.props.getQwant();
    let url = `https://www.pbslearningmedia.org/search/?q=${this.state.inputValue}&hl=${i18n.language}&`;
    if (isElectron()) {
      window.require("electron").shell.openExternal(url);
    } else {
      window.open(url, "_blank");
    }
    // window.open(
    //   `https://www.pbslearningmedia.org/search/?q=${this.state.inputValue}&hl=${configrations.searchBarLang}&`,
    //   "_blank"
    // );
  };

  googleHandler = () => {
    // this.props.getQwant();
    let url = `https://www.google.com/search?q=${this.state.inputValue}&source=lmns&safe=active&hl=${i18n.language}`;
    if (isElectron()) {
      window.require("electron").shell.openExternal(url);
    } else {
      window.open(url, "_blank");
    }
    // window.open(
    //   `https://www.google.com/search?safe=active&q=${this.state.inputValue}&tbm=isch&hl=${configrations.searchBarLang}`,
    //   "_blank"
    // );
  };

  googleImagesHandler = () => {
    // this.props.getQwant();
    let url = `https://www.google.com/search?safe=active&q=${this.state.inputValue}&tbm=isch&hl=${i18n.language}`;
    if (isElectron()) {
      window.require("electron").shell.openExternal(url);
    } else {
      window.open(url, "_blank");
    }
    // window.open(
    //   `https://www.google.com/search?safe=active&q=${this.state.inputValue}&tbm=isch&hl=${configrations.searchBarLang}`,
    //   "_blank"
    // );
  };

  /**
   * hides and opens sidebar
   */
  toggleSearchBar = () => {
    this.props.toggleSearchBar(this.props.searchBarOpened);
    // this.props.toggleSideBar(!this.props.searchBarOpened);
  };
  onSuggestionClick = (e) => {
    this.setState({ inputValue: e.target.dataset.value, showSources: true });
  };

  showContent = () => {
    confirmAlert({ customUI: this.showContentCustomUI });
  };

  showContentCustomUI = ({ onClose }) => {
    const handleClickedClose = () => {
      onClose();
    };
    return (
      <section className="hero is-large">
        <div className="hero-body has-text-centered">
          <div className="container">
            <button
              class="button is-danger is-rounded is-bold is-size-4 is-pulled-right pb-0 pt-0 pl-3 pr-3"
              style={{ marginRight: -15, marginTop: -15, height: "auto" }}
              onClick={handleClickedClose}
            >
              X
            </button>
            <div className="box">
              {this.props.t('Suggested words are words from the current page. You can click a word and search definitions, images, etc.')}
            </div>
          </div>
        </div>
      </section>
    );
  };

  render() {
    const isSmallScreen = this.props.canvasVals
      ? parseInt(this.props.canvasVals.vpW) < 768
      : false;
    // console.log("this.props.pixabay.result", this.props.pixabay.result);
    return (
      <div
        className={`searchbar column is-narrow is-fullheight ${
          this.props.searchBarOpened ? "open" : "hide"
        }`}
        style={{ overflowY: "auto" }}
      >
        <nav className="panel searchbar-list">
          <p className="panel-heading">{this.props.t("Search")}</p>
          <div className="panel-block">
            <div className="control has-icons-left">
              <input
                className="input is-small pl-5"
                onChange={this.handleOnChange}
                onFocus={() => this.props.setInputFocused(true)}
                onBlur={() => this.props.setInputFocused(false)}
                type="text"
                placeholder={this.props.t("search")}
                value={this.state.inputValue}
                onKeyPress={(event) => {
                  if (event.key === "Enter") {
                    this.setState({
                      pixabayClicked: true,
                    });
                    this.props.closePixabay();
                    this.props.closePixabayVideo();
                    this.props.closeUnsplash();
                    this.props.pullImagesFromPixabay(this.state.inputValue);
                    this.props.pullImagesFromUnsplash(this.state.inputValue);
                    this.props.pullVideosFromPixabay(this.state.inputValue);
                  }
                }}
              />
              {this.props.searchWords.length > 0 && (
                <div>
                  <div>
                    <h4 className="suggested-words-title">
                      {this.props.t("Suggested Words")}
                      <span onClick={this.showContent}>
                        <BsInfoCircleFill className="fas fa mb-1 ml-2" />
                      </span>
                    </h4>
                  </div>

                  <div
                    style={{
                      backgroundColor: "white",
                      height: "100px",
                      overflow: "auto",
                    }}
                  >
                    <ul>
                      {this.props.searchWords.map((word) => {
                        return (
                          <li
                            data-value={word}
                            onClick={this.onSuggestionClick}
                            key={word}
                          >
                            {word}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              )}
              {/*  <Select*/}
              {/*      // value={this.state.select_value}*/}
              {/*      onChange={this.handleOnChange}*/}
              {/*      isClearable={true}*/}
              {/*      // defaultValue={colourOptions[1]}*/}
              {/*      options={this.props.searchWords}*/}
              {/*      // formatGroupLabel={formatGroupLabel}*/}
              {/*  />*/}
              <span className="icon is-small is-left">
                <i className="fas fa-search" aria-hidden="true"></i>
              </span>
            </div>
          </div>
          <div
            className="search-type-list"
            style={{ display: this.state.showSources ? "block" : "none" }}
          >
            {/* <a className="panel-block" title="Search images">
              <img
                src={PixabayIcon}
                onClick={() => {
                  this.setState({
                    pixabayClicked: true,
                  });
                  this.props.pullImagesFromPixabay(this.state.inputValue);
                }}
              />
            </a> */}
            <SearchButton
              title={this.props.t("Search Vector")}
              icon={VectorIcon}
              handler={this.pixabayHandlerVector}
              //handler={this.pixabayVideoHandler}
            />
            <SearchButton
              title={this.props.t("Search images")}
              icon={PixabayIcon}
              handler={this.pixabayHandler}
            />
            <SearchButton
              title={this.props.t("Search images")}
              icon={UnsplashIcon}
              handler={this.unSplashHandler}
            />

            <SearchButton
              title={this.props.t("Search Video")}
              icon={PixabayVideoIcon}
              handler={this.pixabayVideoHandler}
            />

            {/*  <SearchButton

              title={this.props.t("Search on Wikizero")}
              icon={Wikipedia}
              handler={this.wikizeroHandler}
            /> */}
            <SearchButton
              title={this.props.t("Search on Wikipedia")}
              icon={WikipediaIcon}
              handler={this.wikipediaHandler}
            />
            {(i18n.language === "en" ||
              process.env.REACT_APP_MERRIAM_KEY !== undefined) && (
              <>
                <SearchButton
                  title={this.props.t("Search on Merriam Dictionary")}
                  icon={MerriamIcon}
                  handler={this.merriamHandler}
                />
                <SearchButton
                  title={this.props.t("Search on Wiktionary")}
                  icon={WiktionaryImg}
                  handler={this.wiktionaryHandler}
                />
                <SearchButton
                  title={this.props.t("Search on WordWeb")}
                  icon={WordWeb}
                  handler={this.wordwebHandler}
                />
                <SearchButton
                  title={this.props.t("Search on PBS Learning Media")}
                  icon={Pbs}
                  handler={this.pbslearningHandler}
                />
              </>
            )}

            <SearchButton
              title={this.props.t("Search on Google")}
              icon={Google}
              handler={this.googleHandler}
            />
            <SearchButton
              title={this.props.t("Search on Google Images")}
              icon={GoogleImages}
              handler={this.googleImagesHandler}
            />

            {/* <a
              className="panel-block"
              onClick={() => {
                // this.props.getQwant();
                window.open(
                  `https://www.qwant.com/?q=${this.state.inputValue}&t=images&s=2`,
                  "_blank"
                );
              }}
            >
              <p style={{ color: "white" }}>Qwant Images</p>
            </a> */}

            {/* <a
              className="panel-block"
              onClick={() => {
                // this.props.getQwant();
                window.open(
                  `https://www.google.com/search?safe=active&q=${this.state.inputValue}&hl=${configrations.searchBarLang}`,
                  "_blank"
                );
              }}
            >
              <p style={{ color: "white" }}>Google</p>
            </a>

            <a
              className="panel-block"
              onClick={() => {
                // this.props.getQwant();
                window.open(
                  `https://www.google.com/search?safe=active&q=${this.state.inputValue}&tbm=isch&hl=${configrations.searchBarLang}`,
                  "_blank"
                );
              }}
            >
              <p style={{ color: "white" }}>Google Images</p>
            </a> */}
          </div>
          {this.state.pixabayClicked ? (
            this.props.pixabay.result?.total > 0 ? (
              <Pixabay inputValue={this.state.inputValue} />
            ) : (
              <SBNotFoundMessage hide={this.props.settings.inputFocused} />
            )
          ) : (
            <></>
          )}
          {this.state.unsplashClicked ? (
            this.props.unsplash.result?.total ? (
              <Unsplash inputValue={this.state.inputValue} />
            ) : (
              <SBNotFoundMessage hide={this.props.settings.inputFocused} />
            )
          ) : (
            <></>
          )}
          {this.state.pixabayVideoClicked ? (
            this.props.pixabayVideo.result?.total ? (
              <PixabayVideo inputValue={this.state.inputValue} />
            ) : (
              <SBNotFoundMessage hide={this.props.settings.inputFocused} />
            )
          ) : (
            <></>
          )}

          {/* {this.props.wikizero && (
            <WikiZero
              url={`https://www.wikizero.com/${configrations.searchBarLang}/${this.state.inputValue}`}
            />
          )} */}
          {this.props.wikipedia && (
            <Wikipedia
              url={`https://${i18n.language}.wikipedia.com/wiki/${this.state.inputValue}`}
            />
          )}
          {this.props.wiktionary && (
            <Wiktionary
              url={`https://${i18n.language}.wiktionary.org/wiki/${this.state.inputValue}`}
            />
          )}
          {!this.state.pixabayClicked &&
            !this.state.unsplashClicked &&
            this.props.merriam && <Merriam />}

          {/*{this.props.qwant &&*/}
          {/*<Qwant url={`https://www.qwant.com/?q=${this.state.inputValue}&t=web`}/>}*/}
        </nav>

        <div className="searchbar-footer" onClick={this.toggleSearchBar}>
          <div className="corporation has-text-centered">
            {/*INTRO JS STEP 1 */}
            <div data-step="1" data-intro={this.props.t("intro_1")}>
              {this.props.searchBarOpened && (
                <button
                  className={`collapse-searchbar ${
                    isSmallScreen
                      ? "search-bar-small-screen"
                      : "is-pulled-right"
                  }`}
                  data-tip={
                    this.props.searchBarOpened
                      ? this.props.t("Close Search Bar")
                      : this.props.t("Open Search Bar")
                  }
                >
                  <span className="icon">
                    <img
                      src={require("../assets/img/svg/toolbar-search.svg")}
                      alt="Collapse Sidebar"
                    />
                  </span>
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  var searchWords = [];
  if (state.pages.pages[state.pages.currentPageNo - 1]) {
    var word_btn_type = state.pages.pages[
      state.pages.currentPageNo - 1
    ].buttons.filter((btn) => {
      return btn.button_type == "words";
    });
    if (word_btn_type[0] && word_btn_type[0].content) {
      searchWords = word_btn_type[0].content.split(",");
      searchWords = searchWords.map((word) => {
        return word.trim();
      });
    }
  }
  // console.log(searchWords);
  // console.log(state.pages.pages,state.pages.currentPageNo - 1,state.pages.pages[state.pages.currentPageNo - 1]);
  return {
    pixabay: state.pixabay,
    unsplash: state.unsplash,
    pixabayVideo: state.pixabayVideo,
    searchBarOpened: state.searchBarOpen,
    /* wikizero: state.wikizero, */
    wikipedia: state.wikipedia,
    wiktionary: state.wiktionary,
    qwant: state.qwant,
    merriam: state.merriam,
    settings: state.settings,
    // p:state.pages
    searchWords: searchWords,
    canvasVals: state.scaleZoomFactor.canvas,
  };
};

export default connect(mapStateToProps, {
  toggleSearchBar,
  toggleSideBar,
  pullImagesFromPixabay,
  pullImagesFromUnsplash,
  pullVideosFromPixabay,
  /*   getWikiZero, */
  getWikipedia,
  getWiktionary,
  getQwant,
  closePixabay,
  closeUnsplash,
  closePixabayVideo,
  pullWordFromMerriam,
  setInputFocused,
})(withTranslation()(SearchBar));
