import {
  ADD_ACTIVITY,
  CANCEL_ACTIVITY,
  DONE_ACTIVITY,
  EDIT_ACTIVITY,
} from "../actions/actionTypes";

var defaultActivitiesState = {
  activityName: null,
  activityLeft: null,
  activityTop: null,
  activityTitle: "",
  activityId:null,
};

export default (state = defaultActivitiesState, action) => {
  switch (action.type) {
    case ADD_ACTIVITY:
      return {
        activityName: action.payload.name,
        activityTitle: action.payload.title,
        activityLeft: action.payload.left,
        activityTop: action.payload.top,
        activityId:null,
      };
    case DONE_ACTIVITY:
      return {
        activityName: null,
        activityLeft: null,
        activityTop: null,
        activityTitle: "",
        activityId:null,
      };
    case CANCEL_ACTIVITY:
      return {
        activityName: null,
        activityLeft: null,
        activityTop: null,
        activityTitle: "",
        activityId:null,
      };
      case EDIT_ACTIVITY:
      return {
        activityName: action.payload.name,
        activityLeft: action.payload.left,
        activityTop: action.payload.top,
        activityTitle: "",
        activityId:action.payload.id,
        activityBtnStyle:action.payload.btnStyle,
        activityShow:action.payload.activityShow,
      };
    // case SHOW_TOAST:
    //   return true;

    default:
      return state;
  }
};
