import { BUTTONS_SHOWN, BUTTONS_HIDDEN } from "./actionTypes";

export const toggleButtons = isButtonsShown => {
  return dispatch => {
    if (isButtonsShown) {
      dispatch(hideButtons());
    } else {
      dispatch(showButtons());
    }
  };
};

const showButtons = () => {
  return {
    type: BUTTONS_SHOWN
  };
};

const hideButtons = () => {
  return {
    type: BUTTONS_HIDDEN
  };
};
