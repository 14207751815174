import React, { Component } from "react";
import { connect } from "react-redux";
import { pageNoChangeFromIndex } from "../../actions/pages";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";

class InteractiveObjects extends Component {
  render() {
    return (
      <div className="column modal-right">
        <div className="modal-title">
          <h2>
            <span className="modal-title-icon">
              <img
                src={require("../../assets/img/svg/tabs-icon-draw-objects.svg")}
                alt={this.props.t("Activities")}
              />
            </span>
            <span className="modal-title-text">
              {this.props.t("Activities")}
            </span>
          </h2>
        </div>
        <table className="table">
          {/* <thead>
            <tr>
              <td>Page Number</td>
              <td>Activities</td>
            </tr>
          </thead> */}
          <tbody>
            {this.props.interactiveObjects.map((obj, ind) => {
              return (
                <tr key={ind}>
                  <td
                    onClick={() => {
                      this.props.pageNoChangeFromIndex(ind + 1);
                    }}
                    key={ind}
                  >
                    {" "}
                    <Link to={(ind + 1).toString()}>
                      <span className="activity-span">{this.props.t("Page")} {ind + 1}</span>
                    </Link>
                    {
                      Object.keys(obj).map((a, id) => {
                        // a=  interactivity type

                        let icon;
                        switch (a) {
                          case "audio":
                            icon = (
                              <img
                                className="svg-small"
                                src={require("../../assets/img/svg/interactive-audio.svg")}
                                alt="Audio"
                                title="Audio"
                                key={id}
                              />
                            );
                            break;
                          case "text":
                            icon = (
                              <img
                                className="svg-small"
                                src={require("../../assets/img/svg/interactive-text.svg")}
                                alt="Note"
                                title="Note"
                                key={id}
                              />
                            );
                            break;
                          case "video":
                            icon = (
                              <img
                                className="svg-small"
                                src={require("../../assets/img/svg/interactive-video.svg")}
                                alt="Video"
                                title="Video"
                                key={id}
                              />
                            );
                            break;
                          case "h5p":
                            icon = (
                              <img
                                className="svg-small"
                                src={require("../../assets/img/svg/interactive2.svg")}
                                alt="Activity"
                                title="Activity"
                                key={id}
                              />
                            );
                            break;
                          case "answer":
                            icon = (
                              <img
                                className="svg-small"
                                src={require("../../assets/img/svg/interactive-text.svg")}
                                alt="Answer"
                                title="Answer"
                                key={id}
                              />
                            );
                            break;
                          case "link":
                            icon = (
                              <img
                                className="svg-small"
                                src={require("../../assets/img/svg/interactive-link.svg")}
                                alt="Link"
                                title="Link"
                                key={id}
                              />
                            );
                            break;
                          case "h5p-answer":
                            icon = (
                              <img
                                className="svg-small"
                                src={require("../../assets/img/svg/interactive.svg")}
                                alt="Interactive Answer"
                                title="Interactive Answer"
                                key={id}
                              />
                            );
                            break;
                          case "image":
                            icon = (
                              <img
                                className="svg-small"
                                src={require("../../assets/img/svg/interactive-image.svg")}
                                alt="Activity as an image"
                                title="Image"
                                key={id}
                              />
                            );
                            break;
                          case "words":
                            return;
                            break;
                          default:
                            break;
                        }

                        return (
                          <div className="activities" key={id}>
                            {/* <span className="modal-title-icon"></span> */}
                            <span className="modal-title-text-small">
                              {obj[a]}
                            </span>
                            {icon}
                          </div>
                        );
                      })
                      //     obj.map((btn,BtnName) => {
                      //     return  "("+btn+")"+BtnName;
                      // })
                    }
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  let interactiveObjects = [];
  let pages = state.pages.pages;

  for (let i = 0; i < pages.length; i++) {
    if (pages[i].buttons && pages[i].buttons.length > 0) {
      interactiveObjects[i] = new Array();
      for (let j = 0; j < pages[i].buttons.length; j++) {
        // if(pages[i].buttons[j].button_type != "words"){
        if (interactiveObjects[i][pages[i].buttons[j].button_type]) {
          interactiveObjects[i][pages[i].buttons[j].button_type]++;
        } else {
          interactiveObjects[i][pages[i].buttons[j].button_type] = 1;
        }
        // }
      }
    }
  }
  // "words"
  // "audio"
  // "text"
  // "h5p"
  // "h5p-answer"
  // "video"
  return {
    interactiveObjects,
  };
};

export default connect(mapStateToProps, { pageNoChangeFromIndex })(
  withTranslation()(InteractiveObjects)
);
