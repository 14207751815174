import React from "react";
import {
  // HiXCircle,
  MdSkipNext,
  // MdSkipPrevious,
  // MdExitToApp
} from "react-icons/md";
import { useTranslation } from "react-i18next";

export default function NextZoomPagePartButton(props) {
  const { t } = useTranslation();
  return (
    <>
      <button
        // disabled={props.isLoading}
        className={`button  is-small modal-button  ${props.disabled ? "button-disabled" : ""}`}
        onClick={props.onClickHandler}
        title={t("Next")}

      >
       <span className="has-text-light is-hidden-tablet-only  is-hidden-mobile mb-2">{t('Next')}</span>
        <span className="icon  has-text-light mb-2">
          <MdSkipNext className="fas fa-2x" />
        </span>
      </button>
    </>
  );
}
