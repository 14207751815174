import React from "react";
// import { useTranslation } from "react-i18next";

export default function SearchButton(props) {
  //   const { t, i18n } = useTranslation();
  //   const { object } = props;
  return (
    <a className="panel-block" title={props.title}>
      <img src={props.icon} onClick={() => props.handler()} />
    </a>
  );
}
