import FabricCanvasTool from "./fabrictool";

class Pencil extends FabricCanvasTool {
  configureCanvas(props) {
    this._canvas.isDrawingMode = true;
    this._canvas.freeDrawingBrush.width = props.toolbar.lineWidth;
    this._canvas.freeDrawingBrush.color = props.toolbar.lineColor;
  }
  doMouseDown(o) {
    if (this._canvas._iTextInstances) {
      this._canvas._iTextInstances.forEach(function(ob) {
        ob.exitEditing();
      });
    }
  }
}

export default Pencil;
