import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

export default function Mouse(props) {
  // console.log("props.activeButton ", props.activeButton);
  const selectedTool = useSelector((state) => state.selectedTool.selectedTool);

  const { t } = useTranslation();
  let status = "";
  let _icon = require("../../assets/img/svg/toolbar-hand.svg");
  switch (selectedTool) {
    case "pan":
      status = "active";
      break;
    case "select":
      status = "active";
      break;
    case "harddrag":
      status = "active";
      break;
    default:
      break;
  }
  switch (selectedTool) {
    case "pan":
      _icon = require("../../assets/img/svg/toolbar-hand.svg");
      break;
    case "select":
      _icon = require("../../assets/img/svg/toolbar-select.svg");
      break;
    case "harddrag":
      _icon = require("../../assets/img/svg/toolbar-move.svg");
      break;
    default:
      break;
  }
  return (
    <li onClick={!props.hideButton ? props.onClick : undefined}>
      <span
        className={`button normal-btn hand ${status}${
          props.hideButton ? "passive" : ""
        }`}
        title={t("Select Drawing Objects") + " (S)"}
      >
        <img src={_icon} alt={t("Select Drawing Objects") + " (S)"} />
      </span>
    </li>
  );
}
