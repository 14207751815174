import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { SampleMultipleChoiceQuestion } from "../Quiz/SampleMultipleChoiceQuestion";
import { ANS_OPTIONS } from "../../services/constants";
import { connect, useSelector, useDispatch } from "react-redux";
import { selectMCQuestion, submitMCQuestion } from "../../actions/quiz";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faCogs,
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
export default function MCQuestion(props) {
  const { t, i18n } = useTranslation();
  const { data } = props;
  const [oValue, setOValue] = useState("style1");
  console.log("+++++++++++++++++++++++++++object", props);
  //   const [startTime, setStartTime] = useState(Date.now()); // TODO TIMESPENT

  //   const domain = useSelector((state) => state.login.user_profile.domain_name);
  const dispatch = useDispatch();
  const [question, setQuestion] = useState(data);
  const [aa, setAa] = useState("11");
  const isMultiChoice = question.button_type === "mc_question";

  //   useEffect(() => { //TODO TIMESPENT
  //     !question.repeatCount === 0 && setQuestion({ ...question, timeSpent: 0 });
  //   }, [question.repeatCount]);

  //   const question = SampleMultipleChoiceQuestion;
  const answerTheQuestion = (ans, answerKey, isTrue, answerId) => {
    console.log("ans ", ans);
    console.log("isTrue", isTrue);
    console.log("answerId", answerId);
    // return;
    console.log("isMultiChoice", isMultiChoice);
    console.log("question.answer", question.answer);

    if (question.submitted) {
      return;
    }
    console.log("e", ans, answerKey, isTrue);
    console.log("answerId", answerId);
    if (!isMultiChoice) {
      console.log(
        "!!!!!!!!!!question.answer.includes(ans.id)",
        question.answer,
        ans.id,
        question.answer.includes(ans.id)
      );

      const correctAnswersLength = question.answers.filter((q) => q.true)
        .length;
      console.log("HERE WE ARE", correctAnswersLength, question);
      if (question.answer.includes(ans.id)) {
        setAa("44");
        console.log("ans------", ans.id);
        setQuestion({
          ...question,
          answer: question.answer.filter((q) => q !== ans.id),
          isTrue: null,
        });
      } else {
        setQuestion({
          ...question,
          answer: [...question.answer, ans.id],
          isTrue:
            question.answer.length + 1 === correctAnswersLength
              ? correctAnswersLength
              : null,
        });
      }
    } else {
      if (ans.id === question.answer) {
        console.log("ans------", ans.id);
        setQuestion({ ...question, answer: null, isTrue: null });
      } else {
        setQuestion({ ...question, answer: ans.id, isTrue: isTrue });
      }
    }
    // const { questionIndex } = this.props;
    // dispatch(answerQuestion(questionIndex, answerKey, isTrue, answerId));
  };
  const submitTheQuestion = () => {
    if (question.submitted) {
      return;
    }
    console.log("submitted");
    setQuestion({
      ...question,
      submitted: true,
      repeatCount: question.repeatCount + 1,
    });
    console.log("++++question", question);
    // const { questionIndex } = this.props;
    // dispatch(answerQuestion(questionIndex, answerKey, isTrue, answerId));
  };
  const retryTheQuestion = () => {
    console.log("question.repeatCount", question.repeatCount);
    if (
      question.maxTimesToRepeat < question.repeatCount ||
      question.showSolution
    ) {
      return;
    }
    console.log("submitted");
    setQuestion({
      ...question,
      submitted: false,
      answer: null,
      showSolution: false,
      repeatCount: question.repeatCount + 1,
    });
    console.log("++++question", question);
    // const { questionIndex } = this.props;
    // dispatch(answerQuestion(questionIndex, answerKey, isTrue, answerId));
  };
  const showSolutionHandler = () => {
    console.log("showSolution");
    setQuestion({ ...question, showSolution: true });
    // console.log("++++question", question);
    // const { questionIndex } = this.props;
    // dispatch(answerQuestion(questionIndex, answerKey, isTrue, answerId));
  };

  const showFeedback = () => {
    if (
      question.submitted &&
      question.canHaveInstantFeedback &&
      // !question.showSolution &&
      // (
      // question.maxTimesToRepeat <= question.repeatCount ||
      // question.showSolution ||
      // question.isTrue) &&
      (question.generalFeedbackOnCorrectAnswer !== "" ||
        question.generalFeedbackOnIncorrectAnswer !== "")
    ) {
      return true;
    }
    // return false;
  };
  const showSubmitButton = () => {
    if (!question.submitted && !question.showSolution) {
      return true;
    }
    // return false;
  };
  const showRetryButton = () => {
    if (
      !question.isTrue &&
      question.enableRetryButton &&
      question.submitted &&
      (question.maxTimesToRepeat > question.repeatCount ||
        !question.showSolution)
    ) {
      return true;
    }
    // return false;
  };
  const labelClass = (ans) => {
    let labelToReturn = "";
    if (isMultiChoice) {
      labelToReturn =
        question.submitted &&
        question.canHaveInstantFeedback &&
        question.answer === ans.id
          ? question.isTrue
            ? "active-true"
            : "active-false"
          : question.showSolution && ans.true
          ? "active-solution"
          : question.answer === ans.id
          ? "active"
          : "";
    } else {
      labelToReturn =
        question.submitted &&
        question.canHaveInstantFeedback &&
        question.answer.includes(ans.id)
          ? question.isTrue
            ? "active-true"
            : "active-false"
          : question.showSolution && ans.true
          ? "active-solution"
          : question.answer.includes(ans.id)
          ? "active"
          : "";
    }
    return labelToReturn;
  };
  return (
    <div>
      <div className="question-text-wrap">
        <div>
          <div>
            <div
              dangerouslySetInnerHTML={{
                __html: question.root,
              }}
              className="question-text noselect"
            />

            <div className="answer-options noselect">
              {question.answers.map((ans, answer_key) => {
                return (
                  <label
                    htmlFor={"radio" + ans.id}
                    key={ans.id}
                    className={labelClass(ans)}
                  >
                    <input
                      type="radio"
                      name={"radio" + ans.id}
                      id={"radio" + ans.id}
                      value={"radio" + ans.id}
                      checked={
                        isMultiChoice
                          ? question.answer === ans.id
                          : question.answer.includes(ans.id)
                      }
                      onChange={answerTheQuestion.bind(
                        this,
                        ans,
                        ANS_OPTIONS[answer_key],
                        ans.true,
                        ans.id
                      )}
                      onClick={answerTheQuestion.bind(
                        this,
                        ans,
                        ANS_OPTIONS[answer_key],
                        ans.true,
                        ans.id
                      )}
                    />
                    <span className="mcq-option">
                      {ANS_OPTIONS[answer_key]}
                    </span>

                    <div
                      dangerouslySetInnerHTML={{
                        __html: ans.answer,
                      }}
                    ></div>
                  </label>
                );
              })}
            </div>
            <div className="exam-bottom">
              {question.enableShowSolutionButton &&
                question.submitted &&
                !question.showSolution &&
                !question.isTrue && (
                  <a
                    className="box-btn style-2  twin-btn-wrap-enabled"
                    onClick={() => showSolutionHandler()}
                  >
                    {t("Show Solution")}
                  </a>
                )}

              {/* question.submitted &&
              (question.maxTimesToRepeat <= question.repeatCount ||
                question.showSolution) */}

              {showSubmitButton() && (
                <a
                  className={`box-btn style-2  ${
                    (question.answer && !question.submitted) ||
                    (question.enableRetryButton &&
                      question.submitted &&
                      question.maxTimesToRepeat > question.repeatCount &&
                      !question.showSolution)
                      ? "twin-btn-wrap-enabled"
                      : "twin-btn-wrap-disabled"
                  }`}
                  onClick={() =>
                    question.answer &&
                    !question.submitted &&
                    submitTheQuestion()
                  }
                >
                  {t("Submit")}
                </a>
              )}
              {showRetryButton() && (
                <a
                  className={`box-btn style-2  ${
                    (question.answer && !question.submitted) ||
                    (question.enableRetryButton &&
                      question.submitted &&
                      question.maxTimesToRepeat > question.repeatCount &&
                      !question.showSolution)
                      ? "twin-btn-wrap-enabled"
                      : "twin-btn-wrap-disabled"
                  }`}
                  onClick={() => question.answer && retryTheQuestion()}
                >
                  {t("Retry")}
                </a>
              )}
            </div>
            {showFeedback() && (
              <div
                className={
                  question.isTrue ? "feedback-positive" : "feedback-negative"
                }
              >
                <FontAwesomeIcon icon={question.isTrue ? faCheckCircle : faTimesCircle} className="mr-2" />
                {question.isTrue
                  ? question.generalFeedbackOnCorrectAnswer
                  : question.generalFeedbackOnIncorrectAnswer}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
