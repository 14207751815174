import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toggleSideBar } from "../../actions/sidebar";
import { connect } from "react-redux";

const SidebarMenu = (props) => {
  const { t, i18n } = useTranslation();

  return (
    <div className="corporation">
      <Link
        to="/library"
        className="corporation-link is-pulled-left"
        data-tip={t("Library")}
      >
        <figure className="icon">
          {/* <img src={'http://cenibook.com/storage/' + this.props.icon} alt="Logo" /> */}
          <img
            // src={require("../assets/img/cenibook-logo.png")}
            src={require("../../assets/img/svg/library.svg")}
            alt={t("Library")}
            title={t("Library")}
          />
        </figure>
      </Link>

      <a
        className="index-icon"
        // href=""
        onClick={()=>props.toggleModal()}
        data-tip={t("TOC")}
      >
        {/*INTRO JS STEP 2 */}
        <div data-step="2" data-intro={t("intro_2")}>
          <figure className="icon">
            <img
              src={require("../../assets/img/svg/sidebar-index.svg")}
              alt={t("TOC")}
              title={t("TOC")}
            />
          </figure>
        </div>
      </a>
      <button
        className="collapse-sidebar is-pulled-right"
        onClick={() => props.toggleSideBar(props.sidebarOpened)}
        data-tip={props.sidebarOpened ? t("Close Sidebar") : t("Open Sidebar")}
      >
        <span className="icon">
          <img
            src={require("../../assets/img/svg/sidebar-collapse-left.svg")}
            alt={t("Collapse Sidebar")}
            title={props.sidebarOpened ? t("Close Sidebar") : t("Open Sidebar")}
          />
        </span>
      </button>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    sidebarOpened: state.sideBarOpened,
  };
};

export default connect(mapStateToProps, {
  toggleSideBar,
})(SidebarMenu);
