import {
  ENABLE_EDIT_MODE,
  DISABLE_EDIT_MODE,
  ENABLE_BUTTON_MOVE,
  DISABLE_BUTTON_MOVE,
  ENABLE_STUDIO_MODE,
  DISABLE_STUDIO_MODE,
  SET_BACKGROUND_IMAGE_OPACITY,
  HIDE_ANNOTATIONS,
  SHOW_ANNOTATIONS,
  HIDE_AREA,
  SHOW_AREA,
  UNDO_TRIGGERED,
  REDO_TRIGGERED,
  REMOVE_TRIGGERED,
  REMOVE_ALL_TRIGGERED,
  ZOOM_IN_TRIGGERED,
  ZOOM_OUT_TRIGGERED,
  IMAGE_LOADED,
  SET_PAGE_PART_ZOOM_MODE,
  SET_PAGE_PART_ZOOM_OBJECT,
  SET_PAGE_PART_ZOOM_OBJECT_TO_SHOW,
  ADD_IMAGE_TO_CANVAS,
  SET_INPUT_FOCUSED,
  SET_PAGE_PART_ZOOM_CUT,
  SET_TIMER_DISPLAY,
  SET_PAGE_BRIGHTNESS,
  SET_PAGE_CONTRAST,
  SET_BRIGHTNESS_MENU_OPEN,
  SET_ZOOM_MODE_MENU_OPEN,
  ADD_MARGIN_A,
  ADD_MARGIN_B,
  SET_SHOW_MARGINS,
  REMOVE_MARGIN_A,
  REMOVE_MARGIN_B,
  SET_PAGE_PART_ZOOM_FULL_SCALE_VAL,
  SET_PAGE_PART_ZOOM_OBJECT_LOADING
} from "./actionTypes";

//EDIT MODE
export const disableEditMode = () => {
  localStorage.editMode = false;
  return {
    type: DISABLE_EDIT_MODE,
  };
};
export const enableEditMode = () => {
  localStorage.editMode = true;
  return {
    type: ENABLE_EDIT_MODE,
  };
};

//MOVE BUTTONS
export const disableButtonMove = () => {
  localStorage.moveButtons = false;
  return {
    type: DISABLE_BUTTON_MOVE,
  };
};
export const enableButtonMove = () => {
  localStorage.moveButtons = true;
  return {
    type: ENABLE_BUTTON_MOVE,
  };
};

//STUDIO MODE
export const disableStudioMode = () => {
  localStorage.studioMode = false;
  return {
    type: DISABLE_STUDIO_MODE,
  };
};
export const enableStudioMode = () => {
  localStorage.studioMode = true;
  return {
    type: ENABLE_STUDIO_MODE,
  };
};

//SET BACKGROUND IMAGE OPACITY
export const setBackgroundImageOpacity = (payload) => {
  return {
    type: SET_BACKGROUND_IMAGE_OPACITY,
    payload,
  };
};

export const hideAnnotations = () => {
  return {
    type: HIDE_ANNOTATIONS,
  };
};
export const showAnnotations = () => {
  return {
    type: SHOW_ANNOTATIONS,
  };
};
export const hideArea = () => {
  return {
    type: HIDE_AREA,
  };
};
export const showArea = () => {
  return {
    type: SHOW_AREA,
  };
};

export const triggerUndo = (payload) => {
  return {
    type: UNDO_TRIGGERED,
    payload,
  };
};
export const triggerRedo = (payload) => {
  return {
    type: REDO_TRIGGERED,
    payload,
  };
};
export const triggerRemove = (payload) => {
  return {
    type: REMOVE_TRIGGERED,
    payload,
  };
};
export const triggerRemoveAll = (payload) => {
  return {
    type: REMOVE_ALL_TRIGGERED,
    payload,
  };
};
export const triggerZoomIn = (payload) => {
  return {
    type: ZOOM_IN_TRIGGERED,
    payload,
  };
};
export const triggerZoomOut = (payload) => {
  return {
    type: ZOOM_OUT_TRIGGERED,
    payload,
  };
};
export const setImageLoaded = (payload) => {
  return {
    type: IMAGE_LOADED,
    payload,
  };
};
export const setPagePartZoomObject = (orderId, downCoords, upCoords) => {
  // TODO orderId id is the order in the page TODO set/change it through an UI
  return {
    type: SET_PAGE_PART_ZOOM_OBJECT,
    payload: { orderId, downCoords, upCoords },
  };
};
export const setPagePartZoomCut = (cutCoords) => {
  // TODO orderId id is the order in the page TODO set/change it through an UI
  return {
    type: SET_PAGE_PART_ZOOM_CUT,
    payload: { cutCoords },
  };
};
export const setPagePartZoomObjectToShow = (
  orderId,
  downCoords,
  upCoords,
  cutCoords = { x: null, y: null }
) => {
  // TODO orderId id is the order in the page TODO set/change it through an UI
  return {
    type: SET_PAGE_PART_ZOOM_OBJECT_TO_SHOW,
    payload: { orderId, downCoords, upCoords, cutCoords },
  };
};
export const setPagePartZoomMode = (payload) => {
  //payload should be one of these: zoomed, null
  return {
    type: SET_PAGE_PART_ZOOM_MODE,
    payload,
  };
};
export const setAddImageToCanvas = (payload) => {
  //true or false
  return {
    type: ADD_IMAGE_TO_CANVAS,
    payload,
  };
};
export const setInputFocused = (payload) => {
  //true or false
  return {
    type: SET_INPUT_FOCUSED,
    payload,
  };
};
export const showTimer = (payload) => {
  //true or false
  return {
    type: SET_TIMER_DISPLAY,
    payload,
  };
};
export const setZoomModeMenu = (payload) => {
  //true or false
  return {
    type: SET_ZOOM_MODE_MENU_OPEN,
    payload,
  };
};
export const setPageBrightness = (payload) => {
  return {
    type: SET_PAGE_BRIGHTNESS,
    payload,
  };
};
export const setBrightnessMenu = (payload) => {
  //true or false
  return {
    type: SET_BRIGHTNESS_MENU_OPEN,
    payload,
  };
};
export const setPageContrast = (payload) => {
  return {
    type: SET_PAGE_CONTRAST,
    payload,
  };
};
export const addMarginA = (payload) => {
  return {
    type: ADD_MARGIN_A,
    payload,
  };
};
export const addMarginB = (payload) => {
  return {
    type: ADD_MARGIN_B,
    payload,
  };
};
export const removeMarginA = (payload) => {
  return {
    type: REMOVE_MARGIN_A,
    payload,
  };
};
export const removeMarginB = (payload) => {
  return {
    type: REMOVE_MARGIN_B,
    payload,
  };
};

export const setShowMargins = (payload) => {
  return {
    type: SET_SHOW_MARGINS,
    payload,
  };
};
export const setZoomPartFullScaleVal = (payload) => {
  return {
    type: SET_PAGE_PART_ZOOM_FULL_SCALE_VAL,
    payload,
  };
};
export const setPageZoomPartShowLoading = (payload) => {
  return {
    type: SET_PAGE_PART_ZOOM_OBJECT_LOADING,
    payload,
  };
};