import React from "react";
import { useTranslation } from "react-i18next";

export default function PrevPage(props) {
  const { t } = useTranslation();
  return (
    <li onClick={props.onClick}>
      <span
        className={`button normal-btn prev-page ${
          props.shouldShowButtonLabels ? "show-label-span" : ""
        } ${
          props.activeButton === "select" ? "active" : ""
        }`}
        title={t("Go to Previous Page") + " (<-)"}
        data-tip={t("Go to Previous Page") + " (<-)"}
      >
        <img
          className={
            parseInt(props.currentPageNumber) === 1 ? "button-low-opacity" : ""
          }
          src={require("../../assets/img/svg/prev-page.svg")}
          alt={t("Go to Previous Page") + " (<-)"}
        />
         {props.shouldShowButtonLabels && <span className="show-label-text">{t("Prev")}</span>}
      </span>
    </li>
  );
}
