import React, { Component } from "react";
import Toolbar from "./Toolbar";

import Page from "./Page";
import { toggleSideBar } from "../actions/sidebar";
import { pullZipImages } from "../actions/pages";
import { changeSelectedToolFromOutside } from "../actions/selectedTool";
import { imagesLoaded as imagesLoadedAction } from "../actions/imageLoad";
import { hideInteractiveObjects as hideInteractiveObjectsAction } from "../actions/interactiveObjects";
// import ReactTooltip from "react-tooltip";

import OnImagesLoaded from "react-on-images-loaded";
import { Progress } from "reactstrap";
import { connect } from "react-redux";
// import Pixabay from "./Pixabay";
import Loading from "./Loading";
import {
  // BrowserView,
  // MobileView,
  // isBrowser,
  isMobile,
} from "react-device-detect";
import NewToolbar from "./NewToolbar";
import ToolbarAlternative from "./ToolbarAlternative";
import LoadingAnim from "./LoadingAnim";

class PdfPageList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeButton: "pan",
      // imagesLoaded: false,
      isMounted: false,
      // zipfileProgress:100,
      // zipFileLoaded:false,
    };
  }

  // hides and shows interactive objects on the page
  hideInteractiveObjects = () => {
    this.props.hideInteractiveObjectsAction();
  };

  onClickLoadAll = () => {
    if (this.props.zipFileLoaded) {
      return;
    }
    this.props.pullZipImages();
  };

  componentDidMount() {
    this.setState({
      isMounted: true,
    });
  }

  componentWillUnmount() {
    this.setState({
      isMounted: false,
    });
  }

  runAfterImagesLoaded = () => {
    if (this.state.isMounted) {
      this.props.imagesLoadedAction();
    }
  };

  renderPages() {
    if (this.state.isMounted) {
      let pages = this.props.currentPage;
      if (pages.length === 0) return false;
      if (typeof pages[0] === "undefined") return false;

      return (
        <OnImagesLoaded
          onLoaded={this.runAfterImagesLoaded}
          timeout={100}
          key={1}
        >
          {pages.map((page, index) => {
            const data = { ...page };

            return (
              <div key={index}>
                {/* {!isMobile && (
                  <ReactTooltip
                    place="top"
                    type="dark"
                    effect="float"
                    className="custom-color"
                    border={true}
                    delayShow={2500}
                    globalEventOff={isMobile ? "click" : undefined}
                  />
                )} */}

                {this.props.zipfileProgress != 100 && (
                  <div className="mb-5">
                    <Progress value={this.props.zipfileProgress}>
                      Images Loading... {this.props.zipfileProgress}%
                    </Progress>
                    {/*<Progress value={this.state.zipfileProgress - 25 }>Images Loading... {(this.state.zipfileProgress -25 < 0) ? 0 : this.state.zipfileProgress -25}%</Progress>*/}
                  </div>
                )}
                <Page
                  data={data}
                  key={page.id}
                  onChangeTool={(param) => this.onChangeTool(param)}
                  hideObjects={this.state.hideInteractiveObjects}
                  sidebarIsOpened={this.props.sidebarIsOpened}
                  pageNumber={page.pageNumber}
                  currentPageNumber={this.props.currentPageNumber}
                />
              </div>
            );
          })}
        </OnImagesLoaded>
      );
    }
  }

  onChangeTool(selectedTool) {
    this.props.changeSelectedToolFromOutside(selectedTool);
  }
  onChangeToolIcon(activeButton) {
    this.setState({ activeButton });
  }

  renderLoading() {
    if (!this.props.imagesLoaded) {
      return <Loading />;
    }
  }

  render() {
    const isSmallScreen = this.props.canvasVals
      ? parseInt(window.innerWidth) < 640
      : // ? parseInt(this.props.canvasVals.vpW) < 768
        false;
    return (
      <div
        className={`pdf-page-list
        ${this.props.annotationSelectionMode ? "qhideable-top-margin" : ""} ${
          this.props.pageZoomPartMode === "zoom-show" ? "no-top-padding" : ""
        } `} // Set top margin more when the qhideable editor opens
        id="pdfPageList"
        ref={this.props.forwardedRef}
        // onScroll={this.onScrollPage}
        style={{
          marginLeft: this.props.sidebarIsOpened
            ? !isSmallScreen
              ? 160
              : ""
            : "",
        }}
      >
              {this.props.settings.pageZoomPartShowLoading && <LoadingAnim />}
        {this.renderLoading()}
        <ul>
          <div id="mainOne" {...this.props.handlers}>
            {this.renderPages()}
          </div>
        </ul>
        <div
          className="page-number-bar-wrapper"
          style={{ marginLeft: this.props.sidebarIsOpened ? "-140px" : 0 }}
        >
          {/* <div in={this.state.showPageNumber} className="page-number-bar">
            {this.props.currentPageNumber} / {this.state.totalPageNumber}
          </div> */}
        </div>

        <div>
          {/* <ToolbarAlternative
            toolBarDraggable={this.props.toolBarDraggable}
            hideInteractiveObjects={this.hideInteractiveObjects}
            ref="child"
            onChangeTool={(param) => this.onChangeTool(param)}
            activeButton={this.state.activeButton}
            // zipFileLoaded={this.state.zipFileLoaded}
            {...this.props}
          /> */}
          <Toolbar
            toolBarDraggable={this.props.toolBarDraggable}
            hideInteractiveObjects={this.hideInteractiveObjects}
            ref="child"
            onChangeTool={(param) => this.onChangeTool(param)}
            activeButton={this.state.activeButton}
            // zipFileLoaded={this.state.zipFileLoaded}
            {...this.props}
          />
          {/* <NewToolbar /> */}
        </div>
        {/* <div>...</div> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  // console.log(state.pixabay.data);
  return {
    sidebarIsOpened: state.sideBarOpened,
    currentPageNumber: state.pages.currentPageNo,
    pages: state.pages.pages,
    currentPage: state.pages.currentPage,
    totalPageNumber: state.pages.totalPageNumber,
    zipfileProgress: state.zipDownloadProg,
    zipFileLoaded: state.pages.zipLoaded,
    hideInteractiveObjectsVal: state.hideInteractiveObjects,
    imagesLoaded: state.imagesLoaded,
    selectedTool: state.selectedTool,
    imageCapture: state.imageCapture,
    canvasVals: state.scaleZoomFactor.canvas,
    annotationSelectionMode: state.annotationSelections.annotationSelectionMode,
    pageZoomPartMode: state.settings.pageZoomPartMode,
    settings: state.settings,
  };
};

export default connect(mapStateToProps, {
  toggleSideBar,
  pullZipImages,
  hideInteractiveObjectsAction,
  imagesLoadedAction,
  changeSelectedToolFromOutside,
})(PdfPageList);
