import React from "react";
import {
  // HiXCircle,
  ImCheckmark,
  // MdSkipPrevious,
  // MdExitToApp
} from "react-icons/im";
import { useTranslation } from "react-i18next";

export default function SubmitButton(props) {
  const { t } = useTranslation();
  return (
    <>
      <button
        // disabled={props.isLoading}
        className={`button  is-small modal-button ${
          props.disabled ? "button-disabled" : ""
        }`}
        onClick={props.onClickHandler}
        title={t("Submit")}
      >
        <span className="has-text-success mr-2">{ t("Submit")}</span>
        <span className="icon  has-text-success">
          <ImCheckmark className="fas fa-2x" />
        </span>
      </button>
    </>
  );
}
