/*eslint no-unused-vars: 0*/

import FabricCanvasTool from "./fabrictool";

class Select extends FabricCanvasTool {
  configureCanvas(props) {
    let canvas = this._canvas;
    this.props = props.currentPage[0];
    canvas.isDrawingMode = false;
    canvas.selection = true;
    canvas.defaultCursor = "default";
    canvas.forEachObject((o) => {
      // vconsole.log('o', o.scaleX)
      o.selectable = o.evented = props.annotationSelectionMode
        ? true
        : o.qId
        ? false
        : true;
    });
  }
  doMouseDown(o) {
    // console.log("----", this._canvas);
    // console.log("----_canvas backgroundImage", this._canvas.backgroundImage);
    console.log(
      "----_selectedToolTop",
      this._canvas.getActiveObject() && this._canvas.getActiveObject().top
    );
    console.log(
      "----_selectedTool",
      this._canvas.getActiveObject() && this._canvas.getActiveObject()
    );
    // console.log("currentPage", this.props);

    // console.log('selected object> ', o)
    if (this._canvas._iTextInstances) {
      this._canvas._iTextInstances.forEach(function (ob) {
        ob.exitEditing();
      });
    }
  }
}

export default Select;
