import {
  SET_ANNOTATION_SELECTION_SAVE_MODE,
  SET_ANNOTATION_SELECTION_COORDS,
  SAVE_ANNOTATION_SELECTION_TO_BUTTON,
  RESET_ANNOTATION_SELECTION,
  SET_EDIT_SELECTION_MODE,
  SET_SELECTION_COPY,
  SET_SELECTION_PASTE,
  SET_SELECTION_REMOVE,
  SET_SELECTION_UNDO,
  SET_SELECTION_REDO,
  SET_ANNOTATION_BEFORE_STATE,
  SHOW_ANNOTATION_BY_ID,
  SHOW_ANNOTATIONS_BY_ID,
  SET_SELECTION_GROUP,
  SET_SELECTION_UNGROUP
} from "../actions/actionTypes";

var defaultState = {
  currentSelection: {
    top: null,
    left: null,
    width: null,
    height: null,
    qId: null,
    canvasWidth: null,
  },
  annotationSelectionMode: false,
  annotationSelectionToButton: false,
  annotations: [],
  annotationIdToShow: null,
  annotationIdsToShow:[],
  editSelectionMode: false,
  copyMode: false,
  pasteMode: false,
  removeMode: false,
  undoMode: false,
  redoMode: false,
  groupMode: false,
  unGroupMode: false,
  stateBefore: {
    //the sate before the qhideable opened. Example: if the buttons are hidden store this val to hide them afterwards
    buttonState: true,
    drawingState: true,
    zoomState: 1,
  },
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_ANNOTATION_SELECTION_SAVE_MODE:
      // console.log("action.payload", action.payload.mode);
      return {
        ...state,
        annotationSelectionMode: action.payload.mode,
      };
    case SET_EDIT_SELECTION_MODE:
      // console.log("action.payload", action.payload);
      return {
        ...state,
        editSelectionMode: action.payload,
      };
    case RESET_ANNOTATION_SELECTION:
      return {
        ...state,
        annotationSelectionToButton: false,
        currentSelection: {
          top: null,
          left: null,
          width: null,
          height: null,
          qId: null,
          canvasWidth: null,
        },
        annotationSelectionMode: false,
        annotationIdToShow: null,

        stateBefore: {
          buttonState: true,
          drawingState: true,
          zoomState: 1,
        },
      };
    case SET_ANNOTATION_SELECTION_COORDS:
      const { top, left, width, height, qId, proportion, visibility } = action.payload;
      return {
        ...state,
        currentSelection: {
          top,
          left,
          width,
          height,
          qId,
          proportion,
          visibility
        },
      };
    case SET_ANNOTATION_BEFORE_STATE:
      const { buttonState, drawingState, zoomState } = action.payload;
      // console.log("action.payload-----", action.payload);
      return {
        ...state,
        stateBefore: {
          buttonState,
          drawingState,
          zoomState,
        },
      };
    case SAVE_ANNOTATION_SELECTION_TO_BUTTON:
      const { pageId, annotation } = action.payload;
      // console.log("SAVE_ANNOTATION_SELECTION_TO_BUTTON", action.payload);
      // const newAnnotation = { pageId, annotation };
      return {
        // annotationSelectionMode: false,
        // currentSelection: {
        //   top: null,
        //   left: null,
        //   width: null,
        //   height: null,
        //   qId: null,
        // },
        // annotationSelectionToButton: true,
        ...state,
        annotations: action.payload,
      };

    case SET_SELECTION_COPY:
      // console.log("action.payload", action.payload);
      return {
        ...state,
        copyMode: action.payload,
      };
    case SET_SELECTION_PASTE:
      // console.log("action.payload", action.payload);
      return {
        ...state,
        pasteMode: action.payload,
      };
    case SET_SELECTION_REMOVE:
      // console.log("action.payload", action.payload);
      return {
        ...state,
        removeMode: action.payload,
      };
    case SET_SELECTION_UNDO:
      // console.log("action.payload", action.payload);
      return {
        ...state,
        undoMode: action.payload,
      };
    case SET_SELECTION_GROUP:
      // console.log("action.payload", action.payload);
      return {
        ...state,
        groupMode: action.payload,
      };
    case SET_SELECTION_UNGROUP:
      // console.log("action.payload", action.payload);
      return {
        ...state,
        unGroupMode: action.payload,
      };
    case SET_SELECTION_REDO:
      // console.log("action.payload", action.payload);
      return {
        ...state,
        redoMode: action.payload,
      };
    case SHOW_ANNOTATION_BY_ID:
      // console.log("action.payload", action.payload);
      return {
        ...state,
        annotationIdToShow: action.payload,
      };
    case SHOW_ANNOTATIONS_BY_ID:
      const currentIds=state.annotationIdsToShow;
      const payloadId=currentIds.find((id)=>id===action.payload)
      const newIds=payloadId?currentIds.filter((id)=>id!==action.payload):[...currentIds,action.payload]
      return {
        ...state,
        annotationIdsToShow: newIds,
      };

    default:
      return state;
  }
};
