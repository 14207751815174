import React, { Component } from "react";
import Index from "./_SidebarIndex/Index";
import DrawingItems from "./_SidebarIndex/DrawingItems";
import Bookmarks from "./_SidebarIndex/Bookmarks";
import InteractiveObjects from "./_SidebarIndex/InteractiveObjects";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

class SidebarIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clickedSection: "index",
    };
  }

  render() {
    return (
      <div className="columns is-mobile">
        {/*<a href="#" title="Close" className="modal-close-btn"><img src={require("../assets/img/svg/modal-close-btn.svg")}*/}
        {/*                                                           alt="Close"/></a>*/}
        <div className="column modal-left is-narrow is-fullheight">
          <div className="tabs">
            <ul>
              <li
                className={this.state.clickedSection == "index" ? "active" : ""}
              >
                <a
                  onClick={() => {
                    this.setState({ clickedSection: "index" });
                  }}
                >
                  <span className="tabs-icon">
                    <img
                      src={require("../assets/img/svg/tabs-icon-list.svg")}
                      alt={this.props.t("TOC")}
                    />
                  </span>
                  <span className="tabs-text">{this.props.t("TOC")}</span>
                </a>
              </li>
              <li
                className={
                  this.state.clickedSection == "interactive_objects"
                    ? "active"
                    : ""
                }
              >
                <a
                  onClick={() => {
                    console.log("asdkhbsakjdbsakdahs idu");
                    this.setState({ clickedSection: "interactive_objects" });
                  }}
                >
                  <span className="tabs-icon">
                    <img
                      src={require("../assets/img/svg/tabs-icon-intreractive-objects.svg")}
                      alt={this.props.t("Activities")}
                    />
                  </span>
                  <span className="tabs-text">
                    {this.props.t("Activities")}
                  </span>
                </a>
              </li>
              <li
                className={
                  this.state.clickedSection == "bookmarks" ? "active" : ""
                }
              >
                <a
                  onClick={() => {
                    this.setState({ clickedSection: "bookmarks" });
                  }}
                >
                  <span className="tabs-icon">
                    <img
                      src={require("../assets/img/svg/tabs-icon-bookmark.svg")}
                      alt={this.props.t("My Bookmarks")}
                    />
                  </span>
                  <span className="tabs-text">
                    {this.props.t("My Bookmarks")}
                  </span>
                </a>
              </li>
              <li
                className={
                  this.state.clickedSection == "drawing_items" ? "active" : ""
                }
              >
                <a
                  onClick={() => {
                    this.setState({ clickedSection: "drawing_items" });
                  }}
                >
                  <span className="tabs-icon">
                    <img
                      src={require("../assets/img/svg/tabs-icon-draw-objects.svg")}
                      alt={this.props.t("My Annotations")}
                    />
                  </span>
                  <span className="tabs-text">
                    {this.props.t("My Annotations")}
                  </span>
                </a>
              </li>
            </ul>
          </div>
        </div>
        {this.state.clickedSection === "index" && <Index />}
        {this.state.clickedSection === "drawing_items" && <DrawingItems />}
        {this.state.clickedSection === "bookmarks" && <Bookmarks />}
        {this.state.clickedSection === "interactive_objects" && (
          <InteractiveObjects />
        )}
      </div>
    );
  }
}

export default connect()(withTranslation()(SidebarIndex));
