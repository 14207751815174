import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import * as yup from "yup";
import katex from "katex";
import "katex/dist/katex.min.css";
import { connect, useSelector, useDispatch } from "react-redux";
import {
  addButtonModalClose,
  addButtonModalOpen,
} from "../../actions/addButton";
import {
  cancelActivity,
  doneActivity,
  insertActivity,
} from "../../actions/addActivity";
import { addMcQuestionModalClose } from "../../actions/quiz";
import { useTranslation } from "react-i18next";
import { toaster } from "../../actions/showToast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { guidGenerator } from "../../services/util";
// import { fas } from "@fortawesome/free-brands-svg-icons";
import {
  faCheckSquare,
  faCogs,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";

import ButtonStylesBulma from "./ButtonStylesBulma";
import SwitchOption from "./SwitchOption";

import { EmptyMultipleChoiceQuestion } from "./EmptyMultipleChoiceQuestion";
import { EmptyMCQAnswer } from "./EmptyMCQAnswer";
import { quillFormats, quillModules } from "../../services/constants";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

function MCQuestionForm(props) {
  useEffect(() => {
    console.log("katex added");
    window.katex = katex;
    library.add(faCheckSquare, faCogs);
  }, []); // Only re-run the effect if count changes

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const activityLeft = useSelector((state) => state.activities.activityLeft);
  const activityTop = useSelector((state) => state.activities.activityTop);
  const activityName = useSelector((state) => state.activities.activityName);
  const activityTitle = useSelector((state) => state.activities.activityTitle);
  const [buttonStyle, setButtonStyle] = useState("style1");
  const emptyQuestion = Object.assign({}, EmptyMultipleChoiceQuestion);
  console.log("emptyQuestion", emptyQuestion);
  const isMultiChoice = activityName === "mc_question";

  const schema = yup
    .object()
    .shape({
      type: yup.string().required(),
      root: yup.string().required(t("This field is required")),
      answer1: yup.string().required(t("This field is required")),
      answer2: yup.string().required(t("This field is required")),
      answer3: yup.string().when("type", {
        is: (type) =>
          type == "mc-3-answer" ||
          type == "mc-4-answer" ||
          type == "mc-5-answer" ||
          type == "mc-6-answer",
        then: yup.string().required(t("This field is required")),
      }),
      answer4: yup.string().when("type", {
        is: (type) =>
          type == "mc-4-answer" ||
          type == "mc-5-answer" ||
          type == "mc-6-answer",
        then: yup.string().required(t("This field is required")),
      }),
      answer5: yup.string().when("type", {
        is: (type) => type == "mc-5-answer" || type == "mc-6-answer",
        then: yup.string().required(t("This field is required")),
      }),
      answer6: yup.string().when("type", {
        is: (type) => type == "mc-6-answer",
        then: yup.string().required(t("This field is required")),
      }),
      maxTimesToRepeat: yup.number().required(t("This field is required")),
      // randomizeAnswers: yup.boolean(),
      // canHaveInstantFeedback: yup.boolean(),
      // enableRetryButton: yup.boolean(),
      // showSolution: yup.boolean(),
      // requireAnswerToViewSolution: yup.boolean(),
      generalFeedbackOnCorrectAnswer: yup.string(),
      generalFeedbackOnIncorrectAnswer: yup.string(),
      top: yup
        .number()
        .required(t("This field is required"))
        .typeError(t("This field must be number")),
      left: yup
        .number()
        .required(t("This field is required"))
        .typeError(t("This field must be number")),
      answer1_check: yup.boolean(),
      answer2_check: yup.boolean(),
      answer3_check: yup.boolean(),
      answer4_check: yup.boolean(),
      answer5_check: yup.boolean(),
      answer6_check: yup.boolean(),
      // isVisible: yup.boolean(),
      // isPublic: yup.boolean(),
    })
    .test("atLeastOneTrue", null, (obj) => {
      if (
        obj.answer1_check ||
        obj.answer2_check ||
        obj.answer3_check ||
        obj.answer4_check ||
        obj.answer5_check ||
        obj.answer6_check
      ) {
        return true; // everything is fine
      }

      return new yup.ValidationError(
        t("The question does not have a correct answer!"),
        null,
        "answers_true"
      );
    })
    .test("maximumOneTrue", null, (obj) => {
      const ansChecks = [
        obj.answer1_check,
        obj.answer2_check,
        obj.answer3_check,
        obj.answer4_check,
        obj.answer5_check,
        obj.answer6_check,
      ];
      let trues = 0;
      ansChecks.map((ans) => {
        ans && trues++;
      });
      if (trues === 1 || !isMultiChoice) {
        return true; // everything is fine
      }

      return new yup.ValidationError(
        t("The question can not have one more then correct answer!"),
        null,
        "answers_true"
      );
    });

  const { register, handleSubmit, watch, errors, control, setValue } = useForm({
    resolver: yupResolver(schema),
  });

  const myRatio =
    props.canvasVals.canvasBackgroundImageWidth / props.canvasVals.canvasWidth; //ratio of the book page
  const originRatio = 1000 / props.canvasVals.canvasBackgroundImageWidth; //ratio of the book editor - 1000
  const fixedLeft =
    activityLeft &&
    parseFloat(myRatio * parseFloat(activityLeft) * originRatio).toFixed(2);
  const fixedTop =
    activityTop &&
    parseFloat(myRatio * parseFloat(activityTop) * originRatio).toFixed(2) - 12; //button width/2

  const onSubmit = (data) => {
    console.log("herrreee");
    console.log("data", data);
    const qId = guidGenerator(); //temporary
    emptyQuestion.left = data.left;
    emptyQuestion.id = qId;
    emptyQuestion.root = data.root;
    // emptyQuestion.subject = null; //TODO
    // emptyQuestion.outcomes = null; //TODO
    // emptyQuestion.description = null; //TODO
    // emptyQuestion.question_point = 1; //TODO
    emptyQuestion.answers = [];
    data.answer1 &&
      emptyQuestion.answers.push({
        id: "answer1",
        question_id: qId,
        answer: data.answer1,
        true: data.answer1_check,
      });
    data.answer2 &&
      emptyQuestion.answers.push({
        id: "answer2",
        question_id: qId,
        answer: data.answer2,
        true: data.answer2_check,
      });
    data.answer3 &&
      emptyQuestion.answers.push({
        id: "answer3",
        question_id: qId,
        answer: data.answer3,
        true: data.answer3_check,
      });
    data.answer4 &&
      emptyQuestion.answers.push({
        id: "answer4",
        question_id: qId,
        answer: data.answer4,
        true: data.answer4_check,
      });
    data.answer5 &&
      emptyQuestion.answers.push({
        id: "answer5",
        question_id: qId,
        answer: data.answer5,
        true: data.answer5_check,
      });
    data.answer6 &&
      emptyQuestion.answers.push({
        id: "answer6",
        question_id: qId,
        answer: data.answer6,
        true: data.answer6_check,
      });
    //   data.answer1,
    //   data.answer2,
    //   watch("type") == "mc-3-answer" && data.answer3,
    //   (watch("type") == "mc-3-answer" || watch("type") == "mc-4-answer") &&
    //     data.answer4,
    //   (watch("type") == "mc-3-answer" ||
    //     watch("type") == "mc-4-answer" ||
    //     watch("type") == "mc-5-answer") &&
    //     data.answer5,
    //   (watch("type") == "mc-3-answer" ||
    //     watch("type") == "mc-4-answer" ||
    //     watch("type") == "mc-5-answer" ||
    //     watch("type") == "mc-6-answer") &&
    //     data.answer6,
    // ];
    // emptyQuestion.submitted = false;
    // emptyQuestion.notes = null; //TODO

    emptyQuestion.maxTimesToRepeat = data.maxTimesToRepeat; //how many times to repeat if canHaveInstantFeedback===true
    emptyQuestion.repeatCount = data.repeatCount;
    emptyQuestion.canHaveInstantFeedback = data.canHaveInstantFeedback;
    emptyQuestion.enableRetryButton = data.enableRetryButton;
    emptyQuestion.enableShowSolutionButton = data.showSolution;
    emptyQuestion.randomizeAnswers = data.randomizeAnswers;
    emptyQuestion.requireAnswerToViewSolution =
      data.requireAnswerToViewSolution;
    emptyQuestion.generalFeedbackOnCorrectAnswer =
      data.generalFeedbackOnCorrectAnswer;
    emptyQuestion.generalFeedbackOnIncorrectAnswer =
      data.generalFeedbackOnIncorrectAnswer;
    emptyQuestion.left = data.left;
    emptyQuestion.top = data.top;
    emptyQuestion.height = 24;
    emptyQuestion.width = 24;
    emptyQuestion.show = data.isVisible;
    emptyQuestion.button_type = activityName;
    emptyQuestion.own_activity = 1;
    emptyQuestion.style = buttonStyle;
    emptyQuestion.status = 1;
    emptyQuestion.public = data.isPublic;
    emptyQuestion.answer = isMultiChoice ? null : [];
    console.log("emptyQuestion", emptyQuestion);
    props.insertActivity(emptyQuestion, props.currentPageId);
    props.addMcQuestionModalClose();
    // this.props.insertActivity(response.data);
    props.doneActivity();
    // return;

    // const sendData = {
    //   id: guidGenerator(),
    //   left: myRatio * parseInt(data.left) * originRatio, // * props.canvasVals.vpW) / 1000, // -
    //   top: myRatio * parseInt(data.top) * originRatio, // * ratio, // - 24,
    //   height: 24,
    //   width: 24,
    //   show: 1,
    //   button_type: data.type,
    //   own_activity: 1,
    //   style: buttonStyle,
    //   status: 1,
    //   public: 1,
    //   content: "",
    // };
    // console.log("sendData", sendData);
    /*
    var formData = new FormData();

    formData.append("content", sendData.content);
    formData.append("button_type", sendData.button_type);
    formData.append("style", sendData.style);
    formData.append("top", parseInt(sendData.top));
    formData.append("left", parseInt(sendData.left));
    formData.append("show", parseInt(sendData.show));
    formData.append("status", parseInt(sendData.status));
    formData.append("sharing", parseInt(sendData.public));
    console.error("formData", formData);
    addButtonService(
      formData,
      String(domain).toLowerCase(),
      props.currentPageId
    )
      .then(async (response) => {
        console.log("response FORMMM", response);
        sendData.id = response.id;
        props.addButtonModalClose();
        // this.props.insertActivity(response.data);
        props.doneActivity();
        props.insertActivity(sendData, props.currentPageId);

        //  window.location.reload();
      })
      .catch((error) => {
        console.log("logged error", error);
      }); */
    //   .then((response) => {
    //     console.log("response", response);
    //     this.props.addButtonModalClose();
    //     // this.props.insertActivity(response.data);
    //     this.props.doneActivity();
    //   })
    //   .catch(() => {});
  };

  const selectButtonStyle = (bstyle) => {
    setButtonStyle(bstyle);
    console.log("bstyle", bstyle);
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {/* <div className="activity-title">
        {activityName === "mr_question"
          ? t("Create a Multiple Response Question")
          : t("Create a Multiple Choice Question")}
      </div> */}
      <div className="q-form">
        <div class="field is-horizontal ">
          <div class="field-label is-normal has-text-left">
            <label className="label">{t("Question Type")}</label>
          </div>
          <div class="field-body">
    <div class="field is-narrow">
          <div className="control">
            <div className="select q-form-span">
              <select name="type" ref={register} defaultValue={"mc-2-answer"}>
                <option value="mc-2-answer">
                  {t(activityTitle) + t(" with 2 Answers")}
                </option>
                <option value="mc-3-answer">
                  {t(activityTitle) + t(" with 3 Answers")}
                </option>
                <option value="mc-4-answer">
                  {t(activityTitle) + t(" with 4 Answers")}
                </option>
                <option value="mc-5-answer">
                  {t(activityTitle) + t(" with 5 Answers")}
                </option>
                <option value="mc-6-answer">
                  {t(activityTitle) + t(" with 6 Answers")}
                </option>
              </select>
              </div>
      </div>
    </div>
  </div>
</div>

        <div class="columns pb-0 mb-1">
          <div class="column is-three-fifths  pt-3 pb-1 has-text-weight-bold"> {t("Question Root")}</div>
          <div class="column has-text-right pb-1"> <a
            href="http://www.imatheq.com/imatheq/com/imatheq/math-equation-editor-latex-mathml.html"
            className="tag is-info"
            target="_blank"
          >
            {t("Open Math Editor in a new page")}
          </a>{" "}
          <a
            className="tag is-success"
            onClick={() => {
              dispatch(
                toaster(
                  t(
                    "Use Math Editor to write your formulas and then past the Latex formula in editor's formula tab"
                  ) + " (fx button)",
                  "success"
                )
              );
            }}
          >
            ?
          </a></div>
        </div>


      
        <div className="field">
          <Controller
            control={control}
            name="root"
            defaultValue=""
            error={errors.root}
            render={({ onChange, onBlur, value }) => (
              <ReactQuill
                theme="snow"
                className="quill-root"
                modules={quillModules}
                formats={quillFormats}
                onChange={(root, delta, source, editor) => onChange(root)}
                value={value || ""}
              />
            )}
          />
         
          <div className="error-message">{errors.root?.message}</div>
        </div>

        <hr />
        <div className="columns">
          <div className="column box mx-2 ">
            <div className=" has-text-centered	has-text-weight-bold	">
              {t("Answers")}
            </div>
            <hr  className="mt-1 mb-2"/>
            <div className="field is-horizontal">
            <div className="field-label is-normal"  style={{ flexGrow:0 }} >
              <label className="label">{t("A-)")}</label>
            </div>
            <div className="field-body">
             <div className="field">
              <div className="control">
                <textarea
                  className={`textarea is-small ${
                    watch("answer1_check") ? "is-primary" : "is-warning"
                  }`}
                  placeholder={t("Answer")}
                  ref={register}
                  name="answer1"
                  rows={2}
                ></textarea>
                <div className="error-message">{errors.answer1?.message}</div>
                <div className="control">
                  <label className="checkbox">
                    <input
                      type="checkbox"
                      ref={register}
                      name="answer1_check"
                    />
                    <span className=" q-form-span ml-2 is-unselectable">
                      {t("Correct Answer")}
                    </span>
                  </label>
                </div>
              </div>
              </div>
              </div>
            </div>
            <div className="field is-horizontal">
               <div className="field-label is-normal"   style={{ flexGrow:0 }} >
                  <label className="label">{t("B-)")}</label>
               </div>
              <div className="field-body">
              <div className="field">
              <div className="control">
                <textarea
                  className={`textarea is-small ${
                    watch("answer2_check") ? "is-primary" : "is-warning"
                  }`}
                  placeholder={t("Answer")}
                  ref={register}
                  name="answer2"
                  rows={2}
                ></textarea>
                <div className="error-message">{errors.answer2?.message}</div>
                <label className="checkbox">
                  <input type="checkbox" ref={register} name="answer2_check" />
                  <span className="q-form-span ml-2 is-unselectable">
                    {t("Correct Answer")}
                  </span>
                </label>
                </div>
               </div>
              </div>
            </div>



            {(watch("type") == "mc-3-answer" ||
              watch("type") == "mc-4-answer" ||
              watch("type") == "mc-5-answer" ||
              watch("type") == "mc-6-answer") && (
                <div className="field is-horizontal">
                  <div className="field-label is-normal"  style={{ flexGrow:0 }} >
                     <label className="label">{t("C-)")}</label>
                  </div>
                <div className="field-body">
                  <div className="field">
                <div className="control">
                  <textarea
                    className={`textarea is-small ${
                      watch("answer3_check") ? "is-primary" : "is-warning"
                    }`}
                    placeholder={t("Answer")}
                    ref={register}
                    name="answer3"
                    rows={2}
                  ></textarea>
                  <div className="error-message">{errors.answer3?.message}</div>
                  <label className="checkbox">
                    <input
                      type="checkbox"
                      ref={register}
                      name="answer3_check"
                    />
                    <span className=" q-form-span ml-2 is-unselectable">
                      {t("Correct Answer")}
                    </span>
                  </label>
                  </div>
               </div>
              </div>
            </div>
            )}
            {(watch("type") == "mc-4-answer" ||
              watch("type") == "mc-5-answer" ||
              watch("type") == "mc-6-answer") && (
                <div className="field is-horizontal">
                   <div className="field-label is-normal"  style={{ flexGrow:0 }} >
                     <label className="label">{t("D-)")}</label>
                  </div>
                  <div className="field-body">
                   <div className="field">
                <div className="control">
                  <textarea
                    className={`textarea is-small ${
                      watch("answer4_check") ? "is-primary" : "is-warning"
                    }`}
                    placeholder={t("Answer")}
                    ref={register}
                    name="answer4"
                    rows={2}
                  ></textarea>
                  <div className="error-message">{errors.answer4?.message}</div>
                  <label className="checkbox">
                    <input
                      type="checkbox"
                      ref={register}
                      name="answer4_check"
                    />
                    <span className=" q-form-span ml-2 is-unselectable">
                      {t("Correct Answer")}
                    </span>
                  </label>
                  </div>
               </div>
              </div>
            </div>
            )}
            {(watch("type") == "mc-5-answer" ||
              watch("type") == "mc-6-answer") && (
                <div className="field is-horizontal">
                    <div className="field-label is-normal"  style={{ flexGrow:0 }} >
                       <label className="label">{t("E-)")}</label>
                    </div>
                    <div className="field-body">
                   <div className="field">
                <div className="control">
                  <textarea
                    className={`textarea is-small ${
                      watch("answer5_check") ? "is-primary" : "is-warning"
                    }`}
                    placeholder={t("Answer")}
                    ref={register}
                    name="answer5"
                    rows={2}
                  ></textarea>
                  <div className="error-message">{errors.answer5?.message}</div>
                  <label className="checkbox">
                    <input
                      type="checkbox"
                      ref={register}
                      name="answer5_check"
                    />
                    <span className=" q-form-span ml-2 is-unselectable">
                      {t("Correct Answer")}
                    </span>
                  </label>
                  </div>
               </div>
              </div>
            </div>
            )}
            {watch("type") == "mc-6-answer" && (
              <div className="field is-horizontal">
              <div className="field-label is-normal"  style={{ flexGrow:0 }} >
                <label className="label">{t("F-)")}</label>
                </div>
                <div className="field-body">
                   <div className="field">
                <div className="control">
                  <textarea
                    className={`textarea is-small ${
                      watch("answer6_check") ? "is-primary" : "is-warning"
                    }`}
                    placeholder={t("Answer")}
                    ref={register}
                    name="answer6"
                    rows={2}
                  ></textarea>
                  <div className="error-message">{errors.answer6?.message}</div>
                  <label className="checkbox">
                    <input
                      type="checkbox"
                      ref={register}
                      name="answer6_check"
                    />
                    <span className=" q-form-span ml-2 is-unselectable">
                      {t("Correct Answer")}
                    </span>
                  </label>
                  </div>
               </div>
              </div>
            </div>
            )}
          </div>
          <div className="column box mx-2">
            <div className="has-text-centered	has-text-weight-bold	">
              <FontAwesomeIcon icon={faCogs} className="mr-3" />
              {t("Answer Options")}
            </div>
            <hr  className="mt-1 mb-2"/>
            <div className="field columns">
              <div className="column is-half has-text-left">
                <label className="label">{t("Maximum Times to Repeat")}</label>
              </div>
              <div className="column is-one-fifth">
                <div className="control">
                  <input
                    className="input"
                    placeholder={t("Number")}
                    defaultValue={1}
                    min={1}
                    max={100}
                    type="number"
                    ref={register}
                    name="maxTimesToRepeat"
                  />
                </div>
                <div className="error-message">
                  {errors.maxTimesToRepeat?.message}
                </div>
              </div>
            </div>
            <div className="field">
              <label className="checkbox">
                <input
                  type="checkbox"
                  ref={register}
                  name="randomizeAnswers"
                  defaultChecked={true}
                />
                <span className="ml-2 is-unselectable">
                  {t("Randomize Answers")}
                </span>
              </label>
            </div>

            <div className="field">
              <label className="checkbox">
                <input
                  type="checkbox"
                  ref={register}
                  name="canHaveInstantFeedback"
                  defaultChecked={true}
                />
                <span className="ml-2 is-unselectable">
                  {t("Enable Instant Feedback")}
                </span>
              </label>
            </div>
            <div className="field">
              <label className="checkbox">
                <input
                  type="checkbox"
                  ref={register}
                  name="enableRetryButton"
                />
                <span className="ml-2 is-unselectable">
                  {t("Enable Retry")}
                </span>
              </label>
            </div>
            <div className="field">
              <label className="checkbox">
                <input
                  type="checkbox"
                  ref={register}
                  name="showSolution"
                  defaultChecked={true}
                />
                <span className="ml-2 is-unselectable">
                  {t("Show Solution")}
                </span>
              </label>
            </div>
            <div className="field">
              <label className="checkbox">
                <input
                  type="checkbox"
                  ref={register}
                  name="requireAnswerToViewSolution"
                />
                <span className="ml-2 is-unselectable">
                  {t("Require Answer to View the Solution")}
                </span>
              </label>
            </div>
            <div className="field">
              <label className="label">
                {t("Feedback for Correct Answer")}
              </label>
              <div className="control">
                <textarea
                  className={"textarea is-small"}
                  placeholder={t("Feedback for Correct Answer")}
                  defaultValue={t("Congrats, your answer is correct!")}
                  ref={register}
                  name="generalFeedbackOnCorrectAnswer"
                  rows={2}
                ></textarea>

                <label className="label">
                  {t("Feedback for Incorrect Answer")}
                </label>
                <div className="control">
                  <textarea
                    className={"textarea is-small"}
                    placeholder={t("Feedback for Incorrect Answer")}
                    defaultValue={t("Your answer is not correct.")}
                    ref={register}
                    name="generalFeedbackOnIncorrectAnswer"
                    rows={2}
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr  className="mt-1 mb-2"/>
        <div className="columns"></div>
        <div className="column box mx-2">
          <div className="has-text-centered	has-text-weight-bold	">
            <FontAwesomeIcon icon={faCogs} className="mr-3" />
            {t("Button Options")}
          </div>
          <hr  className="mt-1 mb-2"/>
          <div className="field is-grouped is-grouped-multiline  is-grouped-centered">
            <div className="field-label is-normal q-input">
              <label className="label">{t("Top")}</label>
            </div>

            <p className="control">
              <input
                className="input "
                placeholder={t("Number")}
                defaultValue={fixedTop}
                // min="0"
                // max="2000"
                type="number"
                ref={register}
                name="top"
                step="any"
              />
              <span className="error-message">{errors.top?.message}</span>
            </p>

            <div className="field-label is-normal q-input">
              <label className="label">{t("Left")}</label>
            </div>

            <p className="control">
              <input
                className="input "
                placeholder={t("Number")}
                defaultValue={fixedLeft}
                // min="0"
                // max="2000"
                type="number"
                ref={register}
                name="left"
                step="any"
              />
              <span className="error-message">{errors.left?.message}</span>
            </p>
            <div className="control">
              <label className="checkbox">
                <input
                  type="checkbox"
                  ref={register}
                  name="isVisible"
                  defaultChecked={true}
                />
                <span className=" q-form-span ml-2 is-unselectable">
                  {t("Visible")}
                  {/* <FontAwesomeIcon icon={faEyeSlash} className="mr-3" /> */}
                </span>
              </label>
            </div>
            <div className="control">
              <label className="checkbox">
                <input
                  type="checkbox"
                  ref={register}
                  name="isPublic"
                  defaultChecked={true}
                />
                <span className=" q-form-span ml-2 is-unselectable">
                  {t("Public")}
                </span>
              </label>
            </div>
          </div>
          <ButtonStylesBulma
            activityType={t("Question")}
            selectButtonStyle={selectButtonStyle}
          />
        </div>
        <div className=" has-background-danger has-text-light has-text-weight-bold has-text-centered">
          {errors.answers_true?.message}
        </div>

        <div className="field is-grouped is-grouped-right">
          <div className="control">
            <input
              type="reset"
              value={t("Cancel")}
              onClick={props.addMcQuestionModalClose}
              className="cancel-btn"
            />
          </div>
          <div className="control">
            <input type="submit" value={t("Submit")} className="submit-btn" />
          </div>
        </div>
      </div>
    </form>
  );
}

const mapStateToProps = (state) => {
  return {
    currentPageId: state.pages.currentPage[0].id,
    canvasScaleX: state.scaleZoomFactor.canvas.scaleX,
    canvasScaleY: state.scaleZoomFactor.canvas.scaleY,
    canvasVals: state.scaleZoomFactor.canvas,
    sideBarOpened: state.sideBarOpened,
  };
};

export default connect(mapStateToProps, {
  addButtonModalOpen,
  addButtonModalClose,
  cancelActivity,
  doneActivity,
  insertActivity,
  addMcQuestionModalClose,
})(MCQuestionForm);
