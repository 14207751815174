import {GET_QWANT,CLOSE_QWANT} from "./actionTypes";

export const getQwant = () => {
    return {
        type: GET_QWANT
    }
};


export const closeQwant = () => {
    return {
        type: CLOSE_QWANT
    }
};