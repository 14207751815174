import React from 'react';
import {connect} from "react-redux";

class Spinner extends React.Component {

    render() {
        // console.log('spinner start', this.props.spinner )
        return (
            <div>{this.props.spinner && <div className="loading">
                LoadingAAA ...
            </div>}</div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        spinner: state.spinner
    };
};

export default connect(mapStateToProps)(Spinner);

