import React, { useState, useEffect, useRef } from "react";
import { BsStopwatchFill } from "react-icons/bs";
import useSound from "use-sound";
import bell from "../../assets/sounds/bell.mp3";

export default function Timer(props) {
  const timerRef = useRef(null);
  const [play] = useSound(bell);
  const [time, setTime] = React.useState(60);
  React.useEffect(() => {
    if (time <= 0) {
      window.clearInterval(timerRef.current);
      play();
    } else {
      timerRef.current = window.setInterval(() => {
        setTime((prevTime) => prevTime - 1); // <-- Change this line!
      }, 1000);
      return () => {
        window.clearInterval(timerRef.current);
      };
    }
  }, [time, timerRef]);

  const seconds = Math.floor(time % 60);
  const minutes = Math.floor((time / 60) % 60);

  return (
    <>
      <button
        className={`button is-small modal-button timer-div`}
        onClick={() => {
          setTime((prevTime) => (Math.floor((prevTime / 60) % 60) + 1) * 60);
        }}
      >
        <span
          className={`icon ${
            time < 10 ? "has-text-danger" : "has-text-light"
          }`}
        >
          <BsStopwatchFill className="fas fa-lg" />
        </span>
        <span
          className={`is-size-5  mx-2 ${
            time < 10 ? "has-text-danger" : "has-text-light"
          }`}
        >
          {time > 0 ? ("0" + minutes).slice(-2) : "00"}
        </span>
        <span
          className={`is-size-5   ${
            time < 10 ? "has-text-danger" : "has-text-light"
          }`}
        >
          {time > 0 ? (": 0" + seconds).slice(-2) : ": 00"}
        </span>
      </button>
    </>
  );
}
