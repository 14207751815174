import {
  SUBMIT_MC_QUESTION,
  SELECT_MC_QUESTION,
  MC_QUESTION_MODAL_OPEN,
  MC_QUESTION_MODAL_CLOSE,
  ADD_MC_QUESTION_MODAL_OPEN,
  ADD_MC_QUESTION_MODAL_CLOSE,
} from "../actions/actionTypes";

var defaultActivitiesState = {
  questions: [],
  currentQuestion: null,
  activityTop: null,
  temp_ques_ans: [],
  session_id: "",
  result: false,
  modalOpen: false,
  quizAddModalOpen: false,
};

export default (state = defaultActivitiesState, action) => {
  switch (action.type) {
    case SELECT_MC_QUESTION:
      const qIndex = action.payload[0];
      const qAnswer = action.payload[1];
      const answerId = action.payload[3];
      return {
        ...state,
        questions: [
          ...state.questions.slice(0, qIndex),
          {
            ...state.questions[qIndex],
            selectedAnswer: qAnswer,
            answer: answerId,
          },
          ...state.questions.slice(1 + qIndex),
        ],
      };
    case SUBMIT_MC_QUESTION:
      const qIndex_ = action.payload[0];
      const qAnswer_ = action.payload[1];
      const isTrueAnswer_ = action.payload[2];
      const answerId_ = action.payload[3];
      return {
        ...state,
        questions: [
          ...state.questions.slice(0, qIndex_),
          {
            ...state.questions[qIndex_],
            selectedAnswer: qAnswer_,
            isTrue: isTrueAnswer_,
            answer: answerId_,
            submitted: true,
          },
          ...state.questions.slice(1 + qIndex_),
        ],
      };

    case MC_QUESTION_MODAL_OPEN:
      return { ...state, modalOpen: true };

    case MC_QUESTION_MODAL_CLOSE:
      return { ...state, modalOpen: false };

    case ADD_MC_QUESTION_MODAL_OPEN:
      return { ...state, quizAddModalOpen: true };

    case ADD_MC_QUESTION_MODAL_CLOSE:
      return { ...state, quizAddModalOpen: false };

    default:
      return state;
  }
};
