export const EmptyMultipleChoiceQuestion = {
  id: 187,
  root: null,
  subject: null,//TODO
  outcomes: null,//TODO
  description: null,//TODO
  question_point: 1,//TODO
  answers: null,
  favorited: false,
  submitted: false,
  answer: null,
  selectedAnswer: null,
  timeSpent: null,
  notes: null,//TODO
  isTrue: null,
  maxTimesToRepeat: 1, //how many times to repeat if canHaveInstantFeedback===true
  repeatCount: 0,
  canHaveInstantFeedback: false,
  enableRetryButton: false,
  enableShowSolutionButton: false,
  showSolution: false,
  randomizeAnswers: false,
  requireAnswerToViewSolution: false,
  generalFeedbackOnCorrectAnswer: null,
  generalFeedbackOnIncorrectAnswer: null,
  left: 0,
  top: 0,
  height: 24,
  width: 24,
  show: 1,
  button_type: "mc_question",
  own_activity: 1,
  style: "style1",
  status: 1,
  sharing: 1,
};
