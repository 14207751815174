import React from "react";
import {
  // HiXCircle,
  // MdSkipNext,
  BsFillSkipBackwardFill,
  // MdExitToApp
} from "react-icons/bs";
import { useTranslation } from "react-i18next";

export default function PreviousPageButton(props) {
  const { t } = useTranslation();
  return (
    <>
      <button
        // disabled={props.isLoading}
        className={`button is-small modal-button ${
          props.disabled ? "button-disabled" : ""
        }`}
        onClick={props.onClickHandler}
        title={t("Previous")}
      >
        <span className="icon  has-text-light mb-2">
          <BsFillSkipBackwardFill className="fas fa-2x" />
        </span>
        <span className="has-text-light ml-2 is-hidden-tablet-only  is-hidden-mobile mb-2">{t("Previous Page")}</span>
      </button>
    </>
  );
}
