import { THEME_CHANGE } from "../actions/actionTypes";

export default (state = localStorage.theme||'dark', action) => {//TODO fix side effect of local storage - non pure function
  switch (action.type) {
    case THEME_CHANGE:
      localStorage.theme = action.payload;
      return action.payload;

    default:
      return state;
  }
};
