import {SEARCHBAR_OPEN, SEARCHBAR_CLOSE} from '../actions/actionTypes'

export default (state = false, action) => {
    switch (action.type) {

        case SEARCHBAR_OPEN:
            return true;
            break;
        case SEARCHBAR_CLOSE:
            return false;
            break;

        default:
            return state;
    }
};