import { login } from "../services/api";
import {
  SET_LOGIN_PENDING,
  SET_LOGIN_SUCCESS,
  SET_DOMAIN,
  SET_LIBRARY_FROM_LOCAL,
  SET_LIBRARY_OFFLINE,
  //   SET_LIBRARY,
} from "./actionTypes";

export const attemptLogin = () => {
  return (dispatch) => {
    login
      .then(function (data) {
        dispatch(setLoginSuccess());
      })
      .catch(function (err) {
        dispatch(setLoginPending());

        console.info("in the error block");
      });
  };
};

// export const setLibrary = () => {
//   return {
//     type: SET_LIBRARY,
//   };
// };
export const setLoginSuccess = (data) => {
  // console.log('data', data)
  return {
    type: SET_LOGIN_SUCCESS,
    payload: data,
  };
};
export const setLibraryOffline = (data) => {
  // console.log('data', data)
  return {
    type: SET_LIBRARY_OFFLINE,
    payload: data,
  };
};
export const setDomain = (data) => {
  return {
    type: SET_DOMAIN,
    payload: data,
  };
};
export const setLibraryFromLocal = () => {
    // console.log(' setLibraryFromLocal called!')
  return {
    type: SET_LIBRARY_FROM_LOCAL,
    // payload: data,
  };
};

export const setLoginPending = () => {
  return {
    type: SET_LOGIN_PENDING,
  };
};
