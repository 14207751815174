import { CAPTURE_ON, CAPTURE_OFF } from "./actionTypes";

export const toggleCapture = isCaptureOn => {
  return dispatch => {
    if (isCaptureOn) {
      dispatch(captureClose());
    } else {
      dispatch(captureOpen());
      // dispatch(searchBarClose());
    }
  };
};

export const captureClose = () => {
  return {
    type: CAPTURE_ON
  };
};

export const captureOpen = () => {
  return {
    type: CAPTURE_OFF
  };
};
