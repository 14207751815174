import React from "react";
import {
  // HiXCircle,
  ImBrightnessContrast,
  // MdSkipPrevious,
  // MdExitToApp
} from "react-icons/im";
import { useTranslation } from "react-i18next";

export default function BrightnessButton(props) {
  const { t } = useTranslation();
  return (
    <>
      <button
        // disabled={props.isLoading}
        className={`button  is-small modal-button ${
          props.disabled ? "button-disabled" : ""
        }`}
        // onClick={props.onClickHandler}
        title={t("Page Brightness")}
      >
        <span className="has-text-light mr-2">{ t("Page Brightness")}</span>
        <span className="icon  has-text-light">
          <ImBrightnessContrast className="fas fa-2x" />
        </span>
      </button>
    </>
  );
}
