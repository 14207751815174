import {GET_WIKIZERO,CLOSE_WIKIZERO} from '../actions/actionTypes'

export default (state = false, action) => {
    switch (action.type) {

        case GET_WIKIZERO:
            return true;

        case CLOSE_WIKIZERO:
            return false;

        default:
            return state;
    }
};