import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import Sidebar from "./Sidebar";
import SearchBar from "./SearchBar";
import PdfPageList from "./PdfPageList";
import { connect, useSelector, useDispatch } from "react-redux";
import { toggleSideBar, sideBarOpen, sideBarClose } from "../actions/sidebar";
import { toggleSearchBar } from "../actions/searchbar";
// import { pageNoChange } from "../actions/pages";
import { pullPages, pageNoChange, getPages } from "../actions/pages";
import Loading from "./Loading";
// import QuestionButton from "./SketchSelectedButtons/QuestionButton";
// import CopyButton from "./SketchSelectedButtons/CopyButton";
import PasteButton from "./SketchSelectedButtons/PasteButton";
import RemoveButton from "./SketchSelectedButtons/RemoveButton";
// import UndoButton from "./SketchSelectedButtons/UndoButton";
// import RedoButton from "./SketchSelectedButtons/RedoButton";
import Timer from "./SketchSelectedButtons/Timer";
import ExitButton from "./SketchSelectedButtons/ExitButton";
import ReactSlider from "react-slider";
// import ApproveButton from "./SketchSelectedButtons/ApprovZoomPagePartButtons";
import {
  setAnnotationSelectionMode,
  // setAnnotationSelectionCoords,
  // saveAnnotationSelectionToButton,
  resetAnnotationSelectionMode,
  setEditSelectionMode,
  setSelectionCopyMode,
  setSelectionPasteMode,
  setSelectionRemoveMode,
  setSelectionUndoMode,
  setSelectionRedoMode,
  setAnnotationBeforeState,
  setSelectionGroupMode,
  setSelectionUnGroupMode,
} from "../actions/annotationSelection";
import {
  setPagePartZoomMode,
  setPagePartZoomObject,
  setPagePartZoomObjectToShow,
  showTimer,
  setPageBrightness,
  setPageContrast,
  setBrightnessMenu,
  setZoomModeMenu,
} from "../actions/settings";

import {
  BrowserRouter as Router,
  useLocation,
  useParams,
  // useRouteMatch,
  useHistory,
} from "react-router-dom";
// import { withTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Offline } from "react-detect-offline";
import startTour from "./Tour";
import TourIntro3 from "./TourIntro3";
import {
  captureInProcess,
  hideCaptureImage,
  captureNotInprocess,
} from "../actions/imageCapture";

// import ReactTooltip from "react-tooltip";
import { addButtonService, addButtonFormService } from "../services/api";

import { insertActivity } from "../actions/addActivity";
import BottomMenu from "./BottomMenu";
// import ResponsiveMenu from "./ResponsiveMenu";
import {
  hideInteractiveObjects,
  showInteractiveObjects,
} from "../actions/interactiveObjects";
import BottomDeleteMenu from "./BottomDeleteMenu";
import CurtainMenu from "./CurtainMenu";
import GroupButton from "./SketchSelectedButtons/GroupButton";
import UnGroupButton from "./SketchSelectedButtons/UnGroupButton";
import ColorButton from "./SketchSelectedButtons/ColorButton";
import { CompactPicker } from "react-color";
import {
  setLineWidth,
  setLineColor,
  setFillColor,
  setOpacity,
} from "../actions/toolbar";
import BrightnessButton from "./SketchSelectedButtons/BrightnessButton";
import ContrastButton from "./SketchSelectedButtons/ContrastButton";
import CancelButton from "./SketchSelectedButtons/CancelButton";
import SubmitButton from "./SketchSelectedButtons/SubmitButton";
import ResetButton from "./SketchSelectedButtons/ResetButton";
import ReactGA from "react-ga";
import { setLibraryOffline } from "../actions/login";
import NewToolbar from "./NewToolbar";
import ZoomModeButton1 from "./SketchSelectedButtons/ZoomModeButton1";

// import RecordView from "./RecordView";

// import { useSwipeable } from "react-swipeable";

function MainPage(props) {
  const CompactPickerColors = (<CompactPicker />).props.colors;
  const _pdfPageList = useRef(null);
  const { annotationSelectionMode } = props;
  const [showColor, setShowColor] = useState(false);
  const [ebooks, setEbooks] = useState([]);

  const { t, i18n } = useTranslation();
  let location = useLocation();
  let history = useHistory();

  let { page } = useParams();

  useEffect(() => {
    const disablePinchZoom = (e) => {
      if (e.touches.length > 1) {
        e.preventDefault();
      }
    };
    document.addEventListener("touchmove", disablePinchZoom, {
      passive: false,
    });
    return () => {
      document.removeEventListener("touchmove", disablePinchZoom);
    };
  }, []);
  const jsonLoader = async () => {
    const bookIds = process.env.REACT_APP_OFFLINE_EBOOKS.split(" ");
    console.log(bookIds[0]);
    console.log(`JSON LOADER bookIds`, bookIds, bookIds.length);
    const books = [];
    await bookIds.map((book) => {
      console.log(`book`, book);
      import("../assets/offline/" + book + "/data")
        .then((background) => {
          console.log(`background`, background.default[0]);
          return background.default;
        })

        .then(async (myJson) => {
          console.log(myJson[0]);
          myJson.map((mj) => {
            mj.icon = require("../assets/offline/" + book + "/" + mj.icon);
            return mj;
          });
          myJson[0].ebook_pages.map((mj) => {
            mj.path = require("../assets/offline/" + book + "/" + mj.path);
            mj.thumbnail = require("../assets/offline/" +
              book +
              "/" +
              mj.thumbnail);
            mj.buttons.map((btn) => {
              if (btn.button_type === "image" || btn.button_type === "audio") {
                btn.content = require("../assets/offline/" +
                  book +
                  "/" +
                  btn.content);
              }
              return btn;
            });
            return mj;
          });
          console.log("myJson[0]", myJson[0]);
          // const { id, icon } = myJson[0];
          // const libBook = {
          //   id,
          //   icon: "/offline/" + book + "/" + icon,
          //   name: myJson[0].name,
          // };
          // console.log(`id, icon`, id, icon);
          // console.log(`libBook`, libBook);
          // newLibrary.push(libBook);
          // this.counter(libBook);
          console.log(`...ebooks`, ebooks);
          console.log(
            `myJson[0].id===book`,
            myJson[0].id === book,
            myJson[0].id,
            book
          );
          books.push(myJson[0]);
          if (myJson[0].id == book) {
            props.getPages(myJson[0]);
          }
          // if(ebooks.length>0){
          //   setEbooks([...ebooks], myJson[0]);
          // }else{
          //   setEbooks([myJson[0]])
          // }
          // const newEbooks = [ebooks, myJson[0]];
          // setEbooks(newEbooks);

          // this.setState(
          //   {
          //     ebooks: [...this.state.ebooks, myJson[0]],
          //     // library: [...this.state.library, libBook],
          //   },
          //   () => this.props.setLibraryOffline(this.state.ebooks)
          // );
        });
    });
    // console.log(`newLibrary`, newLibrary);
    await props.setLibraryOffline(books);
    setEbooks(books);
    // this.setState({
    //   ebooks:1
    // })
  };

  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID, {
      debug: false,
      titleCase: false,
      gaOptions: {
        userId: 123,
      },
    });
    ReactGA.pageview("/home");
    const isOffline = process.env.REACT_APP_OFFLINE === "true";
    console.log(`page`, page);
    console.log(`ebooks---------`, ebooks);
    console.log(`location`, location);
    console.log(`localStorage.cenibook_id`, localStorage.cenibook_id);
    const book = localStorage.cenibook_id;

    if (isOffline) {
      if (props.library.length === 0) {
        jsonLoader();
      }
      const booksLength = process.env.REACT_APP_OFFLINE_EBOOKS.split(" ")
        .length;
      console.log(`booksLength`, booksLength, ebooks);
      if (ebooks.length > 0 && ebooks.length === booksLength) {
        const ebook = ebooks.filter(
          (ebook) => ebook.id === localStorage.cenibook_id
        )[0];
        console.log(`ebook`, ebook);
        ebook && props.getPages(ebook);
      }
      // if (!props.currentPageId) {
      //   import("../assets/offline/" + book + "/data")
      //     .then((background) => {
      //       return background.default;
      //     })
      //     // fetch("./offline/" + book + "/data.json", {
      //     //   headers: {
      //     //     "Content-Type": "application/json",
      //     //     Accept: "application/json",
      //     //   },
      //     // })
      //     //   .then((response) => {
      //     //     console.log(response);
      //     //     return response.json();
      //     //   })
      //     .then((myJson) => {
      //       console.log(myJson[0]);
      //       // console.log(myJson.json());
      //       myJson[0].ebook_pages.map((mj) => {
      //         mj.path = require("../assets/offline/" + book + "/" + mj.path);
      //         mj.thumbnail = require("../assets/offline/" +
      //           book +
      //           "/" +
      //           mj.thumbnail);
      //         mj.buttons.map((btn) => {
      //           if (
      //             btn.button_type === "image" ||
      //             btn.button_type === "audio"
      //           ) {
      //             btn.content = require("../assets/offline/" +
      //               book +
      //               "/" +
      //               btn.content);
      //           }
      //           return btn;
      //         });
      //         return mj;
      //       });
      //       // console.log(`bookJson`, bookJson)
      //       console.log("myJson[0]", myJson[0]);
      //       // const { id, icon } = myJson[0];
      //       // const libBook = {
      //       //   id,
      //       //   icon: "/offline/" + book + "/" + icon,
      //       //   name: myJson[0].name,
      //       // };
      //       // console.log(`id, icon`, id, icon);
      //       // console.log(`libBook`, libBook);
      //       // newLibrary.push(libBook);
      //       // this.counter(libBook);
      //       // this.setState({
      //       //   ebooks: [...this.state.ebooks, myJson[0]],
      //       //   library: [...this.state.library, libBook],
      //       // });
      //       props.getPages(myJson[0]);
      //     });
      // }
    } else {
      props.pullPages();
    }

    // isOffline ? props.getPages() : props.pullPages();
    if (localStorage.showIntro !== "false") {
      startTour();
    }

    if (window.innerWidth < 1024) {
      props.sideBarClose();
    }
  }, []);

  useEffect(() => {
    props.pageNoChange(parseInt(page));
    if (
      props.pageZoomPartObjectToShow.orderId &&
      props.pageZoomPartMode !== "zoom-show"
    ) {
      props.setPagePartZoomObjectToShow(
        null,
        { x: null, y: null },
        { x: null, y: null }
      );
      props.showInteractiveObjects();
    }
  }, [location]);

  useEffect(() => {
    if (props.pageZoomPartMode && props.pageZoomPartMode !== "zoom-show")
      props.setPagePartZoomMode(false);
    if (props.annotationSelectionMode) props.setAnnotationSelectionMode(false);
    if (props.editSelectionMode) props.setEditSelectionMode(false);
  }, [props.currentPageId]);

  // useEffect(() => {
  //  props.annotationIdToShow &&
  // }, [props.annotationIdToShow]);

  const prevPageNumber = useRef();
  useEffect(() => {
    if (
      props.currentPageNumber &&
      props.totalPageNumber > 0 &&
      parseInt(props.currentPageNumber) > parseInt(props.totalPageNumber)
    ) {
      props.pageNoChange(1);
    }

    if (
      props.pageZoomPartMode === "zoom-show" &&
      parseInt(props.currentPageNumber) !== parseInt(prevPageNumber.current)
    ) {
      if (!props.currentPage[0].buttons) return;
      const qZoomButtons = props.currentPage[0].buttons
        .filter((bt) => bt.button_type === "zoomPagePart")
        .map((btz) => {
          return JSON.parse(btz.content);
        })
        .sort((a, b) => b.orderId - a.orderId);
      // .reverse();

      console.log(
        `qZoomButtons`,
        parseInt(props.currentPageNumber),
        parseInt(prevPageNumber.current),
        qZoomButtons
      );
      if (qZoomButtons.length === 0) {
        // toaster(
        //   t(
        //     "Exit presentation mode"
        //   ) ,
        //   "warning"
        // )
        props.setPagePartZoomMode(null);
        return;
      }
      const { orderId, downCoords, upCoords, cutCoords } = qZoomButtons[
        parseInt(props.currentPageNumber) >= parseInt(prevPageNumber.current)
          ? qZoomButtons.length - 1
          : 0 //qZoomButtons.length - 1
      ];
      setTimeout(() => {
        props.setPagePartZoomObjectToShow(
          orderId,
          downCoords,
          upCoords,
          cutCoords
        );
      }, 10);

      // props.setPagePartZoomObjectToShow()
    }

    prevPageNumber.current = props.currentPageNumber;
  }, [props.currentPageNumber]);

  useEffect(() => {
    if (annotationSelectionMode)
      props.setAnnotationBeforeState({
        buttonState: props.hideInteractiveObjectsVal,
        drawingState: props.showAnnotations,
        zoomState: props.scaleZoomFactorVal.scaleFactor,
      });
  }, [annotationSelectionMode]);

  const status = parseInt(props.totalPageNumber) > 0;

  useEffect(() => {
    if (
      props.annotations.annotation &&
      props.annotations.annotation.length > 0
    ) {
      const myRatio =
        props.canvasVals.canvasBackgroundImageWidth /
        props.canvasVals.canvasWidth; //ratio of the book page
      const originRatio = 1000 / props.canvasVals.canvasBackgroundImageWidth; //ratio of the book editor - 1000
      const bLeft =
        Math.floor(
          myRatio * parseInt(props.currentSelection.left) * originRatio
        ) - 10;

      const sendData = {
        // id: guidGenerator(), //For redux based id generation.
        left: bLeft > 0 ? bLeft : 0, // parseInt(props.currentSelection.left),
        top: Math.floor(
          myRatio * parseInt(props.currentSelection.top) * originRatio
        ), //parseInt(props.currentSelection.top),
        height: parseInt(props.currentSelection.height),
        width: parseInt(props.currentSelection.width),
        show: 1,
        button_type: props.currentSelection.visibility
          ? "questionShowable"
          : "questionHideable",
        own_activity: 1,
        style: "style2",
        status: 1,
        public: 1,
        sharing: 1,
        content: JSON.stringify(props.annotations.annotation),
      };
      const formData = new FormData();
      formData.append("content", sendData.content);
      formData.append("button_type", sendData.button_type);
      formData.append("style", sendData.style);
      formData.append("top", sendData.top);
      formData.append("left", sendData.left);
      formData.append("width", sendData.width);
      formData.append("height", sendData.height);
      formData.append("show", sendData.show);
      formData.append("status", sendData.status);
      formData.append("sharing", sendData.sharing);
      addButtonService(
        formData,
        String(props.domain).toLowerCase(),
        props.currentPageId
      )
        .then(async (response) => {
          sendData.id = response.id;
          await props.insertActivity(sendData, props.currentPageId);
          // await props.setAnnotationSelectionMode(true);
          // await props.setAnnotationSelectionMode(false);

          // setIsLoading(false);
        })
        .catch((error) => {
          console.log("logged error", error);

          // setIsLoading(false);
        });
      props.resetAnnotationSelectionMode();
      console.log("degiti");
    }
  }, [props.annotations]);

  const saveZoomButton = () => {
    sendZoomButton(props);
  };

  const shouldTopMenuOpened =
    props.editSelectionMode || props.timerMode || props.zoomModeMenuOpen;
  const shouldBrightnessMenuOpened = props.editSelectionMode || props.timerMode;
  const shouldBottomMenuOpened =
    props.annotationSelectionMode || props.pageZoomPartMode;
  // console.log("shouldBottomMenuOpened", shouldBottomMenuOpened);

  const onClickLineSubItem = (width) => {
    props.setLineWidth(width * 3);
  };
  const onChangeOpacity = (opacity) => {
    props.setOpacity(opacity);
  };
  const onChangeBrightness = (val) => {
    props.setPageBrightness(val);
  };
  const onChangeContrast = (val) => {
    props.setPageContrast(val);
  };

  const onChangeFillColor = (color) => {
    console.log("color", color, showColor);
    if (showColor === "fill") {
      // console.log(` props.toolbar.fillColor === color`,  props.toolbar.fillColor, color)
      if (props.toolbar.fillColor === color.hex) {
        props.setFillColor(null);
        setTimeout(() => {
          props.setFillColor(color.hex);
        }, 100);
      } else {
        props.setFillColor(color.hex);
      }
    } else {
      if (props.toolbar.lineColor === color.hex) {
        props.setLineColor(null);
        setTimeout(() => {
          props.setLineColor(color.hex);
        }, 100);
      } else {
        props.setLineColor(color.hex);
      }
    }

    setShowColor(false);
  };
  const renderTopMenu = () => {
    return (
      <>
        <div
          className={`navbar is-fixed-top top-annotation-menu ${
            shouldTopMenuOpened ? "open" : "hide"
          } `}
        >
          {props.timerMode && <Timer />}
          {props.zoomModeMenuOpen && (
            <>
              <ZoomModeButton1
                onClickHandler={() => {
                  props.setSelectionGroupMode(true);
                }}
              />
            </>
          )}

          {props.editSelectionMode && (
            <>
              <GroupButton
                onClickHandler={() => {
                  props.setSelectionGroupMode(true);
                }}
              />
              <UnGroupButton
                onClickHandler={() => {
                  props.setSelectionUnGroupMode(true);
                }}
              />
              <PasteButton
                onClickHandler={() => {
                  props.setSelectionPasteMode(true);
                }}
              />

              {props.editSelectionMode !== "image" && (
                <>
                  {props.editSelectionMode !== "path" && (
                    <ColorButton
                      color={props.toolbar.currentFillColor}
                      title={t("Fill Color")}
                      onClickHandler={() => {
                        setShowColor(showColor !== "fill" ? "fill" : false);
                        // props.setSelectionRemoveMode(true);
                      }}
                    />
                  )}

                  <ColorButton
                    title={t("Line Color")}
                    color={props.toolbar.currentLineColor}
                    onClickHandler={() => {
                      setShowColor(showColor !== "line" ? "line" : false);
                      // props.setSelectionRemoveMode(true);
                    }}
                  />
                </>
              )}
              <ReactSlider
                className="horizontal-slider"
                thumbClassName="opacity-thumb"
                trackClassName="opacity-track"
                onAfterChange={onChangeOpacity}
                defaultValue={props.toolbar.currentOpacity}
                value={props.toolbar.currentOpacity}
                min={0}
                max={1}
                step={0.1}
                renderThumb={(props, state) => <div {...props}></div>}
              />

              <RemoveButton
                onClickHandler={() => {
                  props.setSelectionRemoveMode(true);
                }}
              />
            </>
          )}

          {(props.annotationSelectionMode ||
            props.timerMode ||
            props.zoomModeMenuOpen) && (
            <ExitButton
              hideTitle={props.timerMode}
              onClickHandler={() => {
                props.annotationSelectionMode &&
                  props.setAnnotationSelectionMode(false);
                props.timerMode && props.showTimer(false);
                props.zoomModeMenuOpen && props.setZoomModeMenu(false);
              }}
            />
          )}
        </div>
        {showColor && (
          <div className="top-color-picker">
            <CompactPicker
              colors={[...CompactPickerColors, "transparent"]}
              onChange={onChangeFillColor}
            />
          </div>
        )}
      </>
    );
  };

  const bookContrastMenu = () => {
    return (
      <>
        {props.settings.brightnessMenuOpen && (
          <div
            className={`navbar is-fixed-top  brightness-menu top-annotation-menu  ${
              true ? "open" : "hide"
            } `}
          >
            <div class="columns">
              <div class="column  is-half">
                <BrightnessButton />
              </div>
              <div class="column  is-half 	">
                <ReactSlider
                  className="horizontal-slider slider-brightness"
                  thumbClassName="simple-thumb"
                  trackClassName="simple-track"
                  onAfterChange={onChangeBrightness}
                  defaultValue={props.settings.pageBrightness}
                  value={props.settings.pageBrightness}
                  min={-1}
                  max={1}
                  step={0.1}
                  renderThumb={(props, state) => <div {...props}></div>}
                />
              </div>
            </div>
            <div class="columns">
              <div class="column  is-half 	">
                <ContrastButton />
              </div>
              <div class="column  is-half 	">
                <ReactSlider
                  className="horizontal-slider slider-brightness"
                  thumbClassName="simple-thumb"
                  trackClassName="simple-track"
                  onAfterChange={onChangeContrast}
                  defaultValue={props.settings.pageContrast}
                  value={props.settings.pageContrast}
                  min={-1}
                  max={1}
                  step={0.1}
                  renderThumb={(props, state) => <div {...props}></div>}
                />
              </div>
            </div>
            <div className="brightness-sub-div">
              <ResetButton
                onClickHandler={() => {
                  onChangeBrightness(0);
                  onChangeContrast(0);
                }}
              />
              <SubmitButton
                onClickHandler={() => props.setBrightnessMenu(false)}
              />
            </div>
          </div>
        )}
      </>
    );
  };

  // const renderSubmitQHideableButton = () => {
  //   const top = props.currentSelection.top
  //     ? props.currentSelection.top - props.currentSelection.height
  //     : window.innerHeight - 100;
  //   const left = props.currentSelection.top
  //     ? props.currentSelection.left + props.currentSelection.width
  //     : window.innerWidth - 100;

  //   return (
  //     <>
  //       {props.annotationSelectionMode && (
  //         <button
  //           className="float-edit-button"
  //           style={{ top, left }}
  //           onClick={() => {
  //             console.log("tik");
  //           }}
  //         >
  //           {t("Select an annotation or add one to submit.")}{" "}
  //         </button>
  //       )}
  //     </>
  //   );
  // };
  const checkIfUserCanDeleteActivity = () => {
    if (props.annotationIdsToShow?.length === 1) {
      const result = props.currentPage[0].buttons.filter((btn) => {
        return btn.own_activity && btn.id === props.annotationIdsToShow[0];
      });
      return result.length;
    } else {
      return false;
    }
  };

  return (
    <div>
      {status ? (
        <div>
          <div
            className="App"
            style={{
              display: `${
                props.imageCapture.captureInprocess ? "none" : "block"
              }`,
            }}
          >
            <div
              className="hero is-fullhd is-fullheight"
              data-theme={props.theme}
            >
              {/* <RecordView/> */}
              <div className="columns is-gapless is-full">
                {/* <NavBar /> */}

                <ToastContainer autoClose={2000} />
                {bookContrastMenu()}
                {renderTopMenu()}
                <BottomDeleteMenu
                  shouldMenuOpened={checkIfUserCanDeleteActivity()}
                  // editDisabled={saveZoomButton}
                />
                <BottomMenu
                  shouldBottomMenuOpened={shouldBottomMenuOpened}
                  saveZoomButton={saveZoomButton}
                />
                {props.selectedTool === "curtain" && <CurtainMenu />}
                {/* {!props.searchBarOpen && !props.sidebarOpened && (
                  <ResponsiveMenu shouldResponsiveMenuOpened={true} />
                )} */}
                <Offline>
                  <div className="no-internet-ebook">
                    {t("No internet connection.")}
                  </div>
                </Offline>
                <div className="column content" id="parentOne">
                  <TourIntro3 />
                  <PdfPageList
                    toolBarDraggable={false}
                    // handlers={props.selectedTool === "pan" ? handlers : null}
                    forwardedRef={_pdfPageList}
                  />
                </div>

                <Sidebar />
                {/* <NewToolbar/> */}
                <div className={`is-pulled-right`}>
                  <SearchBar />
                </div>

                {props.selectedTool !== "curtain" && (
                  <button
                    className="float-search-button collapse-searchbar is-pulled-right"
                    onClick={
                      () => props.toggleSearchBar(props.searchBarOpened) //TODO Add same for the left side bar-thumbnails
                    }
                  >
                    <span className="icon">
                      <img
                        src={require("../assets/img/svg/toolbar-search.svg")}
                        alt="Collapse Sidebar"
                      />
                    </span>
                  </button>
                )}
                {/* {renderSubmitQHideableButton()} */}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="hero is-fullhd is-fullheight content"
          data-theme={props.theme}
        >
          <Loading />
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    theme: state.theme,
    pages: state.pages.pages,
    pagesState: state.pages,
    totalPageNumber: state.pages.totalPageNumber,
    currentPageNumber: state.pages.currentPageNo,
    currentPageId: state.pages.currentPage[0]?.id,
    currentPage: state.pages.currentPage,
    pageLoaded: state.pageLoaded,
    imageCapture: state.imageCapture,
    imagesLoaded: state.imagesLoaded,
    scaleZoomFactorVal: state.scaleZoomFactor,
    selectedTool: state.selectedTool.selectedTool,
    searchBarOpened: state.searchBarOpen,
    annotationSelectionMode: state.annotationSelections.annotationSelectionMode,
    editSelectionMode: state.annotationSelections.editSelectionMode,
    currentSelection: state.annotationSelections.currentSelection,
    annotations: state.annotationSelections.annotations,
    domain: state.login.user_profile?.domain_name,
    ebookId: state.pages.ebookId,
    hideInteractiveObjectsVal: state.hideInteractiveObjects,
    showAnnotations: state.settings.showAnnotations,
    canvasVals: state.scaleZoomFactor.canvas,
    pageZoomPartMode: state.settings.pageZoomPartMode,
    pageZoomPartObject: state.settings.pageZoomPartObject,
    pageZoomPartObjectToShow: state.settings.pageZoomPartObjectToShow,
    sidebarOpened: state.sideBarOpened,
    searchBarOpen: state.searchBarOpen,
    timerMode: state.settings.timerMode,
    zoomModeMenuOpen: state.settings.zoomModeMenuOpen,
    annotationIdsToShow: state.annotationSelections.annotationIdsToShow,
    toolbar: state.toolbar,
    settings: state.settings,
    library: state.login.library,
  };
};

export default connect(mapStateToProps, {
  toggleSideBar,
  toggleSearchBar,
  pageNoChange,
  pullPages,
  captureInProcess,
  captureNotInprocess,
  hideCaptureImage,
  sideBarOpen,
  sideBarClose,
  setAnnotationSelectionMode,
  setEditSelectionMode,
  resetAnnotationSelectionMode,
  setSelectionCopyMode,
  setSelectionPasteMode,
  setSelectionRemoveMode,
  setSelectionUndoMode,
  setSelectionRedoMode,
  setAnnotationBeforeState,
  insertActivity,
  setPagePartZoomMode,
  setPagePartZoomObject,
  hideInteractiveObjects,
  showInteractiveObjects,
  setPagePartZoomObjectToShow,
  setSelectionGroupMode,
  setSelectionUnGroupMode,
  showTimer,
  setLineWidth,
  setLineColor,
  setFillColor,
  setOpacity,
  setPageBrightness,
  setPageContrast,
  setBrightnessMenu,
  setLibraryOffline,
  getPages,
  setZoomModeMenu,
})(MainPage);
function sendZoomButton(props) {
  const originRatio = 1000 / props.canvasVals.canvasBackgroundImageWidth; //ratio of the book editor - 1000
  const bLeft =
    Math.floor(parseInt(props.pageZoomPartObject.downCoords.x * originRatio)) -
    25;
  const bTop = Math.floor(
    parseInt(props.pageZoomPartObject.downCoords.y * originRatio) +
      parseInt(props.pageZoomPartObject.upCoords.y * originRatio) / 2
  );
  const sendData = {
    left: bLeft > 0 ? bLeft : 0,
    top: bTop > 0 ? bTop : 0,
    height: Math.floor(
      parseInt(props.pageZoomPartObject.upCoords.x * originRatio)
    ),
    width: Math.floor(
      parseInt(props.pageZoomPartObject.upCoords.y * originRatio)
    ),
    show: 1,
    button_type: "zoomPagePart",
    own_activity: 1,
    style: "style2",
    status: 1,
    public: 1,
    sharing: 1,
    content: JSON.stringify(props.pageZoomPartObject),
  };
  console.error("----->>>>>>>>>>>>>>>sendData", sendData);
  addButtonFormService(
    sendData,
    String(props.domain).toLowerCase(),
    props.currentPageId
  )
    .then((response) => {
      console.log("response", response);
      sendData.id = response.id;
      props.insertActivity(sendData, props.currentPageId);
      // props.setPagePartZoomMode(null);
      props.setPagePartZoomObject(
        null,
        { x: null, y: null },
        { x: null, y: null }
      );
    })
    .catch((error) => {
      console.log("logged error", error);
    });
  // props.resetAnnotationSelectionMode();
  console.log("degiti");
}
