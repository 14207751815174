import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { toaster } from "../../actions/showToast";

export default function AddHideArea(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onClickAdd = () => {
    // console.log("--Add a note..", props);
    !props.hideButton && props.onClick();
    dispatch(
      toaster(t("Select the area you want to hide."), "warning", 3000)
    );
    // props.changeMyTool("select");
  };

  return (
    <li onClick={onClickAdd}>
      <span
        className={`button normal-btn next-page ${
          props.shouldShowButtonLabels ? "show-label-span" : ""
        } ${
          props.activeButton === "hidearea" ? "active" : ""
        }`}
        title={t("Add Hide Area")}
        data-tip={t("Add Hide Area")}
      >
        <img
          src={require("../../assets/img/svg/toolbar-show-hide-area.svg")}
          alt={t("Add Hide Area")}
        />
        {props.shouldShowButtonLabels && <span className="show-label-text">{t("Open/Close")}</span>}
      </span>
    </li>
  );
}
