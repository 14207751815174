import React, { Component } from "react";
import CustomModal from "../CustomModal";
import MCQuestionForm from "./MCQuestionForm";
import { connect, useSelector, useDispatch } from "react-redux";

import {
  addMcQuestionModalOpen,
  addMcQuestionModalClose,
} from "../../actions/quiz";

function ShowMCQuestionForm(props) {
  const toggleModal = () => {
    if (props.modalOpen) {
      props.addMcQuestionModalClose();
    } else {
      props.addMcQuestionModalOpen();
    }
  };

  return (
    <div>
      {props.modalOpen ? (
        <CustomModal
          isOpen={props.modalOpen}
          toggleModal={(e) => toggleModal(e)}
          data={{ button_type: "component", content: <MCQuestionForm /> }}
        />
      ) : (
        ""
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    // modalOpen:true,
    modalOpen: state.quiz.quizAddModalOpen,
  };
};

export default connect(mapStateToProps, {
  addMcQuestionModalOpen,
  addMcQuestionModalClose,
})(ShowMCQuestionForm);
