import {
  SET_ANNOTATION_SELECTION_SAVE_MODE,
  SET_ANNOTATION_SELECTION_COORDS,
  SAVE_ANNOTATION_SELECTION_TO_BUTTON,
  RESET_ANNOTATION_SELECTION,
  SET_EDIT_SELECTION_MODE,
  SET_SELECTION_COPY,
  SET_SELECTION_PASTE,
  SET_SELECTION_REMOVE,
  SET_SELECTION_UNDO,
  SET_SELECTION_REDO,
  SET_ANNOTATION_BEFORE_STATE,
  SHOW_ANNOTATION_BY_ID,
  SHOW_ANNOTATIONS_BY_ID,
  SET_SELECTION_GROUP,
  SET_SELECTION_UNGROUP
} from "./actionTypes";

export function setAnnotationSelectionMode(mode) {
  //mode is boolean
  return function (dispatch) {
    dispatch({
      type: SET_ANNOTATION_SELECTION_SAVE_MODE,
      payload: { mode },
    });
  };
}
export function setEditSelectionMode(mode) {
  //mode is boolean
  return function (dispatch) {
    dispatch({
      type: SET_EDIT_SELECTION_MODE,
      payload: mode,
    });
  };
}
export function setSelectionCopyMode(mode) {
  //mode is boolean
  return function (dispatch) {
    dispatch({
      type: SET_SELECTION_COPY,
      payload: mode,
    });
  };
}
export function setSelectionPasteMode(mode) {
  //mode is boolean
  return function (dispatch) {
    dispatch({
      type: SET_SELECTION_PASTE,
      payload: mode,
    });
  };
}
export function setSelectionRemoveMode(mode) {
  //mode is boolean
  return function (dispatch) {
    dispatch({
      type: SET_SELECTION_REMOVE,
      payload: mode,
    });
  };
}
export function setSelectionUndoMode(mode) {
  //mode is boolean
  return function (dispatch) {
    dispatch({
      type: SET_SELECTION_UNDO,
      payload: mode,
    });
  };
}
export function setSelectionGroupMode(mode) {
  //mode is boolean
  return function (dispatch) {
    dispatch({
      type: SET_SELECTION_GROUP,
      payload: mode,
    });
  };
}
export function setSelectionUnGroupMode(mode) {
  //mode is boolean
  return function (dispatch) {
    dispatch({
      type: SET_SELECTION_UNGROUP,
      payload: mode,
    });
  };
}
export function setSelectionRedoMode(mode) {
  //mode is boolean
  return function (dispatch) {
    dispatch({
      type: SET_SELECTION_REDO,
      payload: mode,
    });
  };
}
export function showAnnotationById(id) {
  return function (dispatch) {
    dispatch({
      type: SHOW_ANNOTATION_BY_ID,
      payload: id,
    });
  };
}
export function showAnnotationsById(id) {
  return function (dispatch) {
    dispatch({
      type: SHOW_ANNOTATIONS_BY_ID,
      payload: id,
    });
  };
}
export function resetAnnotationSelectionMode() {
  //mode is boolean
  return function (dispatch) {
    dispatch({
      type: RESET_ANNOTATION_SELECTION,
    });
  };
}
export function setAnnotationSelectionCoords(
  top,
  left,
  width,
  height,
  qId,
  proportion,
  visibility=false//Should be visible on the page or opposite at the beginning
) {
  return function (dispatch) {
    dispatch({
      type: SET_ANNOTATION_SELECTION_COORDS,
      payload: { top, left, width, height, qId, proportion, visibility },
    });
  };
}
export function setAnnotationBeforeState(beforeState) {
  // const {buttonState, drawingState, zoomState}=beforeState;
  return function (dispatch) {
    dispatch({
      type: SET_ANNOTATION_BEFORE_STATE,
      payload: beforeState,
    });
  };
}
export function saveAnnotationSelectionToButton(
  // pageId,
  annotation
) {
  //annotation as json
  return function (dispatch) {
    dispatch({
      type: SAVE_ANNOTATION_SELECTION_TO_BUTTON,
      payload: {
        // pageId,
        annotation,
      },
    });
  };
}
