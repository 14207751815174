import React, { Component } from "react";
import { withTranslation } from "react-i18next";

class Selecter extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    // console.log("this.props.activeButton", this.props.activeButton);
    return (
      // <li onClick={!this.props.hideButton && this.props.onClick}>
      <li onClick={!this.props.hideButton ? this.props.onClick : undefined}>
        <span
          className={`button normal-btn selecter ${
            this.props.shouldShowButtonLabels ? "show-label-span" : ""
          }  ${
            this.props.activeButton === "select" ? "active" : ""
          }${this.props.hideButton ? "passive" : ""}`}
          title={this.props.t("Select annotations and objects") + " (D)"}
        >
          <img
            src={require("../../assets/img/svg/toolbar-select.svg")}
            alt={this.props.t("Select annotations and objects") + " (D)"}
          />
          {this.props.shouldShowButtonLabels && <span className="show-label-text">{this.props.t("Select")}</span>}
        </span>
      </li>
    );
  }
}
export default withTranslation()(Selecter);
