import React from 'react';
import { useTranslation } from "react-i18next";



import { GoogleLogin } from 'react-google-login';
// refresh token
// import { refreshTokenSetup } from '../utils/refreshToken';



function Login(props) {
  const { t, i18n } = useTranslation();
  return (
    <div className='w-100 has-text-centered	'>
      <GoogleLogin
        clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
        buttonText={t('Login with Google')}
        onSuccess={props.onSuccess}
        onFailure={props.onFailure}
        cookiePolicy={'single_host_origin'}
        style={{ marginTop: '100px' }}
        isSignedIn={false}
        disabled={process.env.REACT_APP_GOOGLE_CLIENT_ID === "false"}
      />
    </div>
  );
}

export default Login;
