import {GET_WIKIPEDIA,CLOSE_WIKIPEDIA} from "./actionTypes";

export const getWikipedia = () => {
    return {
        type: GET_WIKIPEDIA
    }
};


export const closeWikipedia = () => {
    return {
        type: CLOSE_WIKIPEDIA
    }
};