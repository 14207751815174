import { PAGE_SCALE_UP } from "./actionTypes";

export const setScalePageUp = (scaleFactor) => {
  return {
    type: PAGE_SCALE_UP,
    payload: scaleFactor,
  };
};
export const setScalePageMax = (scaleFactor) => {
  return {
    type: PAGE_SCALE_UP,
    payload: scaleFactor,
  };
};

// import {PAGE_SCALE_UP} from './actionTypes'

// export const setScalePage = (scaleFactor) => {
//     return (dispatch) => {

//         if (scaleFactor>=0.5) {
//             dispatch(sideBarClose());
//         } else {
//             dispatch(sideBarOpen());
//         }

//     }
// };

// const sideBarOpen = () => {
//     return {
//         type: SIDEBAR_OPEN
//     }
// };

// const sideBarClose = () => {
//     return {
//         type: SIDEBAR_CLOSE
//     }
// };
