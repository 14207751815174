import { SET_BUTTON_SCALE_FACTOR } from "./actionTypes";

export const setScalePageScl = (
  scl,
  scaleX,
  scaleY,
  vpW,
  vpH,
  canvasWidth,
  canvasHeight,
  canvasBackgroundImageWidth,
  canvasBackgroundImageHeight,
  // canvasBackgroundImageOriginalWidth,
  // canvasBackgroundImageOriginalHeight
) => {
  return {
    type: SET_BUTTON_SCALE_FACTOR,
    payload: {
      scl,
      scaleX,
      scaleY,
      vpW,
      vpH,
      canvasWidth,
      canvasHeight,
      canvasBackgroundImageWidth,
      canvasBackgroundImageHeight,
      // canvasBackgroundImageOriginalWidth,
      // canvasBackgroundImageOriginalHeight,
    },
  };
};
