import { PAGE_SCALE_RESET, PAGE_SCALE_SET } from "./actionTypes";

export const setScalePageReset = () => {
  return {
    type: PAGE_SCALE_RESET,
  };
};
export const setScalePageSet = (scaleFactor) => {
  return {
    type: PAGE_SCALE_SET,
    payload: scaleFactor,
  };
};
