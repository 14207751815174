import React, { Component } from "react";
import { connect } from "react-redux";
import { pullImagesFromUnsplash } from "../actions/unsplash";
import NewModal from "./NewModal";

class Unsplash extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      modelImgURL: "",
    };
  }

  toggleModal = (e, modalData) => {
    this.setState({ modalIsOpen: !this.state.modalIsOpen, modalData });
  };

  render() {
    return (
      <div className={this.props.scaleZoomFactorVal.scaleCss}>
        <div className="columns is-multiline" style={{}}>
          {this.props.imagesData
            ? this.props.imagesData.map((imageData) => {
                return (
                  <div
                    key={imageData.id}
                    style={{ width: "100%" }}
                    className="column is-one-quarter-desktop is-half-tablet"
                  >
                    <div className="card picZoom">
                      <div className="card-image">
                        <figure className="image is-3by2 ">
                          <img
                            style={{ objectFit: "cover" }}
                            src={imageData.urls.thumb}
                            onClick={() => {
                              this.setState({
                                modelImgURL: imageData.urls.regular,
                                modalIsOpen: true,
                              });
                            }}
                            alt=""
                          />
                        </figure>
                      </div>
                    </div>
                  </div>
                );
              })
            : ""}

          <NewModal
            isOpen={this.state.modalIsOpen}
            toggleModal={(e) => this.toggleModal(e)}
            data={{
              button_type: "image",
              content: this.state.modelImgURL,
              label: "",
            }}
          />
        </div>
        <div className="pagination">
          <p
            onClick={() => {
              if (this.props.unsplashPage > 1) {
                this.props.pullImagesFromUnsplash(
                  this.props.inputValue,
                  this.props.unsplashPage - 1
                );
              }
            }}
          >
            &laquo;
          </p>
          <p
            href="#"
            className={this.props.unsplashPage == 1 ? "selectedPagination" : ""}
            onClick={() => {
              this.props.pullImagesFromUnsplash(this.props.inputValue, 1);
              // this.setState({pixaBayPage:1})
            }}
          >
            1
          </p>
          <p
            href="#"
            className={this.props.unsplashPage == 2 ? "selectedPagination" : ""}
            onClick={() => {
              this.props.pullImagesFromUnsplash(this.props.inputValue, 2);
              // this.setState({pixaBayPage:2})
            }}
          >
            2
          </p>
          <p
            href="#"
            className={this.props.unsplashPage == 3 ? "selectedPagination" : ""}
            onClick={() => {
              this.props.pullImagesFromUnsplash(this.props.inputValue, 3);
              // this.setState({pixaBayPage:3})
            }}
          >
            3
          </p>
          <p
            href="#"
            className={this.props.unsplashPage == 4 ? "selectedPagination" : ""}
            onClick={() => {
              this.props.pullImagesFromUnsplash(this.props.inputValue, 4);
              // this.setState({pixaBayPage:4})
            }}
          >
            4
          </p>
          <p
            href="#"
            onClick={() => {
              this.props.pullImagesFromUnsplash(
                this.props.inputValue,
                this.props.unsplashPage + 1
              );
            }}
          >
            &raquo;
          </p>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  var imagesData = state.unsplash.result ? state.unsplash.result.results : [];
  return {
    imagesData,
    scaleZoomFactorVal: state.scaleZoomFactor,
    unsplashPage: state.unsplash.page,
  };
};

export default connect(mapStateToProps, { pullImagesFromUnsplash })(Unsplash);
