import React, { Component } from "react";
import { connect } from "react-redux";
import {pullWordFromMerriam} from '../actions/merriam';
import configuration from "../config.json";
import soundIcon from "../assets/img/soundIcon2.png";
import merriamMoreIcon from "../assets/img/merriamMoreIcon.png";
import MerriamModal from './MerriamModal';
import { getMerriamModal } from "../actions/merriamModal";
import {closeMerriamModal} from '../actions/merriamModal';

let soundSource="";
class Merriam extends Component {

    constructor(props){
      super(props);
      this.state={
        moreSearchValue:'',
        modalIsOpen:false

      }
    }
    playAudio = (src) => {
        const audioEl =new Audio(src);
        audioEl.play()
        .catch(error => {
          console.error(error);
        });
    }

    //When clicked to moreSearchValueHandle open Merriam Modal
    moreSearchValueHandle = ()=>{
      this.setState({
        moreSearchValue: this.props.whatSearchedOwn,
        modalIsOpen: !this.state.modalIsOpen
      });
      
    }
    //Close Merriam Modal
    toggleModal = (e, modalData) => {
      this.setState({ modalIsOpen: !this.state.modalIsOpen, modalData });
      this.props.closeMerriamModal();
  };       


    render() {
      
      if(this.props.merriam.length>0){
        
        //Check Audio Pronunciation Api
        //**If the file name begins with "bix", the subdirectory should be /bix/. */
        if(this.props.merriamSound.substr(0,3)==='bix'){
          soundSource = 'https://media.merriam-webster.com/soundc11/bix/'+this.props.merriamSound+'.wav';
        }else if(this.props.merriamSound.substr(0,2)==='gg'){
          //**If the file name begins with "gg", the subdirectory should be /gg/. */
          soundSource = 'https://media.merriam-webster.com/soundc11/gg/'+this.props.merriamSound+'.wav';
        }else if(!isNaN(this.props.merriamSound.substr(0,1))){
           //**If the file name begins with a number, the subdirectory should be /number/. */
           soundSource = 'https://media.merriam-webster.com/soundc11/number/'+this.props.merriamSound+'.wav';
        }else{
          //**Add the first letter of the wav file as a subdirectory /h/ if it starts with letter .*
          soundSource = 'https://media.merriam-webster.com/soundc11/'+this.props.merriamSound.substr(0,1)+'/'+this.props.merriamSound+'.wav';
        }
        return (
            <div className="merriamComponent">
                {
                  //Sound part for word pronunciation...
                }
                <img className="soundIcon" src={soundIcon} alt ="word sound Listen" onClick={() => this.playAudio(soundSource)} />
                <audio className="audio-element">
                    <source src={soundSource} type="audio/wav"></source>
                </audio>
                {
                  //Word type and word meaning part
                }
                {' ; '+this.props.merriamWordType+' ; '+this.props.merriam}
                {
                  //Click to see more part
                }
                <div className="moreMerriamWord" onClick={this.moreSearchValueHandle}>
                  Click To See More
                  <img src={merriamMoreIcon} className="merriamMoreIcon" alt="merriamMoreIcon"/>                 
                </div> 
              {
              //Merriam Dictionary Modal only show when user click to see more button
              }
              {this.state.moreSearchValue!=='' &&
                <MerriamModal
                  url={`https://www.merriam-webster.com/dictionary/${this.state.moreSearchValue}`}
                  toggleModal={this.toggleModal}
                  modalIsOpen={this.state.modalIsOpen}
                /> 
              }

                  
            </div>
        )
      }else {
          return null;
      }
}
}
const mapStateToProps = state => {
  
  return {
    merriam:state.merriam.researchedWord,
    merriamSound: state.merriam.researchedSound,
    merriamWordType: state.merriam.researchedWordType,
    whatSearchedOwn: state.merriam.whatSearchedOwn,
    merriamModal: state.merriamModal
  };
};

export default connect(
  mapStateToProps,
  {pullWordFromMerriam,getMerriamModal,closeMerriamModal}
)(Merriam);
