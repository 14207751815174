import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { toaster } from "../../actions/showToast";

export default function ZoomModeEdit(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <li  onClick={props.onClick}>
      <span
        className={`button normal-btn next-page ${
          props.shouldShowButtonLabels ? "show-label-span" : ""
        } ${
          props.activeButton === "zoommodeedit" ? "active" : ""
        }`}
        title={t("Add Zoom Mode")}
        data-tip={t("Add Zoom Mode")}
      >
        <img
          src={require("../../assets/img/svg/add-guide lines.svg")}
          alt={t("Add Zoom Mode")}
        />
        {props.shouldShowButtonLabels && <span className="show-label-text">{t("Guide")}</span>}
      </span>
    </li>
  );
}
