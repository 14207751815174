import React, { Component } from "react";
import { connect } from "react-redux";
// import toc from "../../toc.json";
import { pageNoChangeFromIndex } from "../../actions/pages";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";

// console.log(toc);
class Index extends Component {
  render() {
    // console.log('!!this.props.toc', this.props.toc)
    // console.log('!toc', toc)
    // console.log('!!!!!!!!!!!!!!aaaa')
    return (
      <div className="column modal-right">
        <div className="modal-right-content index-content-area active">
          <div className="modal-title">
            <h2>
              <span className="modal-title-icon">
                <img
                  src={require("../../assets/img/svg/tabs-icon-list.svg")}
                  alt="Index"
                />
              </span>
              <span className="modal-title-text">{this.props.t("TOC")}</span>
            </h2>
          </div>
          <ul id="index-content" className="index-content">
            {this.props.toc &&
              this.props.toc.units &&
              this.props.toc.units.map((unit, index1) => {
                return (
                  <li key={index1}>
                    <Link
                      to={unit.page.toString()}
                      onClick={() => {
                        this.props.pageNoChangeFromIndex(unit.page);
                      }}
                    >
                      {unit.name}
                    </Link>
                    <span className="page-number">{unit.page}</span>
                    <ol className="subject">
                      {unit.topics &&
                        unit.topics.map((topic, index2) => {
                          return (
                            <li key={index2}>
                              <Link
                                onClick={() => {
                                  this.props.pageNoChangeFromIndex(topic.page);
                                }}
                                to={topic.page.toString()}
                              >
                                {topic.name}
                              </Link>
                              <span className="page-number">{topic.page}</span>
                              <ol className="sub-subject">
                                {topic.sections &&
                                  topic.sections.map((section, index3) => {
                                    return (
                                      <li key={index3}>
                                        <Link
                                          to={section.page.toString()}
                                          onClick={() => {
                                            this.props.pageNoChangeFromIndex(
                                              section.page
                                            );
                                          }}
                                        >
                                          {section.name}
                                        </Link>
                                        <span className="page-number">
                                          {section.page}
                                        </span>
                                      </li>
                                    );
                                  })}
                              </ol>
                            </li>
                          );
                        })}
                    </ol>
                  </li>
                );
              })}
          </ul>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    toc: state.pages.toc,
  };
};

export default connect(mapStateToProps, { pageNoChangeFromIndex })(
  withTranslation()(Index)
);
