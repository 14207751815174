import React, { useState } from "react";
import { useTranslation } from "react-i18next";

export default function SwitchOption(props) {
  const { t, i18n } = useTranslation();
  const { object } = props;
  const [oValue, setOValue] = useState(props.defaultValue);
  console.log("props.defaultValue", props.defaultValue);

   const handleChange = (e) => {
    setOValue(e);
    console.log("e.value", e);
    props.selectOption(e);
  };

  console.log(props, oValue);
  return (
    <div>
      <label htmlFor="" className="label" style={{ marginTop: 10 }}>
        {/* {t("Style")} */}
      </label>
      <div className="form-style-button">
        <div className="col-md-6">
          <div className="radio">
            <label className="radio form-radio-label">
              <input
                type="radio"
                onChange={() => {
                  handleChange(1);
                }}
                ref={props.ref}
                name={props.name}
                checked={oValue === 1}
                defaultValue={1}
                style={{ marginRight: 10 }}
              />
             {props.val1}
            </label>
          </div>
        </div>
        <div className="col-md-6">
          <div className="radio">
          <label className="radio form-radio-label">
              <input
                type="radio"
                onChange={() => {
                  handleChange(0);
                }}
                ref={props.ref}
                name={props.name}
                checked={oValue === 0}
                defaultValue={0}
                style={{ marginRight: 10 }}
              />
              {props.val2}
            </label>
          </div>
        </div>
      </div>
    </div>
  );
}
