import { INDEX_OPEN, INDEX_CLOSE } from "../actions/actionTypes";

export default (state = false, action) => {
  switch (action.type) {
    case INDEX_OPEN:
      return true;

    case INDEX_CLOSE:
      return false;

    default:
      return state;
  }
};
