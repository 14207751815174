/*eslint no-unused-vars: 0*/

import FabricCanvasTool from "./fabrictool";

// import { store } from '../../store/configureStore';
// ​
// function pageChange(add) {
//   return {
//     type: 'SET_PAGE_FROM_PAN',
//     payload: add,
//   }
// }
// store.dispatch(pageChange(false));

// ​

const fabric = require("fabric").fabric;
let amount = [0, 0];
class HardDrag extends FabricCanvasTool {
  configureCanvas(props) {
    let canvas = this._canvas;
    canvas.isDrawingMode = canvas.selection = false;
    canvas.forEachObject(o => (o.selectable = o.evented = false));
    //Change the cursor to the move grabber
    canvas.defaultCursor = "grab";
    if (canvas._iTextInstances) {
      canvas._iTextInstances.forEach(function(ob) {
        ob.exitEditing();
      });
    }
    // console.log("jkl");
    // console.log(props);
  }

  doMouseDown(o) {
    // console.log('o', this.isDown)
    let canvas = this._canvas;
    // if (canvas.backgroundImage.scaleX > 0) {
    // console.log(canvas.backgroundImage.scaleX);
    // console.log(canvas.scaleX);
    this.isDown = true;
    let pointer = canvas.getPointer(o.e);
    this.startX = pointer.x;
    this.startY = pointer.y;

    if (this._canvas._iTextInstances) {
      this._canvas._iTextInstances.forEach(function(ob) {
        ob.exitEditing();
      });
    }

    // }
  }

  doMouseMove(o,e) {
    // console.log('o', this.isDown)
    if (!this.isDown) return;
    // }, 100);
    // paGe.scrollLeft = pointer.x - this.startX;
    let canvas = this._canvas;
    let pointer = canvas.getPointer(o.e);
    // console.log("moveX: " + (pointer.x - this.startX));
    // console.log("moveY: " + (pointer.y - this.startY));

    // let paGe = document.getElementById("pdfPageList");

    amount[0] = -(pointer.x - this.startX);
    amount[1] = -(pointer.y - this.startY);
    // oldAmount = amount;
    let paGe = document.getElementById("pdfPageList");
    paGe.scrollTop += amount[1];
    paGe.scrollLeft += amount[0];

    // console.log(amount);
    // console.log("here", canvas.viewportTransform);

    // console.log(o);
    // canvas.renderAll();
    // return ["move", canvas.viewportTransform];
  }

  doMouseUp(o) {
    this.isDown = false;
    // console.log(amount);
    //return;
    // if (amount[0] !== 0 || amount[1] !== 0) {
    //   let paGe = document.getElementById("pdfPageList");
    //   paGe.scrollTop += amount[1] * 2;
    //   paGe.scrollLeft += amount[0] * 2;
    //   amount = [0, 0];
    // }

    // canvas.relativePan({
    //   x: pointer.x - this.startX,
    //   y: pointer.y - this.startY
    // });
    // setTimeout(() => {
  }
}

export default HardDrag;
