import React from "react";

import { useTranslation } from "react-i18next";
export default function SBNotFoundMessage(props) {
  const { t } = useTranslation();
  return (
    <>
      {!props.hide && (
        <div class="notification m-3 is-light button-low-opacity">
          {/* <button class="delete"></button> */}
          {t("No results.")}
        </div>
      )}
    </>
  );
}
