import React, { Component } from "react";
import { withTranslation } from "react-i18next";

class HardDragger extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    // console.log("this.props.activeButton", this.props.activeButton);
    return (
      // <li onClick={!this.props.hideButton && this.props.onClick}>
      <li onClick={!this.props.hideButton ? this.props.onClick : undefined}>
        <span
          className={`button normal-btn selecter ${
            this.props.shouldShowButtonLabels ? "show-label-span" : ""
          } ${
            this.props.activeButton === "harddrag" ? "active" : ""
          }${this.props.hideButton ? "passive" : ""}`}
          title={this.props.t("Drag and scroll the page for old devices")}
        >
          <img
            src={require("../../assets/img/svg/toolbar-move.svg")}
            alt={this.props.t("Drag and scroll the page for old devices")}
          />
          {this.props.shouldShowButtonLabels && <span className="show-label-text">{this.props.t("Scroll")}</span>}
        </span>
      </li>
    );
  }
}
export default withTranslation()(HardDragger);
