import {ZIP_DOWN_PROG} from "../actions/actionTypes";

export default (state = 100, action) => {

    switch (action.type) {

        case ZIP_DOWN_PROG:
            return action.payload;

        default:
            return state;
    }
};