import React from "react";
import { connect } from "react-redux";
import { setLibraryFromLocal } from "../../actions/login";
import { details } from "../../actions/details";
import { Route, Redirect } from "react-router-dom";
import ReactGA from "react-ga";

class ProtectedRoute extends React.Component {
  render() {
    const Component = this.props.component;
    const Path = this.props.path;
    console.log("protected route", this.props.isLoggedIn);
    if (!this.props.isLoggedIn) {
      if (localStorage.getItem("auth_token") !== null) {

        if (
          this.props.ebook_id !== undefined ||
          this.props.page_number !== undefined
        ) {
          console.log(
            "buraya geldi",
            "/indivibook/" + this.props.ebook_id + "/" + this.props.page_number
          );
          return <Redirect to={{ pathname: "/library" }} />;
        } else {
          this.props.details();
          ReactGA.pageview(
            "/indivibook/" + this.props.ebook_id + "/" + this.props.page_number
          );

          return (
            <Route
              exact={this.props.isExact}
              path={Path}
              component={Component}
            />
          );
        }

        // return <Component />;
      } else if (process.env.REACT_APP_OFFLINE === "true") {
        console.log(
          "buraya geldi",
          "/indivibook/" + this.props.ebook_id + "/" + this.props.page_number
        );
        if (
          this.props.ebook_id === undefined ||
          this.props.page_number === undefined
        ) {
          return <Redirect to={{ pathname: "/library" }} />;
        } else {
          // return <Component />;
          ReactGA.pageview(
            "/indivibook/" + this.props.ebook_id + "/" + this.props.page_number
          );
          return (
            <Route
              exact={this.props.isExact}
              path={Path}
              component={Component}
            />
          );
        }
      } else {
        return <Redirect to={{ pathname: "/login" }} />;
      }
    }
    return (
      <Route exact={this.props.isExact} path={Path} component={Component} />
    );
  }
}
const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.login.loggedIn,
    login: state.login,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // setLibraryFromLocal: () => dispatch(setLibraryFromLocal()),
    details: () => dispatch(details()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProtectedRoute);
