import React from "react";
import { useTranslation } from "react-i18next";

export default function EraseAll(props) {
  // console.log('props.activeButton -----HAND', props.activeButton)
  const { t } = useTranslation();
  return (
    <li onClick={!props.hideButton ? props.onClick : undefined}>
      <span
        className={`button normal-btn erase ${
          props.shouldShowButtonLabels ? "show-label-span" : ""
        } ${props.activeButton === "eraseall" ? "active" : ""}`}
        title={t("Clear All")}
        // onClick={props.onClickEraser}
        data-tip={t("Clear All")}
      >
        <img
          src={require("../../assets/img/svg/tools-all-clear.svg")}
          alt={t("Clear All") }
        />
        {props.shouldShowButtonLabels && (
          <span className="show-label-text">{t("Clear")}</span>
        )}
      </span>
    </li>
  );
}
