import {GET_MERRIAM,GET_MERRIAM_SOUND} from '../actions/actionTypes'

const initialState={
    researchedWord:[],
    researchedSound:'null',
    researchedWordType:'',
    whatSearchedOwn:''
}

export default (state = initialState, action) => {
    switch (action.type) {

        case GET_MERRIAM:
            //Get word meaning, word type, word sound, word itself from Merriam
            return {
                ...state,
                researchedWord:action.payload[0].shortdef[0],
                researchedWordType: action.payload[0].fl,
                researchedSound:'null',
                whatSearchedOwn: action.payload[0].meta.id
            }
        case GET_MERRIAM_SOUND:
            return {
                ...state,
                researchedSound:action.payload
            }
        default:
            return state;
    }
};