import React from "react";
import {
  // HiXCircle,
  BsFillSkipForwardFill,
  // MdSkipPrevious,
  // MdExitToApp
} from "react-icons/bs";
import { useTranslation } from "react-i18next";

export default function NextPageButton(props) {
  const { t } = useTranslation();
  return (
    <>
      <button
        // disabled={props.isLoading}
        className={`button  is-small modal-button ${
          props.disabled ? "button-disabled" : ""
        }`}
        onClick={props.onClickHandler}
        title={t("Next")}
      >
        <span className="is-hidden-tablet-only is-hidden-mobile has-text-light mr-2 mb-2">{ t("Next Page")}</span>
        <span className="icon  has-text-light mb-2">
          <BsFillSkipForwardFill className="fas fa-2x" />
        </span>
      </button>
    </>
  );
}
