import React from "react";
import {
  BiTrash,
} from "react-icons/bi";
import { useTranslation } from "react-i18next";
export default function DeleteButton(props) {
  const { t } = useTranslation();
  return (
    <>
      <button
        className={`button is-small modal-button ${
          props.isLoading ? "is-loading" : ""
        }`}
        onClick={!props.isLoading && props.deleteContent}
        title={t("Delete Activity")}
      >
        <span className="icon is-small has-text-danger">
          <BiTrash className="fas fa-2x" />
        </span>
      </button>
    </>
  );
}
