/*eslint no-unused-vars: 0*/

import FabricCanvasTool from "./fabrictool";
const fabric = require("fabric").fabric;

let downCoords = {
  x: 0,
  y: 0,
};
class AddZoom extends FabricCanvasTool {
  configureCanvas(props) {
    let canvas = this._canvas;
    canvas.isDrawingMode = false;
    canvas.selection = true;
    canvas.defaultCursor = "crosshair";
  }

}

export default AddZoom;
