import React from "react";
import {
  HiXCircle,
  // HiOutlineSun,
  // HiOutlineTrash,
  // HiOutlineArrowsExpand,
  // HiLightBulb,
  //   HiPencilAlt,
  HiOutlineX,
} from "react-icons/hi";
import { useTranslation } from "react-i18next";
export default function ExitButton(props) {
  const { t } = useTranslation();
  return (
    <>
      <button
        // disabled={props.isLoading}
        className={`button is-small modal-button`}
        onClick={props.onClickHandler}
        title={t("Exit")}
      >
        {!props.hideTitle && <span className="has-text-danger is-hidden-tablet-only  is-hidden-mobile mb-2">{t("Exit")}</span>}
        <span className="icon  has-text-danger mb-2">
          <HiOutlineX className="fas fa-2x" />
        </span>
      </button>
    </>
  );
}
