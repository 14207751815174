import { SIDEBAR_OPEN, SIDEBAR_CLOSE } from "./actionTypes";
import { searchBarClose } from "./searchbar";

export const toggleSideBar = (isSideBarOpened) => {
  return (dispatch) => {
    if (isSideBarOpened) {
      dispatch(sideBarClose());
    } else {
      dispatch(sideBarOpen());
      // dispatch(searchBarClose());
    }
  };
};
// export const closeSideBar = () => {
//   return (dispatch) => {
//     dispatch(sideBarClose());
//     // dispatch(sideBarOpen());
//   };
// };
// export const openSideBar = () => {
//   return (dispatch) => {
//     // dispatch(sideBarClose());
//     dispatch(sideBarOpen());
//   };
// };

export const sideBarOpen = () => {
  return {
    type: SIDEBAR_OPEN,
  };
};

export const sideBarClose = () => {
  return {
    type: SIDEBAR_CLOSE,
  };
};
