import React from "react";
import { useTranslation } from "react-i18next";
import { BsFillVolumeUpFill } from "react-icons/bs";

export default function AudioButton(props) {
  const { t, i18n } = useTranslation();

  // or const [t, i18n] = useTranslation();
  const { object } = props;
  // console.log("object.description", object);
  const transform = "scale(" + object.scale + ")";
  const styleMini = object.style.includes("mini");
  const styleNoAnimation = object.style.includes("no-animation");
  // console.log(`styleNo`, styleNo, styleNo > 4, styleNo < 5);
  return (
    <div
      className={`interactive-object `}
      style={{ transform, transformOrigin: "left top" }}
    >
      <span className={`object-icon ${styleMini ? "mini-icon" : ""}`}>
        {/* <BsFillVolumeUpFill className="fas fa-1x" /> */}
        <svg
          width={object.width}
          height={object.height}
          viewBox={`0 0 ${object.width} ${object.height}`}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx={object.width / 2}
            cy={object.height / 2}
            r={object.width / 2}
            fill="white"
            stroke="#FD6D87"
            strokeWidth="0" // Was 2 = Mustafa changed to 0
          />
          <path
            d="M12.1538 6C11.6016 6 11.1538 6.44772 11.1538 7V11.1265C11.1538 11.645 10.6338 12 10.1154 12C8.39154 12 7 13.34 7 15C7 16.66 8.39154 18 10.1154 18C11.7088 18 13.0136 16.8418 13.1942 15.3496C13.1953 15.3404 13.2032 15.3333 13.2125 15.3333C13.2226 15.3333 13.2308 15.3251 13.2308 15.315V9C13.2308 8.44772 13.6785 8 14.2308 8H15C15.5523 8 16 7.55228 16 7C16 6.44772 15.5523 6 15 6H12.1538Z"
            fill="#FD6D87"
          />
        </svg>
      </span>

      <span className="object-text">
        {!object.description ||
        object.description === undefined ||
        object.description === "undefined"
          ? t("Audio")
          : object.description}
      </span>
    </div>
  );
}
