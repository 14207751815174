import api from "../api";

import { setLoginSuccess } from "./login";

export const details = () => {
  const baseUrl =
  process.env.NODE_ENV === "_development"
  ? "http://" + localStorage.domain + ".localhost:8003/api/"
  : process.env.REACT_APP_MULTITENANT === "true"
  ? "https://" + localStorage.domain + "." + "uppybook.com/api/"
  : process.env.REACT_APP_API_URL + "api/"; //TODO cenibook.com

  console.log("geldi",  baseUrl);

  return (dispatch) => {
    api
      .getData(baseUrl + "user_details/") //
      .then((res) => {
        console.log("res", res);
        dispatch(setLoginSuccess(res.data.success));
      })
      .catch((err) => {
        console.error('err', err);
        window.location.hash = `#/login`;

      });
  };
};
