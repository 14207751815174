import React from "react";
import {
  HiXCircle,
  // HiOutlineSun,
  // HiOutlineTrash,
  // HiOutlineArrowsExpand,
  // HiLightBulb,
  BsFillSquareFill,
  // HiOutlineX,
} from "react-icons/bs";
import { useTranslation } from "react-i18next";
export default function ColorButton(props) {
    console.log('props.color', props.color)
  const { t } = useTranslation();
  return (
    <>
      <button
        // disabled={props.isLoading}
        className={`button is-small modal-button`}
        onClick={props.onClickHandler}
        title={props.title}
      >
        <span className="icon is-small" style={{color:props.color}}>
          <BsFillSquareFill className="fas fa-2x" />
        </span>
      </button>
    </>
  );
}
