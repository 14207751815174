import { introJs } from "intro.js";
import i18n from "../i18n";

const startTour = (props) => {
  let tour = introJs();
  tour.setOption("tooltipPosition", "auto");
  tour.setOption("tooltipClass", "customDefault");
  tour.setOption("positionPrecedence", ["left", "right", "top", "bottom"]);
  tour.setOption("skipLabel", i18n.t("Skip"));
  tour.setOption("nextLabel", i18n.t("Next") + " &rarr;");
  tour.setOption("prevLabel", "&larr; " + i18n.t("Back"));
  tour.setOption("doneLabel", i18n.t("Done"));

  tour.onbeforeexit(() => {
    if (localStorage.alreadyExitedOnce === "true") {
      localStorage.showIntro = "false";
    } else {
      localStorage.alreadyExitedOnce = "true";
    }
    document.getElementById("toolbar-intro-sudo-div") &&
      (document.getElementById("toolbar-intro-sudo-div").style.display =
        "none");
    document.getElementById("toolbar1") &&
      document
        .getElementById("toolbar1")
        .classList.remove("introjs-helperLayer");

    // this.setState({ toolBarDraggable: false });
  });

  tour.onbeforechange((a) => {
    document.getElementById("toolbar-intro-sudo-div").style.display = "block";
    document.getElementById("toolbar1").classList.remove("introjs-helperLayer");
  });

  tour.onafterchange((a) => {
    if (a.getAttribute("data-step") === 4) {
      document.getElementById("toolbar1").classList.add("introjs-showElement");
    //   this.setState({ toolBarDraggable: true });

      if (document.getElementsByClassName("introjs-helperLayer")[1]) {
        document
          .getElementById("toolbar-intro-sudo-div")
          .classList.remove("introjs-showElement");
      }
    } else {
      document
        .getElementById("toolbar1")
        .classList.remove("introjs-showElement");
    }
  });
  tour.oncomplete(() => {
    document.getElementById("toolbar-intro-sudo-div").style.display = "none";
    document.getElementById("toolbar1").classList.remove("introjs-showElement");

    localStorage.showIntro = "false";
    // this.setState({ toolBarDraggable: false });
  });

  tour.start();
};

export default startTour;
