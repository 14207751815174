export default {
    translation: {
        "My IndiviBooks": "كتبيالذكية",
        language: "AR",
        library: "المكتبة",
        Welcome: "أهلاوسهلا",
        Logout: "تسجيلخروج",
        Loading: "جاريالتحميل",
        intro_1:
          "شريكالبحث: ابحثواعنالصوروالتعريفاتوالمراجعالاخرى! يمكنكمايضامشاهدةالكلماتالمحددةمسبقاهنا",
        intro_2:
          "الفهرس: جدولالمحتويات،فهرسالاحداث،الاشاراتالمرجعيةالخاصةبكوعلاماتاضافيةهنا!",
        intro_3:
          "تحققمنالميزاتالجديدة! اختصارلوحةالمفاتيح،لقطةالشاشة،الثيمات،ازرارالتنقلوالمزيد!",
        "Interactive Objects": "كائناتتفاعلية",
        "Drawing Objects": "كائناتالرسم",
        "Load All Images": "تحميلجميعالصور",
        Drag: "سحب",
        "Drag the page": "اسحبالصفحة",
        "Zoom out": "تصغير",
        "Zoom in": "تكبير",
        "Full Screen": "شاشةكاملة",
        "Draw Toolbar": "شريطادواتالرسم",
        "Navigation Tools": "ادواتالملاحة",
        Capture: "التقاط",
        Curtain: "ستارة",
        Settings: "اعدادات",
        "Vertical Toolbar": "شريطادواتعمودي",
        "Horizontal Toolbar": "شريطادواتافقي",
        Pen: "قلمجاف",
        Highlighter: "قلمفسفوري",
        "Clear All": "امسحالكل",
        Eraser: "ممحاة",
        Shapes: "الاشكال",
        Line: "خط",
        "Empty Circle": "دائرةفارغة",
        Circle: "دائرة",
        "Empty Rectangle": "مستطيلفارغ",
        Rectangle: "مستطيل",
        "Line Size": "حجمالخط",
        "Text and stroke color": "لونالنصوالخط ",
        "Fill color": "لونالتعبئة",
        Undo: "الغاءالتحميل",
        Redo: "اعادة",
        Language: "اللغة",
        Dark: "داكن",
        Light: "ساطع",
        "Dark Blue": "ازرقداكن",
        Green: "اخضر",
        "Go to Previous Page": "اذهبالىالصفحةالسابقة",
        "Go to Next Page": "اذهبالىالصفحةالتالية",
        "Scroll to top of the page": "انتقلالىاعلىالصفحة",
        "Scroll to end of the page": "انتقلالىنهايةالصفحة",
        Erase: "امسح",
        "Select Drawing Objects": "اداةتحديدكائناتالرسم",
        Layers: "طبقات",
        Theme: "موضوع",
        "Change Toolbar Position": "تغييرموضعشريطالادوات",
        "Toolbar Pin": "مثبتشريطالادوات",
        Text: "النص",
        Search: "البحث",
        "Please enter email and password for login.":
          "يرجىادخالعنوانالبريدالالكترونيوكلمةالمرور.",
        "Sign in": "تسجيلدخولالمستخدم",
        Login: "تسجيلالدخول",
        "If you experience a problem, please contact your domain administrator.":
          "اذاواجهتكممشكلةيرجىالاتصالبمسؤولالمجالالخاصبك",
        Email: "البريدالالكتروني",
        "Email is required": "البريدالالكترونيضروري",
        "Please enter your email": "لطفاادخلوابريدكمالالكتروني",
        "Please enter your password": "لطفاادخلواكلمةالمرورالخاصةبكم",
        "Please enter IndiviBook Domain": "لطفاادخلوامجالالكتابالذكيالخاصبكم",
        "IndiviBook domain is required": "اسممجالالكتابالذكيضروري",
        "Password domain is required": "الرقمالسريضروري",
        "Please enter a valid email, password and IndiviBook domain.":
          "الرجاءادخالبريدالكترونيصالحوكلمةمرورواسممجالالكتابالذكي",
        "Please check your login credentials.":
          "يرجىالتحققمنمعلوماتتسجيلالدخولالخاصبكم",
        "Please fill both the email and password fields.":
          "يرجىتعبئةحقليالبريدالالكترونيوكلمةالمرور",
        "IndiviBook Domain": "مجالالكتابالذكي",
        Page: "الصفحة",
        "My Bookmarks": "اشاراتيالمرجعية",
        Note: "ملاحظة",
        Audio: "صوت",
        Video: "فيديو",
        Answer: "الاجابة",
        Activity: "الانشطة",
        Image: "الصورة",
        "Open Sidebar": "افتحالشريطالجانبي",
        "Close Sidebar": "اغلقالشريطالجانبي",
        Library: "المكتبة",
        Back: "عودة",
        Next: "التالي",
        Done: "منجز",
        Skip: "تخطى",
        TOC: "المحتويات",
        Activities: "انشطة",
        "My Annotations": "ملاحظاتيورسوماتي",
        Bookmark: "المرجعية",
        "Stroke Size": "حجم السكتة الدماغية",
        Password: "كلمه السر",
        "Suggested Words": "كلمات مقترحة",
        "Search images from Pixabay":"البحث عن الصور من Pixabay",
        "Search images from Unsplash":"البحث عن الصور من Unsplash",
        "Search on Wikizero":"ابحث في Wikizero",
        "Search on Wikipedia":"ابحث في ويكيبيديا",
        "Search on Merriam Dictionary":"ابحث في قاموس ميريام Merriam Dictionary",
        "Search on Wiktionary":"ابحث في ويكاموس",
        "Search on WordWeb":"ابحث في WordWeb",
        "Search on PBS Learning Media":"ابحث في PBS Learning Media",
        "Search on Google":"بحث في جوجل",
        "Search on Google Images":"ابحث في صور جوجل",

        "Activity Tools":"أدوات النشاط",
        "Add Note":"اضف ملاحظة",
        "Add Video":"أضف فيديو",
        "Add Activity":"أضف نشاط",
        "Add Audio":"أضف الصوت",
        "Add Image":"إضافة صورة",
        "Question Tools":"أدوات السؤال",
        "Add a Multiple Choice Question":"أضف سؤال متعدد الخيارات",
        "Add a Multiple Response Question":"أضف سؤالاً متعدد الإجابات",
        "True False":"خطأ صحيح",
        "Drag Drop":"اسحب اسقاط",
        "Fill in the Blank":"املاء الفراغ",
        "Drag":"سحب",
        "Bookmark":"المرجعية",
        "Next Page":"الصفحة التالية",
        "Previous Page":"الصفحة السابقة",
        "Close Search Bar":"أغلق شريط البحث",
        "Open Search Bar":"افتح شريط البحث",
        "search":"بحث",
        "Movable Buttons":"أزرار متحركة",

        "Confirm":"تؤكد",
        "You moved your own activity. Would you like to save the new position to the server?":"قمت بنقل نشاطك الخاص. هل ترغب في حفظ الوظيفة الجديدة على الخادم؟",
        "Yes":"نعم",
        "No":"لا",

        "Search images": "البحث عن الصور",
        "Search Video": "البحث عن الفيديو",
        "Search Vector": "بحث المتجهات",
        "Button Labels": "تسميات الأزرار",
        "Please enter your email, password and domain name.": "الرجاء إدخال بريدك الإلكتروني وكلمة المرور واسم المجال.",
        "Click a point to add a zoomed page part": "انقر فوق نقطة لإضافة جزء صفحة مكبرة",
        "Link Name": "اسم الارتباط",
        "Advanced Mode": "وضع متقدم",
        "Prev": "السابق",
        "Teacher Tools": "أدوات المعلم",
        "Zoom Tools": "أدوات التكبير",
        "Add Hideable Answer": "إضافة إجابة قابلة للإخفاء",
        "This object can't be inserted.": "لا يمكن إدراج هذا الكائن.",
        "Submit":"يقدم",
        "Cancel":"يلغي",
        "Upload audio":"تحميل الصوت",
        "Public":"عامة",
        "Private":"خاص",
        "Top":"قمة",
        "Left":"اليسار",
        "audio":"صوتي",
        "Upload image":"تحميل الصور",
        "image":"صورة",
        "Find an image":"ابحث عن صورة",
        "Style":"أسلوب",
        "Link":"نهاية لهذه الغاية",
        "Please enter a valid link like":"الرجاء إدخال ارتباط صالح مثل",
        "Video Link (Youtube, Vimeo, Facebook, Twitch,SoundCloud, Streamable, Wistia, DailyMotion)":"رابط الفيديو (Youtube و Vimeo و Facebook و Twitch و SoundCloud و Streamable و Wistia و DailyMotion)",
        "Video Link":"رابط الفيديو",
        "text":"نص",
        "link":"نهاية لهذه الغاية",

        "Click a point to add a button":"انقر فوق نقطة لإضافة زر",

        
        "Select":"يختار",
        "Scroll":"انتقل",
        "Click a point to add a zoomed page part":"انقر فوق نقطة لإضافة جزء صفحة مكبرة",
        "Content is loading":"يتم تحميل المحتوى",
        "Delete Activity":"حذف النشاط",
        "Button Name":"اسم الزر",
        "Caps Lock On":"قبعات قفل على",
        "Group Selection":"اختيار المجموعة",
        "UnGroup Selection":"UnGroup التحديد",
        "Duplicate Selection":"تحديد مكرر",
        "Toolbar Size":"حجم شريط الأدوات",
        "Brightness Settings":"إعدادات السطوع",
        "Page Brightness":"سطوع الصفحة",
        "Page Contrast":"تباين الصفحة",
        "Reset":"إعادة ضبط",
        "Timer":"الموقت",
        "Exit":"مخرج",
        "Presentation":"عرض",
        "Presentation Mode":"وضع العرض",
        "Full":"ممتلىء",
        "Highlight": "تسليط الضوء",
        "Cut": "يقطع",
        "Confirm": "يتأكد",
        "Open/Close":"فتح / إغلاق",
        "Add Hide Area":"إضافة إخفاء المنطقة",
        "Add Blank Page":"أضف صفحة فارغة",
        "Add Presentation Mode":"أضف وضع العرض التقديمي",



      },
  };
