import { ADD_BUTTON_MODAL_OPEN,ADD_BUTTON_MODAL_CLOSE } from "../actions/actionTypes";

export default (state = {modalOpen:false}, action) => {
    switch (action.type) {
        case ADD_BUTTON_MODAL_OPEN:
            return {...state,modalOpen:true};

        case ADD_BUTTON_MODAL_CLOSE:
            return {...state,modalOpen:false};

        default:
            return state;
    }
};
