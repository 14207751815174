import React from "react";
import { useTranslation } from "react-i18next";

export default function QButton(props) {
  const { t, i18n } = useTranslation();
  const { object } = props;
  const transform = "scale(" + object.scale + ")";
  const svgPath = props.eyeOff
    ? "M17.7176 10.4866C17.3176 9.7928 15.1176 6.31155 11.3801 6.42405C7.92387 6.51155 5.92387 9.54905 5.38012 10.4866C5.32527 10.5816 5.29639 10.6893 5.29639 10.7991C5.29639 10.9088 5.32527 11.0165 5.38012 11.1116C5.77387 11.7928 7.88012 15.1741 11.5614 15.1741H11.7176C15.1739 15.0866 17.1801 12.0491 17.7176 11.1116C17.7725 11.0165 17.8014 10.9088 17.8014 10.7991C17.8014 10.6893 17.7725 10.5816 17.7176 10.4866V10.4866ZM11.5489 12.9866C11.1162 12.9866 10.6933 12.8583 10.3336 12.6179C9.97383 12.3775 9.69345 12.0359 9.52788 11.6362C9.36232 11.2365 9.319 10.7966 9.4034 10.3723C9.48781 9.94796 9.69615 9.55818 10.0021 9.25226C10.308 8.94633 10.6978 8.73799 11.1221 8.65358C11.5464 8.56918 11.9863 8.6125 12.386 8.77807C12.7857 8.94363 13.1273 9.22401 13.3677 9.58374C13.6081 9.94348 13.7364 10.3664 13.7364 10.7991C13.7364 11.3792 13.5059 11.9356 13.0957 12.3458C12.6854 12.7561 12.129 12.9866 11.5489 12.9866V12.9866Z"
    : "M15.1772 11.7973C16.2775 10.8935 16.9907 9.60345 17.171 8.19106C17.1808 8.10898 17.1744 8.02577 17.1521 7.94617C17.1298 7.86657 17.092 7.79215 17.041 7.72715C16.9899 7.66215 16.9265 7.60784 16.8545 7.56733C16.7824 7.52682 16.7031 7.50091 16.621 7.49106C16.5389 7.48121 16.4557 7.48762 16.3761 7.50993C16.2965 7.53224 16.2221 7.57001 16.1571 7.62108C16.0921 7.67215 16.0378 7.73553 15.9973 7.80759C15.9568 7.87965 15.9308 7.95898 15.921 8.04106C15.7716 9.09498 15.2467 10.0596 14.4429 10.7574C13.6391 11.4553 12.6105 11.8395 11.546 11.8395C10.4815 11.8395 9.45283 11.4553 8.64904 10.7574C7.84526 10.0596 7.32041 9.09498 7.17099 8.04106C7.16114 7.95898 7.13522 7.87965 7.09471 7.80759C7.0542 7.73553 6.9999 7.67215 6.9349 7.62108C6.8699 7.57001 6.79547 7.53224 6.71587 7.50993C6.63628 7.48762 6.55306 7.48121 6.47099 7.49106C6.38891 7.50091 6.30958 7.52682 6.23752 7.56733C6.16546 7.60784 6.10209 7.66215 6.05101 7.72715C5.99994 7.79215 5.96217 7.86657 5.93986 7.94617C5.91755 8.02577 5.91114 8.10898 5.92099 8.19106C6.09964 9.60252 6.81057 10.8925 7.90849 11.7973L6.47099 13.2473C6.3686 13.3669 6.31509 13.5207 6.32117 13.678C6.32724 13.8353 6.39245 13.9845 6.50376 14.0958C6.61507 14.2071 6.76428 14.2723 6.92158 14.2784C7.07888 14.2845 7.23267 14.2309 7.35224 14.1286L8.98349 12.5036C9.58961 12.8099 10.2459 13.0046 10.921 13.0786V15.3036C10.921 15.4693 10.9868 15.6283 11.104 15.7455C11.2213 15.8627 11.3802 15.9286 11.546 15.9286C11.7117 15.9286 11.8707 15.8627 11.9879 15.7455C12.1051 15.6283 12.171 15.4693 12.171 15.3036V13.0786C12.8461 13.0046 13.5024 12.8099 14.1085 12.5036L15.7397 14.1286C15.8593 14.2309 16.0131 14.2845 16.1704 14.2784C16.3277 14.2723 16.4769 14.2071 16.5882 14.0958C16.6995 13.9845 16.7647 13.8353 16.7708 13.678C16.7769 13.5207 16.7234 13.3669 16.621 13.2473L15.1772 11.7973Z";
  return (
    <div
      className="interactive-object-2"
      style={{ transform, transformOrigin: "left top" }}
    >
      <span className="object-icon icon-only">
        <svg
          width={object.width}
          height={object.height}
          viewBox={`0 0 ${object.width} ${object.height}`}
          fill="none"
          // fill="white"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx={object.width / 2}
            cy={object.height / 2}
            r={object.width / 2}
            fill="#C4C4C4"
          />
          <path d={svgPath} fill="white" />
        </svg>
      </span>
    </div>
  );
}
