import React, { Component } from "react";
import { connect } from "react-redux";
import { setLoginSuccess, setDomain } from "../actions/login";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import axios from "axios";
// import { login } from "../services/util";
import { login, socialLogin } from "../services/api";
import Spinner from "./Spinner";
import { spinner } from "../actions/spinner";
import { withTranslation } from "react-i18next";
import ChangeLanguage from "../components/SubComponents/ChangeLanguage";
import { Offline, Online } from "react-detect-offline";
import configuration from "../config";
// import Login from "./Login";
// import { GoogleLogin } from "react-google-login";
import Login from "./Login";

class LoginPage extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      password: "",
      cenibook_domain: "",
      warning: false,
      showModal: false,
      modalText: "",
    };
  }

  gotoLibrary = async (response, cenibook_domain, social = false) => {
    if (social) {
      // localStorage.auth_token = await response.success.token;
      localStorage.domain = await cenibook_domain;
      // this.setState({
      //   modalText: "Something went wrong please try again later",
      // });
      const status = await localStorage.auth_token;
      console.log("status", status);
      console.log("logged in successfully");
      this.props.setLoginSuccess(response.success);
      this.props.setDomain(cenibook_domain);

      this.props.history.push("/library");
    } else if (response.success.token) {
      localStorage.auth_token = await response.success.token;
      localStorage.domain = await cenibook_domain;
      this.setState({
        modalText: "Something went wrong please try again later",
      });
      const status = await localStorage.auth_token;
      console.log("status", status);
      console.log("logged in successfully");
      this.props.setLoginSuccess(response.success);
      this.props.setDomain(cenibook_domain);

      this.props.history.push("/library");
    }
  };
  /**
   * does the form submit process
   */
  loginHandler = (e) => {
    e.preventDefault();
    //this.props.spinner(true);

    const { email, password, cenibook_domain } = this.state;

    if (
      email === "" ||
      password === "" ||
      (process.env.REACT_APP_MULTITENANT === "true" && cenibook_domain === "")
    ) {
      this.setState(
        {
          modalText: this.props.i18n.t(
            "Please enter a valid email, password and IndiviBook domain."
          ),
        },
        () => {
          this.toggleModal();
        }
      );
      return;
    }
    // this.props.attemptLogin(email, password);
    // return;
    login(email, password, cenibook_domain)
      .then(async (response) => {
        console.log("response", response);
        this.gotoLibrary(response, cenibook_domain);

        //  window.location.reload();
      })
      .catch((error) => {
        this.setState(
          {
            modalText: this.props.i18n.t(
              "Please check your login credentials."
            ),
          },
          () => {
            this.toggleModal();
          }
        );
        console.log("logged error", error);
        this.props.setLoginSuccess(false);
      })
      .finally(() => {
        //this.props.spinner(false);
      });
  };

  onFormSubmit = (e) => {
    e.preventDefault();
    if (!this.state.form.email.trim() || !this.state.form.password.trim()) {
      this.setState(
        {
          modalText: this.props.i18n.t(
            "Please fill both the email and password fields."
          ),
        },
        () => {
          this.toggleModal();
        }
      );
      return;
    }

    if (!login(this.state.form.email, this.state.form.password)) {
      this.setState(
        {
          modalText: this.props.i18n.t("Please check your login credentials."),
        },
        () => {
          this.toggleModal();
        }
      );
    } else {
      window.location.reload();
    }
  };

  /**
   * when text in inputs change it pass text to the state
   */

  /**
   * shows and hides modal
   */
  toggleModal = () => {
    this.setState((prevState) => ({
      showModal: !prevState.showModal,
    }));
  };
  loginWithGoogle = (e) => {
    e.preventDefault();
    console.log("Log in with google");
  };
  /**
   * renders modal
   */
  renderModal = () => {
    return (
      <Modal
        isOpen={this.state.showModal}
        toggle={this.toggleModal}
        centered={true}
      >
        <ModalHeader>
          <b>Oops!</b>
        </ModalHeader>
        <ModalBody>{this.state.modalText}</ModalBody>
        <ModalFooter>
          <Button color="dark" onClick={this.toggleModal}>
            {this.props.t("Close")}
          </Button>
        </ModalFooter>
      </Modal>
    );
  };
  _onTextChange = (e, key) => {
    if (key === "cenibook_domain") {
      this.setState({
        cenibook_domain: e,
      });
    } else if (key === "email") {
      this.setState({
        email: e,
      });
    } else {
      this.setState({
        password: e,
      });
    }
  };

  onKeyDown = (keyEvent) => {
    if (keyEvent.getModifierState("CapsLock")) {
      this.setState({ warning: true });
    } else {
      this.setState({ warning: false });
    }
  };
  onSuccess = (res) => {
    console.log("res", res);
    console.log("res", res.accessToken);
    console.log("res.profileObj", res.profileObj);
    if (res.profileObj) {
      console.log("Login Success: currentUser:", res.profileObj);
      socialLogin(res.accessToken)
        .then(async (response) => {
          console.log("response", response);
          this.gotoLibrary(response, "");

          //  window.location.reload();
        })
        .catch((error) => {
          this.setState(
            {
              modalText: this.props.i18n.t("Google error, please try again."),
            },
            () => {
              this.toggleModal();
            }
          );
          console.log("logged error", error);
          this.props.setLoginSuccess(false);
        })
        .finally(() => {
          //this.props.spinner(false);
        });
    }
  };
  //   alert(
  //     `Logged in successfully welcome ${res.profileObj.name} 😍. \n See console for full profile object.`
  //   );
  // };
  onFailure = (res) => {
    console.log("Login failed: res:", res);
    // alert(
    //   `Failed to login. 😢 Please ping this to repo owner twitter.com/sivanesh_fiz`
    // );
  };

  render() {
    const { email, password, cenibook_domain } = this.state;

    // console.log(
    //   "process.env.REACT_APP_COMPANY_LOGO_PATH",
    //   process.env.REACT_APP_COMPANY_LOGO_PATH,
    //   process.env.REACT_APP_GOOGLE_CLIENT_ID
    // );
    return (
      <React.Fragment>
        {this.renderModal()}

        <div className="login-form-area">
          <div className="login-form" id="login-form">
            <div className="lf-left">
              <figure
                className={`welcome-logo${
                  process.env.REACT_APP_COMPANY_LOGO_PATH ? "-company" : ""
                }`}
              >
                <img
                  src={
                    process.env.REACT_APP_COMPANY_LOGO_PATH
                      ? process.env.REACT_APP_COMPANY_LOGO_PATH //require("../assets/img/company_logo.png")
                      : process.env.REACT_APP_INDIVIBOOK_LOGO_PATH
                  }
                  alt="Logo"
                />
              </figure>
              <div className="lf-left-title">
                <span>{this.props.t("Welcome")}</span>{" "}
              </div>
              <div className="lf-left-text">
                <p>
                  <Online>
                    {process.env.REACT_APP_MULTITENANT === "true" &&
                      this.props.t(
                        "Please enter your email, password and domain name."
                      )}
                  </Online>
                  <Offline>
                    <div className="no-internet-login">
                      {this.props.t("No internet connection.")}
                    </div>
                  </Offline>
                </p>
              </div>
              <div className="lf-left-notice">
                <p>
                  {process.env.REACT_APP_MULTITENANT === "true" &&
                    this.props.t(
                      "If you experience a problem, please contact your domain administrator."
                    )}
                </p>
              </div>
              <div className="library-selection is-pulled-left mt-5 login-selection">
                <div className="select-area">
                  {/* <label htmlFor="do-switchSmall">Theme</label> */}
                  <div className="select is-small">
                    <ChangeLanguage />
                    <span className="icon">
                      <img
                        src={require("../assets/img/svg/pagi-combobox-arrow.svg")}
                        alt="First Page"
                      />
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="lf-right">
              <div className="lf-right-title"> {this.props.t("Sign in")} </div>
              <form className="login-form-box" onSubmit={this.loginHandler}>
                <label className="login-form-label" htmlFor="email">
                  {this.props.t("Email")}
                </label>
                <div
                  className="validate-input"
                  data-validate={this.props.t("Email is required")}
                >
                  <input
                    className="login-form-input"
                    type="text"
                    name="email"
                    placeholder={this.props.t("Please enter your email")}
                    autoComplete="book-email"
                    onChange={(e) =>
                      this._onTextChange(e.target.value.toLowerCase(), "email")
                    }
                    value={email}
                  />
                </div>
                <label className="login-form-label" htmlFor="password">
                  {this.props.t("Password")}
                </label>
                <div
                  className="validate-input"
                  data-validate={this.props.t("Password is required")}
                >
                  <input
                    className="login-form-input"
                    type="password"
                    name="password"
                    placeholder={this.props.t("Please enter your password")}
                    autoComplete="book-password"
                    value={password}
                    onKeyDown={this.onKeyDown}
                    onChange={(e) =>
                      this._onTextChange(e.target.value, "password")
                    }
                  />

                  {this.state.warning && (
                    <p class="help is-danger">{this.props.t("Caps Lock On")}</p>
                  )}
                </div>
                {process.env.REACT_APP_MULTITENANT === "true" && (
                  <>
                    <label
                      className="login-form-label"
                      htmlFor="cenibook_domain-id"
                    >
                      {this.props.t("IndiviBook Domain")}
                    </label>
                    <div
                      className="validate-input"
                      data-validate={this.props.t(
                        "IndiviBook domain is required"
                      )}
                    >
                      <input
                        className="login-form-input"
                        type="cenibook_domain"
                        name="cenibook_domain"
                        placeholder={this.props.t(
                          "Please enter IndiviBook Domain"
                        )}
                        autoComplete="cenibook_domain"
                        value={cenibook_domain}
                        onChange={(e) =>
                          this._onTextChange(
                            e.target.value.toLowerCase(),
                            "cenibook_domain"
                          )
                        }
                      />
                    </div>
                  </>
                )}
                <div className="login-form-footer">
                  <button className="login-form-btn">
                    {" "}
                    {this.props.t("Login")}{" "}
                  </button>
                </div>
              </form>
              <div className="login-form-footer">
                {/* <button
                    className="login-form-btn"
                    onClick={this.loginWithGoogle}
                  >
                    {" "}
                    {this.props.t("Login with Google")}{" "}
                  </button> */}
                <Login onSuccess={this.onSuccess} onFailure={this.onFailure} />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    isLoginPending: state.isLoginPending,
    isLoginSuccess: state.isLoginSuccess,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    spinner: (show) => dispatch(spinner(show)),
    setLoginSuccess: (status) => dispatch(setLoginSuccess(status)),
    setDomain: (domain) => dispatch(setDomain(domain)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(LoginPage));
