import React, { useState, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import * as yup from "yup";
import { connect, useSelector, useDispatch } from "react-redux";
import {
  addButtonModalClose,
  addButtonModalOpen,
} from "../../actions/addButton";
import {
  cancelActivity,
  doneActivity,
  insertActivity,
  updateActivity
} from "../../actions/addActivity";
import { addButtonService, updateButtonService } from "../../services/api";
import { useTranslation } from "react-i18next";
import SwitchOption from "./SwitchOption";
import ButtonStylesBulma from "./ButtonStylesBulma";
import SearchButton from "../SubComponents/SearchBar/SearchButton";
import { pullImagesFromPixabay } from "../../actions/pixabay";
import { closePixabay } from "../../actions/pixabay";
import PixabayIcon from "../../assets/img/pixabay3.png";
import PixabayForm from "../PixabayForm";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
// import { useDropzone } from "react-dropzone";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { quillFormats, quillModules } from "../../services/constants";
import { guidGenerator, parseBoolean } from "../../services/util";
import { confirmAlert } from "react-confirm-alert";
import AddNoteGif from "../../assets/img/gif/addNote.gif";
// import modalCloseBtn from "../../assets/img/svg/modal-close-btn.svg";

function NoteForm(props) {
  const { t } = useTranslation();
  const domain = useSelector((state) => state.login.user_profile.domain_name);
  const activityId = useSelector((state) => state.activities.activityId);
  const activityType = useSelector((state) => state.activities.activityName);
  const activityLeft = useSelector((state) => state.activities.activityLeft);
  const activityTop = useSelector((state) => state.activities.activityTop);
  const activityBtnStyle = useSelector(
    (state) => state.activities.activityBtnStyle
  );
  const activityShow = useSelector((state) => state.activities.activityShow);
  console.log("activityId", activityId);
  console.log("activityType", activityType);
  console.log("activityBtnStyle", activityBtnStyle);
  console.log("activityShow", activityShow);
  !activityType && props.addButtonModalClose();
  !activityType && props.cancelActivity();

  //   const [loader, setLoader] = useState(false);
  const [buttonStyle, setButtonStyle] = useState("style1");
  const [isPublic, setIsPublic] = useState(1);
  const [visible, setVisible] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  //   const [buttonStyle, setButtonStyle] = useState("style1");

  const [fileName, setFileName] = useState("filename");

  const [showSources, setShowSources] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [pixabayClicked, setPixabayClicked] = useState(false);
  const [pixabayImage, setPixabayImage] = useState(null);
  const [pixabayImageLoaded, setPixabayImageLoaded] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);

  const handleOnChange = (e) => {
    props.closePixabay();

    if (e.target.value) {
      setShowSources(true);
      setInputValue(e.target.value);
    } else {
      setShowSources(false);
      setInputValue("");
    }
  };

  const pixabayHandler = () => {
    setPixabayClicked(true);
    props.pullImagesFromPixabay(inputValue);
  };

  const pixabayImageHandler = (imageUrl) => {
    setPixabayImage(imageUrl);
    setPixabayImageLoaded(false);
    console.log("imageUrl", imageUrl);
  };

  const [picture, setPicture] = useState(null);
  const onChangePicture = (e) => {
    if (e.target.files[0]) {
      setPicture(URL.createObjectURL(e.target.files[0]));
    }
  };

  const showContent = () => {
    confirmAlert({ customUI: showContentCustomUI });
  };

  const showContentCustomUI = ({ onClose }) => {
    const handleClickedClose = () => {
      onClose();
    };
    return (
      <section className="hero is-large">
        <div className="hero-body has-text-centered">
          <div className="container">
            <button
              class="button is-danger is-rounded is-bold is-size-4 is-pulled-right pb-0 pt-0 pl-3 pr-3"
              style={{ marginRight: -15, marginTop: -15, height: "auto" }}
              onClick={handleClickedClose}
            >
              X
            </button>
            <div className="box">
              <img src={AddNoteGif} alt="Placeholder image" />
            </div>
          </div>
        </div>
      </section>
    );
  };

  const schema = yup.object().shape({
    type: yup.string().required(),
    fileupload: yup.mixed().when("type", {
      is: (type) => type == "audio",
      then: yup
        .mixed()
        .test("required", "This Field is Required", (value) => {
          return value[0] != undefined;
        })
        .test("fileSize", "Max 4 MB is allowed", (value) => {
          return value && value[0] && value[0].size <= 4000000;
        })
        .test("type", "Only wav/mp3/aiff is allowed", (value) => {
          return (
            value &&
            value[0] &&
            (value[0].name.split(".")[1] == "wav" ||
              value[0].name.split(".")[1] == "mp3" ||
              value[0].name.split(".")[1] == "aiff")
          );
        }),
    }),
    content: yup.string().when("type", {
      is: (type) => type == "text" || type == "link" || type == "video",
      then: yup.string().required(),
    }),
  });

  const { register, handleSubmit, watch, errors, control } = useForm({
    resolver: yupResolver(schema),
  });

  //   id(pin):1542
  //   left(pin):"317"
  //   top(pin):"132"
  //   height(pin):24
  //   width(pin):24
  //   show(pin):true
  //   button_type(pin):"text"
  //   own_activity(pin):true
  //   style(pin):"style1"
  //   content(pin):"sdfdsfsdf"
  const onSubmit = (data) => {
    setIsLoading(true);
    console.log("props.sideBarOpened", props.sideBarOpened);
    console.log("data", data);
    console.log("canvasScaleX", props.canvasScaleX, data.left);
    console.log("canvasScaleY", props.canvasScaleY, data.top);
    console.log("buttonStyle", buttonStyle);
    // content: "werewrwe"
    // left: "100"
    // top: "100"
    // type: "note"
    const myRatio =
      props.canvasVals.canvasBackgroundImageWidth /
      props.canvasVals.canvasWidth; //ratio of the book page
    const originRatio = 1000 / props.canvasVals.canvasBackgroundImageWidth; //ratio of the book editor - 1000

    const sendData = {
      id: guidGenerator(), //For redux based id generation.
      left: myRatio * parseInt(data.left) * originRatio, // * props.canvasVals.vpW) / 1000, // -
      top: myRatio * parseInt(data.top) * originRatio, // * ratio, // - 24,
      height: 24,
      width: 24,
      show: 1,
      button_type: data.type,
      own_activity: 1,
      style: buttonStyle,
      status: 1,
      public: isPublic,
      description: data.description,
      content:
        activityType === "audio" || activityType === "image"
          ? tabIndex === 0
            ? data.fileupload[0]
            : activityType === "image"
            ? pixabayImage
            : data.content
          : data.content,
    };
    if (activityId) {
      const formData = new FormData();
      parseInt(data.left) !== parseInt(oldValue.left) &&
        formData.append("left", parseInt(data.left));

      parseInt(data.top) !== parseInt(oldValue.top) &&
        formData.append("top", parseInt(data.top));

      // sendData.height !== parseInt(oldValue.height) &&
      //   formData.append("height", sendData.height);

      // sendData.width !== parseInt(oldValue.width) &&
      //   formData.append("width", sendData.width);

      parseBoolean(sendData.show) !== oldValue.show &&
        formData.append("show", sendData.show);

      parseBoolean(sendData.own_activity) !== oldValue.own_activity &&
        formData.append("own_activity", sendData.own_activity);

      sendData.style !== oldValue.style &&
        formData.append("style", sendData.style);

      sendData.description !== oldValue.description &&
        formData.append("description", sendData.description);

      // sendData.status !== parseInt(oldValue.status) &&
      //   formData.append("status", sendData.status);

      parseBoolean(sendData.public) !== oldValue.public &&
        formData.append("sharing", sendData.public);

      data.content &&
        sendData.content !== oldValue.content &&
        formData.append("content", sendData.content);

      console.error("____formData", formData);
      updateButtonService(formData, String(domain).toLowerCase(), activityId)
        .then(async (response) => {
          sendData.id = response.id;
          sendData.left = response.left;
          sendData.top = response.top;
          sendData.content = sendData.content || oldValue.content;
          props.addButtonModalClose();
          props.doneActivity();
          props.updateActivity(sendData, props.currentPageId);
          setIsLoading(false);
        })
        .catch((error) => {
          console.log("update error", error);
          setIsLoading(false);
        });
    } else {
      const formData = new FormData();

      activityType === "audio" || activityType === "image"
        ? tabIndex === 0
          ? formData.append("content", {
              uri: sendData.content.uri, /// URL
              type: sendData.content.type, /// "url"
              name: sendData.content.name, // no empty
            })
          : formData.append("content", sendData.content)
        : formData.append("content", sendData.content);
      formData.append("content", sendData.content);
      formData.append("button_type", sendData.button_type);
      formData.append("style", sendData.style);
      formData.append("top", parseInt(sendData.top));
      formData.append("left", parseInt(sendData.left));
      formData.append("show", parseInt(sendData.show));
      formData.append("status", parseInt(sendData.status));
      formData.append("sharing", parseInt(sendData.public));
      formData.append("description", sendData.description);
      console.error("____formData", formData);

      addButtonService(
        formData,
        String(domain).toLowerCase(),
        props.currentPageId
      )
        .then(async (response) => {
          sendData.id = response.id;
          if (activityType === "audio" || activityType === "image") {
            sendData.content = response.content;
          }

          props.addButtonModalClose();
          props.doneActivity();
          props.insertActivity(sendData, props.currentPageId);
          setIsLoading(false);
        })
        .catch((error) => {
          console.log("logged error", error);
          setIsLoading(false);
        });
    }
  };

  const selectButtonStyle = (bstyle) => {
    setButtonStyle(bstyle);
    console.log("bstyle", bstyle);
  };
  const selectOptionPublic = (opt) => {
    setIsPublic(opt);
    console.log("public?", opt);
  };
  const selectOptionVisible = (opt) => {
    setVisible(opt);
    console.log("visible?", opt);
  };

  const oldValue = activityId
    ? props.buttons.filter((btn) => btn.id === activityId)[0]
    : null;
  console.log(
    "oldValue1",
    activityId,
    oldValue,
    props.buttons,
    props.buttons.filter((btn) => btn.id === activityId)
  );
  return (
    <>
      {activityType && (
        <div className="main-model">
          <label className="label">
            {t(activityType)}
            <a
              className="tag is-success has-text-right p-1 ml-2"
              onClick={() => showContent()}
            >
              ?
            </a>
          </label>

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="w-100 is-hidden">
              <div className="error-message">{errors.type?.message}</div>
              <label className="label">Type </label>
              <select
                className="new-text-filed"
                name="type"
                ref={register}
                defaultValue={activityType}
              >
                <option value="text">Note</option>
                <option value="link">Link</option>
                <option value="audio">Audio</option>
                <option value="image">Image</option>
                <option value="video">Video</option>
              </select>
            </div>

            {(activityType == "image" ||
              activityType == "audio" ||
              activityType == "video") && (
              <div className="w-100">
                {/*<div className="error-message">field is empty.</div>*/}
                <div className="error-message">
                  {errors.fileupload?.message}
                </div>
                <Tabs defaultIndex={0} onSelect={(index) => setTabIndex(index)}>
                  {activityType == "image" && (
                    <TabList>
                      <Tab>
                        <span className="is-size-6 m-0">
                          {t("Upload " + activityType)}
                        </span>
                      </Tab>
                      <Tab>
                        <span className="is-size-6 m-0">
                          {t("Find an image")}
                        </span>
                      </Tab>
                    </TabList>
                  )}
                  {activityType == "video" && (
                    <TabList>
                      <Tab>
                        <span className="is-size-6 m-0">
                          {t("Upload" + " " + activityType)}
                        </span>
                      </Tab>
                      <Tab>
                        <span className="is-size-6 m-0">
                          {t("Find an image")}
                        </span>
                      </Tab>
                    </TabList>
                  )}
                  {activityType == "audio" && (
                    <TabList>
                      <Tab>
                        <span className="is-size-6 m-0">
                          {t("Upload" + " " + activityType)}
                        </span>
                      </Tab>
                    </TabList>
                  )}
                  <TabPanel>
                    {/* <section>Deneme</section> */}
                    <div className="input-group mb-3">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          Upload {activityType}
                        </span>
                      </div>
                      <div className="custom-file">
                        <input
                          type="file"
                          className="custom-file-input"
                          ref={register}
                          name="fileupload"
                          id="inputGroupFile01"
                          accept="image/*"
                          onChange={onChangePicture}
                        />
                        <label
                          className="custom-file-label"
                          htmlFor="inputGroupFile01"
                        >
                          {watch("fileupload") &&
                            watch("fileupload")[0] &&
                            watch("fileupload")[0].name}
                        </label>
                      </div>
                    </div>
                    <div
                      className={`previewProfilePic preview-pic ${
                        picture ? "is-hidden" : ""
                      }`}
                    >
                      <img
                        className="playerProfilePic_home_tile"
                        src={picture && picture}
                      ></img>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className="row">
                      <div className="col-2">
                        <input
                          className="new-text-filed"
                          onChange={handleOnChange}
                          type="text"
                          placeholder="search"
                          value={inputValue}
                          onKeyPress={(event) => {
                            if (event.key === "Enter") {
                              setPixabayClicked(true);
                              /*  this.setState({
                              pixabayClicked: true,
                            }); */
                              props.closePixabay();
                              props.pullImagesFromPixabay(inputValue);
                            }
                          }}
                        />
                        <div
                          className="search-type-list"
                          style={{
                            borderBottom: 0,
                            display: showSources ? "block" : "none",
                          }}
                        >
                          <SearchButton
                            title="Search images from Pixabay"
                            icon={PixabayIcon}
                            handler={pixabayHandler}
                          />
                        </div>
                      </div>
                      <div className="col-10">
                        {pixabayImage ? (
                          <div
                            // className="previewProfilePic preview-pic"
                            className={
                              pixabayImageLoaded
                                ? "preview-pic"
                                : "preview-pic-loading"
                            }
                          >
                            <img
                              className="playerProfilePic_home_tile pr-1"
                              src={
                                pixabayImageLoaded
                                  ? pixabayImage
                                  : require("../../assets/img/loading2.svg")
                              }
                              onLoad={() => setPixabayImageLoaded(true)}
                            ></img>
                            <button
                              class="button is-danger"
                              onClick={() => pixabayImageHandler(null)}
                            >
                              X
                            </button>
                          </div>
                        ) : (
                          pixabayClicked &&
                          props.pixabay.result && (
                            <PixabayForm
                              inputValue={inputValue}
                              imageHandler={(imageUrl) =>
                                pixabayImageHandler(imageUrl)
                              }
                            />
                          )
                        )}
                      </div>
                    </div>
                  </TabPanel>
                </Tabs>
                {/* <hr /> */}
              </div>
            )}

            {activityType == "text" && (
              <div className="w-100">
                {/*<div className="error-message">field is empty.</div>*/}
                <div className="error-message">{errors.link?.message}</div>
                {/* <label className="label"> {t("type")} </label> */}

                <div className="field">
                  <Controller
                    control={control}
                    name="content"
                    defaultValue={activityId ? oldValue.content : ""}
                    refs={register}
                    error={errors.root}
                    render={({ onChange, onBlur, value }) => (
                      <ReactQuill
                        theme="snow"
                        className="quill-root"
                        modules={quillModules}
                        formats={quillFormats}
                        onChange={(root, delta, source, editor) =>
                          onChange(root)
                        }
                        value={value || ""}
                      />
                    )}
                  />
                </div>
                <input
                  className="input mt-2"
                  ref={register}
                  defaultValue={activityId ? oldValue.description : t("text")}
                  type="text"
                  name="description"
                  placeholder={t("Button Name")}
                />
              </div>
            )}

            {!(
              activityType == "image" ||
              activityType == "audio" ||
              activityType == "text"
            ) && (
              <div className="w-100">
                {/*<div className="error-message">field is empty.</div>*/}
                <div className="error-message">{errors.link?.message}</div>
                {/* <label className="label"> {t("type")} </label> */}
                <textarea
                  className="new-text-area"
                  ref={register}
                  name="content"
                ></textarea>
              </div>
            )}

            {/* <div className="w-100"></div> */}
            <div className="column box w-100">
              <ButtonStylesBulma
                activityType={activityType}
                selectButtonStyle={selectButtonStyle}
                defaultStyle={activityId ? oldValue.style : "style1"}
              />
            </div>

            <div className="row w-100">
              <div className="col-md-6 border-right">
                <SwitchOption
                  activityType={activityType}
                  val1={t("Public")}
                  val2={t("Private")}
                  name="public"
                  selectOption={selectOptionPublic}
                  id={1}
                  defaultValue={activityId ? (oldValue.show ? 1 : 0) : 1}
                />
              </div>
              <div className="col-md-6">
                <div className="box-left">
                  {/*<div className="error-message">field is empty.</div>*/}
                  <label className="label">{t("Top")}</label>
                  <input
                    className="new-text-filed"
                    // value="100"
                    defaultValue={parseInt(
                      activityId ? oldValue.top : activityTop
                    )}
                    ref={register}
                    type="text"
                    name="top"
                  />
                </div>

                <div className="box-right">
                  {/*<div className="error-message">field is empty.</div>*/}
                  <label className="label">{t("Left")}</label>
                  <input
                    className="new-text-filed" // value="100"
                    defaultValue={parseInt(
                      activityId ? oldValue.left : activityLeft
                    )}
                    type="text"
                    ref={register}
                    name="left"
                  />
                </div>
              </div>
            </div>

            {/* <SwitchOption
              activityType={activityType}
              val1={t("Visible")}
              val2={t("Hidden")}
              selectOption={selectOptionVisible}
              id={3}
            /> */}

            <div className="field is-grouped w-100">
              <p className="control">
                <button
                  type="submit"
                  value={t("Submit")}
                  className={`button is-info  ${isLoading ? "is-loading" : ""}`}
                  disabled={isLoading}
                >
                  {t("Submit")}
                </button>
              </p>
              <p className="control">
                <button
                  type="reset"
                  value={t("Cancel")}
                  onClick={props.addButtonModalClose}
                  disabled={isLoading}
                  className={`button is-danger  ${
                    isLoading ? "is-loading" : ""
                  }`}
                >
                  {t("Cancel")}
                </button>
              </p>
            </div>
          </form>
        </div>
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    currentPageId: state.pages.currentPage[0].id,
    canvasScaleX: state.scaleZoomFactor.canvas.scaleX,
    canvasScaleY: state.scaleZoomFactor.canvas.scaleY,
    canvasVals: state.scaleZoomFactor.canvas,
    sideBarOpened: state.sideBarOpened,
    pixabay: state.pixabay,
    buttons: state.pages.currentPage[0].buttons,
  };
};

export default connect(mapStateToProps, {
  addButtonModalOpen,
  addButtonModalClose,
  cancelActivity,
  doneActivity,
  updateActivity,
  insertActivity,
  pullImagesFromPixabay,
  closePixabay,
})(NoteForm);
