import {HIDE_INTERACTIVE_OBJECTS, SHOW_INTERACTIVE_OBJECTS} from "./actionTypes";

export const hideInteractiveObjects = () => {
    return {
        type: HIDE_INTERACTIVE_OBJECTS
    }
};
export const showInteractiveObjects = () => {
    return {
        type: SHOW_INTERACTIVE_OBJECTS
    }
};