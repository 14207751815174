import React, { Component } from "react";
import BookPlaceholder from "../assets/img/book-placeholder.png";
import { connect } from "react-redux";
// // import MainPage from "./MainPage";
import { withRouter } from "react-router-dom";
import { imagesUnloaded as imagesUnloadedAction } from "../actions/imageLoad";
import { resetPages } from "../actions/pages";
import { setLibraryFromLocal } from "../actions/login";
import { withTranslation } from "react-i18next";
import ChangeLanguage from "../components/SubComponents/ChangeLanguage";
import { Offline } from "react-detect-offline";
import ReactGA from "react-ga";
import { getPages } from "../actions/pages";
import { setLibraryOffline } from "../actions/login";

// import {asyncLocalStorage} from "../services/util"

class Library extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ebooks: [],
      library: [],
    };
  }
  componentDidMount() {
    // const OtherComponent = React.lazy(() => import('../assets/offline/17/data'));
    // console.log(
    //   `process.env.REACT_APP_OFFLINE === "false"`,
    //   process.env.REACT_APP_OFFLINE === "false"
    // );
    // console.log(`OtherComponent`, OtherComponent)
    if (process.env.REACT_APP_OFFLINE === "false") {
      this.props.imagesUnloadedAction();
      this.props.resetPages();
      // console.log(
      //   "09-099-09-09-09-0this.library",
      //   this.props.library,
      //   !this.props.loggedIn
      // );
      if (!this.props.loggedIn) {
        this.props.setLibraryFromLocal();
      }
    } else {
      // if(this.props.)
      console.log(`this.props.library`, this.props.library);
      if (this.props.library.length === 0) this.jsonLoader();
    }
  }

  logoutHandler = (e) => {
    // e.preventDefault();
    localStorage.clear(); //TODO DELETE ONLY AUTH , KEEP THE ANNOTS AND CREATE A UI TO DELETE OWN SETTINGS
    window.location.reload();
    // this.props.i18n.changeLanguage("en");
  };
  langHandler = (e) => {
    e.preventDefault();
    // localStorage.clear();
    // window.location.reload();
    console.log("e", e);
    const lang = this.props.i18n.language === "en" ? "tr" : "en";
    // console.log('this.props.i18n', this.props.i18n)
    this.props.i18n.changeLanguage(lang);
  };
  counter = (libBook) => {
    console.log("libBook", libBook);
    // this.setState({
    //   ebooks: 1,
    // });
  };

  jsonLoader = async () => {
    const bookIds = process.env.REACT_APP_OFFLINE_EBOOKS.split(" ");
    console.log(bookIds[0]);
    console.log(`JSON LOADER bookIds`, bookIds, bookIds.length);
    const newLibrary = [];
    const ebookJsons = [];
    bookIds.map((book) => {
      console.log(`book`, book);
      import("../assets/offline/" + book + "/data")
        .then((background) => {
          // this.setState({ background })
          console.log(`background`, background.default[0]);
          return background.default;
        })
        // fetch("./offline/" + book + "/data.json", {
        //   headers: {
        //     "Content-Type": "application/json",
        //     Accept: "application/json",
        //   },
        // })
        //   .then((response) => {
        //     console.log(response);
        //     return response.json();
        //   })
        .then((myJson) => {
          console.log(myJson[0]);
          // console.log(myJson.json());

          myJson.map((mj) => {
            mj.icon = require("../assets/offline/" + book + "/" + mj.icon);
            return mj;
          });
          myJson[0].ebook_pages.map((mj) => {
            mj.path = require("../assets/offline/" + book + "/" + mj.path);
            mj.thumbnail = require("../assets/offline/" +
              book +
              "/" +
              mj.thumbnail);
            mj.buttons.map((btn) => {
              if (btn.button_type === "image" || btn.button_type === "audio") {
                btn.content = require("../assets/offline/" +
                  book +
                  "/" +
                  btn.content);
              }
              return btn;
            });
            return mj;
          });
          // console.log(`bookJson`, bookJson)
          console.log("myJson[0]", myJson[0]);
          const { id, icon } = myJson[0];
          const libBook = {
            id,
            icon: "/offline/" + book + "/" + icon,
            name: myJson[0].name,
          };
          console.log(`id, icon`, id, icon);
          console.log(`libBook`, libBook);
          // newLibrary.push(libBook);
          this.counter(libBook);
          this.setState(
            {
              ebooks: [...this.state.ebooks, myJson[0]],
              // library: [...this.state.library, libBook],
            },
            () => this.props.setLibraryOffline(this.state.ebooks)
          );

          // this.props.getPages(myJson[0])
        });
    });
    // console.log(`newLibrary`, newLibrary);

    // this.setState({
    //   ebooks:1
    // })
  };
  //🇬🇧  🇹🇷
  libraryClickHandler = async (id) => {
    localStorage.cenibook_id = id; //TODO Move this from localStorage to Redux

    const ebooks =
      this.props.library.length === 0
        ? this.state.ebooks.filter((ebook) => ebook.id === id)[0]
        : this.props.library.filter((ebook) => ebook.id === id)[0];
    console.log(`ebooks`, ebooks);
    process.env.REACT_APP_OFFLINE === "true" &&
      (await this.props.getPages(
        ebooks
        // this.state.ebooks.filter((ebook) => ebook.id === id)[0]
      ));
    this.props.history.push("/indivibook/1");
    ReactGA.pageview("/indivibook/1");
  };
  render() {
    const { profile, domain, t, theme } = this.props;
    const isOffline = process.env.REACT_APP_OFFLINE === "true";
    const library =
      isOffline && this.props.library.length === 0
        ? this.state.library
        : this.props.library;

    console.log("profile", profile);
    console.log("library", library, this.props.library);

    return (
      <div className="library" data-theme={theme}>
        <div className="navbar library-header is-fixed-top">
          <div className="container column">
            <div className=" is-one-quarter">
              <img
                className={
                  process.env.REACT_APP_COMPANY_INNER_LOGO_PATH
                    ? "library-company-logo" //require("../assets/img/company_logo.png")
                    : "library-indivi-logo"
                }
                src={
                  process.env.REACT_APP_COMPANY_INNER_LOGO_PATH
                    ? process.env.REACT_APP_COMPANY_INNER_LOGO_PATH //require("../assets/img/company_logo.png")
                    : process.env.REACT_APP_INDIVIBOOK_LOGO_PATH
                }
                alt={this.props.t("company-logo")}
              />
              {/* <h2 className=" is-hidden-tablet">{t("library")}</h2>
              <h1 className=" is-hidden-mobile">{t("library")}</h1> */}
            </div>

            <Offline>
              <div className="no-internet">
                {t("Warning! You do not have an internet connection!")}
              </div>
            </Offline>
            {!isOffline ? (
              <div className="welcome-user">
                <div className="library-username">
                  {t("Welcome")}, {profile && profile.name}
                </div>
                <a
                  className="library-logout"
                  href=""
                  onClick={this.logoutHandler}
                >
                  {t("Logout")}
                </a>
                {/* <img src="https://source.unsplash.com/collection/190727/1600x900" /> */}
              </div>
            ) : (
              <div className="welcome-user">
                <div>{t("IndiviBook Portable")}</div>
              </div>
            )}
          </div>
          {/* <a onClick={this.langHandler}>{t("language")}</a> */}
          <div className="library-selection">
            <div className="select-area">
              {/* <label htmlFor="do-switchSmall">Theme</label> */}
              <div className="select is-small">
                <ChangeLanguage />
                <span className="icon">
                  <img
                    src={require("../assets/img/svg/pagi-combobox-arrow.svg")}
                    alt="First Page"
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="library-content">
          <div className="container column">
            {/* <div className="library-content-row">
              <div className="library-content-title">
                <h2>Last Opened</h2>
              </div>
              <div className="library-book-list">
                <ul>
                  <li>
                    <div className="library-book-item">
                      <a className="library-book-cover" href="">
                        <img src={BookPlaceholder} alt="" />
                      </a>
                      <a className="library-book-title" href="">
                        Temel ve Genel Matematik
                      </a>
                    </div>
                  </li>
                  <li>
                    <div className="library-book-item">
                      <a className="library-book-cover" href="">
                        <img src={BookPlaceholder} alt="" />
                      </a>
                      <a className="library-book-title" href="">
                        Osmanlı Tarihi (1730-1908)
                      </a>
                    </div>
                  </li>
                  <li>
                    <div className="library-book-item">
                      <a className="library-book-cover" href="">
                        <img src={BookPlaceholder} alt="" />
                      </a>
                      <a className="library-book-title" href="">
                        İngilizce Dil Becerilerinin Öğretimi - II{" "}
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
            </div> */}

            <div className="library-content-row">
              <div className="library-content-title">
                <h2>{t("My IndiviBooks")}</h2>
              </div>
              <div className="library-book-list">
                <ul>
                  {library
                    ? library.map((indivibooks) => {
                        return (
                          <li key={indivibooks.id}>
                            <div className="library-book-item">
                              <a
                                className="library-book-cover"
                                onClick={() => {
                                  this.libraryClickHandler(indivibooks.id);
                                }}
                              >
                                <img
                                  src={
                                    indivibooks.icon
                                      ? indivibooks.icon
                                      : require("../assets/img/book-placeholder.png")
                                  }
                                  alt=""
                                />
                              </a>
                              <a
                                className="library-book-title"
                                // href=""
                                onClick={() => {
                                  this.libraryClickHandler(indivibooks.id);
                                }}
                              >
                                {indivibooks.name}
                              </a>
                            </div>
                          </li>
                        );
                      })
                    : "You have no indivibooks."}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    library: state.login.library,
    domain: state.login.domain,
    profile: state.login.user_profile,
    loggedIn: state.login.loggedIn,
    theme: state.theme,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    imagesUnloadedAction: () => dispatch(imagesUnloadedAction()),
    resetPages: () => dispatch(resetPages()),
    setLibraryFromLocal: () => dispatch(setLibraryFromLocal()),
    getPages: (pages) => dispatch(getPages(pages)),
    // getPages: (pages) => dispatch(getPages(pages)),
    setLibraryOffline: (lib) => dispatch(setLibraryOffline(lib)),

    // spinner: (show) => dispatch(spinner(show)),
    // setLoginSuccess: (status) => dispatch(setLoginSuccess(status)),
  };
};
// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(withRouter(Library));

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Library))
);
