import {ADD_BUTTON_MODAL_OPEN, ADD_BUTTON_MODAL_CLOSE} from "./actionTypes";

export const addButtonModalOpen = () => {
    return {
        type: ADD_BUTTON_MODAL_OPEN
    };
};

export const addButtonModalClose = () => {
    return {
        type: ADD_BUTTON_MODAL_CLOSE
    };
};
