import {SEARCHBAR_OPEN, SEARCHBAR_CLOSE} from './actionTypes'
import {sideBarClose} from './sidebar'
export const toggleSearchBar = (isSearchBarOpened) => {
    return (dispatch) => {

        if (isSearchBarOpened) {
            dispatch(searchBarClose());
        } else {
            dispatch(searchBarOpen());
            dispatch(sideBarClose());
        }

    }
};

export const searchBarOpen = () => {
    return {
        type: SEARCHBAR_OPEN
    }
};

export const searchBarClose = () => {
    return {
        type: SEARCHBAR_CLOSE
    }
};

