import React from "react";
import { useTranslation } from "react-i18next";

export default function Highlighter(props) {
  const { t } = useTranslation();
  return (
    <li onClick={props.onClick}>
      <span
        className={`button normal-btn highliter ${
          props.shouldShowButtonLabels ? "show-label-span" : ""
        } ${props.activeButton === "highlighter" ? "active" : ""
        } `}
        title={t("Highlighter")}
        data-tip={t("Highlighter")}
      >
        <img
          src={require("../../assets/img/svg/tools-highliter.svg")}
          alt={t("Highlighter")}
        />
        {props.shouldShowButtonLabels && <span className="show-label-text">{t("Highlight")}</span>}
      </span>
    </li>
  );
}
