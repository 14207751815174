import React from "react";
import { useTranslation } from "react-i18next";

export default function VideoButton(props) {
  const { t, i18n } = useTranslation();
  const { object } = props;
  const transform = "scale(" + object.scale + ")";
  return (
    <div
      className="interactive-object"
      style={{ transform, "transformOrigin": "left top" }}
      >
        <span className="object-icon">
          <svg
            width={object.width} // width={object.width}
            height={object.height}
            viewBox={`0 0 ${object.width} ${object.height}`}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx={object.width / 2}
              cy={object.height / 2}
              r={object.width / 2}
              fill="white"
              stroke="#FD6D87"
              strokeWidth="0" // Was 2 = Mustafa changed to 0
            />
            <path
              d="M9 16.8889V6.97899C9 6.15885 9.93293 5.68743 10.5932 6.17394L16.9638 10.8681C17.4892 11.2552 17.509 12.0335 17.0041 12.4469L10.6335 17.6626C9.98061 18.1972 9 17.7327 9 16.8889Z"
              fill="#FD6D87"
            />
          </svg>
        </span>
        <span className="object-text">{(!object.description || object.description===undefined || object.description==='undefined')? t("Video") :object.description}</span>
      </div>
  );
}

