/*eslint no-unused-vars: 0*/

import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import History from "./history";
import Pan from "./pan";
import Capture from "./capture";
import QHideable from "./qHideable";
import Curtain from "./curtain";
import Pencil from "./pencil";
import Line from "./line";
import Arrow from "./arrow";
import Rectangle from "./rectangle";
import ZoomArea from "./ZoomArea";
import EmptyRectangle from "./empty-rectangle";
import EmptyCircle from "./empty-circle";
import Circle from "./circle";
import Select from "./select";
import HardDrag from "./HardDrag";
import Tool from "./tools";
import Highlighter from "./highlighter";
import AddNote from "./AddNoteSketch";
import AddVideo from "./AddVideoSketch";
import AddH5p from "./AddH5pSketch";
import AddAudio from "./AddAudioSketch";
import AddImage from "./AddImageSketch";
import AddLink from "./AddLinkSketch";
import Text from "./text";
import "./main.css";
import { connect } from "react-redux";
import { pageNoChange } from "../../actions/pages";
import { toggleButtons } from "../../actions/buttonShowHide";
import { changeSelectedToolFromOutside } from "../../actions/selectedTool";
import { setScalePageDown } from "../../actions/scalePageDown";
import { setScalePageUp } from "../../actions/scalePageUp";
import { setScalePageSet } from "../../actions/scalePageReset";
import { setScalePageScl } from "../../actions/scalePageSetScl";
import { toggleCapture } from "../../actions/captureStatus";
import { addActivity } from "../../actions/addActivity";
import { addMcQuestionModalOpen } from "../../actions/quiz";
import { addButtonModalOpen } from "../../actions/addButton";
import {
  hideInteractiveObjects,
  showInteractiveObjects,
} from "../../actions/interactiveObjects";
import { Annotation } from "./Annotation";
import {
  bookmarkPage,
  unBookmarkPage,
  isPageBookmarked,
  isOdd,
} from "../../services/util";
import {
  setAnnotationSelectionMode,
  setAnnotationSelectionCoords,
  saveAnnotationSelectionToButton,
  setEditSelectionMode,
  setAnnotationBeforeState,
  setSelectionUndoMode,
  setSelectionRedoMode,
  setSelectionPasteMode,
  setSelectionCopyMode,
  setSelectionRemoveMode,
  setSelectionGroupMode,
  setSelectionUnGroupMode,
} from "../../actions/annotationSelection";
import { guidGenerator } from "../../services/util";
import {
  triggerRedo,
  triggerUndo,
  triggerRemove,
  triggerRemoveAll,
  setImageLoaded,
  showAnnotations,
  hideAnnotations,
  showArea,
  hideArea,
  setPagePartZoomMode,
  setPagePartZoomObject,
  setPagePartZoomCut,
  setAddImageToCanvas,
  addMarginA,
  addMarginB,
  removeMarginA,
  removeMarginB,
  setShowMargins,
  setZoomPartFullScaleVal,
  setPageZoomPartShowLoading,
} from "../../actions/settings";
import { toaster } from "../../actions/showToast";
import {
  nothingSelectedMessage,
  cantDeleteMessage,
  cantInsertMessage,
} from "../../services/constants";
import { toggleSideBar, sideBarClose } from "../../actions/sidebar";
import {
  setToolbarPosition,
  resetToolbarPosition,
  setToolbarVertical,
  setOpacity,
  setCurrentLineWidth,
  setCurrentLineColor,
  setCurrentFillColor,
} from "../../actions/toolbar";
import AddZoom from "./AddZoom";
import QShowable from "./qShowable";
import CaptureZoom from "./CaptureZoom";

const fabric = require("fabric").fabric;
const IMAGESIZE = 1000;
/**
 * Sketch Tool based on FabricJS for React Applications
 */
class SketchField extends PureComponent {
  static propTypes = {
    // the color of the line
    lineColor: PropTypes.string,
    // The width of the line
    lineWidth: PropTypes.number,
    // the fill color of the shape when applicable
    fillColor: PropTypes.string,
    // the background color of the sketch
    backgroundColor: PropTypes.string,
    // the opacity of the object
    opacity: PropTypes.number,
    // number of undo/redo steps to maintain
    undoSteps: PropTypes.number,
    // The tool to use, can be pencil, rectangle, circle, brush;
    tool: PropTypes.string,
    // image format when calling toDataURL
    imageFormat: PropTypes.string,
    // Sketch data for controlling sketch from
    // outside the component
    value: PropTypes.object,
    // Set to true if you wish to force load the given value, even if it is  the same
    forceValue: PropTypes.bool,
    // Specify some width correction which will be applied on auto resize
    widthCorrection: PropTypes.number,
    // Specify some height correction which will be applied on auto resize
    heightCorrection: PropTypes.number,
    // Specify action on change
    onChange: PropTypes.func,
    // Default initial value
    defaultValue: PropTypes.object,
    // Sketch width
    width: PropTypes.number,
    // Sketch height
    height: PropTypes.number,
    // Class name to pass to container div of canvas
    className: PropTypes.string,
    // Style options to pass to container div of canvas
    style: PropTypes.object,
  };

  static defaultProps = {
    lineColor: "black",
    lineWidth: 10,
    fillColor: "transparent",
    backgroundColor: "transparent",
    opacity: 1.0,
    undoSteps: 25,
    // tool: Tool.Select,
    widthCorrection: 2,
    heightCorrection: 0,
    forceValue: false,
  };

  state = {
    action: true,
    annotationList: [],
    deneme: 0,
    scl: 1,
    objectSelected: false,
    downCoords: {
      x: 0,
      y: 0,
    },
    previousPageScale: 1,
    tempImage: null,
    isLoading: true,
    lockScaleConversion: false,
    dontSave: false,
    backgroundImageScaledWidth: null,
    backgroundImageScaledHeight: null,
    canvasChanged: false,
    fulllScaleValueForZoomedObject: 1,
    // brightness: 0.5,
    // contrast: 0.5,
  };
  _initTools = (fabricCanvas) => {
    this._tools = {};
    this._tools[Tool.Capture] = new Capture(fabricCanvas);
    this._tools[Tool.Curtain] = new Curtain(fabricCanvas);
    this._tools[Tool.Select] = new Select(fabricCanvas);
    this._tools[Tool.Pencil] = new Pencil(fabricCanvas);
    this._tools[Tool.Line] = new Line(fabricCanvas);
    this._tools[Tool.Arrow] = new Arrow(fabricCanvas);
    this._tools[Tool.Rectangle] = new Rectangle(fabricCanvas);
    this._tools[Tool.EmptyRectangle] = new EmptyRectangle(fabricCanvas);
    this._tools[Tool.EmptyCircle] = new EmptyCircle(fabricCanvas);
    this._tools[Tool.Circle] = new Circle(fabricCanvas);
    this._tools[Tool.Pan] = new Pan(fabricCanvas);
    this._tools[Tool.HardDrag] = new HardDrag(fabricCanvas);
    this._tools[Tool.Highlighter] = new Highlighter(fabricCanvas);
    this._tools[Tool.AddNote] = new AddNote(fabricCanvas);
    this._tools[Tool.AddVideo] = new AddVideo(fabricCanvas);
    this._tools[Tool.AddH5p] = new AddH5p(fabricCanvas);
    this._tools[Tool.AddAudio] = new AddAudio(fabricCanvas);
    this._tools[Tool.AddImage] = new AddImage(fabricCanvas);
    this._tools[Tool.AddLink] = new AddLink(fabricCanvas);
    this._tools[Tool.AddMCQuestion] = new AddImage(fabricCanvas);
    this._tools[Tool.Text] = new Text(fabricCanvas);
    this._tools[Tool.AddMRQuestion] = new AddImage(fabricCanvas);
    this._tools[Tool.QHideable] = new QHideable(fabricCanvas);
    this._tools[Tool.QShowable] = new QShowable(fabricCanvas);
    this._tools[Tool.AddZoom] = new AddZoom(fabricCanvas);
    this._tools[Tool.CaptureZoom] = new CaptureZoom(fabricCanvas);
    this._tools[Tool.ZoomArea] = new ZoomArea(fabricCanvas);
  };

  /**
   * Enable touch Scrolling on Canvas
   */
  enableTouchScroll = () => {
    let canvas = this._fc;
    if (canvas.allowTouchScrolling) return;
    canvas.allowTouchScrolling = true;
  };

  /**
   * Disable touch Scrolling on Canvas
   */
  disableTouchScroll = () => {
    let canvas = this._fc;
    if (canvas.allowTouchScrolling) {
      canvas.allowTouchScrolling = false;
    }
  };

  /**
   * Add an image as object to the canvas
   *
   * @param dataUrl the image url or Data Url
   * @param options object to pass and change some options when loading image, the format of the object is:
   *
   * {
   *   left: <Number: distance from left of canvas>,
   *   top: <Number: distance from top of canvas>,
   *   scale: <Number: initial scale of image>
   * }
   */

  /**
   * Action when an object is added to the canvas
   */
  _onPathCreated = (e) => {
    this.saveCanvas();
    if (!this.state.action) {
      this.setState({ action: true });
      return;
    }
    let obj = e.path;
    obj.__version = 1;
    // record current object state as json and save as originalState
    let objState = obj.toJSON();
    obj.__originalState = objState;
    let state = JSON.stringify(objState);
    // object, previous state, current state
    this._history.keep([obj, state, state]);
  };

  _afterQHideableClose = async () => {
    // if (!this.props.annotationSelectionMode) {
    const canvas = this._fc;

    await this._fc.forEachObject((obj) => {
      if (obj.qId === this.props.annotationSelections.currentSelection.qId) {
        obj.canvasWidth = this.props.scaleZoomFactorVal.canvas.canvasWidth; //*
        //this.props.annotationSelections.currentSelection.proportion;
        obj.scaleX =
          obj.scaleX * parseFloat(this.props.scaleZoomFactorVal.scaleFactor);
        // obj.left =
        //   (obj.left * 1) /
        //   this.props.annotationSelections.currentSelection.proportion;
        // obj.top =
        //   (obj.top * 1) /
        //   this.props.annotationSelections.currentSelection.proportion;
        console.log("~~~~~~~~~~~object: ", obj.qId ? false : true, obj);
        return obj;
      }
      // obj.visible = false; //obj.qId ? false : true;
    });

    await canvas.discardActiveObject();
    console.log(
      "canvas.getObjects().filter((ob) => ob.visible",
      canvas.getObjects().filter((ob) => ob.visible),
      canvas.getObjects().filter((ob) => ob.visible).length
    );
    if (canvas.getObjects().filter((ob) => ob.visible).length > 0) {
      let sel = await new fabric.ActiveSelection(
        canvas.getObjects().filter((ob) => ob.visible),
        {
          canvas: canvas,
        }
      );

      console.log("---->sel", sel, sel.length);
      console.log("---->sel", sel, sel.length);
      await canvas.setActiveObject(sel);
      await canvas.requestRenderAll();
      await this.group();
      const annotObjects = await this._fc
        ?.toJSON([
          "isCapture",
          "oldScaleX",
          "oldScaleY",
          "canvasWidth",
          "canvasHeight",
          // "isQuestion",
          "qId", //if its added on question selection annot mode
        ])
        .objects.filter((ann) => {
          console.log("ann", ann);
          return ann.visible;
        });
      // .map((ob) => (ob.qId = guidGenerator()));
      console.log("---------->>>>>>>>> ", annotObjects[0]);
      console.log(
        "---------->>>>>>>>> ",
        this.props.annotationSelections.currentSelection.top,
        this.props.annotationSelections.currentSelection.left
      );
      annotObjects[0].left =
        this.props.annotationSelections.currentSelection.left +
        annotObjects[0].left; //*
      // (1 / this.props.annotationSelections.currentSelection.proportion);
      annotObjects[0].top =
        this.props.annotationSelections.currentSelection.top +
        annotObjects[0].top; //*
      // (1 / this.props.annotationSelections.currentSelection.proportion);
      annotObjects.length > 0 &&
        (await this.props.saveAnnotationSelectionToButton(annotObjects));

      await canvas.getActiveObjects().forEach(function (object) {
        canvas.remove(object);
      });
      this.props.showInteractiveObjects();
      this.reloadDraw();
      // console.log("2---------->>>>>>>>> ", annotObjects);
    }
    // await this._fc.forEachObject((obj) => {
    //   if (obj.visible) {
    //     obj.__removed = true;
    //   }
    //   // obj.visible = false; //obj.qId ? false : true;
    // });

    //scaleZoomFactorVal
    // hideInteractiveObjectsVal
    // showAnnotations

    await this.props.setScalePageSet(this.props.scaleZoomFactorVal.scaleFactor);

    this.props.annotationSelections.stateBefore.drawingState
      ? this.props.showAnnotations()
      : this.props.hideAnnotations();

    // this.props.annotationSelections.stateBefore.buttonState
    //   ? this.props.hideInteractiveObjects()
    //   : this.props.showInteractiveObjects();

    // console.log("this._fc.getObjects()", this._fc.getObjects());

    // this._fc.renderAll();
    // }
  };
  _afterPagePartZoomClose = async () => {
    // await new Promise((r) => setTimeout(r, 3000));

    console.log(
      "this.props.pageZoomPartObjectToShow.orderId",
      this.props.pageZoomPartObjectToShow.orderId
    );
    const objs = await this._fc.getObjects();
    this.setState(
      {
        dontSave: true,
      },
      () => {
        let objs = this._fc.getObjects();
        objs.forEach((obj) => {
          if (obj.zoomPagePartId || obj.type === "zoom-image") {
            console.log(`obj.zoomPagePartId`, obj.zoomPagePartId, obj.type);
            this._fc.remove(obj);
          }
        });
        // setTimeout(() => {
        this.setState({
          dontSave: false,
        });
      }
    );

    console.log("_afterPagePartZoomClose", !this.props.pageZoomPartMode, objs);

    !this.props.pageZoomPartMode &&
      this.setThumbnailBGFromDataUrl(
        this.props.image.thumbnail,
        this.props.image.path,
        {},
        null,
        true
        // reloadDraw()
      );
    !this.props.pageZoomPartMode && this.props.showInteractiveObjects();
    // this.props.setPagePartZoomMode(false);
    // _sketch.current.setBackgroundFromDataUrl(props.image.path, {}, _setBg);
  };

  _onObjectAdded = (e) => {
    //after:render
    // console.log(`addddded objects`, this.state.dontSave);
    if (e.target.marginType) {
      return;
    }
    let obj = e.target;
    console.log(`~~~~ _onObject Added obj.fromJson`, obj.fromJson, obj.type);
    if (obj.fromJson === true) return;
    if (this.props.annotationSelectionMode) {
      obj.qId = this.props.annotationSelections.currentSelection.qId;
    }
    if (this.props.pageZoomPartMode) {
      obj.zoomPagePartId = 111;
    }

    // console.log("obj.fromJson", obj.fromJson, obj.qId);
    obj.canvasWidth = this.props.setAnnotationSelectionMode
      ? this.props.scaleZoomFactorVal.canvas.canvasWidth // *
      : // this.props.annotationSelections.currentSelection.proportion
        this._fc.getWidth();
    obj.oldScaleX = 1;
    obj.oldScaleY = 1;
    if (e.target.type === "zoom-image") {
      return;
    }
    // console.log("object added????");
    // console.log("object added????", obj, this._fc.getObjects());

    // console.log("object:added", e.target);
    if (
      this._selectedTool === this._tools[Tool.Rectangle] ||
      this._selectedTool === this._tools[Tool.Circle] ||
      this._selectedTool === this._tools[Tool.EmptyRectangle] ||
      this._selectedTool === this._tools[Tool.EmptyCircle] ||
      this._selectedTool === this._tools[Tool.Line]
    ) {
      setTimeout(() => {
        !this.state.dontSave && this.saveCanvas();
      }, 1500);
    } else {
      !this.state.dontSave && this.saveCanvas();
    }

    if (!this.state.action) {
      this.setState({ action: true });
      return;
    }

    obj.__version = 1;
    // record current object state as json and save as originalState
    let objState = obj.toJSON();
    obj.__originalState = objState;
    let state = JSON.stringify(objState);
    // object, previous state, current state
    this._history.keep([obj, state, state]);
  };

  /**
   * Action when an object is scaling inside the canvas
   */
  _onObjectScaling = (e) => {
    // this.saveCanvas();
  };

  /**
   * Action when an object is rotating inside the canvas
   */
  _onObjectRotating = (e) => {
    // this.saveCanvas();
  };

  _onObjectModified = (e) => {
    console.log(" modified????", e);
    let obj = e.target;
    obj.__version += 1;
    let prevState = JSON.stringify(obj.__originalState);
    let objState = obj.toJSON();
    // record current object state as json and update to originalState
    obj.__originalState = objState;
    let currState = JSON.stringify(objState);
    this._history.keep([obj, prevState, currState]);
    console.log(
      `e.target.marginType !== undefined`,
      e.target.marginType,
      e.target.marginType !== undefined
    );
    if (e.target.marginType) {
      e.target.marginType === "A"
        ? this.props.addMarginA(
            obj.toJSON([
              "lockRotation",
              "lockMovementY",
              "marginType",
              "marginId",
              "canvasAddedWidth",
            ])
          )
        : this.props.addMarginB(
            obj.toJSON([
              "lockRotation",
              "lockMovementY",
              "marginType",
              "marginId",
              "canvasAddedWidth",
            ])
          );
    } else {
      this.saveCanvas();
    }
  };
  _onObjectScaled = (e) => {
    // return;
    if (this.state.lockScaleConversion) return;

    let obj = e.target;
    console.log("OBJECT SCALED");
    setTimeout(() => {
      if (obj.type === "rect") {
        obj.set({
          width: obj.width * obj.scaleX,
          height: obj.height * obj.scaleY,
        });
        obj.scaleX = obj.scaleY = 1;
      } else {
        obj.oldScaleX = obj.scaleX;
        obj.oldScaleY = obj.scaleY;
      }

      console.log("OBJECT SCALED", obj);

      obj.setCoords();
      this._fc.calcOffset();
      this._fc.renderAll();
      obj.__version += 1;
      let prevState = JSON.stringify(obj.__originalState);
      let objState = obj.toJSON();
      // record current object state as json and update to originalState
      obj.__originalState = objState;
      let currState = JSON.stringify(objState);
      this._history.keep([obj, prevState, currState]);

      this.saveCanvas();
    }, 100);
  };

  /**
   * Action when an object is removed from the canvas
   */
  _onObjectRemoved = (e) => {
    // console.log("removed", "this.state.dontSave", this.state.dontSave);
    let obj = e.target;
    if (obj.__removed) {
      obj.__version += 1;
      return;
    }
    obj.__version = 0;
    if (e.target.marginType) {
      e.target.marginType === "A"
        ? this.props.removeMarginA(e.target.marginId)
        : this.props.removeMarginB(e.target.marginId);
      return;
    }

    !this.state.dontSave && this.saveCanvas();
  };

  /**
   * Action when the mouse button is pressed down
   */
  _onMouseDown = (e) => {
    console.log("this._selectedTool", this._selectedTool);
    if (!this.props.modalOpen) {
      switch (this._selectedTool) {
        case this._tools[Tool.AddNote]:
          this.props.addActivity(
            "text",
            e.pointer.y,
            e.pointer.x,
            "Note Activity"
          );
          this.props.addButtonModalOpen();
          break;
        case this._tools[Tool.AddVideo]:
          this.props.addActivity(
            "video",
            e.pointer.y,
            e.pointer.x,
            "Video Activity"
          );
          this.props.addButtonModalOpen();
          break;
        case this._tools[Tool.AddH5p]:
          this.props.addActivity(
            "h5p",
            e.pointer.y,
            e.pointer.x,
            "Embed Activity"
          );
          this.props.addButtonModalOpen();
          break;
        case this._tools[Tool.AddAudio]:
          this.props.addActivity(
            "audio",
            e.pointer.y,
            e.pointer.x,
            "Audio Activity"
          );
          this.props.addButtonModalOpen();
          break;
        case this._tools[Tool.AddImage]:
          this.props.addActivity(
            "image",
            e.pointer.y,
            e.pointer.x,
            "Image Activity"
          );
          this.props.addButtonModalOpen();
          break;
        case this._tools[Tool.AddLink]:
          this.props.addActivity(
            "link",
            e.pointer.y,
            e.pointer.x,
            "Link Activity"
          );
          this.props.addButtonModalOpen();
          break;
        case this._tools[Tool.AddMCQuestion]:
          this.props.addActivity(
            "mc_question",
            e.pointer.y,
            e.pointer.x,
            "Multiple Choice Question"
          );
          this.props.addMcQuestionModalOpen();
          break;
        case this._tools[Tool.AddMRQuestion]:
          this.props.addActivity(
            "mr_question",
            e.pointer.y,
            e.pointer.x,
            "Multiple Response Question"
          );
          this.props.addMcQuestionModalOpen();
          break;
        case this._tools[Tool.HardDrag]:
          this._selectedTool.doMouseDown(e);
          break;

        case this._tools[Tool.QHideable]:
          this.onChangeDrawingObjects(false);
          let canvas = this._fc;
          console.log("e", e);
          this.setState({
            downCoords: {
              x: e.pointer.x,
              y: e.pointer.y,
            },
          });
          exitTextEdit(canvas);
          this._selectedTool.doMouseDown(e);

          break;
        case this._tools[Tool.QShowable]: {
          this.onChangeDrawingObjects(false);
          let canvas = this._fc;
          console.log("e", e);
          this.setState({
            downCoords: {
              x: e.pointer.x,
              y: e.pointer.y,
            },
          });
          exitTextEdit(canvas);
          this._selectedTool.doMouseDown(e);

          break;
        }
        case this._tools[Tool.AddZoom]:
        case this._tools[Tool.CaptureZoom]: {
          this.onChangeDrawingObjects(false);
          let canvas = this._fc;
          this.setState({
            downCoords: {
              x: e.pointer.x,
              y: e.pointer.y,
            },
          });
          exitTextEdit(canvas);
          this._selectedTool.doMouseDown(e);
          break;
        }

        default:
          this._selectedTool.doMouseDown(e);
          // console.log("this._selectedTool", this._selectedTool);
          break;
      }
    }
  };

  /**
   * Action when the mouse button double clicked
   */

  _onMouseDblclick = (e) => {
    // let canvas = this._fc;
    // let self = this;
    // console.log('this._canvas', this._canvas)
    // console.log('canvas.upperCanvasEl', canvas.upperCanvasEl)
    // console.log('document.getElementById("canvas").captureStream()',(document.getElementById("mycanvas")))
    // return;
    // var video = document.querySelector("video");
    // fabric.util.requestAnimFrame(function render() {
    //   canvas.renderAll();
    //   fabric.util.requestAnimFrame(render);
    // });
    // // this.videoElement.getElement().play();
    // let chunks = []; // here we will store our recorded media chunks (Blobs)
    // let stream = canvas.lowerCanvasEl.captureStream(30); // grab our canvas MediaStream
    // this.rec = new MediaRecorder(stream, { mimeType: "video/webm;codecs=vp8" }); // init the recorder
    // // every time the recorder has new data, we will store it in our array
    // this.rec.ondataavailable = (e) => chunks.push(e.data);
    // // only when the recorder stops, we construct a complete Blob from all the chunks
    // this.rec.onstop = (e) => {
    //   var blob = new Blob(chunks, { type: "video/mp4" });
    //   // chunks = [];
    //   var videoURL = URL.createObjectURL(blob);
    //   console.log("finished", chunks);
    //   console.log("videoUrl", videoURL);
    //   // var videoURL = URL.createObjectURL(blob);
    //   video.src = videoURL;
    // };
    // // this.exportVid(new Blob(chunks, { type: "video/mp4" }));
    // setTimeout( ()=> {
    //   this.rec.start();
    // }, 2000);
    // // this.rec.start();
    // // // var canvas = document.querySelector("canvas");
    // // var ctx = canvas.getContext("2d");
    // // var video = document.querySelector("video");
    // // // var colors = ["red", "blue", "yellow", "orange", "black", "white", "green"];
    // // // function draw() {
    // // //   ctx.fillStyle = colors[Math.floor(Math.random() * colors.length)];
    // // //   ctx.fillRect(0, 0, canvas.width, canvas.height);
    // // // }
    // // // draw();
    // // var videoStream = canvas.captureStream(30);
    // // var mediaRecorder = new MediaRecorder(videoStream);
    // // var chunks = [];
    // // mediaRecorder.ondataavailable = function (e) {
    // //   chunks.push(e.data);
    // // };
    // // mediaRecorder.onstop = function (e) {
    // //   var blob = new Blob(chunks, { type: "video/mp4" });
    // //   chunks = [];
    // //   var videoURL = URL.createObjectURL(blob);
    // //   video.src = videoURL;
    // // };
    // // mediaRecorder.ondataavailable = function (e) {
    // //   chunks.push(e.data);
    // // };
    // // mediaRecorder.start();
    // // setInterval(draw, 300);
    // setTimeout( ()=> {
    //   this.rec.stop();
    // }, 12000);
    // console.log("canvas", canvas);
    // if (this.props.captured) {
    //   this.props.changeSelectedToolFromOutside("select");
    //   this.props.showInteractiveObjects();
    //   this.props.toggleCapture(this.props.captured);
    //   canvas.backgroundImage.set("opacity", 1);
    //   canvas.renderAll();
    // }
  };

  _onMouseMove = (e) => {
    if (this._selectedTool === this._tools[Tool.Pan]) {
    } else {
      this._selectedTool.doMouseMove(e);
    }
  };

  /**
   * Action when the mouse cursor is moving out from the canvas
   */
  _onMouseOut = (e) => {
    this._selectedTool.doMouseOut(e);
    if (this.props.onChange) {
      let onChange = this.props.onChange;
      setTimeout(() => {
        onChange(e.e);
      }, 10);
    }
  };
  _onMouseOver = (e) => {
    console.log("e.target", e.target);
  };

  _onMouseUp = (e) => {
    if (
      this._selectedTool == this._tools[Tool.Rectangle] ||
      this._selectedTool == this._tools[Tool.Circle] ||
      this._selectedTool == this._tools[Tool.EmptyRectangle] ||
      this._selectedTool == this._tools[Tool.EmptyCircle]
    ) {
      this._selectedTool.doMouseUp(e);
      setTimeout(() => {
        this.saveCanvas();
        this.props.changeSelectedToolFromOutside("select", false);
      }, 500);
    } else if (this._selectedTool === this._tools[Tool.Capture]) {
      this._selectedTool.doMouseUp(e, this.props.imagePath);
      setTimeout(() => {
        this.props.changeSelectedToolFromOutside("select");
        let paGe = document.getElementById("pdfPageList");

        paGe.scrollTop = 0;
        paGe.scrollLeft = 0;

        if (!this.props.hideInteractiveObjectsVal) {
          this.props.hideInteractiveObjects();
          this.props.toggleCapture(true);
        }
      }, 500);
    } else if (this._selectedTool === this._tools[Tool.QHideable]) {
      this.qHideableMouseUp(e, this.props.imagePath);
      setTimeout(() => {
        this.props.changeSelectedToolFromOutside("select");
        if (!this.props.hideInteractiveObjectsVal) {
          this.props.hideInteractiveObjects();
          this.props.toggleCapture(true);
        }
      }, 100);
    } else if (this._selectedTool === this._tools[Tool.QShowable]) {
      this.qHideableMouseUp(e, this.props.imagePath, true);
      setTimeout(() => {
        this.props.changeSelectedToolFromOutside("select");
        if (!this.props.hideInteractiveObjectsVal) {
          this.props.hideInteractiveObjects();
          this.props.toggleCapture(true);
        }
      }, 500);
    } else if (this._selectedTool === this._tools[Tool.AddZoom]) {
      this.qZoomMouseUp(e, this.props.imagePath);
    } else if (this._selectedTool === this._tools[Tool.CaptureZoom]) {
      this.qZoomMouseUp(e, this.props.imagePath, true);
      setTimeout(() => {
        this.props.changeSelectedToolFromOutside("select");
        if (!this.props.hideInteractiveObjectsVal) {
          this.props.hideInteractiveObjects();
          this.props.toggleCapture(true);
        }
      }, 500);
    } else if (this._selectedTool === this._tools[Tool.Pan]) {
    } else {
      this._selectedTool.doMouseUp(e);
    }

    // Update the final state to new-generated object
    // Ignore Path object since it would be created after mouseUp
    // Assumed the last object in canvas.getObjects() in the newest object
    if (this.props.tool !== Tool.Pencil) {
      const canvas = this._fc;
      const objects = canvas.getObjects();
      const newObj = objects[objects.length - 1];
      if (newObj && newObj.__version === 1) {
        newObj.__originalState = newObj.toJSON();
      }
    }
    if (this.props.onChange) {
      let onChange = this.props.onChange;
      setTimeout(() => {
        onChange(e.e);
      }, 10);
    }
  };

  qHideableMouseUp = (o, imagePath, visibility = false) => {
    // this.props.toggleSideBar(true);
    this.props.setToolbarVertical(true);
    this.props.setToolbarPosition({ x: 100, y: 0 });
    const { downCoords } = this.state;
    console.log("downCoords", downCoords);
    console.log("o", o);
    console.log("o.eclientX", o.pointer.x, o.pointer.y);
    console.log(
      "o.pointer.X - downCoords.x",
      o.pointer.x,
      downCoords.x,
      o.pointer.x - downCoords.x
    );
    console.log(
      "(o.pointer.y - downCoords.y)",
      o.pointer.y,
      downCoords.y,
      o.pointer.y - downCoords.y
    );
    let actualWidth = this._fc.backgroundImage.getScaledWidth();
    let proportion =
      this._fc.backgroundImage.getOriginalSize().width / actualWidth;
    let proportionY =
      this._fc.backgroundImage.getOriginalSize().height /
      this._fc.backgroundImage.getScaledHeight();

    if (
      Math.abs(o.pointer.x - downCoords.x) < 10 ||
      Math.abs(o.pointer.y - downCoords.y) < 10
    )
      return;

    let canvas = this._fc;

    console.log("proportion", proportion, proportionY);

    const iWidth = (o.pointer.x - downCoords.x) * proportion;
    const iHeight = (o.pointer.y - downCoords.y) * proportionY;
    const cropX = downCoords.x * proportion;
    const cropY = downCoords.y * proportion;
    console.log("iWidth, iHeight, cropX, cropY", iWidth, iHeight, cropX, cropY);

    let imgObject = new Image();
    imgObject.src = this.props.imagePath;
    // console.log("imgObject", imgObject);

    imgObject.onload = () => {
      const imgInstance = new fabric.Image(imgObject, {
        left: 0,
        top: 0,
        cropX,
        cropY,
        width: iWidth,
        height: iHeight,
        isQuestion: true,
        scaleX: 1 / proportion,
        scaleY: 1 / proportionY,
        crossOrigin: "Anonymous",
      });
      // canvas.add(imgInstance).setActiveObject(imgInstance);

      canvas.setBackgroundImage(
        imgInstance,
        () => {
          canvas.setWidth((imgInstance.width * 1) / proportion);
          canvas.setHeight((imgInstance.height * 1) / proportionY);
        },
        { excludeFromExport: true }
      );
      // console.log("imgInstance", imgInstance);

      this.props.setAnnotationSelectionMode(true);

      this.props.setAnnotationSelectionCoords(
        (cropY * 1) / proportion,
        (cropX * 1) / proportion,
        iWidth,
        iHeight,
        guidGenerator(),
        proportion,
        visibility
      );

      canvas.renderAll();

      // if (
      //   window.innerWidth / img.width > 1.25 &&
      //   window.innerHeight / img.height > 1.25
      // ) {
      //   this.setState({
      //     previousPageScale: this.props.scaleZoomFactorVal.scaleFactor,
      //   });
      //   // this.props.setScalePageUp(this.props.scaleZoomFactorVal.scaleFactor);
      // }

      const paGe = document.getElementById("pdfPageList");
      paGe.scrollTop = 0;
    };
  };

  qZoomMouseUp = (o, imagePath, captureOnly = false) => {
    // this.props.toggleSideBar(true);
    // this.props.setToolbarVertical(true);
    // this.props.setToolbarPosition({ x: 100, y: 0 });
    const { downCoords } = this.state;

    let actualWidth = this._fc.backgroundImage
      ? this._fc.backgroundImage.getScaledWidth()
      : null;
    let proportion = this._fc.backgroundImage
      ? actualWidth / this._fc.backgroundImage.getOriginalSize().width
      : 1;
    let proportionY = this._fc.backgroundImage
      ? this._fc.backgroundImage.getScaledHeight() /
        this._fc.backgroundImage.getOriginalSize().height
      : 1;

    // let proportion =
    //   this._fc.backgroundImage.getOriginalSize().width / actualWidth;
    // let proportionY =
    //   this._fc.backgroundImage.getOriginalSize().height /
    //   this._fc.backgroundImage.getScaledHeight();

    if (
      Math.abs(o.pointer.x - downCoords.x) < 10 ||
      Math.abs(o.pointer.y - downCoords.y) < 10
    ) {
      this.props.showInteractiveObjects();
      this.props.toggleCapture(false);
      return;
    }

    setTimeout(() => {
      this.props.changeSelectedToolFromOutside("select");
      if (!this.props.hideInteractiveObjectsVal) {
        this.props.hideInteractiveObjects();
        this.props.toggleCapture(true);
      }
    }, 100);
    this.props.setPagePartZoomMode(captureOnly ? "capturezoomed" : "zoomed");
    let canvas = this._fc;

    const iWidth = (o.pointer.x - downCoords.x) / proportion;
    const iHeight = (o.pointer.y - downCoords.y) / proportionY;
    const cropX = downCoords.x / proportion;
    const cropY = downCoords.y / proportion;
    // const iWidth = (o.pointer.x - downCoords.x) * proportion;
    // const iHeight = (o.pointer.y - downCoords.y) * proportionY;
    // const cropX = downCoords.x * proportion;
    // const cropY = downCoords.y * proportion;

    // const fullScale = Math.min(
    //   canvas.width / iWidth,
    //   (window.innerHeight - 50) / iHeight
    // );
    const fullScale = Math.min(
      canvas.width / (iWidth * this.props.scaleZoomFactorVal.scaleFactor),
      (window.innerHeight - 50) /
        (iHeight * this.props.scaleZoomFactorVal.scaleFactor)
    );
    this.setState({
      fulllScaleValueForZoomedObject: fullScale,
    });
    // console.log("fullScale, proportion", fullScale, proportion, 1 / proportion);
    fabric.Image.fromURL(
      imagePath,
      (img) => {
        img.set({
          left: 0,
          top: 0,
          cropX,
          cropY,
          width: iWidth,
          height: iHeight,
          zoomPagePart: true, //TODO change
          scaleX: fullScale,
          scaleY: fullScale,
          crossOrigin: "Anonymous",
        });

        canvas.add(img).setActiveObject(img);
        canvas.getActiveObject().setOptions({
          zoomPagePart: true, //TODO change
        });

        canvas.setBackgroundColor("#fff");
        canvas.backgroundImage.set("opacity", 0);
        const qZoomCount = this.props.currentPage[0].buttons.filter(
          (bt) => bt.button_type === "zoomPagePart"
        ).length;
        // this.props.setPagePartZoomObject(qZoomCount + 1, downCoords, o.pointer);
        this.props.setPagePartZoomObject(
          qZoomCount + 1,
          { x: cropX, y: cropY },
          { x: iWidth, y: iHeight }
        );
        canvas.renderAll();
        const paGe = document.getElementById("pdfPageList");
        paGe.scrollTop = 0;
      },
      { crossOrigin: "anonymous" }
    );
  };

  deleteNoSave = async (orderId = false) => {
    let canvas = this._fc;
    await this.setState(
      {
        dontSave: true,
      },
      () => {
        let objs = canvas.getObjects();
        console.log("this.state.dontSave", this.state.dontSave);
        objs.forEach((obj) => {
          // if (obj.zoomPagePartId) {
          // console.log(`obj.zoomPagePartId`, obj.zoomPagePartId);
          canvas.remove(obj);
          // }
        });
        // setTimeout(() => {
        this.setState({
          dontSave: false,
        });
        // }, 100);
        // await this.removeAllObjects();
      }
    );

    // !this.state.dontSave && this.saveCanvas();
    canvas.discardActiveObject();
    canvas.requestRenderAll();
    console.log("_____________DELETED", orderId);

    // if (orderId) {
    //   const itemId = `${this.props.ebookId}_item_${this.props.currentPage[0].id}_part_${this.props.pageZoomPartObjectToShow.orderId}`;
    //   let last = JSON.parse(localStorage.getItem(itemId));
    //   // console.log("last---items---", last);
    //   last && this.addObjectsFromJsonForZoomParts(last);
    // }
    // this._fc.requestRenderAll();
  };
  zoomer = async () => {
    let canvas = this._fc;
    console.log("ZOOMER", this.props.pageZoomPartObjectToShow.orderId);
    if (
      this.props.pageZoomPartObjectToShow.orderId &&
      this.props.pageZoomPartMode === "zoom-show"
    ) {
      this.props.setPageZoomPartShowLoading(true);
      const {
        orderId,
        downCoords,
        upCoords,
        cutCoords,
      } = this.props.pageZoomPartObjectToShow;
      if (this.props.scaleZoomFactorVal.scaleFactor !== 1) {
        await this.props.changeSelectedToolFromOutside("zoomnormal");
        console.log(
          `!!!!!!!!! this.props.scaleZoomFactorVal.scaleFactor !== 1`
        );
      }
      // console.log("ZOOMER-", this.props.pageZoomPartObjectToShow.orderId);
      // await this.deleteNoSave(this.props.pageZoomPartObjectToShow.orderId);
      // this.props.hideInteractiveObjects();
      canvas.backgroundImage && canvas.backgroundImage.set("opacity", 0);

      // this.props.sideBarClose(false);

      // let actualWidth = this.state.backgroundImageScaledWidth;
      // console.log('actualWidth', actualWidth)
      // let proportion =
      //   actualWidth /
      //   this.props.scaleZoomFactorVal.canvas.canvasBackgroundImageWidth;
      // //  /actualWidth;
      // let proportionY =
      //   this.state.backgroundImageScaledHeight /
      //   this.props.scaleZoomFactorVal.canvas.canvasBackgroundImageHeight;
      //  /
      // this.state.backgroundImageScaledHeight;

      // if (
      //   Math.abs(upCoords.x - downCoords.x) < 10 ||
      //   Math.abs(upCoords.y - downCoords.y) < 10
      // )
      //   return;

      // this.props.setPagePartZoomMode("zoomed");
      if (cutCoords && cutCoords.y > 0) {
        console.log("CUT COORDS");
        const iWidth = upCoords.x; /// proportion;
        const iHeight = cutCoords.y; /// proportionY;
        const cropX = downCoords.x; /// proportion;
        const cropY = downCoords.y; /// proportion;

        const img1height = downCoords.y + cutCoords.y;

        const iHeight2 = upCoords.y - cutCoords.y; /// proportionY;
        const cropY2 = img1height; /// proportion;
        const byHeight =
          (upCoords.y - cutCoords.y) * (canvas.width / iWidth) - 450 >
          window.innerHeight;

        let fullScale = byHeight
          ? Math.min(canvas.width / iWidth, (window.innerHeight - 50) / iHeight)
          : canvas.width / iWidth / 2.1;
        this.props.setZoomPartFullScaleVal({
          fullScale,
          scaleX: this.props.scaleZoomFactorVal.canvas.scaleX,
        });
        // const heightOfFirstImage=

        console.log(
          fullScale,
          "fullScale byHeight",
          byHeight,
          (upCoords.y - cutCoords.y) * (canvas.width / iWidth),
          window.innerHeight,
          canvas.width / iWidth
        );
        let imgObject = new Image();
        imgObject.crossOrigin = "Anonymous";
        imgObject.src = this.props.imagePath;
        imgObject.onload = null;
        let imgObject2 = new Image();
        imgObject2.crossOrigin = "Anonymous";
        imgObject2.onload = null;
        imgObject2.src = this.props.imagePath;
        let imgInstance = null;
        imgObject.onload = async () => {
          imgInstance = new fabric.ZoomImage(imgObject, {
            left: 0,
            top: 0,
            cropX,
            cropY,
            width: iWidth,
            height: iHeight,
            zoomPagePart: true, //TODO change
            zoomBgImage: true,
            scaleX: fullScale,
            scaleY: fullScale,
            crossOrigin: "Anonymous",
            canvasWidth: canvas.width,
          });

          // await this.deleteNoSave(this.props.pageZoomPartObjectToShow.orderId);
          // const orderId = this.props.pageZoomPartObjectToShow.orderId;
          // if (orderId) {
          //   const itemId = `${this.props.ebookId}_item_${this.props.currentPage[0].id}_part_${this.props.pageZoomPartObjectToShow.orderId}`;
          //   let last = JSON.parse(localStorage.getItem(itemId));
          //   if (last) {
          //     last.objects = await [imgInstance.toJSON(), ...last.objects];
          //     (await last) && this.addObjectsFromJsonForZoomParts(last);
          //   } else {
          //     await canvas.add(imgInstance).setActiveObject(imgInstance);
          //     canvas.getActiveObject().sendToBack();
          //     canvas.discardActiveObject();
          //     canvas.setBackgroundColor("#fff");
          //   }
          // }
        };
        imgObject2.onload = async () => {
          const imgInstance2 = new fabric.ZoomImage(imgObject2, {
            left: iWidth * fullScale,
            top: 10,
            cropX,
            cropY: cropY2,
            width: iWidth,
            height: iHeight2,
            zoomPagePart: true, //TODO change
            zoomBgImage: true,
            scaleX: fullScale,
            scaleY: fullScale,
            canvasWidth: canvas.width,
            crossOrigin: "Anonymous",
          });
          const orderId = this.props.pageZoomPartObjectToShow.orderId;
          if (orderId) {
            const itemId = `${this.props.ebookId}_item_${this.props.currentPage[0].id}_part_${this.props.pageZoomPartObjectToShow.orderId}`;
            let last = JSON.parse(localStorage.getItem(itemId));
            if (last) {
              last.objects = await [
                imgInstance.toJSON(),
                imgInstance2.toJSON(),
                ...last.objects,
              ];
              (await last) && this.addObjectsFromJsonForZoomParts(last);
            } else {
              await canvas.add(imgInstance).setActiveObject(imgInstance);
              canvas.getActiveObject().sendToBack();
              await canvas.add(imgInstance2).setActiveObject(imgInstance2);
              canvas.getActiveObject().sendToBack();
              canvas.discardActiveObject();
              canvas.setBackgroundColor("#fff");
            }
          }
          this._fc.requestRenderAll();
          this.zoomQhideableAdd(fullScale);

          canvas.backgroundImage && canvas.backgroundImage.set("opacity", 0);
          console.log("ZOOMER", canvas.backgroundImage);
          canvas.renderAll();
          const paGe = document.getElementById("pdfPageList");
          paGe.scrollTop = 0;
          this.props.setPageZoomPartShowLoading(false);
        };
      } else {
        // console.log("NOOOO CUT COORDS", cutCoords);
        const iWidth = upCoords.x; /// proportion;
        const iHeight = upCoords.y; /// proportionY;
        const cropX = downCoords.x; /// proportion;
        const cropY = downCoords.y; /// proportion;
        const fScale1 =
          canvas.width / (iWidth * this.props.scaleZoomFactorVal.scaleFactor);
        const fScale2 =
          (window.innerHeight - 50) /
          (iHeight * this.props.scaleZoomFactorVal.scaleFactor);
        const fullScale = Math.min(fScale1, fScale2);
        this.props.setZoomPartFullScaleVal({
          fullScale,
          scaleX: this.props.scaleZoomFactorVal.canvas.scaleX,
        });

        this.setState({
          fulllScaleValueForZoomedObject: fullScale,
        });
        // console.log(
        //   "iWidth,iHeight,cropX,cropY",
        //   iWidth,
        //   iHeight,
        //   cropX,
        //   cropY
        // );
        // console.log(`canvas.width`, canvas.width);
        // console.log("fullScale, fScale1, fScale2", fullScale, fScale1, fScale2);
        let imgObject = new Image();
        imgObject.crossOrigin = "Anonymous";
        imgObject.src = this.props.imagePath;

        // console.log("imgObject", imgObject);

        imgObject.onload = async () => {
          const imgInstance = new fabric.ZoomImage(imgObject, {
            left: 0,
            top: 0,
            cropX,
            cropY,
            // strokeWidth: 1,
            // stroke: "gray",
            width: iWidth,
            height: iHeight,
            zoomPagePart: true, //TODO change
            zoomBgImage: true,
            scaleX: fullScale,
            scaleY: fullScale,
            canvasWidth: canvas.width,
            crossOrigin: "Anonymous",
          });

          // let newProps = { zoomPagePart: true, zoomBgImage: true };
          // imgInstance.set("helloworld", newProps);
          await this.deleteNoSave(this.props.pageZoomPartObjectToShow.orderId);

          const orderId = this.props.pageZoomPartObjectToShow.orderId;
          if (orderId) {
            const itemId = `${this.props.ebookId}_item_${this.props.currentPage[0].id}_part_${this.props.pageZoomPartObjectToShow.orderId}`;
            let last = JSON.parse(localStorage.getItem(itemId));
            if (last) {
              last.objects = await [imgInstance.toJSON(), ...last.objects];

              (await last) && this.addObjectsFromJsonForZoomParts(last);
            } else {
              await canvas.add(imgInstance).setActiveObject(imgInstance);

              console.log("imgInstance", imgInstance);

              canvas.getActiveObject() && canvas.getActiveObject().sendToBack();
              canvas.discardActiveObject();
              canvas.setBackgroundColor("#fff");
            }
          }

          canvas.backgroundImage && canvas.backgroundImage.set("opacity", 0);
          console.log("ZOOMER", canvas.backgroundImage);
          this.zoomQhideableAdd(fullScale);
          //* (1 + (1 - this.props.scaleZoomFactorVal.canvas.scaleX))
          // this.setCanvasObjectSizes(true);
          // canvas.setZoom(fullScale);
          canvas.setHeight(window.innerHeight - 10);

          canvas.renderAll();
          const paGe = document.getElementById("pdfPageList");
          paGe.scrollTop = 0;
          this.props.setPageZoomPartShowLoading(false);
        };
      }
    } else {
      // this.props.setPagePartZoomMode(null);
      console.log(`ZOOM MODE EXIT???`);
      this.props.setPageZoomPartShowLoading(false);

      // await this.props.sideBarClose(false);

      // this.setThumbnailBGFromDataUrl(
      //   this.props.image.thumbnail,
      //   this.props.image.path
      // );

      // canvas.forEachObject((obj) => {
      //   if (obj.zoomPagePartId) {
      //     canvas.remove(obj)
      //   }

      // });
      // canvas.renderAll();

      setTimeout(async () => {
        this._resize(null, true, true);
        await this.props.showInteractiveObjects();
      }, 200);
    }
  };
  /**
   * Track the resize of the window and update our state
   *
   * @param e the resize event
   * @private
   */

  _resize = (e, shouldReRender = false) => {
    try {
      if (this.props.pageZoomPartMode === "zoom-show") shouldReRender = true;
      console.log("r!!!!!!esize", shouldReRender);
      if (this.props.annotationSelectionMode) return;
      if (e) e.preventDefault();
      let canvas = this._fc;

      let diffSize = 0;
      if (window.innerWidth < 1224) {
        this.vpW = window.innerWidth - 50;
        diffSize = -50;
      } else {
        this.vpW = window.innerWidth - 50;
        diffSize = -50;
      }

      if (
        this.props.sidebarIsOpened
        // && window.innerWidth < 640
      ) {
        this.vpW -= 200;
        diffSize -= 200;
      }

      if (this.vpW < 640) this.vpW = 640;

      this.vpH = window.innerHeight;

      if (
        !shouldReRender &&
        this.vpW === this.props.scaleZoomFactorVal.canvas.vpW &&
        this.vpH === this.props.scaleZoomFactorVal.canvas.vpH &&
        this.props.scaleZoomFactorVal.canvas.canvasBackgroundImageWidth > 250
      ) {
        if (canvas.backgroundImage) {
          canvas.backgroundImage.scaleToWidth(canvas.getWidth());
          // canvas.setHeight(canvas.backgroundImage.getScaledHeight());
        }
        setTimeout(() => {
          this.setCanvasObjectSizes();
        }, 100);

        canvas.renderAll();
        return;
      }
      let sclOld = (this.vpW - IMAGESIZE) / IMAGESIZE;

      this.props.interactiveButtonsSizeChanged(sclOld);

      this.setState({
        scl: sclOld,
      });

      canvas.setWidth(this.vpW);
      canvas.setHeight(this.vpH);
      if (canvas.backgroundImage) {
        canvas.backgroundImage.scaleToWidth(canvas.getWidth());
        canvas.setHeight(canvas.backgroundImage.getScaledHeight());
      }
      const isZoomMode = this.props.pageZoomPartMode === "zoom-show";
      const nRatio =
        this.props.scaleZoomFactorVal.canvas.canvasWidth / canvas.width;
      // console.log(`isZoomMode`, isZoomMode);
      // console.log(`nRatio`, nRatio);
      sclOld &&
        this.props.setScalePageScl(
          sclOld,
          !isZoomMode
            ? canvas.backgroundImage
              ? canvas.backgroundImage.scaleX
              : null
            : this.props.scaleZoomFactorVal.canvas.scaleX / nRatio,
          !isZoomMode
            ? canvas.backgroundImage
              ? canvas.backgroundImage.scaleY
              : null
            : this.props.scaleZoomFactorVal.canvas.scaleY / nRatio,
          this.vpW,
          this.vpH,
          canvas.width,
          isZoomMode
            ? this.props.scaleZoomFactorVal.canvas.canvasHeight / nRatio
            : canvas.height,
          this.props.pages.firstImageProps["0"],
          this.props.pages.firstImageProps["1"]
          // canvas.backgroundImage
          //   ? canvas.backgroundImage.width
          //   : this.props.scaleZoomFactorVal.canvas.canvasBackgroundImageWidth,
          // canvas.backgroundImage
          //   ? canvas.backgroundImage.height
          //   : this.props.scaleZoomFactorVal.canvas.canvasBackgroundImageHeight
        );
      // return;
      // this.setCanvasObjectSizes();
      // setTimeout(() => {

      this.setCanvasObjectSizes(shouldReRender);

      // }, 100);

      canvas.renderAll();
    } catch (error) {
      console.log("error", error);
    }
  };

  toggleQHideables = () => {
    console.log("toggleQHideables", this.props.annotationIdsToShow);
    const canvas = this._fc;
    canvas.forEachObject((obj) => {
      // console.log("obj", obj);
      if (obj.qId) {
        console.log(
          "obj.qId",
          obj.qId,
          obj.left,
          obj.top,
          this.props.annotationIdsToShow,
          obj.visible
        );
        obj.visible =
          this.props.annotationIdsToShow &&
          this.props.annotationIdsToShow.find((id) => id === obj.qId)
            ? true
            : false;
      }
    });
    canvas.renderAll();
  };

  setCanvasObjectSizes = async (shouldReRender = false) => {
    // return;
    console.log(">>>>>>>>>setCanvasObjectSizes");
    const canvas = this._fc;
    await this.setState({
      lockScaleConversion: true,
      backgroundImageScaledWidth: this._fc.backgroundImage?.getScaledWidth(),
      backgroundImageScaledHeight: this._fc.backgroundImage?.getScaledHeight(),
    });
    // let previuosTrueObjectanvasWidth
    await canvas.forEachObject((obj) => {
      console.log(`obj.type`, obj.type);
      try {
        const newRatioX = canvas.getWidth() / obj.canvasWidth || 1;
        console.log(`newRatioX`, newRatioX);
        console.log(`shouldReRender`, shouldReRender);
        // *this.state.fulllScaleValueForZoomedObject;

        if (shouldReRender)
          obj.visible = this.props.annotationSelectionMode
            ? obj.qId
              ? true
              : false
            : obj.qId
            ? this.props.annotationIdsToShow &&
              this.props.annotationIdsToShow.find((id) => id !== obj.qId)
              ? true
              : false
            : true;
        // if (this.state.fulllScaleValueForZoomedObject !== 1) {
        //   obj.set(
        //     "scaleX",
        //     obj.scaleX * newRatioX * this.state.fulllScaleValueForZoomedObject
        //   );
        //   obj.set(
        //     "scaleY",
        //     obj.scaleY * newRatioX * this.state.fulllScaleValueForZoomedObject
        //   );
        // }
        if (newRatioX !== 1) {
          // console.log(
          //   canvas.getWidth(),
          //   obj.type,
          //   obj.canvasWidth,
          //   "newRatioX",
          //   newRatioX
          // );
          obj.canvasWidth = canvas.getWidth();
          // console.log(
          //   `this.state.fulllScaleValueForZoomedObject`,
          //   this.state.fulllScaleValueForZoomedObject
          // );
          obj.set("scaleX", obj.scaleX * newRatioX);
          obj.set("scaleY", obj.scaleY * newRatioX);

          obj.set("left", obj.left * newRatioX);
          obj.set("top", obj.top * newRatioX);
          obj.setCoords();
          console.log(`obj`, obj);
          this._fc.calcOffset();
        }
        this._fc.renderAll();
      } catch (err) {
        console.error(err);
      }
    });
    await this.setState({
      lockScaleConversion: false,
    });
  };

  objInArray(obj, annotationArray) {
    for (var i = 0; i < annotationArray.length; i++) {
      if (annotationArray[i].obj === obj) {
        return i;
      }
    }
    return -1;
  }

  /**
   * Perform an undo operation on canvas, if it cannot undo it will leave the canvas intact
   */
  undo = () => {
    try {
      let history = this._history;
      if (!history.current) return false;
      let [obj, prevState, currState] = history.getCurrent();
      history.undo();
      if (obj.__removed) {
        this.setState({ action: false }, () => {
          this._fc.add(obj);
          obj.__version -= 1;
          obj.__removed = false;
        });
      } else if (obj.__version <= 1 && !obj.zoomPagePart) {
        this._fc.remove(obj);
      } else {
        obj.__version -= 1;
        obj.setOptions(JSON.parse(prevState));
        obj.setCoords();
        this._fc.renderAll();
      }
      if (this.props.onChange) {
        this.props.onChange();
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  /**
   * Perform a redo operation on canvas, if it cannot redo it will leave the canvas intact
   */
  redo = () => {
    try {
      let history = this._history;
      if (history.canRedo()) {
        let canvas = this._fc;
        //noinspection Eslint
        let [obj, prevState, currState] = history.redo();
        if (obj.__version === 0) {
          this.setState({ action: false }, () => {
            canvas.add(obj);
            obj.__version = 1;
          });
        } else {
          obj.__version += 1;
          obj.setOptions(JSON.parse(currState));
        }
        obj.setCoords();
        canvas.renderAll();
        if (this.props.onChange) {
          this.props.onChange();
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  /**
   * Delegation method to check if we can perform an undo Operation, useful to disable/enable possible buttons
   *
   * @returns {*} true if we can undo otherwise false
   */
  canUndo = () => {
    return this._history.canUndo();
  };

  /**
   * Delegation method to check if we can perform a redo Operation, useful to disable/enable possible buttons
   *
   * @returns {*} true if we can redo otherwise false
   */
  canRedo = () => {
    return this._history.canRedo();
  };

  /**
   *
   * @returns {String} URL containing a representation of the object in the format specified by options.format
   */
  toDataURL = (options) => this._fc.toDataURL(options);

  /**
   * Returns JSON representation of canvas
   *
   * @param propertiesToInclude Array <optional> Any properties that you might want to additionally include in the output
   * @returns {string} JSON string
   */
  toJSON = (propertiesToInclude) => this._fc.toJSON(propertiesToInclude);

  /**
   * Populates canvas with data from the specified JSON.
   *
   * JSON format must conform to the one of fabric.Canvas#toDatalessJSON
   *
   * @param json JSON string or object
   */
  fromJSON = (json) => {
    console.log("JSONDAAAN");
    if (!json) return;
    let canvas = this._fc;
    setTimeout(() => {
      canvas.loadFromJSON(json, () => {
        canvas.renderAll();
        if (this.props.onChange) {
          this.props.onChange();
        }
      });
    }, 100);
  };

  /**
   * Clear the content of the canvas, this will also clear history but will return the canvas content as JSON to be
   * used as needed in order to undo the clear if possible
   *
   * @param propertiesToInclude Array <optional> Any properties that you might want to additionally include in the output
   * @returns {string} JSON string of the canvas just cleared
   */
  clear = (propertiesToInclude) => {
    let discarded = this.toJSON(propertiesToInclude);
    this._fc.clear();
    this._history.clear();
    this.saveCanvas();
    return discarded;
  };

  /**
   * Remove all objects from the canvas
   */
  removeAllObjects = () => {
    let canvas = this._fc;
    let objs = canvas.getObjects();
    objs.forEach((obj) => {
      // console.log(`obj`, obj)
      // console.log(`obj.zoomPagePart`, obj.zoomPagePartId)
      if (obj.marginType || obj.qId || obj.type === "zoom-image") {
        return;
      }
      obj.__removed = true;
      let objState = obj.toJSON();
      obj.__originalState = objState;
      let state = JSON.stringify(objState);
      this._history.keep([obj, state, state]);
      canvas.remove(obj);
    });
    !this.state.dontSave && this.saveCanvas();
    canvas.discardActiveObject();
    canvas.requestRenderAll();
  };

  /**
   * Hides/shows all drawing objects from the canvas
   */
  onChangeDrawingObjects = (visible) => {
    console.log("~~~~~~~~~visible", visible);
    let canvas = this._fc;
    canvas.selection = false;
    canvas.forEachObject((obj) => {
      obj.visible = visible;
      // canvas.remove(obj);
    });
    canvas.requestRenderAll();
  };

  /**
   * Remove selected object from the canvas
   */
  removeSelected = () => {
    let canvas = this._fc;
    let activeObj = canvas.getActiveObject();

    if (activeObj) {
      let selected = [];
      if (activeObj.type === "activeSelection") {
        activeObj.forEachObject((obj) => selected.push(obj));
      } else {
        selected.push(activeObj);
      }
      selected.forEach((obj) => {
        if (obj.marginType) {
          const pageType = isOdd(this.props.currentPageNumber) ? "A" : "B";
          pageType === "A"
            ? this.props.removeMarginA(obj.marginId)
            : this.props.removeMarginB(obj.marginId);
        }
        if (obj.type !== "zoom-image") {
          obj.__removed = true;
          let objState = obj.toJSON();
          obj.__originalState = objState;
          let state = JSON.stringify(objState);
          this._history.keep([obj, state, state]);
          canvas.remove(obj);
        } else {
          // this.props.toaster(cantDeleteMessage, "warning", 3500);
        }
      });
      canvas.discardActiveObject();
      canvas.requestRenderAll();
      console.log(`delete - this.state.dontSave`, this.state.dontSave);
      !this.state.dontSave && this.saveCanvas();
    } else {
      this.props.toaster(nothingSelectedMessage, "warning", 3500);
    }
  };
  changeSelected = (action = "fillColor") => {
    let canvas = this._fc;
    let activeObj = canvas.getActiveObject();

    if (activeObj) {
      let selected = [];
      if (activeObj.type === "activeSelection") {
        activeObj.forEachObject((obj) => selected.push(obj));
      } else {
        selected.push(activeObj);
      }
      selected.forEach((obj) => {
        switch (action) {
          case "fillColor":
            obj.set("fill", this.props.toolbar.fillColor);
            canvas.fire("object:modified", { target: obj });
            break;
          case "lineColor":
            obj.set("stroke", this.props.toolbar.lineColor);
            canvas.fire("object:modified", { target: obj });
            break;
          case "lineWidth":
            obj.set("strokeWidth", this.props.toolbar.lineWidth);
            canvas.fire("object:modified", { target: obj });
            break;
          case "opacity":
            obj.set("opacity", this.props.toolbar.currentOpacity);
            canvas.fire("object:modified", { target: obj });
            break;
          default:
            break;
        }
      });

      canvas.renderAll();
    }
  };

  onKeyUp = (event) => {
    let canvas = this._fc;
    console.log("event.keyCode: ", event.keyCode);
    if (!event) return;
    let inText = this._selectedTool != this._tools[Tool.Text];
    if (!inText || this.props.settings.inputFocused) {
      return;
    }

    switch (event.keyCode) {
      case 27:
        //escape

        this.props.changeSelectedToolFromOutside("select");
        if (this.props.hideInteractiveObjectsVal && this.props.captured) {
          this.props.hideInteractiveObjects();
          this.props.toggleCapture(this.props.captured);
          canvas.backgroundImage.set("opacity", 1);
        }
        canvas.renderAll();
        break;

      case 46:
        this.removeSelected();
        if (this.props.captured) {
          this.props.showInteractiveObjects();
          this.props.toggleCapture(this.props.captured);
          canvas.backgroundImage.set("opacity", 1);
        }
        break;

      case 8:
        if (!this.props.modalOpen && !this.props.quizAddModalOpen) {
          this.removeSelected();
        }

        if (this.props.captured) {
          this.props.showInteractiveObjects();
          this.props.toggleCapture(this.props.captured);
          canvas.backgroundImage.set("opacity", 1);
        }
        break;
      case 39:
        //next page
        if (!this.props.modalOpen && !this.props.quizAddModalOpen) {
          this.props.pageNoChange(parseInt(this.props.currentPageNumber) + 1);
        }

        break;
      case 37:
        //prev page
        if (!this.props.modalOpen && !this.props.quizAddModalOpen) {
          this.props.pageNoChange(parseInt(this.props.currentPageNumber) - 1);
        }
        break;
      case 72:
        //Highlight
        if (!this.props.modalOpen && !this.props.quizAddModalOpen) {
          this.props.changeSelectedToolFromOutside("highlighter");
        }
        break;

      case 84:
        //Text
        if (!this.props.modalOpen && !this.props.quizAddModalOpen) {
          this.props.changeSelectedToolFromOutside("text");
        }
        break;
      case 83:
        //Select
        if (!this.props.modalOpen && !this.props.quizAddModalOpen) {
          this.props.changeSelectedToolFromOutside("select");
        }
        break;
      case 80:
        //Pen
        if (!this.props.modalOpen && !this.props.quizAddModalOpen) {
          this.props.changeSelectedToolFromOutside("pencil");
        }
        break;
      case 70:
        //Full Screen
        if (!this.props.modalOpen && !this.props.quizAddModalOpen) {
          document.getElementById("fullscreen-btn").click();
        }
        break;
      case 90:
        //Zoom In
        if (!this.props.modalOpen && !this.props.quizAddModalOpen) {
          this.props.setScalePageUp(this.props.scaleZoomFactorVal.scaleFactor);
        }
        break;
      case 88:
        //Zoom Out
        if (!this.props.modalOpen && !this.props.quizAddModalOpen) {
          this.props.setScalePageDown(
            this.props.scaleZoomFactorVal.scaleFactor
          );
        }
        break;
      case 67:
      //Capture
      // if (!this.props.modalOpen && !this.props.quizAddModalOpen) {
      //   this.props.changeSelectedToolFromOutside("capture");
      // }
      // break;
      case 68:
        //Drag the page
        if (!this.props.modalOpen && !this.props.quizAddModalOpen) {
          this.props.changeSelectedToolFromOutside("pan");
        }
        break;
      case 66:
        //Bookmark the page
        if (
          !this.props.modalOpen &&
          !this.props.quizAddModalOpen &&
          this.props.pageZoomPartMode === "zoom-show"
        ) {
          if (!isPageBookmarked(this.props.currentPageNumber)) {
            bookmarkPage(this.props.currentPageNumber);
            document
              .getElementsByClassName("bookmark")[0]
              .classList.add("active");
          } else {
            unBookmarkPage(this.props.currentPageNumber);
            document
              .getElementsByClassName("bookmark")[0]
              .classList.remove("active");
          }
        }
      case 86:
        //Change tool to Select
        if (!this.props.modalOpen && !this.props.quizAddModalOpen) {
          this.props.changeSelectedToolFromOutside("select"); //TODO change toolbar active button
        }
        break;

      case 82:
        //rectangle
        if (!this.props.modalOpen && !this.props.quizAddModalOpen) {
          this.props.changeSelectedToolFromOutside("rectangle");
        }
        break;

      case 49:
        // this.deleteNoSave()
        // const pointer = canvas.getPointer(o.e);
        // const points = [pointer.x, pointer.y, pointer.x, pointer.y];
        // {
        //   const points = [
        //     100,
        //     0,
        //     100,
        //     canvas.backgroundImage.getScaledHeight(),
        //   ];
        //   const points2 = [
        //     canvas.backgroundImage.getScaledWidth() - 100,
        //     0,
        //     canvas.backgroundImage.getScaledWidth() - 100,
        //     canvas.backgroundImage.getScaledHeight(),
        //   ];
        //   this.addMargin(points);
        //   this.addMargin(points2);
        //   setTimeout(() => {
        //     this.props.setShowMargins(true);
        //     // this.toggleMargins(true)
        //   }, 100);
        // }
        // this.props.selectedTool
        // const obj = this._fc.getActiveObject();
        // obj.set("scaleX", 2);
        // obj.set("scaleY", 2);
        // this._fc.add(
        //   new fabric.Circle({
        //     radius: 40,
        //     left: 50,
        //     top: 50,
        //     fill: "rgb(0,255,0)",
        //     opacity: 0.5,
        //   })
        // );

        // // obj.set("left", scaleX);
        // // obj.set("top", scaleY);
        // // obj.setCoords();
        // // console.log("actObj", obj);
        // this._fc.renderAll();
        // //escape
        // // canvas.setZoom(scaleRatio)

        // fabric.loadSVGFromURL(
        //   "http://fabricjs.com/assets/1.svg",
        //   function (objects, options) {
        //     var obj = fabric.util.groupSVGElements(objects, options);
        //     canvas.add(obj).renderAll();
        //   }
        // );
        break;

      case 50: //2
        // const currentObj = this._fc.getActiveObject();
        // currentObj.set("scaleX", 2);
        // currentObj.set("scaleY", 2);
        // currentObj.set("left", 100);
        // currentObj.set("top", 110);
        // currentObj.setCoords();
        // this._fc.renderAll();
        // this.createMarginRect(10, 10, 100, 100);

        // {
        //   // const points = [pointer.x, pointer.y, pointer.x, pointer.y];

        //   const points = [
        //     canvas.backgroundImage.getScaledWidth() / 2 - 10,
        //     0,
        //     canvas.backgroundImage.getScaledWidth() / 2 - 10,
        //     canvas.backgroundImage.getScaledHeight(),
        //   ];

        //   const points2 = [
        //     canvas.backgroundImage.getScaledWidth() / 2 + 10,
        //     0,
        //     canvas.backgroundImage.getScaledWidth() / 2 + 10,
        //     canvas.backgroundImage.getScaledHeight(),
        //   ];
        //   this.addMargin(points);
        //   this.addMargin(points2);
        //   setTimeout(() => {
        //     this.props.setShowMargins(true);
        //     // this.toggleMargins(true)
        //   }, 100);
        // }
        // const obj = this._fc.getActiveObject();
        // console.log(obj.toJSON());
        // console.log("canvas.getObjects()", canvas.getObjects());

        // canvas.getActiveObject().sendToBack();
        // canvas.discardActiveObject();
        // canvas.setBackgroundColor("#fff");
        // canvas.backgroundImage && canvas.backgroundImage.set("opacity", 0);
        // const pointer = canvas.getPointer(o.e);
        // const points = [pointer.x, pointer.y, pointer.x, pointer.y];
        // const points = [
        //   0,
        //   parseInt(window.innerHeight / 2),
        //   canvas.width,
        //   parseInt(window.innerHeight / 2),
        // ];
        // const newLine = new fabric.Line(points, {
        //   strokeWidth: 10,
        //   fill: this.props.toolbar.lineColor,
        //   stroke: "black",
        //   strokeDashArray: [5, 5],
        //   originX: "center",
        //   originY: "center",
        //   selectable: true,
        //   cutter: true,
        // });
        // canvas.add(newLine).renderAll();
        // this._selectedTool = this._tools[Tool.Select];
        // this.changeSelected("select");
        // console.log("newLine", newLine);
        break;
      case 51: //3
        // this.props.setShowMargins(!this.props.settings.showMargins);
        // console.log("canvas.toSVG();", canvas.toSVG());
        // console.log(
        //   "this.props.scaleZoomFactorVal.scaleFactor",
        //   this.props.scaleZoomFactorVal.scaleFactor,
        //   this._fc.getActiveObject().top /
        //     this.state.fulllScaleValueForZoomedObject,
        //   (this._fc.getActiveObject().top /
        //     this.state.fulllScaleValueForZoomedObject) *
        //     this.props.scaleZoomFactorVal.scaleFactor
        // );
        // this.props.setPagePartZoomCut({
        //   x: this.state.fulllScaleValueForZoomedObject,
        //   y:
        //     this._fc.getActiveObject().top /
        //     this.state.fulllScaleValueForZoomedObject,
        //   // this.props.scaleZoomFactorVal.scaleFactor >= 1
        //   //   ? this._fc.getActiveObject().top /
        //   //     this.state.fulllScaleValueForZoomedObject
        //   //   : (this._fc.getActiveObject().top /
        //   //       this.state.fulllScaleValueForZoomedObject) *
        //   //     (1 + parseFloat(this.props.scaleZoomFactorVal.scaleFactor)),
        // });
        break;
      case 53: //5
        console.log("canvas.getObjects()", canvas.getObjects());
        console.log("canvas.", canvas);
        console.log("canvas.backgroundImage", canvas.backgroundImage);
        // this.copy();
        //   this._fc.getActiveObject().sendToBack();

        //   break;
        // case 52: //4
        //   fabric.loadSVGFromURL(
        //     "http://fabricjs.com/assets/1.svg",
        //     function (objects, options) {
        //       var obj = fabric.util.groupSVGElements(objects, options);
        //       canvas.add(obj).renderAll();
        //     }
        //   );
        break;
      case 54: //6
      return;
        const qZoomButtons = this.props.currentPage[0].buttons
          .filter((bt) => bt.button_type === "zoomPagePart")
          .map((btz) => {
            return JSON.parse(btz.content);
          });

        qZoomButtons.forEach((qB) => {
          const { orderId, downCoords, upCoords } = qB;
          const scl = this.props.scaleZoomFactorVal.canvas.scaleX;
          const iWidth = upCoords.x * scl; /// proportion;
          const iHeight = upCoords.y * scl; /// proportionY;
          const left = downCoords.x * scl; /// proportion;
          const top = downCoords.y * scl; /// proportion;
          // console.log(`qB`, qB);
          // const byHeight =
          //   (upCoords.y - cutCoords.y) * (canvas.width / iWidth) - 450 >
          //   window.innerHeight;
          console.log(
            `left,top,iWidth,iHeight`,
            left,
            top,
            iWidth,
            iHeight,
            scl
          );
          this.createMarginRect(left, top, iWidth, iHeight, scl);
        });
        //const { orderId, downCoords, upCoords, cutCoords }
        //SHOW ZOOM AREAS

        break;

      default:
        break;
    }
  };

  createMarginRect = (left, top, width, height, scl = 1) => {
    const mRect = new fabric.MarginRect({
      left,
      top,
      originX: "left",
      originY: "top",
      width,
      height,
      stroke: "red",
      strokeWidth: 3,
      fill: "transparent",
      //fill: 'rgba(255,0,0,0.5)',
      transparentCorners: false,
      selectable: false,
      evented: false,
      angle: 0,
      // scaleX:1,
      // scaleY:scl,
    });
    this._fc.add(mRect);
  };
  toggleMargins = () => {
    console.log("toggleMargins", this.props.settings.showMargins);
    const canvas = this._fc;
    const pageType = isOdd(this.props.currentPageNumber) ? "A" : "B";
    console.log(`pageType`, pageType);
    canvas.forEachObject((obj) => {
      console.log("obj", obj);
      if (obj.marginType === pageType) {
        obj.visible = this.props.settings.showMargins;
      }
    });
    canvas.renderAll();
  };
  addMargin = (points) => {
    const canvas = this._fc;
    const marginLimit = 4;
    if (
      (isOdd(this.props.currentPageNumber) &&
        this.props.settings.marginA.length === marginLimit) ||
      (!isOdd(this.props.currentPageNumber) &&
        this.props.settings.marginB.length === marginLimit)
    ) {
      return;
    }
    const newLine = new fabric.Line(points, {
      strokeWidth: 3,
      stroke: isOdd(this.props.currentPageNumber) ? "#07fa8c" : "#ff00e6",
      strokeDashArray: [6, 6],
      originX: "center",
      originY: "center",
      selectable: true,
      // evented: false,
      lockRotation: true,
      lockMovementY: true,
      marginType: isOdd(this.props.currentPageNumber) ? "A" : "B",
      marginId: isOdd(this.props.currentPageNumber)
        ? this.props.settings.marginA.length + 1
        : this.props.settings.marginB.length + 1,
      canvasAddedWidth: canvas.width,
      // shadow: { color: "rgba(0,0,0,0.3)" },
    });
    newLine.set(
      "shadow",
      new fabric.Shadow({
        color: "black",
        blur: 2,
        offsetX: 1,
        offsetY: 1,
        opacity: 0.2,
      })
    );

    if (isOdd(this.props.currentPageNumber)) {
      this.props.addMarginA(
        newLine.toJSON([
          "lockRotation",
          "lockMovementY",
          "marginType",
          "marginId",
          "canvasAddedWidth",
        ])
      );
    } else {
      this.props.addMarginB(
        newLine.toJSON([
          "lockRotation",
          "lockMovementY",
          "marginType",
          "marginId",
          "canvasAddedWidth",
        ])
      );
    }
    canvas.add(newLine);
  };
  addAnImageToCanvas = () => {
    let imgObject = document.getElementById("popup-image-searchbar");
    let element = document.querySelector("#popup-image-searchbar");
    let element2 = document.querySelector(".modal-content");

    console.log("imgObject", imgObject, element, element2);
    if (!imgObject) {
      this.props.toaster(cantInsertMessage, "warning", 3500);
      return; //TODO add popup message
    }
    imgObject.crossOrigin = "anonymous";
    console.log("imgObject", imgObject);
    imgObject.onload = () => {
      // alert('here you go')
      const imgInstance = new fabric.Image(imgObject, {
        left: 100,
        top: 110,
        crossOrigin: "Anonymous",
        // cropX,
        // cropY,
        // width: iWidth,
        // height: iHeight,
        // zoomPagePart: true, //TODO change
        scaleX: 0.5,
        scaleY: 0.5,
        canvasWidth: this._fc.width,
        // zoomPagePart: true,
      });
      this._fc.add(imgInstance).setActiveObject(imgInstance);
      this._fc.requestRenderAll();
    };
    // imgObject.onload(){}
  };
  group = () => {
    const canvas = this._fc;
    if (!canvas.getActiveObject()) {
      return;
    }
    if (canvas.getActiveObject().type !== "activeSelection") {
      return;
    }
    canvas.getActiveObject().toGroup();
    canvas.requestRenderAll();
  };

  ungroup = () => {
    const canvas = this._fc;
    if (!canvas.getActiveObject()) {
      return;
    }
    if (canvas.getActiveObject().type !== "group") {
      return;
    }
    canvas.getActiveObject().toActiveSelection();
    canvas.requestRenderAll();
  };

  copy = async () => {
    let canvas = this._fc;
    console.log("canvas.getActiveObject()", canvas.getActiveObject().type);
    // return;
    // const object = await canvas.getActiveObject();
    // object.set("top", object.top + 5);
    // object.set("left", object.left + 5);
    // console.log("this._clipboard", this._clipboard, canvas.getActiveObject());
  };

  paste = async () => {
    let canvas = this._fc;
    canvas.getActiveObject().clone((cloned) => (this._clipboard = cloned));

    this._clipboard &&
      this._clipboard.clone((clonedObj) => {
        canvas.discardActiveObject();
        clonedObj.set({
          left: clonedObj.left + 10,
          top: clonedObj.top + 10,
          evented: true,
        });
        if (clonedObj.type === "activeSelection") {
          // active selection needs a reference to the canvas.
          clonedObj.canvas = canvas;
          clonedObj.forEachObject((obj) => {
            canvas.add(obj);
          });
          // this should solve the unselectability
          clonedObj.setCoords();
        } else {
          canvas.add(clonedObj);
        }
        this._clipboard.top += 10;
        this._clipboard.left += 10;
        canvas.setActiveObject(clonedObj);
        canvas.requestRenderAll();
      });
    return;
    console.log("paste canvas.getActiveObject()", canvas.getActiveObject());
    // return;
    const object = fabric.util.object.clone(canvas.getActiveObject());
    object.set("top", object.top + 5);
    object.set("left", object.left + 5);
    canvas.add(object);
    // clone again, so you can do multiple copies.
    // await this.copy();
    // return;
    // await this._clipboard.clone((clonedObj) => {
    //   let canvas = this._fc;
    //   canvas.discardActiveObject();
    //   clonedObj.set({
    //     left: clonedObj.left + 10,
    //     top: clonedObj.top + 10,
    //     evented: true,
    //   });
    //   if (clonedObj.type === "activeSelection") {
    //     // active selection needs a reference to the canvas.
    //     clonedObj.canvas = canvas;
    //     clonedObj.forEachObject((obj) => canvas.add(obj));
    //     clonedObj.setCoords();
    //   } else {
    //     canvas.add(clonedObj);
    //   }
    //   this._clipboard.top += 10;
    //   this._clipboard.left += 10;
    //   canvas.setActiveObject(clonedObj);
    //   canvas.requestRenderAll();
    // });
  };

  changeCanvasBackgroundContrast = () => {};

  // setBackground: function () {
  //   fabric.Image.fromURL(this.model.get('background'), (function(image){
  //     this.canvas.setWidth(image.width);
  //     this.canvas.setHeight(image.height);
  //     image.filters.push(new fabric.Image.filters.Grayscale(30));
  //     image.filters.push(new fabric.Image.filters.Brightness(90));
  //     image.applyFilters((function(){
  //       this.canvas.backgroundImage = image.getElement();
  //       this.canvas.renderAll();
  //     }).bind(this));
  //   }).bind(this));
  // }

  setBackgroundFromDataUrl = (
    dataUrl,
    options = {},
    // fn = null,
    shouldReRender = false
  ) => {
    if (this.props.pageZoomPartMode === "zoom-show") return;
    let canvas = this._fc;
    let img = new Image();
    const { stretched, stretchedX, stretchedY, ...fabricOptions } = options;
    img.src = dataUrl;
    // console.log("/....../ dataUrl", dataUrl);
    img.crossOrigin = "anonymous";
    img.onload = () => {
      this.props.setImageLoaded(true);
      const imgObj = new fabric.Image(img);
      // imgObj.setSrc(
      //   img.src,
      //   () => {
      //     console.log(">>>>>imgObj.getCrossOrigin()", imgObj.getCrossOrigin());
      //   },
      //   { crossOrigin: "anonymous" }
      // );
      if (
        this.props.settings.pageContrast !== 0 ||
        this.props.settings.pageBrightness !== 0
      ) {
        const filter = new fabric.Image.filters.Brightness({
          brightness: this.props.settings.pageBrightness,
        });
        const filterContrast = new fabric.Image.filters.Contrast({
          contrast: this.props.settings.pageContrast,
        });
        imgObj.filters.push(filter);
        imgObj.filters.push(filterContrast);
        imgObj.applyFilters();
      }
      canvas.setBackgroundImage(
        imgObj,
        () => {
          this._resize(false, shouldReRender);
          // if (fn) fn();
        },
        { crossOrigin: "Anonymous", excludeFromExport: true }
      );
    };
  };
  setThumbnailBGFromDataUrl = (
    dataUrl,
    imgToDownload,
    options = {},
    fn = null,
    shouldReRender = false
  ) => {
    let canvas = this._fc;
    if (dataUrl && this.props.pageZoomPartMode !== "zoom-show") {
      // let img = new Image();
      let img2 = new Image();
      // img.crossOrigin = "anonymous";
      img2.crossOrigin = "anonymous";

      // console.log("LOADINGGGGGG _ PAGE", dataUrl);
      // this.reloadDraw();
      this.setState({ isLoading: false }, () => {
        img2.src = imgToDownload;
        const { stretched, stretchedX, stretchedY, ...fabricOptions } = options;
      });

      img2.onload = () => {
        const imgObj = new fabric.Image(img2, { crossOrigin: "Anonymous" });
        // console.log("brightness", this.state.brightness);
        //TODO Localhost Https to fix the contrast feature
        if (
          this.props.settings.pageContrast !== 0 ||
          this.props.settings.pageBrightness !== 0
        ) {
          const filter = new fabric.Image.filters.Brightness({
            brightness: this.props.settings.pageBrightness,
          });
          const filterContrast = new fabric.Image.filters.Contrast({
            contrast: this.props.settings.pageContrast,
          });
          imgObj.filters.push(filter);
          imgObj.filters.push(filterContrast);
          imgObj.applyFilters();
        }
        this.reloadDraw();

        canvas.setBackgroundImage(
          imgObj,
          () => {
            this.setState({
              isLoading: true,
              backgroundImageScaledWidth: this._fc.backgroundImage.getScaledWidth(),
              backgroundImageScaledHeight: this._fc.backgroundImage.getScaledHeight(),
            });
            this._resize(null, shouldReRender, shouldReRender);
            if (this.props.pageZoomPartMode === "zoom-show") {
              canvas.setBackgroundColor("#fff");
              canvas.backgroundImage.set("opacity", 0);
            }
            // canvas.renderAll();

            canvas.requestRenderAll();
          },
          { excludeFromExport: true }
        );
      };
    } else {
      // pages
      canvas.setBackgroundColor("#fff");
      this.props.currentPage.canvasBackgroundImageWidth &&
        canvas.setWidth(this.props.currentPage.canvasBackgroundImageWidth);
      this.props.currentPage.canvasBackgroundImageHeight &&
        canvas.setHeight(this.props.currentPage.canvasBackgroundImageHeight);
      this.reloadDraw();
      // this._fc.requestRenderAll();
      this._resize();
    }
  };
  addObjectsFromJson = async (last) => {
    console.log("======addObjectsFromJson last", last);
    const canvas = this._fc;
    if (last.backgroundImage) {
      console.log("deleting the bg from last");
      await delete last.backgroundImage;
      await this.saveCanvas();
    }
    // if (last.background) {
    //   console.log("deleting the background from last");
    //   await delete last.background;
    //   await this.saveCanvas();
    // }

    const pageType = isOdd(this.props.currentPageNumber) ? "A" : "B";
    // console.log(`pageType`, pageType);
    last.objects &&
      last.objects.map((obj) => {
        obj.fromJson = true;
        // console.log(`obj.marginType`, obj.marginType);
        if (obj.marginType) {
          obj.visible = this.props.settings.showMargins
            ? obj.marginType === pageType
            : false;
          // console.log(`obj.visible`, obj.visible);
        } else {
          obj.visible = obj.qId
            ? this.props.annotationIdsToShow.find((id) => id !== obj.qId)
              ? true
              : false
            : true;
        }
        return obj;
      });
    console.log("----------last--------", last);
    // await new Promise(r => setTimeout(r, 2000));

    last.objects &&
      fabric.util.enlivenObjects(last.objects, (objects) => {
        canvas.add(...objects);
      });
    // canvas.loadFromJSON(
    //   last,
    //   canvas.renderAll.bind(canvas),
    //   function (o, object) {
    //     // `o` = json object
    //     // `object` = fabric.Object instance
    //     // ... do some stuff ...
    //   }
    // );
    // canvas.loadFromJSON(last, function () {});
    return;
  };
  addObjectsFromJsonForZoomArea = (newObjects) => {
    this.setState(
      {
        dontSave: true,
      },
      () => {
        const canvas = this._fc;
        const currentObjects = canvas.getObjects();
        const annotations = this._fc?.toJSON([
          "isCapture",
          "oldScaleX",
          "oldScaleY",
          "canvasWidth",
          "canvasHeight",
          // "isQuestion",
          "qId", //if its added on question selection annot mode
        ]); //Adding new properties to objects.

        annotations.objects = [...annotations.objects, ...newObjects];
        canvas.loadFromJSON(annotations, () => {
          this.setState({
            dontSave: false,
          });
        });
      }
    );

    return;
  };

  addObjectsFromJsonForZoomParts = async (last) => {
    console.log("addObjectsFromJsonForZoomParts", last);
    const canvas = this._fc;
    // const canvasJson = canvas.toJSON();
    // console.log("canvasJson", canvasJson);
    last.objects &&
      last.objects.map((obj) => {
        obj.fromJson = obj.type === "zoom-image" ? false : true;
        obj.zoomPagePartId = 111;
        obj.visible = obj.qId
          ? this.props.annotationIdsToShow.find((id) => id !== obj.qId)
            ? true
            : false
          : true;
        // canvas.add(obj);
      });
    // canvas.requestRenderAll();
    // console.log("----------last--------", last);
    // canvas.loadFromJSON(
    //   last,
    //   canvas.renderAll.bind(canvas),
    //   function (o, object) {
    //     // `o` = json object
    //     // `object` = fabric.Object instance
    //     // ... do some stuff ...
    //   }
    // );
    // fabric.util.enlivenObjects(last.objects, (objects) => {
    //   canvas.add(...objects);
    // });
    canvas.loadFromJSON(last, function () {});
    // canvas.loadFromJSON(last, this.setBackgroundFromDataUrl(this.props.imagePath));
    return;
  };
  zoomQhideableAdd = (zoom = 1) => {
    // const difference = this.props.scaleZoomFactorVal.canvas.scaleX;
    // const difference = 0.89;

    const qHideables = this.props.currentPage[0].buttons
      .filter((qh) => qh.button_type === "questionHideable")
      .map(({ content, id }) => {
        const jsonContent = JSON.parse(content)[0];
        const difference =
          jsonContent.canvasWidth /
          this.props.scaleZoomFactorVal.canvas.canvasBackgroundImageWidth;
        // console.log(`zoom, difference`, zoom, difference);
        // console.log(`jsonContent`, jsonContent);
        // console.log(
        //   `jsonContent.left, downCoords.x, jsonContent.left-downCoords.x`,
        //   jsonContent.left,
        //   this.props.pageZoomPartObjectToShow.downCoords.x,
        //   jsonContent.left - this.props.pageZoomPartObjectToShow.downCoords.x
        // );
        // console.log(
        //   `jsonContent.top, downCoords.y`,
        //   jsonContent.top,
        //   this.props.pageZoomPartObjectToShow.downCoords.y
        // );

        return {
          ...jsonContent,
          qId: id,
          scaleX: (jsonContent.scaleX * zoom) / difference,
          scaleY: (jsonContent.scaleY * zoom) / difference,
          left:
            ((jsonContent.left -
              this.props.pageZoomPartObjectToShow.downCoords.x * difference) *
              zoom) /
            difference,
          top:
            ((jsonContent.top -
              this.props.pageZoomPartObjectToShow.downCoords.y * difference) *
              zoom) /
            difference,
          visible: false,
        };
      });
    this.addObjectsFromJsonForZoomArea(qHideables);
  };
  reloadDraw = async () => {
    // await new Promise((r) => setTimeout(r, 2000));
    console.log("reloadDraw");

    // return;
    const itemId = `${this.props.ebookId}_item_${this.props.currentPage[0].id}`;
    let last = JSON.parse(localStorage.getItem(itemId));
    console.log(`~~~~~~~~last`, itemId, last);
    const qHideables = this.props.currentPage[0].buttons
      .filter((qh) => qh.button_type === "questionHideable")
      .map(({ content, id }) => {
        return { ...JSON.parse(content)[0], qId: id, zoomX: 2, zoomY: 2 };
      });
    const marginsA = this.props.settings.marginA;
    const marginsB = this.props.settings.marginB;
    // console.log(`marginsA`, marginsA);
    // console.log(`marginsB`, marginsB);
    // console.log(`this.props.settings.marginA`, this.props.settings.marginA);
    // console.log(`this.props.settings.marginB`, this.props.settings.marginB);
    // const newLast=last.objects

    // console.log(
    //   "...last.objects, ...qHideables",
    //   ...last.objects,
    //   ...qHideables
    // );
    // console.log("last.objects, qHideables", last.objects, qHideables);
    if (last) {
      // setTimeout(() => {
      last.objects = [...last.objects, ...qHideables, ...marginsA, ...marginsB];
      // console.log("last.objects", last.objects);
      this.props.pageZoomPartMode !== "zoom-show" &&
        this.addObjectsFromJson(last);
      // }, 500);
    } else {
      const annotations = this._fc?.toJSON([
        "isCapture",
        "oldScaleX",
        "oldScaleY",
        "canvasWidth",
        "canvasHeight",
        "zoomBgImage",
        // "isQuestion",
        "qId", //if its added on question selection annot mode
      ]); //Adding new properties to objects.

      // const itemId = `${this.props.ebookId}_item_${this.props.currentPage[0].id}`;
      last = annotations;
      last.objects = [...qHideables, ...marginsA, ...marginsB];
      this.addObjectsFromJson(last);
    }

    // console.log("~~~~~~~~~~~~~~~~~~~~~~qHideables", last, qHideables);
  };

  addText = (text, options = {}) => {
    let canvas = this._fc;
    let iText = new fabric.IText(text, options);
    let opts = {
      left: (canvas.getWidth() - iText.width) * 0.5,
      top: (canvas.getHeight() - iText.height) * 0.5,
    };
    Object.assign(options, opts);
    iText.set({
      left: options.left,
      top: options.top,
    });

    canvas.add(iText);
  };

  saveCanvas = () => {
    if (
      this.props.annotationSelectionMode ||
      this.props.pageZoomPartMode === "zoomed" ||
      this.props.pageZoomPartMode === "capturezoomed"
    )
      return;
    console.log("SAVING !!", this.props.pageZoomPartMode);
    this.setState({
      canvasChanged: true,
    });
    try {
      if (this.props.image.hasOwnProperty("id")) {
        const annotations = this._fc
          ?.toJSON([
            "isCapture",
            "oldScaleX",
            "oldScaleY",
            "canvasWidth",
            "canvasHeight",
            "zoomBgImage",
            // "isQuestion",
            "qId", //if its added on question selection annot mode
          ]);
          annotations.objects=annotations.objects.filter((ann) => ann.type !== "zoom-image"); //Adding new properties to objects.
        console.log(`annotations`, annotations);
        let itemId = `${this.props.ebookId}_item_${this.props.currentPage[0].id}`;
        if (
          this.props.pageZoomPartObjectToShow.orderId &&
          this.props.pageZoomPartMode === "zoom-show"
        ) {
          itemId = `${this.props.ebookId}_item_${this.props.currentPage[0].id}_part_${this.props.pageZoomPartObjectToShow.orderId}`;
          console.log(`annotations.objects?.length`, annotations.objects?.length)
          console.log(`annotations.objects`, annotations.objects)
          if (annotations.objects?.length > 0) {
            const newObjects = annotations.objects.filter((obj) => {
              console.log("->>->>obj", obj);
              return obj.src !== this.props.imagePath && !obj.qId;
            });
            annotations.objects = newObjects;
            newObjects.length > 0 &&
              localStorage.setItem(itemId, JSON.stringify(annotations));
          } else {
            localStorage.removeItem(itemId);
          }
        } else {
          if (annotations.objects?.length > 0) {
            const newObjects = annotations.objects.filter((obj) => {
              return !obj.qId && !obj.zoomBgImage;
            });
            annotations.objects = newObjects;
            localStorage.setItem(itemId, JSON.stringify(annotations));
          } else {
            localStorage.removeItem(itemId);
          }
        }

        // if (!this.props.currentPage[0].thumbnail) {

        // }
      }
    } catch (e) {
      console.log(e);
    }
  };

  componentDidMount = () => {
    console.log("componentDidMount");
    let { tool, value, undoSteps, defaultValue, backgroundColor } = this.props;
    fabric.Object.NUM_FRACTION_DIGITS = 4;
    fabric.Object.prototype.set({
      transparentCorners: false,
      cornerColor: "rgba(102,153,255,0.5)",
      cornerSize: 12,
      // padding: 5,
    });
    let canvas = (this._fc = new fabric.Canvas(this._canvas, {
      preserveObjectStacking: false,
      width:
        this.props.scaleZoomFactorVal.canvas.canvasWidth ||
        window.innerWidth - 50,
      height:
        this.props.scaleZoomFactorVal.canvas.canvasHeight || window.innerHeight,
    }));

    this._initTools(canvas);

    // New ZooMImage Type
    fabric.ZoomImage = fabric.util.createClass(fabric.Image, {
      type: "zoom-image",
      initialize: function (element, options) {
        this.callSuper("initialize", element, options);
        options && this.set("zoomPart", options.zoomPart);
      },
      toObject: function () {
        return fabric.util.object.extend(this.callSuper("toObject"), {
          zoomPart: this.zoomPart,
        });
      },
    });
    fabric.ZoomImage.fromObject = function (object, callback) {
      fabric.util.loadImage(object.src, function (img) {
        callback && callback(new fabric.ZoomImage(img, object));
      });
    };
    fabric.ZoomImage.async = true;

    // New MarginRect Type
    fabric.MarginRect = fabric.util.createClass(fabric.Rect, {
      type: "margin-rect",
      initialize: function (element, options) {
        this.callSuper("initialize", element, options);
        options && this.set("marginType", options.marginType) && this.set("marginId", options.marginId);
      },
      toObject: function () {
        return fabric.util.object.extend(this.callSuper("toObject"), {
          marginType: this.marginType,
          marginId: this.marginId,
        });
      },
      // fromObject: function () {
      //   return fabric.util.object.extend(this.callSuper("fromObject"), {
      //     marginType: this.marginType,
      //   });
      // },
    });
    fabric.MarginRect.fromObject = function (object, callback) {
      // fabric.util.loadImage(object.src, function (img) {
      //   callback && callback(new fabric.MarginRect(img, object));
      // });
      return new fabric.Rect(object, callback);
    };
    fabric.MarginRect.async = true;

    let selectedTool = this._tools[tool];
    selectedTool && selectedTool.configureCanvas(this.props);

    this._selectedTool = selectedTool;
    this.props.setAnnotationSelectionMode(false);
    // Control resize
    window.addEventListener("resize", this._resize, false);

    // Initialize History, with maximum number of undo steps
    this._history = new History(undoSteps);
    this.timeOutFunctionId = null;

    // Events binding

    canvas.on("mouse:down", this._onMouseDown);
    // canvas.on("after:render", console.log("after render fired!"));
    canvas.on("mouse:dblclick", this._onMouseDblclick);
    canvas.on("mouse:move", this._onMouseMove);
    // canvas.on("mouse:over", this._onMouseOver);
    canvas.on("mouse:out", this._onMouseOut);
    canvas.on("mouse:up", this._onMouseUp);
    canvas.on("object:modified", this._onObjectModified);
    canvas.on("object:scaled", this._onObjectScaled);
    canvas.on("object:removed", this._onObjectRemoved);
    canvas.on("object:added", this._onObjectAdded);
    canvas.on("selection:created", this._onObjectSelected);
    canvas.on("selection:updated", this._onObjectSelected);
    canvas.on("selection:cleared", this._onObjectSelectionCleared);
    canvas.on("path:created", this._onPathCreated);
    fabric.util.addListener(document.body, "keyup", this.onKeyUp);

    // IText Events fired on Adding Text
    // canvas.on("text:event:changed", console.log)
    // canvas.on("text:selection:changed", console.log)
    // canvas.on("text:editing:entered", console.log)
    // canvas.on("text:editing:exited", console.log("exited"));

    this.enableTouchScroll();

    // initialize canvas with controlled value if exists
    (value || defaultValue) && this.fromJSON(value || defaultValue);
    setTimeout(() => {
      canvas.on("-object:added", this._onObjectAdded);
    }, 3000);
  };

  _onObjectSelected = (e) => {
    const obj = e.target;
    const selectedObj = this._fc.getActiveObject();
    console.log("e- object selected", selectedObj.type, selectedObj);

    if (selectedObj.type === "activeSelection") {
      //&& !selectedObj.zoomBgImage) {
    } else {
      // if (selectedObj.type === "i-text") {
      //   console.log('selectedObj.text.fill', selectedObj.text.fill)
      //   console.log('selectedObj.fill', selectedObj.fill)
      //   this.props.setCurrentFillColor(selectedObj.fill);
      //   this.props.setCurrentLineColor(selectedObj.stroke);
      //   this.props.setCurrentLineWidth(selectedObj.strokeWidth);
      //   selectedObj.opacity &&
      //     this.props.setOpacity(selectedObj.opacity);
      // } else {
      this.props.setCurrentFillColor(selectedObj.fill);
      this.props.setCurrentLineColor(selectedObj.stroke);
      this.props.setCurrentLineWidth(selectedObj.strokeWidth);
      selectedObj.opacity && this.props.setOpacity(selectedObj.opacity);
      // }
    }
    if (this.props.selectedTool === "eraseselected") {
      this.removeSelected();
    } else {
      // console.log(`selectedObj.zoomBgImage === undefined`, selectedObj.zoomBgImage === undefined)
      if (selectedObj.type !== "zoom-image") {
        this.props.setEditSelectionMode(selectedObj.type);
      }
    }
  };
  _onObjectSelectionCleared = (e) => {
    // console.log("CLEARED");
    // console.log(e);
    this.props.setEditSelectionMode(false);
  };
  componentWillUnmount = () => {
    let canvas = this._fc;
    window.removeEventListener("resize", this._resize);

    fabric.util.removeListener(document.body, "keyup", this.onKeyUp);
    canvas.off("mouse:down", this._onMouseDown);
    // canvas.off("after:render", console.log("after render fired!"));
    canvas.off("mouse:dblclick", this._onMouseDblclick);
    canvas.off("mouse:move", this._onMouseMove);
    canvas.off("mouse:up", this._onMouseUp);
    canvas.off("mouse:out", this._onMouseOut);
    canvas.off("object:removed", this._onObjectRemoved);
    canvas.off("object:modified", this._onObjectModified);
    canvas.off("object:selected", this.onObjectSelected);

    if (this.timeOutFunctionId) {
      clearTimeout(this.timeOutFunctionId);
    }
    if (!this.props.currentPage[0].thumbnail && this.state.canvasChanged) {
      try {
        const dataURL = this._fc.toDataURL({
          format: "jpeg",
          quality: 0.5,
          multiplier: 0.1,
        });
        // console.log('dataURL', dataURL)
        let itemThumbnailId = `${this.props.ebookId}_thumbnail_${this.props.currentPage[0].id}`;
        localStorage.setItem(itemThumbnailId, dataURL);
      } catch (error) {
        console.log("error", error);
      }
    }

    // if(this.props.captured){
    //   console.log('CAPTURED')
    //   // const deleteObject = (eventData, target) => {
    //   // let canvas = target.target.canvas;
    //   // console.log('eventData', eventData)
    //   // console.log('target', target)
    //   // canvas.backgroundImage.set("opacity", 1);
    //   // canvas.remove(target.target);
    //   // this.props.toggleCapture(this.props.captured);
    //   // this.props.changeSelectedToolFromOutside("select");
    //   // this.props.showInteractiveObjects();
    //   // canvas.requestRenderAll();
    // // };
    // }
  };

  getFilter(type, obj) {
    // var obj = canvas.getActiveObject();
    if (obj) {
      let filter = null;
      obj.filters.forEach(function (f) {
        if (f.type == type) {
          filter = f;
        }
      });
      return filter;
    }
  }
  applyFilter(type, filterIndex, filter, obj) {
    const canvas = this._fc;
    // var obj = canvas.backgroundImage();
    let indexExists = false;
    let filterFound = false;

    if (obj) {
      obj.filters.forEach(function (f, i) {
        if (f.type == type) {
          filterFound = true;
          obj.filters[i] = filter;
        }
        if (filterIndex == i) {
          indexExists = true;
        }
      });

      if (!filterFound && indexExists) {
        obj.filters.splice(filterIndex, 0, filter);
      } else if (!filterFound && !indexExists) {
        obj.filters[filterIndex] = filter;
      }
    }
    obj.applyFilters();
    canvas.renderAll();
  }

  applyFilterValue(type, prop, value, obj) {
    // var obj = canvas.getActiveObject();
    // console.log( type, prop, value, obj.filters.length)
    const canvas = this._fc;
    if (obj) {
      obj.filters.forEach(function (f, i) {
        // console.log(f, i,'f.type', f.type, type, prop, value)
        if (f.type == type) {
          obj.filters[i][prop] = value;
        }
      });
    }
    obj.applyFilters();
    canvas.renderAll();
  }
  changePageBrightness = () => {
    const canvas = this._fc;
    const img = canvas.getObjects().filter((ob) => ob.type === "zoom-image")[0];
    img.crossOrigin = "Anonymous";

    console.log(`img`, img);
    if (!img) {
      return;
    }
    console.log("changePageBrightness", img.filters);
    // canvas.backgroundImage.filters.forEach((ob) => {
    //   console.log("ob", ob);
    //   console.log("ob type", ob.type);
    // });
    // applyFilter(5, this.checked && new f.Brightness({
    //   brightness: parseFloat($('brightness-value').value)
    // }));
    // canvas.backgroundImage.filters = [];
    const filter = new fabric.Image.filters.Brightness({
      brightness: this.props.settings.pageBrightness,
    });
    const filterContrast = new fabric.Image.filters.Contrast({
      contrast: this.props.settings.pageContrast,
    });

    if (img.filters[0]) {
      this.applyFilterValue(
        "Brightness",
        "brightness",
        this.props.settings.pageBrightness,
        img
      );
      this.applyFilterValue(
        "Contrast",
        "contrast",
        this.props.settings.pageContrast,
        img
      );
      // console.log(
      //   "img.filters[0]",
      //   img.filters[0]
      // );
      //  img.filters=filter;
      // img.filters[1] &&
      // delete img.filters[0];

      // img.filters.push(filter);
    } else {
      img.filters.push(filter);
      img.filters.push(filterContrast);
      img.applyFilters();
      canvas.renderAll();
    }

    // img.applyFilters(()=> {canvas.renderAll(); });
    // canvas.backgroundImage.filters.push(filter);
    // imgObj.filters.push(filter);
    // canvas.backgroundImage.applyFilters();
    // canvas.renderAll();
  };

  deselect = () => {
    this._fc.discardActiveObject().renderAll();
  };
  componentDidUpdate = (prevProps, prevState) => {
    // console.log(
    //   "this.props.toolbar.fillColor !== prevProps.toolbar.fillColor",
    //   this.props.toolbar.fillColor !== prevProps.toolbar.fillColor, this.props.toolbar.fillColor, prevProps.toolbar.fillColor

    // );
    if (this.props.settings.showMargins !== prevProps.settings.showMargins) {
      this.toggleMargins();
    }
    if (
      this.props.settings.pageBrightness !==
        prevProps.settings.pageBrightness ||
      this.props.settings.pageContrast !== prevProps.settings.pageContrast
    ) {
      this.changePageBrightness();
    }
    if (
      this.props.pageZoomPartObjectToShow.downCoords.x !==
        prevProps.pageZoomPartObjectToShow.downCoords.x ||
      this.props.pageZoomPartObjectToShow.orderId !==
        prevProps.pageZoomPartObjectToShow.orderId
    ) {
      // console.log("call zoomer!!");
      this.props.pageZoomPartMode === "zoom-show" && this.zoomer();
    }
    if (
      this.props.settings.addImageToCanvas &&
      this.props.settings.addImageToCanvas !==
        prevProps.settings.addImageToCanvas
    ) {
      // console.log(">>>>>>>>> IMAGE ADD ACTIVATED");
      this.addAnImageToCanvas();
      this.props.setAddImageToCanvas(false);
    }
    if (this.props.currentPageNumber !== prevProps.currentPageNumber) {
      if (this.props.pageZoomPartMode !== "zoom-show") {
        console.log("zoom-showwww");
        this._afterPagePartZoomClose();
      } else {
        this.zoomer();
      }
      if (this.props.annotationSelectionMode) {
        this._afterQHideableClose();
      }
    }
    if (this.props.addedActivities !== prevProps.addedActivities) {
      // console.log(">>>>>>>>> ACTIVITY INSERTED ");
      // this.reloadDraw()
      window.location.reload();
    }

    if (
      this.props.annotationSelections.pasteMode &&
      this.props.annotationSelections.pasteMode !==
        prevProps.annotationSelections.pasteMode
    ) {
      // console.log(">>>>>>>>> PASTE ACTIVATED");
      this.paste();
      this.props.setSelectionPasteMode(false);
    }

    if (
      this.props.annotationSelections.copyMode &&
      this.props.annotationSelections.copyMode !==
        prevProps.annotationSelections.copyMode
    ) {
      this.copy();
      this.props.setSelectionCopyMode(false);
    }
    if (
      this.props.annotationSelections.groupMode &&
      this.props.annotationSelections.groupMode !==
        prevProps.annotationSelections.groupMode
    ) {
      this.group();
      this.props.setSelectionGroupMode(false);
    }
    if (
      this.props.annotationSelections.unGroupMode &&
      this.props.annotationSelections.unGroupMode !==
        prevProps.annotationSelections.unGroupMode
    ) {
      this.ungroup();
      this.props.setSelectionUnGroupMode(false);
    }
    if (
      this.props.annotationSelections.removeMode &&
      this.props.annotationSelections.removeMode !==
        prevProps.annotationSelections.removeMode
    ) {
      this.removeSelected();
      this.props.setSelectionRemoveMode(false);
    }
    if (
      this.props.annotationSelections.undoMode !==
      prevProps.annotationSelections.undoMode
    ) {
      // console.log(">>>>>>>>> UNDO ACTIVATED");
      this.props.annotationSelections.undoMode && this.undo();
      // this.props.changeSelectedToolFromOutside("undo");
      this.props.setSelectionUndoMode(false);
      // this.props.annotationSelections.undoMode
    }
    if (
      this.props.annotationSelections.redoMode &&
      this.props.annotationSelections.redoMode !==
        prevProps.annotationSelections.redoMode
    ) {
      this.redo();
      this.props.triggerRedo(false);
    }
    if (
      !this.props.annotationSelectionMode &&
      this.props.annotationSelectionMode !== prevProps.annotationSelectionMode
    ) {
      this._afterQHideableClose();
    }
    if (
      !this.props.pageZoomPartMode &&
      this.props.pageZoomPartMode !== prevProps.pageZoomPartMode
    ) {
      console.log("zoompart");
      this._afterPagePartZoomClose();
    }
    if (this.props.annotationIdsToShow !== prevProps.annotationIdsToShow) {
      this.toggleQHideables();
    }

    if (
      this.props.settings.remove &&
      this.props.settings.remove !== prevProps.settings.remove
    ) {
      this.props.triggerRemove(false);
      this.removeSelected();
    }
    if (
      this.props.settings.removeAll &&
      this.props.settings.removeAll !== prevProps.settings.removeAll
    ) {
      this.removeAllObjects();
      this.props.triggerRemoveAll(false);
    }
    if (
      this.props.settings.redo &&
      this.props.settings.redo !== prevProps.settings.redo
    ) {
      this.redo();
      this.props.triggerRedo(false);
    }
    if (
      this.props.settings.undo &&
      this.props.settings.undo !== prevProps.settings.undo
    ) {
      this.undo();
      this.props.setSelectionUndoMode(false);
    }
    // if (this.props.showAnnotationsVal !== prevProps.showAnnotations) {
    //   console.log(
    //     "~~~this.props.showAnnotations ",
    //     this.props.showAnnotationsVal
    //   );
    //   this.onChangeDrawingObjects(this.props.showAnnotationsVal);
    // }
    if (
      // If fill color changed and something selected then change the selected object's  fill color.
      this.props.toolbar.fillColor !== prevProps.toolbar.fillColor
      // &&
      // this._selectedTool === this._tools[Tool.Select]
    ) {
      this.changeSelected("fillColor");
    }
    if (
      // If stroke color changed and something selected then change the selected object's  stroke color.
      this.props.toolbar.lineColor !== prevProps.toolbar.lineColor &&
      this._selectedTool === this._tools[Tool.Select]
    ) {
      this.changeSelected("lineColor");
    }
    if (
      // If stroke width changed and something selected then change the selected object's  stroke width.
      this.props.toolbar.lineWidth !== prevProps.toolbar.lineWidth &&
      this._selectedTool === this._tools[Tool.Select]
    ) {
      this.changeSelected("lineWidth");
    }
    if (
      // If stroke width changed and something selected then change the selected object's  stroke width.
      this.props.toolbar.currentOpacity !== prevProps.toolbar.currentOpacity &&
      this._selectedTool === this._tools[Tool.Select]
    ) {
      this.changeSelected("opacity");
    }

    if (
      this.props.sideBarOpened !== prevProps.sideBarOpened &&
      prevState.deneme > 1
    ) {
      this._resize();
    } else {
      this.setState({
        deneme: prevState.deneme + 1,
      });
    }
    if (this.state.scl !== prevState.scl) {
      this._resize();
    }
    if (this.props.tool !== prevProps.tool) {
      this._selectedTool =
        this._tools[this.props.tool] || this._tools[Tool.Select];
      this.props.tool === Tool.Pan && this.deselect();
    }

    //Bring the cursor back to default if it is changed by a tool
    this._fc.defaultCursor = "grab";
    this._selectedTool.configureCanvas(this.props);

    if (this.props.backgroundColor !== prevProps.backgroundColor) {
      this._backgroundColor(this.props.backgroundColor);
    }

    if (
      this.props.value !== prevProps.value ||
      (this.props.value && this.props.forceValue)
    ) {
      this.fromJSON(this.props.value);
    }
  };

  render = () => {
    return (
      <div
        className={`canvas-container-new ${
          this._tools && this._selectedTool === this._tools[Tool.Pan]
            ? "canvas-no-pointer-events"
            : ""
        }`}
      >
        {/* <video autoplay controls style={{
          width:'250px',
          height:'250px',
        }}></video> */}
        <canvas
          className={`draw-canvas canvas-container-new-canvas `}
          ref={(c) => (this._canvas = c)}
          id="mycanvas"
          style={{
            backgroundImage:
              !this.state.isLoading &&
              this.props.pageZoomPartMode !== "zoom-show"
                ? "url(" + this.props.image.thumbnail + ")"
                : "",
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            border:
              this.props.pageZoomPartMode === "zoom-show"
                ? "1px solid var(--page-content-bg)"
                : "none",
            borderRadius:
              this.props.pageZoomPartMode === "zoom-show" ? "10px" : "unset",
            // sepia(${sepia}) grayscale(${grayscale}) hue-rotate(${hue}) saturate(${saturate}) invert(${invert}) contrast(${contrast})
            // filter: !this.state.isLoading &&
            //   this.props.pageZoomPartMode !== "zoom-show"
            //     ?  `contrast(${1- this.props.settings.pageContrast}) brightness(${1+this.props.settings.pageBrightness})`
            //     : "",
          }}
        >
          Sorry, Canvas HTML5 element is not supported by your browser :(
        </canvas>
      </div>
    );
  };
}

// export default SketchField;
const mapStateToProps = (state) => {
  return {
    currentPageNumber: state.pages.currentPageNo,
    scaleZoomFactorVal: state.scaleZoomFactor,
    isButtonsShown: state.isButtonsShown,
    hideInteractiveObjectsVal: state.hideInteractiveObjects,
    tool: state.selectedTool.selectedTool,
    captured: state.captured,
    modalOpen: state.addButton.modalOpen,
    quizAddModalOpen: state.quiz.quizAddModalOpen,
    ebookId: state.pages.ebookId,
    canvasScaleX: state.scaleZoomFactor.canvas.scaleX,
    canvasScaleY: state.scaleZoomFactor.canvas.scaleY,
    sideBarOpened: state.sideBarOpened,
    annotationSelections: state.annotationSelections,
    annotationSelectionMode: state.annotationSelections.annotationSelectionMode,
    currentSelection: state.annotationSelections.currentSelection,
    showAnnotationsVal: state.settings.showAnnotations,
    settings: state.settings,
    toolbar: state.toolbar,
    currentPage: state.pages.currentPage,
    addedActivities: state.pages.addedActivities,
    // annotationIdToShow: state.annotationSelections.annotationIdToShow,
    annotationIdsToShow: state.annotationSelections.annotationIdsToShow,
    pageZoomPartMode: state.settings.pageZoomPartMode,
    pageZoomPartObjectToShow: state.settings.pageZoomPartObjectToShow,
    pages: state.pages,
    selectedTool: state.selectedTool.selectedTool,
  };
};

export default connect(
  mapStateToProps,
  {
    setScalePageDown,
    setScalePageUp,
    pageNoChange,
    toggleButtons,
    showInteractiveObjects,
    hideInteractiveObjects,
    changeSelectedToolFromOutside,
    toggleCapture,
    addActivity,
    addButtonModalOpen,
    setScalePageScl,
    addMcQuestionModalOpen,
    setAnnotationSelectionMode,
    setAnnotationSelectionCoords,
    saveAnnotationSelectionToButton,
    toaster,
    triggerRedo,
    triggerUndo,
    triggerRemove,
    triggerRemoveAll,
    toggleSideBar,
    setToolbarVertical,
    setToolbarPosition,
    resetToolbarPosition,
    setImageLoaded,
    setEditSelectionMode,
    setAnnotationBeforeState,
    setScalePageSet,
    showAnnotations,
    hideAnnotations,
    showArea,
    hideArea,
    setSelectionUndoMode,
    setSelectionPasteMode,
    setSelectionRedoMode,
    setPagePartZoomMode,
    setPagePartZoomObject,
    sideBarClose,
    setAddImageToCanvas,
    setPagePartZoomCut,
    setSelectionCopyMode,
    setSelectionRemoveMode,
    setSelectionGroupMode,
    setSelectionUnGroupMode,
    setOpacity,
    setCurrentLineWidth,
    setCurrentLineColor,
    setCurrentFillColor,
    addMarginA,
    addMarginB,
    removeMarginA,
    removeMarginB,
    setShowMargins,
    setZoomPartFullScaleVal,
    setPageZoomPartShowLoading,
  },
  null,
  { forwardRef: true }
)(SketchField);
function exitTextEdit(canvas) {
  if (canvas._iTextInstances) {
    canvas._iTextInstances.forEach(function (ob) {
      ob.exitEditing();
    });
  }
}
