import {GET_MERRIAM_MODAL,CLOSE_MERRIAM_MODAL} from '../actions/actionTypes';

export default (state = false, action) => {
    switch (action.type) {

        case GET_MERRIAM_MODAL:
            return true;

        case CLOSE_MERRIAM_MODAL:
            return false;

        default:
            return state;
    }
};