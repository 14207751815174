import React from "react";
import { useTranslation } from "react-i18next";

export default function LinkButton(props) {
  const { t, i18n } = useTranslation();
  const { object } = props;
  const transform = "scale(" + object.scale + ")";
  return (
    <div
      className="interactive-object"
      style={{ transform, transformOrigin: "left top" }}
    >
      <span className="object-icon">
        <svg
          width={object.width}
          height={object.height}
          viewBox={`0 0 ${object.width} ${object.height}`}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx={object.width / 2}
            cy={object.height / 2}
            r={object.width / 2}
            fill="white"
            stroke="#FD6D87"
            strokeWidth="0" // Was 2 = Mustafa changed to 0
          />
          <path
            d="M13.2788 6.75702C13.0722 6.75702 12.8741 6.83907 12.7281 6.98513C12.582 7.13119 12.5 7.32928 12.5 7.53584C12.5 7.74239 12.582 7.94049 12.7281 8.08655C12.8741 8.2326 13.0722 8.31466 13.2788 8.31466H15.2928L10.3917 13.2158C10.3173 13.2876 10.258 13.3735 10.2172 13.4686C10.1763 13.5636 10.1549 13.6658 10.154 13.7692C10.1531 13.8726 10.1728 13.9752 10.2119 14.0709C10.2511 14.1666 10.3089 14.2535 10.382 14.3267C10.4552 14.3998 10.5421 14.4576 10.6378 14.4968C10.7336 14.5359 10.8361 14.5556 10.9395 14.5547C11.0429 14.5538 11.1451 14.5324 11.2401 14.4915C11.3352 14.4507 11.4211 14.3914 11.4929 14.317L16.394 9.4159V11.4299C16.394 11.6365 16.4761 11.8346 16.6222 11.9806C16.7682 12.1267 16.9663 12.2087 17.1729 12.2087C17.3794 12.2087 17.5775 12.1267 17.7236 11.9806C17.8696 11.8346 17.9517 11.6365 17.9517 11.4299V7.53584C17.9517 7.32928 17.8696 7.13119 17.7236 6.98513C17.5775 6.83907 17.3794 6.75702 17.1729 6.75702H13.2788Z"
            fill="#C4C4C4"
          />
          <path
            d="M8.60587 8.31467C8.19276 8.31467 7.79657 8.47877 7.50445 8.77089C7.21234 9.063 7.04823 9.45919 7.04823 9.8723V16.1029C7.04823 16.516 7.21234 16.9122 7.50445 17.2043C7.79657 17.4964 8.19276 17.6605 8.60587 17.6605H14.8364C15.2495 17.6605 15.6457 17.4964 15.9378 17.2043C16.2299 16.9122 16.3941 16.516 16.3941 16.1029V13.7664C16.3941 13.5598 16.312 13.3617 16.1659 13.2157C16.0199 13.0696 15.8218 12.9876 15.6152 12.9876C15.4087 12.9876 15.2106 13.0696 15.0645 13.2157C14.9185 13.3617 14.8364 13.5598 14.8364 13.7664V16.1029H8.60587V9.8723H10.9423C11.1489 9.8723 11.347 9.79025 11.493 9.64419C11.6391 9.49814 11.7211 9.30004 11.7211 9.09349C11.7211 8.88693 11.6391 8.68883 11.493 8.54278C11.347 8.39672 11.1489 8.31467 10.9423 8.31467H8.60587Z"
            fill="#C4C4C4"
          />
        </svg>
      </span>
      <span className="object-text">
        {!object.description ||
        object.description === undefined ||
        object.description === "undefined"
          ? t("Link")
          : object.description}
      </span>
    </div>
  );
}
