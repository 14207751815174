import {
  SET_LOGIN_SUCCESS,
  SET_DOMAIN,
  SET_LIBRARY_FROM_LOCAL,
  SET_LIBRARY_OFFLINE,
} from "../actions/actionTypes";

export default (
  state = {
    loggedIn: false,
    library: "",
    domain: "",
    user_profile: "",
    // url: 1,
  },
  action
) => {
  switch (action.type) {
    case SET_LOGIN_SUCCESS:
      // console.log("action.type", action.type); //TODO remove
      // console.log("action.payload", action.payload); //TODO remove
      return {
        ...state,
        loggedIn: true,
        library: action.payload.library,
        user_profile: action.payload.details,
      };
    case SET_LIBRARY_OFFLINE:
      // console.log("action.type", action.type); //TODO remove
      // console.log("action.payload", action.payload); //TODO remove
      return {
        ...state,
        library: action.payload,
      };
    case SET_DOMAIN:
      // console.log("action.type", action.type); //TODO remove
      // console.log("action.payload", action.payload); //TODO remove
      return {
        ...state,
        domain: action.payload,
        // url: action.payload.url,//TODO
      };
    case SET_LIBRARY_FROM_LOCAL:
      console.log(`localStorage.getItem("auth_token")`, localStorage.getItem("auth_token"))
      if (!localStorage.getItem("auth_token")) {
        window.location.hash = `#/login`;
        return {
          ...state,
        };
      }
      // console.log("localStorage.library", localStorage.library);
      return {
        ...state,
        loggedIn: true,
        // library: JSON.parse(localStorage.library),
        // user_profile: JSON.parse(localStorage.user_profile),
        // url: localStorage.url,
        domain: localStorage.domain,
        theme: localStorage.theme || "dark",
      };

    default:
      return state;
  }
};
