import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import { persistStore, persistReducer } from "redux-persist"; //TODO Add Persist

import thunk from "redux-thunk";
import sidebarReducer from "../reducers/sidebarReducer";
import themeReducer from "../reducers/themeReducer";
import pagesReducer from "../reducers/pagesReducer";
import pageLoadedReducer from "../reducers/pageLoadedReducer";
import zipDownProgReducer from "../reducers/zipDownProgReducer";
import toolbarReducer from "../reducers/toolbarReducer";
import interactiveObjectReducer from "../reducers/interactiveObjectReducer";
import scalePageReducer from "../reducers/scalePageReducer";
import buttonsReducer from "../reducers/buttonsReducer";
import imageLoadReducer from "../reducers/imageLoadReducer";
import selectedToolReducer from "../reducers/selectedToolReducer";
import pixabayReducer from "../reducers/pixabayReducer";
import unsplashReducer from "../reducers/unsplashReducer";
import pixabayVideoReducer from "../reducers/pixabayVideoReducer";
import searchBarReducer from "../reducers/searchBarReducer";
import wikizeroReducer from "../reducers/wikizeroReducer";
import wikipediaReducer from "../reducers/wikipediaReducer";
import wiktionaryReducer from "../reducers/wiktionaryReducer";
import qwantReducer from "../reducers/qwantReducer";
import indexReducer from "../reducers/indexReducer";
import merriamReducer from "../reducers/merriamReducer";
import merriamModalReducer from "../reducers/merriamModalReducer";
import imageCaptureReducer from "../reducers/imageCaptureReducer";
import loginReducer from "../reducers/loginReducer";
import captureReducer from "../reducers/captureReducer";
import addButtonReducer from "../reducers/addButtonReducer";
import activitiesReducer from "../reducers/activitiesReducer";
import quizReducer from "../reducers/quizReducer";
import settingsReducer from "../reducers/settingsReducer";
import annotationSelectionReducer from "../reducers/annotationSelectionReducer";
import { isMobile } from "react-device-detect";

export default () => {
  const userAgent = navigator.userAgent.toLowerCase();

  const store = createStore(
    combineReducers({
      sideBarOpened: sidebarReducer,
      theme: themeReducer,
      pages: pagesReducer,
      pageLoaded: pageLoadedReducer,
      zipDownloadProg: zipDownProgReducer,
      toolbar: toolbarReducer,
      hideInteractiveObjects: interactiveObjectReducer,
      scaleZoomFactor: scalePageReducer,
      isButtonsShown: buttonsReducer,
      imagesLoaded: imageLoadReducer,
      selectedTool: selectedToolReducer,
      pixabay: pixabayReducer,
      unsplash: unsplashReducer,
      pixabayVideo: pixabayVideoReducer,
      searchBarOpen: searchBarReducer,
      wikizero: wikizeroReducer,
      wikipedia: wikipediaReducer,
      wiktionary: wiktionaryReducer,
      merriamModal: merriamModalReducer,
      qwant: qwantReducer,
      indexOpened: indexReducer,
      merriam: merriamReducer,
      imageCapture: imageCaptureReducer,
      captured: captureReducer,
      login: loginReducer,
      addButton: addButtonReducer,
      activities: activitiesReducer,
      quiz: quizReducer,
      settings: settingsReducer,
      annotationSelections: annotationSelectionReducer,
    }),

    compose(
      applyMiddleware(thunk),
      process.env.NODE_ENV === "development" &&
        window.navigator.userAgent.includes("Chrome") &&
        userAgent.indexOf(" electron/") === -1 &&
        !isMobile
        ? window.__REDUX_DEVTOOLS_EXTENSION__ &&
            window.__REDUX_DEVTOOLS_EXTENSION__({ trace: true, traceLimit: 25 })
        : compose
      // ,window.__REDUX_DEVTOOLS_EXTENSION__ &&
      // window.__REDUX_DEVTOOLS_EXTENSION__({ trace: true, traceLimit: 25 })
    )
  );

  return store;
};
