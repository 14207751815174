import React, { useState } from "react";
import { useTranslation } from "react-i18next";

export default function ButtonStylesBulma(props) {
  const { t, i18n } = useTranslation();
  const { object } = props;
  const [oValue, setOValue] = useState(props.defaultStyle);
  const [scaleValue, setScaleValue] = useState("");
  const [animatedValue, setAnimatedValue] = useState("");

  // const handleChange = (e) => {
  //   setOValue(e);
  //   console.log("e.value", e);
  //   props.selectButtonStyle(e);
  // };
  // const handleScale = (e) => {
  //   setScaleValue(e);
  // };
  // const handleAnimated = (e) => {
  //   if (animatedValue === "") {
  //     setAnimatedValue("no-animation");
  //   } else {
  //     setAnimatedValue("");
  //   }
  // };
  const handleChange = (e) => {
    setOValue(e);
    // console.log("e.value", e, e + " " + scaleValue + " " + animatedValue);
    props.selectButtonStyle(e + " " + scaleValue + " " + animatedValue);
  };
  const handleScale = (e) => {
    setScaleValue(e);
    props.selectButtonStyle(oValue + " " + e + " " + animatedValue);
  };
  const handleAnimated = (e) => {
    if (e === "") {
      setAnimatedValue("no-animation");
      props.selectButtonStyle(oValue + " no-animation " + scaleValue );
    } else {
      setAnimatedValue("");
      props.selectButtonStyle(oValue + " " + scaleValue);
    }
  };
  return (
    <>
      <div className="field is-grouped is-grouped-multiline  is-grouped-left mt-3 mb-1">
        <div className="field-label is-normal q-input">
          <label className="label">{t("Style")}</label>
        </div>

        <div className="radio mx-3 mt-1">
          <label>
            <input
              type="radio"
              onChange={() => {
                handleChange("style1");
              }}
              name="style"
              checked={oValue === "style1"}
              //   ng-click="changeStyle($event)"
              //   ng-model="obj.style"
              value={`style1 ${scaleValue} ${animatedValue}`}
            />{" "}
            &nbsp;
            <div
              className={`interactive-object-area video-object style1 ${scaleValue} ${animatedValue}`}
              style={{ position: "inherit", marginLeft: 3, float: "right" }}
            >
              <div className="interactive-object">
                <span className="object-icon">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="12"
                      cy="12"
                      r="11"
                      fill="white"
                      stroke="#FD6D87"
                      strokeWidth="2"
                    ></circle>
                    <path
                      d="M9 16.8889V6.97899C9 6.15885 9.93293 5.68743 10.5932 6.17394L16.9638 10.8681C17.4892 11.2552 17.509 12.0335 17.0041 12.4469L10.6335 17.6626C9.98061 18.1972 9 17.7327 9 16.8889Z"
                      fill="#FD6D87"
                    ></path>
                  </svg>
                  &nbsp;
                </span>
                <span className="object-text">{t(props.activityType)}</span>
              </div>
            </div>
          </label>
        </div>

        <div className="radio mx-3 mt-1">
          <label>
            <input
              type="radio"
              onChange={() => {
                handleChange("style2");
              }}
              name="style"
              checked={oValue === "style2"}
              //   ng-click="changeStyle($event)"
              //   ng-model="obj.style"
              value={`style2 ${scaleValue} ${animatedValue}`}
            />{" "}
            &nbsp;
            <div
              className={`interactive-object-area video-object style2 ${scaleValue} ${animatedValue}`}
              style={{ position: "inherit", marginLeft: 3, float: "right" }}
            >
              <div className="interactive-object">
                <span className="object-icon">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="12"
                      cy="12"
                      r="11"
                      fill="white"
                      stroke="#FD6D87"
                      strokeWidth="2"
                    ></circle>
                    <path
                      d="M9 16.8889V6.97899C9 6.15885 9.93293 5.68743 10.5932 6.17394L16.9638 10.8681C17.4892 11.2552 17.509 12.0335 17.0041 12.4469L10.6335 17.6626C9.98061 18.1972 9 17.7327 9 16.8889Z"
                      fill="#FD6D87"
                    ></path>
                  </svg>
                  &nbsp;
                </span>
                <span className="object-text">{t(props.activityType)}</span>
              </div>
            </div>
          </label>
        </div>

        <div className="radio mx-3 mt-1">
          <label>
            <input
              type="radio"
              onChange={() => {
                handleChange("style3");
              }}
              name="style"
              checked={oValue === "style3"}
              //   ng-click="changeStyle($event)"
              //   ng-model="obj.style"
              value={`style3 ${scaleValue} ${animatedValue}`}
            />{" "}
            &nbsp;
            <div
              className={`interactive-object-area video-object style3 ${scaleValue} ${animatedValue}`}
              style={{ position: "inherit", marginLeft: 3, float: "right" }}
            >
              <div className="interactive-object">
                <span className="object-icon">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="12"
                      cy="12"
                      r="11"
                      fill="white"
                      stroke="#FD6D87"
                      strokeWidth="2"
                    ></circle>
                    <path
                      d="M9 16.8889V6.97899C9 6.15885 9.93293 5.68743 10.5932 6.17394L16.9638 10.8681C17.4892 11.2552 17.509 12.0335 17.0041 12.4469L10.6335 17.6626C9.98061 18.1972 9 17.7327 9 16.8889Z"
                      fill="#FD6D87"
                    ></path>
                  </svg>
                  &nbsp;
                </span>
                <span className="object-text">{t(props.activityType)}</span>
              </div>
            </div>
          </label>
        </div>

        <div className="radio mx-3 mt-1">
          <label>
            <input
              type="radio"
              onChange={() => {
                handleChange("style4");
              }}
              name="style"
              checked={oValue === "style4"}
              //   ng-click="changeStyle($event)"
              //   ng-model="obj.style"
              value={`style4 ${scaleValue} ${animatedValue}`}
            />{" "}
            &nbsp;
            <div
              className={`interactive-object-area video-object style4 ${scaleValue} ${animatedValue}`}
              style={{ position: "inherit", marginLeft: 3, float: "right" }}
            >
              <div className="interactive-object">
                <span className="object-icon">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="12"
                      cy="12"
                      r="11"
                      fill="white"
                      stroke="#FD6D87"
                      strokeWidth="2"
                    ></circle>
                    <path
                      d="M9 16.8889V6.97899C9 6.15885 9.93293 5.68743 10.5932 6.17394L16.9638 10.8681C17.4892 11.2552 17.509 12.0335 17.0041 12.4469L10.6335 17.6626C9.98061 18.1972 9 17.7327 9 16.8889Z"
                      fill="#FD6D87"
                    ></path>
                  </svg>
                  &nbsp;
                </span>
                <span className="object-text">{t(props.activityType)}</span>
              </div>
            </div>
          </label>
        </div>
      </div>
      <div className="row w-100">
        <div className="col-md-6">
          <div className="field is-grouped is-grouped-multiline  is-grouped-left mt-3 mb-1">
            {/* <div className="field-label is-normal q-input">
              <label className="label">{t("Scale")}</label>
            </div>{" "} */}
            <div className="radio mx-3 mt-2">
              <label>
                <input
                  type="radio"
                  onChange={() => {
                    handleScale("");
                  }}
                  name="normal"
                  checked={scaleValue === ""}
                  value=""
                />
                {t("Normal")}
              </label>
            </div>
            <div className="radio mx-3  mt-2">
              <label>
                <input
                  type="radio"
                  onChange={() => {
                    handleScale("midi");
                  }}
                  name="midi"
                  checked={scaleValue === "midi"}
                  value="midi"
                />
                {t("Midi")}
              </label>
            </div>
            <div className="radio mx-3  mt-2">
              <label>
                <input
                  type="radio"
                  onChange={() => {
                    handleScale("mini");
                  }}
                  name="mini"
                  checked={scaleValue === "mini"}
                  value="mini"
                />
                {t("Mini")}
              </label>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="field is-grouped is-grouped-multiline  is-grouped-left mt-3 mb-1">
            {/* <div className="field-label is-normal q-input">
              <label className="label">{t("Animated")}</label>
            </div>{" "} */}
            <div className="radio mx-3 mt-2">
              <label>
                <input
                  type="checkbox"
                  onChange={() => {
                    handleAnimated(animatedValue);
                  }}
                  name="normal"
                  checked={animatedValue === ""}
                  value=""
                />
                {t("Animated")}
              </label>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
