import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bookmarkedPages } from "../../services/util";
import { pageNoChangeFromIndex } from "../../actions/pages";
import { withTranslation } from "react-i18next";

class Bookmarks extends Component {
  render() {
    return (
      <div className="column modal-right">
        <div className="modal-title">
          <h2>
            <span className="modal-title-icon">
              <img
                src={require("../../assets/img/svg/tabs-icon-bookmark.svg")}
                alt={this.props.t("My Bookmarks")}

              />
            </span>
            <span className="modal-title-text">
              {this.props.t("My Bookmarks")}
            </span>
          </h2>
        </div>
        <table className="table">
          {/* <thead>
            <tr>
              <td>Page</td>
            </tr>
          </thead> */}
          <tbody>
            {bookmarkedPages().map((obj, id) => {
              return (
                <tr key={id}>
                  <td key={id}>
                    <Link to={obj.toString()}>
                      <span
                        onClick={() => {
                          this.props.pageNoChangeFromIndex(obj);
                        }}
                      >
                        <span className="activity-span">
                          {" "}
                          {this.props.t("Page")} {obj}
                        </span>
                      </span>
                    </Link>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, { pageNoChangeFromIndex })(
  withTranslation()(Bookmarks)
);
