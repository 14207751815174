import { INDEX_OPEN, INDEX_CLOSE } from "./actionTypes";

export const toggleIndex = isIndexOpened => {
  return dispatch => {
    if (isIndexOpened) {
      dispatch(indexClose());
    } else {
      dispatch(indexOpen());
    }
  };
};

export const indexOpen = () => {
  return {
    type: INDEX_OPEN
  };
};

export const indexClose = () => {
  return {
    type: INDEX_CLOSE
  };
};
