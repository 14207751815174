import {
  CHANGE_TOOLBAR_VIEW,
  SET_TOOLBAR_POSITION,
  RESET_TOOLBAR_POSITION,
  PIN_TOOLBAR,
  UNPIN_TOOLBAR,
  SET_LINE_WIDTH,
  SET_FILL_COLOR,
  SET_LINE_COLOR,
  SET_TOOLBAR_VERTICAL,
  SET_TOOLBAR_SIZE,
  SHOW_TOOLBAR_LABELS,
  OPEN_ADVANCED_MODE,
  SET_OPACITY,
  SET_CURRENT_FILL_COLOR,
  SET_CURRENT_LINE_COLOR,
  SET_CURRENT_LINE_WIDTH,
} from "./actionTypes";

export const changeToolbarView = () => {
  return {
    type: CHANGE_TOOLBAR_VIEW,
  };
};

//Toolbar X,Y Positions
export const setToolbarPosition = (position) => {
  // localStorage.toolbarSize = val;
  return {
    type: SET_TOOLBAR_POSITION,
    payload: { x: position.x, y: position.y },
  };
};
export const setToolbarVertical = (status) => {
  localStorage.verticalToolbar = status;
  return {
    type: SET_TOOLBAR_VERTICAL,
    payload: status,
  };
};
export const resetToolbarPosition = () => {
  // localStorage.toolbarSize = JSON.stringify({ x: 0, y: 0 });
  return {
    type: RESET_TOOLBAR_POSITION,
  };
};
export const pinToolbar = () => {
  localStorage.toolbarPin = true;
  return {
    type: PIN_TOOLBAR,
  };
};
export const unpinToolbar = () => {
  localStorage.toolbarPin = false;
  return {
    type: UNPIN_TOOLBAR,
  };
};
export const setOpacity = (val) => {
  // localStorage.opacity = val;
  return {
    type: SET_OPACITY,
    payload: val,
  };
};
export const setLineWidth = (val) => {
  localStorage.lineWidth = val;
  return {
    type: SET_LINE_WIDTH,
    payload: val,
  };
};
export const setLineColor = (val) => {
  localStorage.lineColor = val;
  return {
    type: SET_LINE_COLOR,
    payload: val,
  };
};

export const setFillColor = (val) => {
  localStorage.fillColor = val;
  return {
    type: SET_FILL_COLOR,
    payload: val,
  };
};

export const setCurrentLineWidth = (val) => {
  return {
    type: SET_CURRENT_LINE_WIDTH,
    payload: val,
  };
};
export const setCurrentLineColor = (val) => {
  return {
    type: SET_CURRENT_LINE_COLOR,
    payload: val,
  };
};

export const setCurrentFillColor = (val) => {
  return {
    type: SET_CURRENT_FILL_COLOR,
    payload: val,
  };
};
export const setToolbarSize = (val) => {
  localStorage.toolbarSize = val;
  return {
    type: SET_TOOLBAR_SIZE,
    payload: val,
  };
};
export const showToolbarLabels = (val) => {
  localStorage.showLabels = val;
  return {
    type: SHOW_TOOLBAR_LABELS,
    payload: val,
  };
};
export const openAdvancedMode = (val) => {
  localStorage.advancedMode = val;
  return {
    type: OPEN_ADVANCED_MODE,
    payload: val,
  };
};
