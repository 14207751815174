import React from "react";
import { useTranslation } from "react-i18next";

export default function StrokeSize(props) {
  const { t } = useTranslation();
  return (
    <li className={`sub-menu ${props.activeButton==='linewidth' ? "open" : ""}`}>
      <span
        className={`button normal-btn linewidth ${
          props.shouldShowButtonLabels ? "show-label-span" : ""
        }  ${
          props.activeButton === "linewidth" ? "active" : ""
        }`}
        title={t("Stroke Size")}
        data-tip={t("Stroke Size")}
        onClick={props.onClick}
      >
        <img
          src={require("../../assets/img/svg/tools-line-size.svg")}
          alt={t("Stroke Size")}
        />
        {props.shouldShowButtonLabels && <span className="show-label-text">{t("Size")}</span>}
      </span>
      <div className="line-menu">
        <div className="buttons has-addons">
          <ul>
            <li onClick={() => props.onClickSubItem(6)}>
              <span
                className={`button normal-btn line-size-6 ${
                  props.lineWidth === 18
                    ? "active"
                    : ""
                }`}
                title={t("Stroke Size")+" 6"}
                data-tip={t("Stroke Size" +" 6")}
              >
                <img
                  src={require("../../assets/img/svg/line-size-6.svg")}
                  alt={t("Stroke Size")+" 6"}
                />
              </span>
            </li>
            <li onClick={() => props.onClickSubItem(5)}>
              <span
                className={`button normal-btn line-size-5 ${
                  props.lineWidth === 15
                    ? "active"
                    : ""
                }`}
                title={t("Stroke Size")+" 5"}
                data-tip={t("Stroke Size" +" 5")}
              >
                <img
                  src={require("../../assets/img/svg/line-size-5.svg")}
                  alt={t("Stroke Size")+" 5"}
                />
              </span>
            </li>
            <li onClick={() => props.onClickSubItem(4)}>
              <span
                className={`button normal-btn line-size-4 ${
                  props.lineWidth === 12
                    ? "active"
                    : ""
                }`}
                title={t("Stroke Size")+" 4"}
                data-tip={t("Stroke Size" +" 4")}
              >
                <img
                  src={require("../../assets/img/svg/line-size-4.svg")}
                  alt={t("Stroke Size")+" 4"}
                />
              </span>
            </li>
            <li onClick={() => props.onClickSubItem(3)}>
              <span
                className={`button normal-btn line-size-3 ${
                  props.lineWidth === 9
                    ? "active"
                    : ""
                }`}
                title={t("Stroke Size")+" 3"}
                data-tip={t("Stroke Size" +" 3")}
              >
                <img
                  src={require("../../assets/img/svg/line-size-3.svg")}
                  alt={t("Stroke Size")+" 3"}
                />
              </span>
            </li>
            <li onClick={() => props.onClickSubItem(2)}>
              <span
                className={`button normal-btn line-size-2 ${
                  props.lineWidth === 6
                    ? "active"
                    : ""
                }`}
                title={t("Stroke Size")+" 2"}
                data-tip={t("Stroke Size" +" 2")}
              >
                <img
                  src={require("../../assets/img/svg/line-size-2.svg")}
                  alt={t("Stroke Size")+" 2"}
                />
              </span>
            </li>
            <li onClick={() => props.onClickSubItem(1)}>
              <span
                className={`button normal-btn line-size-1 ${
                  props.lineWidth === 3
                    ? "active"
                    : ""
                }`}
                title={t("Stroke Size")+" 1"}
                data-tip={t("Stroke Size" +" 1")}
              >
                <img
                  src={require("../../assets/img/svg/line-size-1.svg")}
                  alt={t("Stroke Size")+" 1"}
                />
              </span>
            </li>
          </ul>
        </div>
      </div>
    </li>
  );
}
