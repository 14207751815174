import React from "react";
import { useTranslation } from "react-i18next";

export default function TextButton(props) {
  const { t, i18n } = useTranslation();
  const { object } = props;
  const transform = "scale(" + object.scale + ")";
  return (

      <div
        className="interactive-object"
        //   style={{ height: object.height }}
        style={{ transform, "transformOrigin": "left top" }}
      >
        <span className="object-icon">
          <svg
            width={object.width}
            height={object.height}
            viewBox={`0 0 ${object.width} ${object.height}`}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx={object.width / 2}
              cy={object.height / 2}
              r={object.width / 2}
              fill="white"
              stroke="#FD6D87"
              strokeWidth="0" // Was 2 = Mustafa changed to 0
            />
            <path
              d="M17.3333 11.2H6.66667C6.3 11.2 6 11.56 6 12C6 12.44 6.3 12.8 6.66667 12.8H17.3333C17.7 12.8 18 12.44 18 12C18 11.56 17.7 11.2 17.3333 11.2ZM6.66667 16H13.3333C13.7 16 14 15.64 14 15.2C14 14.76 13.7 14.4 13.3333 14.4H6.66667C6.3 14.4 6 14.76 6 15.2C6 15.64 6.3 16 6.66667 16ZM17.3333 8H6.66667C6.3 8 6 8.36 6 8.8V8.808C6 9.248 6.3 9.608 6.66667 9.608H17.3333C17.7 9.608 18 9.248 18 8.808V8.8C18 8.36 17.7 8 17.3333 8Z"
              fill="#FD6D87"
            />
          </svg>
        </span>
        <span className="object-text">{(!object.description || object.description===undefined || object.description==='undefined')? t("Note") :object.description}</span>
      </div>

  );
}
