/*eslint no-unused-vars: 0*/

import FabricCanvasTool from "./fabrictool";
import { deleteIcon, lightIcon } from "./captureIcons";
const fabric = require("fabric").fabric;

let downCoords;
class Capture extends FabricCanvasTool {
  configureCanvas(props) {
    this.props = props;
    let canvas = this._canvas;
    canvas.isDrawingMode = false;
    canvas.selection = true;
    canvas.defaultCursor = "crosshair";
  }
  doMouseDown(o) {
    downCoords = o.pointer;
    if (this._canvas._iTextInstances) {
      this._canvas._iTextInstances.forEach(function (ob) {
        ob.exitEditing();
      });
    }
  }

  doMouseUp(o, imagePath) {
    let actualWidth = this._canvas.backgroundImage
      ? this._canvas.backgroundImage.getScaledWidth()
      : this._canvas.getWidth();
    let proportion =
    this._canvas.backgroundImage? this._canvas.backgroundImage.getOriginalSize().width / actualWidth:1;
    let proportionY =
    this._canvas.backgroundImage? this._canvas.backgroundImage.getOriginalSize().height /
      this._canvas.backgroundImage.getScaledHeight():1;
    if (
      Math.abs(o.pointer.x - downCoords.x) < 10 ||
      Math.abs(o.pointer.y - downCoords.y) < 10
    )
      return;
    let canvas = this._canvas;

    const iLeft = downCoords.x * proportion;
    const iTop = downCoords.y * proportion;
    const iWidth = (o.pointer.x - downCoords.x) * proportion;
    const iHeight = (o.pointer.y - downCoords.y) * proportionY;
    const cropX = downCoords.x * proportion;
    const cropY = downCoords.y * proportion;

    const newScaleX =
      iWidth / proportion / canvas.width > 1
        ? 1
        : 1 + (1 - iWidth / proportion / canvas.width);

    // console.log("newScaleX", newScaleX);


    let imgDelete = document.createElement("img");
    imgDelete.src = deleteIcon;
    let imgLight = document.createElement("img");
    imgLight.src = lightIcon;

    fabric.Object.prototype.transparentCorners = false;
    fabric.Object.prototype.cornerColor = "blue";
    fabric.Object.prototype.cornerStyle = "circle";
    const deleteObject = (eventData, target) => {
      let canvas = target.target.canvas;
      canvas.backgroundImage.set("opacity", 1);
      canvas.remove(target.target);
      this.props.toggleCapture(this.props.captured);
      this.props.changeSelectedToolFromOutside("select");
      this.props.showInteractiveObjects();
      canvas.requestRenderAll();
    };
    fabric.Object.prototype.controls.deleteControl = new fabric.Control({
      x: 0.5,
      y: -0.5,
      offsetY: 16,
      cursorStyle: "pointer",
      mouseUpHandler: deleteObject,
      render: renderIcon(imgDelete),
      cornerSize: 24,
    });

    fabric.Object.prototype.controls.light = new fabric.Control({
      x: 0.5,
      y: -0.5,
      offsetY: 96,
      cursorStyle: "pointer",
      mouseUpHandler: lightsOff,
      render: renderIcon(imgLight),
      cornerSize: 36,
    });

    function lightsOff(eventData, target) {
      let canvas = target.target.canvas;
      switch (canvas.backgroundImage.opacity) {
        case 0:
          if (canvas.backgroundColor === "") {
            canvas.backgroundImage.set("opacity", 1);
          } else {
            canvas.setBackgroundColor("");
          }
          break;
        case 0.5:
          canvas.setBackgroundColor("#fff");
          canvas.backgroundImage.set("opacity", 0);
          break;
        case 1:
          canvas.setBackgroundColor("");
          canvas.backgroundImage.set("opacity", 0.5);
          break;

        default:
          break;
      }

      canvas.requestRenderAll();
    }

    function renderIcon(icon) {
      return function renderIcon(ctx, left, top, styleOverride, fabricObject) {
        var size = this.cornerSize;
        ctx.save();
        ctx.translate(left, top);
        ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
        ctx.drawImage(icon, -size / 2, -size / 2, size, size);
        ctx.restore();
      };
    }
    fabric.Image.fromURL(
      imagePath,
      function (img) {
        img.scale(newScaleX / proportion).set({
          //+ 0.9 / biggerScale
          left: 10,
          top: 10,
          cropX,
          cropY,
          width: iWidth,
          height: iHeight,
          stroke: "green",
          strokeDashArray: [5, 5],
          strokeWidth: 3,
          isCapture: true,
        });
        canvas.add(img).setActiveObject(img);
        canvas.backgroundImage.set("opacity", 0.5);
        canvas.renderAll();
      },
      { crossOrigin: "anonymous" }
    );
  }
}

export default Capture;
