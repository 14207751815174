import React from "react";
import {
  HiXCircle,
  // HiOutlineSun,
  // HiOutlineTrash,
  // HiOutlineArrowsExpand,
  // HiLightBulb,
  BiTrash,
  // HiOutlineX,
} from "react-icons/bi";
import { useTranslation } from "react-i18next";
export default function RemoveButton(props) {
  const { t } = useTranslation();
  return (
    <>
      <button
        // disabled={props.isLoading}
        className={`button is-small modal-button`}
        onClick={props.onClickHandler}
        title={t("Remove Selection")}
      >
        <span className="icon is-small has-text-light">
          <BiTrash className="fas fa-2x" />
        </span>
      </button>
    </>
  );
}
