import React from "react";
// import { useTranslation } from "react-i18next";

export default function LoadingAnim(props) {
  // const { t } = useTranslation();
  return (
    <div className="preloader-circle is-loading-anim">
      <span></span>
      <span></span>
      <span></span>
      {/* <div className="preloader-text">~ {t("Loading")} ~</div> */}
    </div>
  );
}
